import { DatePicker } from "components";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import { api } from "services";

export default function Retention() {
  const [data, setData] = useState<any>(null);
  const [date, setDate] = useState<Date>(new Date());
  useEffect(() => {
    async function load() {
      let rs = await api.post("/operation/get-retention", {
        date: date.getTime(),
      });
      setData(rs);
    }
    load();
  }, [date]);
  return (
    <div>
      <div className="flex justify-between w-full">
        <p className="font-semibold text-2xl">Retention</p>
        <Form>
          <Form.Field>
            <DatePicker
              todayButton="Today"
              selected={date}
              onChange={(val: Date) => {
                if (!val) {
                  setDate(null);
                } else {
                  setDate(val);
                }
              }}
              dateFormat="yyyy/MM/dd HH:mm"
            />
          </Form.Field>
        </Form>
      </div>
      {data !== null && (
        <table className="table mt-6">
          <thead>
            <tr>
              <th>Date</th>
              {[...Array(15)].map((a: any, index: number) => (
                <th>{index}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row: any, rowIndex: number) => {
              return (
                <tr>
                  <td className="w-32">
                    {dayjs(date)
                      .add(rowIndex - data.length, "day")
                      .format("YYYY-MM-DD")}
                  </td>
                  {row.map((col: number, index: number) => {
                    if (index === 0) {
                      return (
                        <td
                          className="font-semibold"
                          style={{
                            width: 50,
                            border: "1px solid white",
                            textAlign: "center",
                            backgroundColor: `rgba(0, 149, 255, 1)`,
                          }}
                        >
                          {col}
                        </td>
                      );
                    } else {
                      return (
                        <td
                          className="font-semibold"
                          style={{
                            border: "1px solid white",
                            textAlign: "center",
                            width: 50,
                            backgroundColor: `rgba(0, 149, 255, ${(col / 100) * 0.8 + 0.2})`,
                          }}
                        >
                          {col || 0}%
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}
