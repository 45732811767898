import dayjs from "dayjs";
import BasicChart from "./basic-chart";

export default function UserOnlineChart() {
  return (
    <BasicChart
      url="/operation/get-user-online-report"
      chartType="line"
      source="backend"
      showTimeSelect={true}
      value={{
        group: "minute",
        dateFrom: dayjs().add(-2, "hour").toDate(),
        dateTo: dayjs().toDate(),
      }}
    />
  );
}
