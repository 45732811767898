/** @format */

import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
//@ts-ignore
import { api, utils } from "services";
import dayjs from "dayjs";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Select,
  Statistic,
} from "semantic-ui-react";
import { DatePicker } from "components";
import _ from "lodash";
import BasicChart from "../chart/basic-chart";
import LockItemChart from "../chart/lock";
import UserRegisterChart from "../chart/register";
import LoginChart from "../chart/login";
import MinerChart from "./miner-chart";
import { Link } from "react-router-dom";
import MiniChart from "./mini-chart";
const RESOURCES = ["MAG", "MSTR", "FOOD"];
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);
export default function Home() {
  const [total, setTotal] = useState(null);
  const [showUserRegister, setShowUserRegister] = useState<boolean>(false);
  const [showLock, setShowLock] = useState<boolean>(false);
  const [userBehavior, setUserBehavior] = useState<boolean>(false);
  useEffect(() => {
    async function loadData() {
      let rs = await api.post("/operation/get-realtime-statistic", {});
      setTotal(rs);
    }
    loadData();
  }, []);
  return (
    <div>
      <Modal
        open={showUserRegister}
        closeIcon
        onClose={() => {
          setShowUserRegister(false);
        }}
      >
        <Modal.Content>
          <div className="w-full flex gap-2">
            <div className="w-2/3">
              <UserRegisterChart />
            </div>
            <div className="w-1/3">
              <BasicChart url="/operation/get-fame-report" chartType="pie" />
            </div>
          </div>
          <div className="w-full flex gap-2 mt-2">
            <div className="w-1/3">
              <BasicChart
                url="/operation/get-user-group-report"
                chartType="pie"
              />
            </div>
            <div className="w-2/3">
              <UserRegisterChart />
            </div>
          </div>
        </Modal.Content>
      </Modal>
      {total !== null && (
        <>
          <div className="w-full flex flex-wrap">
            <div className="p-2 w-1/3">
              <Link to="/chart/useronline">
                <Card fluid>
                  <Card.Content>
                    <div className="flex justify-between  text-xl font-semibold">
                      <p>CCU Now</p>
                      <p className="text-right">
                        {utils.intToString(total?.customerOnlineCount)}
                        <p className="text-sm font-normal text-gray-500">
                          [current customer online]
                        </p>
                      </p>
                    </div>
                  </Card.Content>
                  <Card.Content>
                    <MiniChart
                      url="/operation/get-user-online-report"
                      source="backend"
                      dateFrom={dayjs().add(-2, "hour").toDate()}
                      dateTo={dayjs().toDate()}
                    />
                  </Card.Content>
                </Card>
              </Link>
            </div>
            <div className="p-2  w-1/3">
              <Link to="/chart/register">
                <Card fluid>
                  <Card.Content>
                    <div className="flex justify-between  text-xl font-semibold">
                      <p>Total Register</p>
                      <p className="text-right">
                        {utils.intToString(total?.customerRegisterCount)}
                        <p className="text-sm font-normal text-gray-500">
                          [total user register]
                        </p>
                      </p>
                    </div>
                  </Card.Content>
                  <Card.Content>
                    <MiniChart
                      input={{ type: 3 }}
                      url="/operation/get-register-user-report"
                      dateFrom={dayjs().add(-7, "day").startOf("day").toDate()}
                      dateTo={dayjs().add(-1, "day").endOf("day").toDate()}
                    />
                  </Card.Content>
                </Card>
              </Link>
            </div>

            <div className="p-2  w-1/3">
              <Link to="/chart/user-spend-mstr">
                <Card fluid>
                  <Card.Content>
                    <div className="flex justify-between  text-xl font-semibold">
                      <p>Total User spend MSTR</p>
                    </div>
                  </Card.Content>
                  <Card.Content>
                    <MiniChart
                      url="/operation/get-number-user-spend-mstr"
                      dateFrom={dayjs().add(-7, "day").startOf("day").toDate()}
                      dateTo={dayjs().add(-1, "day").endOf("day").toDate()}
                    />
                  </Card.Content>
                </Card>
              </Link>
            </div>

            <div className="p-2  w-1/3">
              <Link to="/chart/complete-daily-quest">
                <Card fluid>
                  <Card.Content>
                    <div className="flex justify-between  text-xl font-semibold">
                      <p>Total User Completed Daily Quest</p>
                    </div>
                  </Card.Content>
                  <Card.Content>
                    <MiniChart
                      url="/operation/get-complete-daily-quest"
                      dateFrom={dayjs().add(-7, "day").startOf("day").toDate()}
                      dateTo={dayjs().add(-1, "day").endOf("day").toDate()}
                    />
                  </Card.Content>
                </Card>
              </Link>
            </div>

            <div className="p-2 w-1/3">
              <Link to="/chart/revenue">
                <Card fluid>
                  <Card.Content>
                    <div className="flex justify-between  text-xl font-semibold">
                      <p>Total Revenue</p>
                      <p className="text-right">
                        {utils.intToString(total?.revenue)}
                        <p className="text-sm font-normal text-gray-500">
                          [yesterday revenue mstr]
                        </p>
                      </p>
                    </div>
                  </Card.Content>
                  <Card.Content>
                    <MiniChart
                      url="/operation/get-token-spend-earn-report"
                      source="analytic"
                      input={{ resource: "mstr", totalOnly: true }}
                      dateFrom={dayjs().add(-7, "day").startOf("day").toDate()}
                      dateTo={dayjs().add(-1, "day").endOf("day").toDate()}
                    />
                  </Card.Content>
                </Card>
              </Link>
            </div>
            <div className="p-2 w-1/3">
              <Link to="/chart/arpu">
                <Card fluid>
                  <Card.Content>
                    <div className="flex justify-between  text-xl font-semibold">
                      <p>ARPU</p>
                      <p className="text-right">
                        {(total?.revenue / total?.paidUsers).toFixed(2)}/
                        {(total?.revenue / total?.login).toFixed(2)}
                        <p className="text-sm font-normal text-gray-500">
                          [7 days ARPPU/ARPU]
                        </p>
                      </p>
                    </div>
                  </Card.Content>
                  <Card.Content>
                    <MiniChart
                      url="/operation/get-arpu-report"
                      source="analytic"
                      input={{ resource: "mstr", totalOnly: true }}
                      dateFrom={dayjs().add(-7, "day").startOf("day").toDate()}
                      dateTo={dayjs().add(-1, "day").endOf("day").toDate()}
                    />
                  </Card.Content>
                </Card>
              </Link>
            </div>
            <div className="p-2  w-1/3">
              <Link to="/chart/adaptation">
                <Card fluid>
                  <Card.Content>
                    <div className="flex justify-between  text-xl font-semibold">
                      <p>DAO MSTR Pool</p>
                      <p className="text-right">
                        {utils.intToString(total?.adaptive)}
                        <p className="text-sm font-normal text-gray-500">
                          [current mstr pool]
                        </p>
                      </p>
                    </div>
                  </Card.Content>
                  <Card.Content>
                    <MiniChart
                      source="backend"
                      url="/operation/get-adaptation"
                      input={{}}
                      dateFrom={dayjs().add(-7, "day").startOf("day").toDate()}
                      dateTo={dayjs().add(-1, "day").endOf("day").toDate()}
                    />
                  </Card.Content>
                </Card>
              </Link>
            </div>
            <div className="p-2 w-1/3">
              <Link to="/chart/miner">
                <Card fluid>
                  <Card.Content>
                    <div className="flex justify-between  text-xl font-semibold">
                      <p>Spend and Earn</p>
                      <p className="text-right">
                        {utils.intToString(total?.magMiner)}
                        <p className="text-sm font-normal text-gray-500">
                          [miner]
                        </p>
                      </p>
                    </div>
                  </Card.Content>
                  <Card.Content>
                    <MiniChart
                      url="/operation/get-miner-report"
                      source="analytic"
                      input={{ type: "mag" }}
                      dateFrom={dayjs().add(-7, "day").startOf("day").toDate()}
                      dateTo={dayjs().add(-1, "day").endOf("day").toDate()}
                    />
                  </Card.Content>
                </Card>
              </Link>
            </div>
            <div className="p-2 w-1/3">
              <Link to="/chart/network">
                <Card fluid>
                  <Card.Content>
                    <div className="flex justify-between  text-xl font-semibold">
                      <p>Network</p>
                      <p className="text-right">
                        {utils.intToString(total?.timeoutPerUser)}{" "}
                        <p className="text-sm font-normal text-gray-500">
                          [timeout per user]
                        </p>
                      </p>
                    </div>
                  </Card.Content>
                  <Card.Content>
                    <MiniChart
                      url="/operation/get-network-report"
                      source="analytic"
                      input={{ groupBy: "date" }}
                      dateFrom={dayjs().add(-7, "day").startOf("day").toDate()}
                      dateTo={dayjs().add(-1, "day").endOf("day").toDate()}
                    />
                  </Card.Content>
                </Card>
              </Link>
            </div>

            <div className="p-2 w-1/3">
              <Link to="/chart/login">
                <Card fluid>
                  <Card.Content>
                    <div className="flex justify-between  text-xl font-semibold">
                      <p>Active User</p>
                      <p className="text-right">
                        {utils.intToString(total?.login)}
                        <p className="text-sm font-normal text-gray-500">
                          [yesterday user login]
                        </p>
                      </p>
                    </div>
                  </Card.Content>
                  <Card.Content>
                    <MiniChart
                      url="/operation/get-login-report"
                      input={{ totalOnly: true }}
                      source="analytic"
                      dateFrom={dayjs().add(-7, "day").startOf("day").toDate()}
                      dateTo={dayjs().add(-1, "day").endOf("day").toDate()}
                    />
                  </Card.Content>
                </Card>
              </Link>
            </div>

            <div className="p-2 w-1/3">
              <Link to="/chart/marketplace">
                <Card fluid>
                  <Card.Content>
                    <div className="flex justify-between  text-xl font-semibold">
                      <p>Market Place vol 24h</p>
                      <p className="text-right">
                        {utils.intToString(total?.rps)}
                        <p className="text-sm font-normal text-gray-500">
                          [volume]
                        </p>
                      </p>
                    </div>
                  </Card.Content>
                  <Card.Content>
                    <MiniChart
                      url="/operation/get-market-place"
                      input={{}}
                      dateFrom={dayjs().add(-7, "day").startOf("day").toDate()}
                      dateTo={dayjs().add(-1, "day").endOf("day").toDate()}
                    />
                  </Card.Content>
                </Card>
              </Link>
            </div>
          </div>

          <div className="p-2  w-1/3">
            <Link to="/chart/token-balance">
              <Card fluid>
                <Card.Content>
                  <div className="flex justify-between  text-xl font-semibold">
                    <p>Token balance tracking</p>
                  </div>
                </Card.Content>
                <Card.Content>
                  <MiniChart
                    source="backend"
                    url="/operation/get-token-balance-report"
                    input={{}}
                    dateFrom={dayjs().add(-7, "day").startOf("day").toDate()}
                    dateTo={dayjs().add(-1, "day").endOf("day").toDate()}
                  />
                </Card.Content>
              </Card>
            </Link>
          </div>

          <div className="flex">
            <div className="p-2 w-1/3">
              <Card fluid>
                <Card.Content>
                  <div className="flex justify-between  text-xl font-semibold">
                    <p>Hot Wallet Balance</p>
                  </div>
                </Card.Content>
                <Card.Content>
                  <p>
                    [BSC] User Queue/Total: {total.bscQueue}/
                    {Math.round(Number(total.adminBalanceBSC))}
                  </p>
                  <p>
                    [TERRA] User Queue/Total: {total.terraQueue}/
                    {Math.round(Number(total.adminBalanceTerra))}
                  </p>
                </Card.Content>
              </Card>
            </div>
            <div className="p-2 w-1/3">
              <Card fluid>
                <Card.Content>
                  <div className="flex justify-between  text-xl font-semibold">
                    <p>Retention</p>
                  </div>
                </Card.Content>
                <Card.Content>
                  <p className="text-blue-700 font-semibold">
                    D1: {total.retention?.d1.toFixed(2)}%
                  </p>
                  <p className="text-blue-700 font-semibold">
                    D3: {total.retention?.d3.toFixed(2)}%
                  </p>
                  <p className="text-blue-700 font-semibold">
                    D7: {total.retention?.d7.toFixed(2)}%
                  </p>
                </Card.Content>
              </Card>
            </div>
            <div className="p-2 w-1/3">
              <Card fluid>
                <Card.Content>
                  <div className="flex justify-between  text-xl font-semibold">
                    <p>Support Ticket</p>
                  </div>
                </Card.Content>
                <Card.Content>
                  <p className="text-blue-700 font-semibold">
                    Total: {total.ticket?.all}
                  </p>
                  <p className="text-green-600 font-semibold">
                    Resolved: {total.ticket?.resolved}
                  </p>
                </Card.Content>
              </Card>
            </div>
            <div className="p-2  w-1/3">
              <Link to="/chart/c2e-report">
                <Card fluid>
                  <Card.Content>
                    <div className="flex justify-between  text-xl font-semibold">
                      <p>Click To Earn Report</p>
                    </div>
                  </Card.Content>
                  <Card.Content>
                    <MiniChart
                      source="backend"
                      url="/operation/get-get-clicktoearn-report"
                      input={{}}
                      dateFrom={dayjs().add(-7, "day").startOf("day").toDate()}
                      dateTo={dayjs().add(-1, "day").endOf("day").toDate()}
                    />
                  </Card.Content>
                </Card>
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
