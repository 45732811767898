import { useTranslation } from "react-i18next";
import { Button, Card, Input, Select } from "semantic-ui-react";

export default function BetQuestion({
  value,
  onChange,
  viewOnly,
}: {
  viewOnly?: boolean;
  value: any;
  onChange: Function;
}) {
  const { t } = useTranslation();
  function handleChange(index: number, field: string, val: any) {
    let tmp = Object.assign([], value);
    if (!tmp[index]) {
      tmp[index] = {};
    }
    //@ts-ignore
    tmp[index][field] = val;
    onChange(tmp);
  }
  function removeReward(index: number) {
    let tmp = [...value];
    tmp.splice(index, 1);
    onChange(tmp);
  }
  function addquestionData() {
    let tmp = Object.assign([], value);
    tmp.push({
      question_text: "",
      answers: [],
      result: -1,
    });
    onChange(tmp);
  }
  function addAnswer(index: number) {
    let tmp = Object.assign([], value);
    tmp[index].answers.push("");
    onChange(tmp);
  }
  function removeAnswer(index: number) {
    let tmp = Object.assign([], value);
    tmp[index].answers.splice(index, 1);
    onChange(tmp);
  }
  function handleChangeAnswer(
    index: number,
    field: string,
    val: any,
    ind: number
  ) {
    let tmp = Object.assign([], value);
    tmp[index].answers[ind] = val;
    onChange(tmp);
  }
  return (
    <div>
      {value.map((questionData: any, index: number) => {
        return (
          <div key={index} className="mt-5">
            <Card fluid className="mt-4" key={index}>
              <Card.Content>
                <div>
                  <label className="font-bold">Question</label>
                  <Input
                    fluid
                    type="text"
                    value={questionData.question_text}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(index, "question_text", evt.target.value);
                    }}
                  />
                </div>
                <div className="mt-5">
                  <label className="font-bold">Result</label>
                  <Select
                    placeholder={"Result"}
                    fluid
                    multiple={false}
                    value={questionData?.result}
                    options={questionData?.answers.map(
                      (i: string, index: number) => {
                        return { text: i, value: index };
                      }
                    )}
                    onChange={(evt: any, val: any) => {
                      handleChange(index, "result", val.value);
                    }}
                  />
                </div>
                <div className="mt-5">
                  <label className="font-bold">Answers</label>
                  {value &&
                    questionData?.answers.map((answer: string, ind: number) => (
                      <div key={`ind_${ind}`} className="mt-3 flex gap-4">
                        <div className="ml-5 w-[100px] mt-5">Option {ind + 1}</div>
                        <Input
                          fluid
                          className="mt-3"
                          type="text"
                          value={answer}
                          onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChangeAnswer(
                              index,
                              "answers",
                              evt.target.value,
                              ind
                            );
                          }}
                        />
                        {!viewOnly && (
                          <div className="mt-5">
                            <Button
                              icon="times"
                              color="red"
                              onClick={() => {
                                removeAnswer(index);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  <div className="mt-5 flex gap-3 justify-between">
                    <Button
                      icon="plus"
                      content="Add Option"
                      labelPosition="left"
                      onClick={() => addAnswer(index)}
                    />
                    {!viewOnly && (
                      <Button
                        icon="times"
                        color="red"
                        onClick={() => {
                          removeReward(index);
                        }}
                      >Remove QUESTION !</Button>
                    )}
                  </div>
                </div>
              </Card.Content>
            </Card>
          </div>
        );
      })}
      <div className="mt-2">
        {!viewOnly && (
          <Button
            icon="plus"
            content="Add"
            labelPosition="left"
            onClick={addquestionData}
          />
        )}
      </div>
    </div>
  );
}
