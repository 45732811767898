import { useEffect, useState } from "react";
import startGame, { queryImage } from "../gamefix/game";

function Mongen({ dna, lock, style }) {
  const [img, setImg] = useState(null);
  useEffect(() => {
    startGame();
  }, []);

  useEffect(() => {
    queryImage(dna, (img) => {
      setImg(img);
    });
    return () => {};
  }, [JSON.stringify(dna), lock]);
  console.log({img})

  return img === null ? (
    <div></div>
  ) : (
    <>
    <img
      className="max-h-full mx-auto"
      style={{ style }}
      src={img}
      alt="mongen"
    />
    </>
  );
}

export default function StaticAnim({ dna, lock }) {
  if (dna.length === 2) {
    return <></>;
  } else {
    return <Mongen lock={lock} dna={dna} />;
  }
}
