import { useEffect, useState } from "react";
import { Tabs, Tab, Form, Button, Card } from "react-bootstrap";
import { Select } from "semantic-ui-react";
import api from "../services/api";
import Config from "./Config";
import StaticAnim from "dna/staticanim";
import dataServices from "services/data";
import { Enum } from "components";
const WIDTH = 5,
  HEIGHT = 6;

const DNAS = [
  [
    [
      [0, 0, 1],
      [0, 0, 1],
      [0, 0, 1],
      [0, 0, 1],
      [0, 0, 1],
      [0, 0, 1],
      [0, 0, 1],
      [0, 0, 1],
      [0, 0, 1],
      [0],
    ],
    [
      [0, 1, 1],
      [0, 1, 1],
      [0, 1, 1],
      [0, 1, 1],
      [0, 1, 1],
      [0, 1, 1],
      [0, 1, 1],
      [0, 1, 1],
      [0, 1, 1],
      [1],
    ],
    [
      [0, 2, 1],
      [0, 2, 1],
      [0, 2, 1],
      [0, 2, 1],
      [0, 2, 1],
      [0, 2, 1],
      [0, 2, 1],
      [0, 2, 1],
      [0, 2, 1],
      [2],
    ],
    [
      [0, 3, 1],
      [0, 3, 1],
      [0, 3, 1],
      [0, 3, 1],
      [0, 3, 1],
      [0, 3, 1],
      [0, 3, 1],
      [0, 3, 1],
      [0, 3, 1],
      [3],
    ],
    [
      [0, 4, 1],
      [0, 4, 1],
      [0, 4, 1],
      [0, 4, 1],
      [0, 4, 1],
      [0, 4, 1],
      [0, 4, 1],
      [0, 4, 1],
      [0, 4, 1],
      [4],
    ],
  ],
  [
    [
      [1, 0, 1],
      [1, 0, 1],
      [1, 0, 1],
      [1, 0, 1],
      [1, 0, 1],
      [1, 0, 1],
      [1, 0, 1],
      [1, 0, 1],
      [1, 0, 1],
      [0],
    ],
    [
      [1, 1, 1],
      [1, 1, 1],
      [1, 1, 1],
      [1, 1, 1],
      [1, 1, 1],
      [1, 1, 1],
      [1, 1, 1],
      [1, 1, 1],
      [1, 1, 1],
      [1],
    ],
    [
      [1, 2, 1],
      [1, 2, 1],
      [1, 2, 1],
      [1, 2, 1],
      [1, 2, 1],
      [1, 2, 1],
      [1, 2, 1],
      [1, 2, 1],
      [1, 2, 1],
      [2],
    ],
    [
      [1, 3, 1],
      [1, 3, 1],
      [1, 3, 1],
      [1, 3, 1],
      [1, 3, 1],
      [1, 3, 1],
      [1, 3, 1],
      [1, 3, 1],
      [1, 3, 1],
      [3],
    ],
    [
      [1, 4, 1],
      [1, 4, 1],
      [1, 4, 1],
      [1, 4, 1],
      [1, 4, 1],
      [1, 4, 1],
      [1, 4, 1],
      [1, 4, 1],
      [1, 4, 1],
      [4],
    ],
  ],
  [
    [
      [2, 0, 1],
      [2, 0, 1],
      [2, 0, 1],
      [2, 0, 1],
      [2, 0, 1],
      [2, 0, 1],
      [2, 0, 1],
      [2, 0, 1],
      [2, 0, 1],
      [0],
    ],
    [
      [2, 1, 1],
      [2, 1, 1],
      [2, 1, 1],
      [2, 1, 1],
      [2, 1, 1],
      [2, 1, 1],
      [2, 1, 1],
      [2, 1, 1],
      [2, 1, 1],
      [1],
    ],
    [
      [2, 2, 1],
      [2, 2, 1],
      [2, 2, 1],
      [2, 2, 1],
      [2, 2, 1],
      [2, 2, 1],
      [2, 2, 1],
      [2, 2, 1],
      [2, 2, 1],
      [2],
    ],
    [
      [2, 3, 1],
      [2, 3, 1],
      [2, 3, 1],
      [2, 3, 1],
      [2, 3, 1],
      [2, 3, 1],
      [2, 3, 1],
      [2, 3, 1],
      [2, 3, 1],
      [3],
    ],
    [
      [2, 4, 1],
      [2, 4, 1],
      [2, 4, 1],
      [2, 4, 1],
      [2, 4, 1],
      [2, 4, 1],
      [2, 4, 1],
      [2, 4, 1],
      [2, 4, 1],
      [4],
    ],
  ],
  [
    [
      [3, 0, 1],
      [3, 0, 1],
      [3, 0, 1],
      [3, 0, 1],
      [3, 0, 1],
      [3, 0, 1],
      [3, 0, 1],
      [3, 0, 1],
      [3, 0, 1],
      [0],
    ],
    [
      [3, 1, 1],
      [3, 1, 1],
      [3, 1, 1],
      [3, 1, 1],
      [3, 1, 1],
      [3, 1, 1],
      [3, 1, 1],
      [3, 1, 1],
      [3, 1, 1],
      [1],
    ],
    [
      [3, 2, 1],
      [3, 2, 1],
      [3, 2, 1],
      [3, 2, 1],
      [3, 2, 1],
      [3, 2, 1],
      [3, 2, 1],
      [3, 2, 1],
      [3, 2, 1],
      [2],
    ],
    [
      [3, 3, 1],
      [3, 3, 1],
      [3, 3, 1],
      [3, 3, 1],
      [3, 3, 1],
      [3, 3, 1],
      [3, 3, 1],
      [3, 3, 1],
      [3, 3, 1],
      [3],
    ],
    [
      [3, 4, 1],
      [3, 4, 1],
      [3, 4, 1],
      [3, 4, 1],
      [3, 4, 1],
      [3, 4, 1],
      [3, 4, 1],
      [3, 4, 1],
      [3, 4, 1],
      [4],
    ],
  ],
  [
    [
      [4, 0, 1],
      [4, 0, 1],
      [4, 0, 1],
      [4, 0, 1],
      [4, 0, 1],
      [4, 0, 1],
      [4, 0, 1],
      [4, 0, 1],
      [4, 0, 1],
      [0],
    ],
    [
      [4, 1, 1],
      [4, 1, 1],
      [4, 1, 1],
      [4, 1, 1],
      [4, 1, 1],
      [4, 1, 1],
      [4, 1, 1],
      [4, 1, 1],
      [4, 1, 1],
      [1],
    ],
    [
      [4, 2, 1],
      [4, 2, 1],
      [4, 2, 1],
      [4, 2, 1],
      [4, 2, 1],
      [4, 2, 1],
      [4, 2, 1],
      [4, 2, 1],
      [4, 2, 1],
      [2],
    ],
    [
      [4, 3, 1],
      [4, 3, 1],
      [4, 3, 1],
      [4, 3, 1],
      [4, 3, 1],
      [4, 3, 1],
      [4, 3, 1],
      [4, 3, 1],
      [4, 3, 1],
      [3],
    ],
    [
      [4, 4, 1],
      [4, 4, 1],
      [4, 4, 1],
      [4, 4, 1],
      [4, 4, 1],
      [4, 4, 1],
      [4, 4, 1],
      [4, 4, 1],
      [4, 4, 1],
      [4],
    ],
  ],
];

export default function ArenaBot() {
  const [mongenStrategy, setMongenStrategy] = useState(
    Array.from(Array(3).keys()).map((item) => {
      return Array.from(Array(30).keys()).map((i) => -1);
    })
  );
  const [stage, setStage] = useState(null);
  const [stageId, setStageId] = useState(0);
  const [skills, setSkills] = useState([]);
  const [runewords, setRunewords] = useState([]);
  const [botLeague, setBotLeague] = useState(0);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [entryType, setEntryType] = useState(null);
  const [mongenTeams, setMongenTeams] = useState([[], [], []]);
  const [selectMongen, setSelectMongen] = useState(-1);
  const [selectCellStrategy, setSelectCellStrategy] = useState(-1);
  const [isStrategyEditMode, setIsStrategyEditMode] = useState(false);
  const [selectTeam, setSelectTeam] = useState(0);
  async function createStage() {
    let rs = await api.battlePost("/arena-bot/create-blank-arena-bot", {});
    progressStageInfo(rs);
    alert(`create new stage id=${rs.id}`);
  }
  useEffect(() => {
    async function loadSkill() {
      let rs = await api.battlePost("/level-design/get-all-skills", {});
      setSkills(rs);
    }
    async function loadRuneWord() {
      let rs = await api.battlePost("/arena-bot/get-runeword", {});
      setRunewords(rs);
    }
    loadSkill();
    loadRuneWord();
  }, []);
  function progressStageInfo(stage: {
    mongen_teams: any;
    mongen_strategies: any[];
    bot_league: any;
  }) {
    let tmpMongenTeam = stage.mongen_teams;
    if (!tmpMongenTeam || !tmpMongenTeam.length) {
      tmpMongenTeam = [[], [], []];
    }
    let tmpMongenStrategy = stage.mongen_strategies;
    if (!tmpMongenStrategy || !tmpMongenStrategy.length) {
      tmpMongenStrategy = Array.from(Array(3).keys()).map((item) => {
        return Array.from(Array(30).keys()).map((i) => -1);
      });
    }
    setMongenTeams(tmpMongenTeam);
    setMongenStrategy(tmpMongenStrategy);
    setStage(stage);
  }
  async function cloneStage() {
    let rs = await api.battlePost("/arena-bot/clone-arena-bot", {
      stage_id: stage.id,
    });
    alert(`clone success stage=${rs.id}`);
    progressStageInfo(rs);
  }
  async function saveMap() {
    console.log({ mongenTeams, mongenStrategy, botLeague });
    if (!stage) {
      return alert("stage is not loaded");
    }
    try {
      await api.battlePost("/arena-bot/edit-arena-bot", {
        stage_id: stage.id,
        bot_league: botLeague,
        mongen_teams: mongenTeams,
        mongen_strategies: mongenStrategy,
        current_team: currentTeam,
        entry_type: entryType,
      });
      alert("save success");
    } catch (error) {
      console.error(error);
      alert("save fail");
    }
  }
  async function loadStage() {
    if (!stageId) {
      return;
    }
    try {
      let rs = await api.battlePost("/arena-bot/get-arena-bot-by-id", {
        stage_id: stageId,
      });
      progressStageInfo(rs);
      if (rs.bot_league >= 0) setBotLeague(rs.bot_league);
      if (rs.current_team >= 0) setCurrentTeam(rs.current_team);
      if (rs.entry_type >= 0) setEntryType(rs.entry_type);
      alert("load success");
    } catch (error: any) {
      console.log(error);
      alert(error.message);
    }
  }
  return (
    <div className="bootstrap-iso">
      <div className="row">
        <div className="col-6 p-2">
          <Card>
            <Card.Header>
              <Button
                variant="primary"
                onClick={() => {
                  setIsStrategyEditMode(!isStrategyEditMode);
                  setSelectCellStrategy(-1);
                }}
              >
                {isStrategyEditMode ? "Done" : "Edit Strategy"}
              </Button>
            </Card.Header>
            <Card.Body
              style={{ overflow: "auto", height: "calc(100vh - 130px)" }}
            >
              {Array.from(Array(HEIGHT).keys()).map((row, indexRow) => {
                return (
                  <div style={{ width: WIDTH * 130, height: 130 }}>
                    {Array.from(Array(WIDTH).keys()).map((col, indexCol) => {
                      return (
                        <div
                          className="map-tile position-relative !w-[130px] !h-[130px]"
                          key={`element_${indexCol}`}
                          onClick={() => {
                            if (
                              selectCellStrategy !== -1 &&
                              isStrategyEditMode &&
                              selectTeam !== -1
                            ) {
                              let tmp =
                                mongenStrategy[selectTeam][selectCellStrategy];
                              mongenStrategy[selectTeam][selectCellStrategy] =
                                mongenStrategy[selectTeam][
                                  WIDTH * (WIDTH - indexCol - 1) +
                                    indexRow +
                                    (WIDTH - indexCol - 1)
                                ];
                              mongenStrategy[selectTeam][
                                WIDTH * (WIDTH - indexCol - 1) +
                                  indexRow +
                                  (WIDTH - indexCol - 1)
                              ] = tmp;
                              setSelectCellStrategy(-1);
                            } else {
                              setSelectCellStrategy(
                                WIDTH * (WIDTH - indexCol - 1) +
                                  indexRow +
                                  (WIDTH - indexCol - 1)
                              );
                            }
                          }}
                          style={{
                            border:
                              selectCellStrategy ===
                              WIDTH * (WIDTH - indexCol - 1) +
                                indexRow +
                                (WIDTH - indexCol - 1)
                                ? "1px solid rgb(54, 7, 226)"
                                : "",
                          }}
                        >
                          {selectTeam !== -1 &&
                            mongenStrategy[selectTeam][
                              WIDTH * (WIDTH - indexCol - 1) +
                                indexRow +
                                (WIDTH - indexCol - 1)
                            ] >= 0 && (
                              <div className="absolute">
                                <b>
                                  #
                                  {
                                    mongenStrategy[selectTeam][
                                      WIDTH * (WIDTH - indexCol - 1) +
                                        indexRow +
                                        (WIDTH - indexCol - 1)
                                    ]
                                  }
                                </b>
                                <StaticAnim
                                  dna={
                                    DNAS[
                                      mongenTeams[selectTeam][
                                        mongenStrategy[selectTeam][
                                          WIDTH * (WIDTH - indexCol - 1) +
                                            indexRow +
                                            (WIDTH - indexCol - 1)
                                        ]
                                      ].race
                                    ][
                                      mongenTeams[selectTeam][
                                        mongenStrategy[selectTeam][
                                          WIDTH * (WIDTH - indexCol - 1) +
                                            indexRow +
                                            (WIDTH - indexCol - 1)
                                        ]
                                      ].rarity
                                    ]
                                  }
                                  lock={[]}
                                />
                              </div>
                            )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Card.Body>
          </Card>
        </div>
        <div className="col-6 p-2">
          <Card>
            <Card.Header as="h5">Properties</Card.Header>
            <Card.Body>
              <div className="position-relative">
                <Tabs
                  defaultActiveKey="stage"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={(val) => {
                    if (val.includes("mongen")) {
                      const team = val.split("_")[1];
                      setSelectTeam(Number(team));
                    }
                    setIsStrategyEditMode(false);
                    setSelectCellStrategy(-1);
                    setSelectMongen(-1);
                  }}
                >
                  <Tab eventKey="stage" title="Stage Info">
                    <div
                      style={{
                        height: "calc(100vh - 170px)",
                        overflowY: "auto",
                      }}
                      className="position-relative"
                    >
                      <Card>
                        <Card.Header>Stage Info</Card.Header>
                        <Card.Body>
                          <div className="d-flex">
                            <p className="d-flex align-items-center me-2">
                              <label className="me-2">Id:</label>{" "}
                              <input
                                value={stageId}
                                type="text"
                                className="form-control"
                                onChange={(evt) => {
                                  setStageId(Number(evt.target.value));
                                }}
                              />
                            </p>
                            <Button
                              variant="primary"
                              className="me-2"
                              onClick={loadStage}
                            >
                              Load
                            </Button>
                            <Button
                              variant="success"
                              className="me-2"
                              onClick={createStage}
                            >
                              Create new
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                      <Card className="mt-4">
                        <Card.Header>League</Card.Header>
                        <Card.Body>
                          <div className="d-flex">
                            <p className="d-flex align-items-center me-2">
                              <label className="me-2">league:</label>{" "}
                              <Form.Select
                                value={botLeague}
                                onChange={(evt: { target: { value: any } }) => {
                                  setBotLeague(Number(evt.target.value));
                                }}
                              >
                                <option value={0}>Select league</option>
                                {Object.keys(Config.League).map((i: string) => (
                                  // @ts-ignore
                                  <option value={Config.League[i]}>{i}</option>
                                ))}
                              </Form.Select>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                      <Card className="mt-4">
                        <Card.Header>Select Team</Card.Header>
                        <Card.Body>
                          <div className="d-flex">
                            <p className="d-flex align-items-center me-2">
                              <label className="me-2">team:</label>{" "}
                              <Form.Select
                                value={currentTeam}
                                onChange={(evt: { target: { value: any } }) => {
                                  setCurrentTeam(evt.target.value);
                                }}
                              >
                                <option value={null}>Select team</option>
                                <option value={0}>Team 0</option>
                                <option value={1}>Team 1</option>
                                <option value={2}>Team 2</option>
                              </Form.Select>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                      <Card className="mt-4">
                        <Card.Header>Select Entry Cost</Card.Header>
                        <Card.Body>
                          <Enum
                            placeholder="Entry Cost Type"
                            enumName="customer-resources"
                            value={entryType}
                            onChange={(val) => {
                              setEntryType(Number(val));
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                  {mongenTeams.map((mongens, indexTeam) => {
                    return (
                      <Tab
                        eventKey={`mongen_${indexTeam}`}
                        title={`Team ${indexTeam}`}
                      >
                        <div
                          style={{
                            height: "calc(100vh - 170px)",
                            overflowY: "auto",
                          }}
                          className="position-relative"
                        >
                          <Card className="mt-4 pb-10">
                            <Card.Header>Mongen List</Card.Header>
                            <Card.Body>
                              {mongens.map((mongen, index) => {
                                return (
                                  <div
                                    key={`mongen_${index}`}
                                    className="!w-[100px] !h-[100px] map-tile"
                                    onClick={() => {
                                      setSelectMongen(index);
                                      if (
                                        selectCellStrategy !== -1 &&
                                        isStrategyEditMode
                                      ) {
                                        let tmp = [...mongenStrategy];
                                        tmp[indexTeam] = tmp[indexTeam].map(
                                          (i) => {
                                            if (i === index) return -1;
                                            return i;
                                          }
                                        );
                                        tmp[indexTeam][selectCellStrategy] =
                                          index;
                                        setMongenStrategy(tmp);
                                        setSelectCellStrategy(-1);
                                      }
                                    }}
                                    style={{
                                      border:
                                        selectMongen === index
                                          ? "1px solid rgb(54, 7, 226)"
                                          : "",
                                    }}
                                  >
                                    <b>#{index}</b>
                                    <StaticAnim
                                      dna={DNAS[mongen.race][mongen.rarity]}
                                      lock={[]}
                                    />
                                  </div>
                                );
                              })}
                            </Card.Body>
                            <Card.Body>
                              <Button
                                variant="success"
                                className="mt-2 "
                                onClick={() => {
                                  let tmp = [...mongens];
                                  tmp.push({
                                    race: 0,
                                    rarity: 0,
                                    basic: 1,
                                    ultimate: 2,
                                    level: 1,
                                    evolution: 1,
                                    stat: 100,
                                    dmg: 100,
                                    strategy: [
                                      -1, -1, -1, -1, -1, -1, -1, -1, -1,
                                    ],
                                    troop_list: [
                                      { id: 1, quant: 0 },
                                      { id: 1, quant: 0 },
                                      { id: 1, quant: 0 },
                                    ],
                                  });
                                  mongenTeams[indexTeam] = tmp;
                                  setMongenTeams(
                                    JSON.parse(JSON.stringify(mongenTeams))
                                  );
                                  setSelectMongen(tmp.length - 1);
                                }}
                              >
                                Add Mongen
                              </Button>
                            </Card.Body>
                            <Card.Body>
                              {mongens[selectMongen] && (
                                <MongenSetting
                                  name={selectMongen}
                                  mongen={mongens[selectMongen]}
                                  skills={skills}
                                  runewords={runewords}
                                  onUpdate={(key: string, val: any) => {
                                    let tmp = [...mongens];
                                    tmp[selectMongen][key] = val;
                                    mongenTeams[indexTeam] = tmp;
                                    setMongenTeams(
                                      JSON.parse(JSON.stringify(mongenTeams))
                                    );
                                  }}
                                  onDelete={() => {
                                    let tmp = [...mongens];
                                    tmp.splice(selectMongen, 1);
                                    mongenTeams[indexTeam] = tmp;
                                    setMongenTeams(
                                      JSON.parse(JSON.stringify(mongenTeams))
                                    );
                                    setSelectMongen(-1);
                                  }}
                                />
                              )}
                            </Card.Body>
                          </Card>
                        </div>
                      </Tab>
                    );
                  })}
                </Tabs>
                <div className="position-absolute bottom-0 left-0 w-100">
                  <div className="d-flex gap-4">
                    <Button
                      variant="info"
                      className="w-100 mt-3"
                      onClick={cloneStage}
                    >
                      Clone
                    </Button>
                    <Button
                      variant="primary"
                      className="w-100 mt-3"
                      onClick={saveMap}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}
const MongenSetting = ({
  mongen,
  skills,
  onDelete,
  onUpdate,
  name,
  runewords,
}: any) => {
  return (
    <Card className="mt-2">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <b>#{name}</b>
          <Button variant="danger" onClick={onDelete}>
            Delete
          </Button>
        </div>
        <div className="d-flex mt-2 w-100">
          <div className="me-2 w-100">
            <Form.Select
              value={mongen.race}
              onChange={(evt: { target: { value: any } }) => {
                onUpdate("race", evt.target.value);
              }}
            >
              <option value={-1}>Select mongen race</option>
              {Config.Race.map((i, index) => (
                <option value={index}>{i}</option>
              ))}
            </Form.Select>
            <Form.Select
              value={mongen.rarity}
              className="mt-2"
              onChange={(evt: { target: { value: any } }) => {
                onUpdate("rarity", evt.target.value);
              }}
            >
              <option value={-1}>Select plot rarity</option>
              {Config.Rarity.map((i, index) => (
                <option value={index}>{i}</option>
              ))}
            </Form.Select>
          </div>
          <div className="w-100">
            <Form.Select
              value={mongen.basic}
              className=""
              onChange={(evt: { target: { value: any } }) => {
                onUpdate("basic", evt.target.value);
              }}
            >
              <option value={-1}>Select basic skill</option>
              {skills
                .filter((i: { speed: number }) => i.speed > 0)
                .map((i: any, index: any) => (
                  <option value={i.id}>{i.name}</option>
                ))}
            </Form.Select>
            <Form.Select
              value={mongen.ultimate}
              className="mt-2"
              onChange={(evt: { target: { value: any } }) => {
                onUpdate("ultimate", evt.target.value);
              }}
            >
              <option value={-1}>Select ultimate skill</option>
              {skills
                .filter((i: { speed: any }) => !i.speed)
                .map((i: any, index: any) => (
                  <option value={i.id}>{i.name}</option>
                ))}
            </Form.Select>
          </div>
        </div>
        <div className="d-flex mt-2">
          <div className="me-2 w-100">
            <div className="d-flex align-items-center">
              <label className="me-2">LVL</label>
              <input
                type="text"
                className="form-control"
                value={mongen.level}
                onChange={(evt) => {
                  onUpdate("level", Number(evt.target.value));
                }}
              />
            </div>
            <div className="d-flex mt-2 align-items-center">
              <label className="me-2">EVO</label>
              <input
                type="text"
                className="form-control"
                value={mongen.evolution}
                onChange={(evt) => {
                  onUpdate("evolution", Number(evt.target.value));
                }}
              />
            </div>
          </div>
          <div className="w-100">
            <div className="d-flex align-items-center">
              <label className="me-2">STAT</label>
              <input
                type="text"
                className="form-control"
                value={mongen.stat}
                onChange={(evt) => {
                  onUpdate("stat", Number(evt.target.value));
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="mb-3">
            <b>RuneWord</b>
          </div>
          {mongen.runewords?.map((runeword: any, indexRuneWord: number) => {
            return (
              <div className="flex">
                <div className="w-1/2">
                  <label>Type</label>
                  <Form.Select
                    value={runeword.code}
                    onChange={(evt: { target: { value: any } }) => {
                      let tmp: any = [];
                      if (mongen.runewords) {
                        tmp = [...mongen.runewords];
                      }
                      tmp[indexRuneWord].code = evt.target.value;
                      onUpdate("runewords", tmp);
                    }}
                  >
                    <option value={-1}>Select RuneWord</option>
                    {runewords.map((i: any) => (
                      <option value={i.code}>{i.name}</option>
                    ))}
                  </Form.Select>
                </div>
                <div className="w-1/2">
                  <label>Power</label>
                  <input
                    type="text"
                    className="form-control"
                    value={runeword.power}
                    onChange={(evt) => {
                      let tmp: any = [];
                      if (mongen.runewords) {
                        tmp = [...mongen.runewords];
                      }
                      tmp[indexRuneWord].power = Number(evt.target.value);
                      onUpdate("runewords", tmp);
                    }}
                  />
                </div>
                <div>
                  <label>&nbsp;</label>
                  <div>
                    <Button variant="danger" onClick={onDelete}>
                      X
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
          <Button
            variant="success"
            className="mt-3 "
            onClick={() => {
              let tmp: any = [];
              if (mongen.runewords) {
                tmp = [...mongen.runewords];
              }
              tmp.push({
                code: -1,
                power: 0,
              });
              onUpdate("runewords", tmp);
            }}
          >
            Add RuneWord
          </Button>
        </div>
        <div className="mt-2">
          <div className="mb-3">
            <b>Troops</b>
          </div>
          <div className="flex">
            {[0, 1, 2].map((idx: number) => {
              return (
                <div style={{ marginRight: 10 }}>
                  <Select
                    value={mongen.troop_list?.[idx]?.id}
                    style={{ width: 100 }}
                    onChange={(evt: any, val: any) => {
                      let tmp = [
                        { id: 1, quant: 0 },
                        { id: 1, quant: 0 },
                        { id: 1, quant: 0 },
                      ];
                      if (mongen.troop_list) {
                        tmp = [...mongen.troop_list];
                      }
                      tmp[idx] = { id: val.value, quant: 0 };
                      console.log({ tmp });
                      onUpdate("troop_list", tmp);
                    }}
                    options={dataServices.getAllTroop().map((troop) => {
                      return {
                        value: troop?.id,
                        text: (
                          <div className="w-full">
                            <img
                              src={`/assets/Troop/${troop?.code}.webp`}
                              alt="troop"
                              className="w-14 h-14"
                            />
                            <div className="text-center">{troop?.code}</div>
                          </div>
                        ),
                      };
                    })}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-2">
          <div className="mb-3">
            <b>Strategy</b>
          </div>
          {[0, 1, 2].map((index) => {
            if (index === 1)
              return (
                <div className="w-60">
                  <StaticAnim
                    dna={DNAS[mongen.race][mongen.rarity]}
                    lock={[]}
                  />
                </div>
              );
            return (
              <div className="flex">
                {[2, 1, 0].map((idx: number) => {
                  return (
                    <div
                      className="!w-20 !h-20 relative cursor-pointer map-tile"
                      onClick={() => {
                        let stra = [
                          [0, 3, 6],
                          [1, 4, 7],
                          [2, 5, 8],
                        ][index][idx];
                        let tmp = [-1, -1, -1, -1, -1, -1, -1, -1, -1];
                        if (mongen.strategy) {
                          tmp = [...mongen.strategy];
                        }
                        if (tmp[stra] === -1) {
                          tmp[stra] = idx;
                        } else {
                          tmp[stra] = -1;
                        }
                        onUpdate("strategy", tmp);
                      }}
                    >
                      <img
                        src={`/assets/Troop/${
                          dataServices
                            .getAllTroop()
                            .find(
                              (troop) => troop.id === mongen.troop_list[idx].id
                            ).code
                        }.webp`}
                        alt="troop"
                        className="w-20 h-20"
                      />
                      {mongen.strategy[
                        [
                          [0, 3, 6],
                          [1, 4, 7],
                          [2, 5, 8],
                        ][index][idx]
                      ] === -1 && (
                        <div className="absolute w-20 h-20 bg-[#00000045] top-0"></div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </Card.Body>
    </Card>
  );
};
