import { useEffect, useState } from "react";
import { Card, Header, Table } from "semantic-ui-react";
import { api } from "services";

export default function SupportStatistic() {
  let [report, setReport] = useState<any>(null);
  useEffect(() => {
    async function loadData() {
      let rs = await api.post("/operation/get-report-support-center", {});
      console.log({ rs });
      setReport(rs);
    }
    loadData();
  }, []);
  return (
    <div>
      <p className="text-3xl font-semibold">Support statistic</p>
      {report !== null && (
        <Card fluid>
          <Card.Content>
            <Header color="blue">Rate</Header>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>1 Star</Table.HeaderCell>
                  <Table.HeaderCell>2 Stars</Table.HeaderCell>
                  <Table.HeaderCell>3 Stars</Table.HeaderCell>
                  <Table.HeaderCell>4 Stars</Table.HeaderCell>
                  <Table.HeaderCell>5 Stars</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {report.rate.find((i: any) => i.star === 1)?.total || 0}
                  </Table.Cell>
                  <Table.Cell>
                    {report.rate.find((i: any) => i.star === 2)?.total || 0}
                  </Table.Cell>
                  <Table.Cell>
                    {report.rate.find((i: any) => i.star === 3)?.total || 0}
                  </Table.Cell>
                  <Table.Cell>
                    {report.rate.find((i: any) => i.star === 4)?.total || 0}
                  </Table.Cell>
                  <Table.Cell>
                    {report.rate.find((i: any) => i.star === 5)?.total || 0}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Header color="red">
              Unresolved Ticket. Total:{" "}
              {report.ticket
                .filter((i: any) => i.status === 0)
                .reduce((a: any, b: any) => a + Number(b.total || 0), 0)}
            </Header>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Priority</Table.HeaderCell>
                  <Table.HeaderCell>Total</Table.HeaderCell>
                  <Table.HeaderCell>Not response yet</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {[3, 2, 1, 0].map((priority: number) => (
                  <Table.Row>
                    <Table.Cell>{priority + 1}</Table.Cell>
                    <Table.Cell>
                      {report.ticket
                        .filter(
                          (i: any) => i.status === 0 && i.priority === priority
                        )
                        .reduce(
                          (a: any, b: any) => a + Number(b.total || 0),
                          0
                        )}
                    </Table.Cell>
                    <Table.Cell>
                      {report.ticket
                        .filter(
                          (i: any) =>
                            i.status === 0 &&
                            i.priority === priority &&
                            i.is_response === 0
                        )
                        .reduce(
                          (a: any, b: any) => a + Number(b.total || 0),
                          0
                        )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Header color="green">
              Resolve Ticket. Total:{" "}
              {report.ticket
                .filter((i: any) => i.status === 1)
                .reduce((a: any, b: any) => a + Number(b.total || 0), 0)}
            </Header>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Priority</Table.HeaderCell>
                  <Table.HeaderCell>Total</Table.HeaderCell>
                  <Table.HeaderCell>Not response yet</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {[3, 2, 1, 0].map((priority: number) => (
                  <Table.Row>
                    <Table.Cell>{priority + 1}</Table.Cell>
                    <Table.Cell>
                      {report.ticket
                        .filter(
                          (i: any) => i.status === 1 && i.priority === priority
                        )
                        .reduce(
                          (a: any, b: any) => a + Number(b.total || 0),
                          0
                        )}
                    </Table.Cell>
                    <Table.Cell>
                      {report.ticket
                        .filter(
                          (i: any) =>
                            i.status === 1 &&
                            i.priority === priority &&
                            i.is_response === 0
                        )
                        .reduce(
                          (a: any, b: any) => a + Number(b.total || 0),
                          0
                        )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Card.Content>
        </Card>
      )}
    </div>
  );
}
