import { useEffect, useState } from "react";
import { Button, Input, Select } from "semantic-ui-react";
import { api } from "services";

export default function BossMilestonesConfig({
  value,
  onChange,
}: {
  value: any;
  onChange: Function;
}) {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    async function loadSkill() {
      let rs = await api.battlePost("/level-design/get-all-skills", {});
      setSkills(rs);
    }
    loadSkill();
  }, []);

  function addMilestone() {
    let tmp: any[] = [...value];
    tmp.push({
      bs: null,
      lv: null,
      ma: null,
      agr: null,
      eng: null,
      evo: null,
      std: null,
      utl: null,
      multi: null,
      dmg_bonus: null,
      milestone: null,
    });
    onChange(tmp);
  }

  function removeMilestone(index: number) {
    let tmp: any[] = [...value];
    tmp.splice(index, 1);
    onChange(tmp);
  }

  function handleChange(index: number, name: string, val: any) {
    let tmp: any[] = [...value];
    //@ts-ignore
    tmp[index][name] = val;
    onChange(tmp);
  }
  return (
    <div>
      {value.map((i: any, index: number) => (
        <div
          key={index}
          className=" gap-2 justify-between border hover:border-gray-200 border-white p-1"
        >
          <span className="font-semibold mr-2 w-6">{index + 1}.</span>
          <div className="w-full flex gap-2 items-center flex-wrap">
            <div>
              <div>Basic skill</div>
              <Select
                value={i.bs}
                className=""
                placeholder={"Basic skill"}
                options={skills
                  .filter((i: { speed: number }) => i.speed > 0)
                  .map((i) => {
                    return { text: i.name, value: i.id };
                  })}
                onChange={(evt: any, val: any) => {
                  handleChange(index, "bs", val.value);
                }}
              />
            </div>
            <div>
              <div>Ultimate skill</div>
              <Select
                value={i.utl}
                placeholder={"Ultimate skill"}
                className=""
                options={skills
                  .filter((i: { speed: any }) => !i.speed)
                  .map((i) => {
                    return { text: i.name, value: i.id };
                  })}
                onChange={(evt: any, val: any) => {
                  handleChange(index, "utl", val.value);
                }}
              />
            </div>
            <div>
              <div>Level</div>
              <Input
                className="!w-32"
                value={i.lv || ""}
                placeholder="Level"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(index, "lv", Number(evt.target.value));
                }}
              />
            </div>
            <div>
              <div>Magic</div>
              <Input
                className="!w-32"
                value={i.ma || ""}
                placeholder="Magic"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(index, "ma", Number(evt.target.value));
                }}
              />
            </div>
            <div>
              <div>Aggresion</div>
              <Input
                className="!w-32"
                value={i.agr || ""}
                placeholder="Aggresion"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(index, "agr", Number(evt.target.value));
                }}
              />
            </div>
            <div>
              <div>Energy</div>
              <Input
                className="!w-32"
                value={i.eng || ""}
                placeholder="Energy"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(index, "eng", Number(evt.target.value));
                }}
              />
            </div>
            <div>
              <div>Evol</div>
              <Input
                className="!w-32"
                value={i.evo || ""}
                placeholder="Evol"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(index, "evo", Number(evt.target.value));
                }}
              />
            </div>
            <div>
              <div>Sturdiness</div>
              <Input
                className="!w-32"
                value={i.std || ""}
                placeholder="Sturdiness"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(index, "std", Number(evt.target.value));
                }}
              />
            </div>
            <div>
              <div>Multi</div>
              <Input
                className="!w-32"
                value={i.multi || ""}
                placeholder="Multi"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(index, "multi", Number(evt.target.value));
                }}
              />
            </div>
            <div>
              <div>Damage Bonus</div>
              <Input
                className="!w-32"
                value={i.dmg_bonus || ""}
                placeholder="Damage Bonus"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(index, "dmg_bonus", Number(evt.target.value));
                }}
              />
            </div>
            <div>
              <div>Milestone</div>
              <Input
                className="!w-32"
                value={i.milestone || ""}
                placeholder="Milestone"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(index, "milestone", Number(evt.target.value));
                }}
              />
            </div>
            <Button
              icon="times"
              color="red"
              className="!mt-6"
              onClick={() => {
                removeMilestone(index);
              }}
            />
          </div>
        </div>
      ))}
      <div className="mt-2">
        <Button
          icon="plus"
          content="Add"
          labelPosition="left"
          onClick={addMilestone}
        />
      </div>
    </div>
  );
}
