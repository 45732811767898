import { RewardEntity } from "interfaces";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Button, Card, Checkbox, Input } from "semantic-ui-react";
import Enum from "./enum";
import RewardEditor from "./reward-editor";
import dataService from "../services/data";
import { useState } from "react";

export default function BattlepassRewardTopPlayer({
  value,
  onChange,
  viewOnly,
  detail,
}: {
  viewOnly?: boolean;
  value: {
    topX: number;
    weights: number[];
    size: number;
  };
  onChange: Function;
  detail: any;
}) {
  const { t } = useTranslation();
  const [totalWeight, setTotalWeight] = useState(0);
  function handleChange(val: any, key: string, ind?: number) {
    console.log(detail);
    let tmp: {
      topX: number;
      weights: number[];
      size: number;
    } = { ...value };

    if (key !== "weights") {
      //@ts-ignore
      tmp[key] = val;
      if (!tmp.weights) {
        tmp.weights = [];
      }
      if (tmp.weights.length > tmp.topX) {
        tmp.weights = tmp.weights.slice(0, tmp.topX);
      } else {
        console.log(tmp.topX - tmp.weights.length);
        for (let i = tmp.topX - tmp.weights.length; i > 0; i--) {
          tmp.weights.push(0);
        }
      }
    } else {
      //@ts-ignore
      tmp[key][ind] = val;
    }
    setTotalWeight(tmp.weights.reduce((a, b) => a + b));
    onChange(tmp);
  }
  return (
    <div className="mb-10">
      <div>
        <div className="font-bold">Number Customer in Top</div>
        <Input
          className="w-20"
          value={value.topX}
          placeholder="Number"
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(Number(evt.target.value), "topX");
          }}
        />
      </div>
      <div className="mt-5">
        <div className="flex">
          <div className="w-1/3">
            <div className="font-bold">Size Pool {`(%)`}</div>
            <Input
              className="w-20"
              value={value.size}
              placeholder="Number"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(Number(evt.target.value), "size");
              }}
            />
          </div>
          <div className="w-1/3">
            <div className="font-bold">Total Pool {`(MSTR)`}</div>
            <Input
              className="w-20"
              value={detail.mstr_pool}
              placeholder="Number"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {}}
              disabled
            />
          </div>
          <div className="w-1/3">
            <div className="font-bold">Pool Value {`(MSTR)`}</div>
            <Input
              className="w-20"
              value={(detail.mstr_pool * value.size) / 100}
              placeholder="Number"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {}}
              disabled
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="font-bold">Rewards</div>
        {value?.weights?.map((reward: any, ind: number) => (
          <div className="mt-5 border-t pt-3">
            <div className="flex">
              <div className="w-1/3">
                <div className="text-base">Reward top {ind + 1}</div>
                <Input
                  className="w-20"
                  value={reward}
                  placeholder="Number"
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(Number(evt.target.value), "weights", ind);
                  }}
                />
              </div>
              <div className="w-1/3">
                <div className="text-base">Percent</div>
                <Input
                  className="w-20"
                  value={totalWeight ? (reward / totalWeight) * 100 : 0}
                  placeholder="Number"
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {}}
                  disabled
                />
              </div>
              <div className="w-1/3">
                <div className="text-base">Reward value</div>
                <Input
                  className="w-20"
                  value={
                    totalWeight && value.size
                      ? ((reward / totalWeight) *
                          (detail.mstr_pool * value.size)) /
                        100
                      : 0
                  }
                  placeholder="Number"
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {}}
                  disabled
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
