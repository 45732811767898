import { useEffect, useState } from "react";
import {
 Chart as ChartJS,
 CategoryScale,
 LinearScale,
 BarElement,
 Title,
 Tooltip,
 Legend,
 PointElement,
 LineElement,
 ArcElement,
} from "chart.js";
import { api } from "services";
import dayjs from "dayjs";
import {
 Button,
 Card,
 Form,
 Pagination,
 Select,
 Table,
} from "semantic-ui-react";
import { DatePicker } from "components";
import _ from "lodash";
import saveAs from "file-saver";
ChartJS.register(
 CategoryScale,
 LinearScale,
 BarElement,
 Title,
 Tooltip,
 Legend,
 PointElement,
 LineElement,
 ArcElement
);
export default function MagMstrReportTable() {
 const pageSize = 10;
 const orderData = [
  { amount: 1 },
  { amount: -1 },
  { createdAt: 1 },
  { createdAt: -1 },
 ];
 const [loading, setLoading] = useState<boolean>(false);
 const [earn, setEarn] = useState<{ options: any; data: any }>(null);
 const [columns, setColumns] = useState([]);
 const [order, setOrder] = useState(3);
 const [page, setPage] = useState(1);
 const [dateFrom, setDateFrom] = useState(
  dayjs().add(-1, "week").startOf("day").toDate()
 );
 const [dateTo, setDateTo] = useState(dayjs().add(1, "day").toDate());

 const download = async () => {
  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
  setLoading(true);
  const labels = earn?.data?.labels ? [earn?.data?.labels] : [[]];
  let result = labels;
  let i = 1;
  while (true) {
   let rs = await api.post("/operation/get-mag-mstr-report", {
    dateFrom: dateFrom,
    dateTo: dateTo,
    columns,
    page: i,
    groupBy: "date",
    order: orderData[order],
   });
   result = result.concat(rs?.data?.datasets ?? []);
   i++;
   if (i > Math.ceil(earn?.data?.total / pageSize)) break;
   await sleep(1000);
  }
  let text = result.map((s: string[]) => s.join(",")).join("\n");
  var blob = new Blob([text], {
   type: "text/plain;charset=utf-8;",
  });
  saveAs(blob, "report.csv");
  setLoading(false);
 };

 async function loadData() {
  setLoading(true);
  try {
   let rs = await api.post("/operation/get-mag-mstr-report", {
    dateFrom: dateFrom,
    dateTo: dateTo,
    columns,
    page,
    groupBy: "date",
    order: orderData[order],
   });
   setEarn(rs);
  } catch (error) {
  } finally {
   setLoading(false);
  }
 }
 useEffect(() => {
  loadData();
 }, [page]);
 return (
  <div>
   <Card fluid>
    {earn !== null && (
     <Card.Content>
      <Table>
       <Table.Header>
        {earn?.data?.labels.map((label: string) => (
         <Table.HeaderCell>{label}</Table.HeaderCell>
        ))}
       </Table.Header>
       <Table.Body>
        {earn?.data?.datasets.map((dataset: any) => {
         return (
          <Table.Row>
           {dataset.map((data: any) => (
            <Table.Cell>{data}</Table.Cell>
           ))}
          </Table.Row>
         );
        })}
       </Table.Body>
      </Table>
      <Pagination
       activePage={page}
       onPageChange={(page: any, rs: any) => {
        setPage(rs.activePage);
       }}
       size="mini"
       totalPages={Math.ceil(earn?.data?.total / pageSize)}
      />
     </Card.Content>
    )}
    <Card.Content>
     <Form className="flex gap-2 w-full">
      <Form.Field className="w-full">
       <label>Columns</label>
       <Select
        multiple
        placeholder={"Select columns"}
        fluid
        value={columns}
        options={[
         "Customer ID", // 0
         "Date", // 1
         "Earn Stake MAG", // 2
         "Spend Stake MAG", // 3
         "Earn MSTR", // 4
         "Spend MSTR", // 5
         "Withdraw MSTR", // 6
         "Deposit MSTR", // 7
         "Withdraw Stake MAG", // 8
         "Deposit Stake MAG", // 9
         "Earn Lock MAG", // 10
         "Spend Lock MAG", // 11
        ].map((i) => {
         return { text: i, value: i };
        })}
        onChange={(evt: any, val: any) => {
         setColumns(val.value);
        }}
       />
      </Form.Field>
      <Form.Field className="w-full">
       <label>Order By</label>
       <Select
        placeholder={"Select columns"}
        fluid
        value={order}
        options={[
         { text: "ASC Amount", value: 0 },
         { text: "DESC Amount", value: 1 },
         { text: "ASC Date", value: 2 },
         { text: "DESC Date", value: 3 },
        ].map((i) => {
         return i;
        })}
        onChange={(evt: any, val: any) => {
         setOrder(val.value);
        }}
       />
      </Form.Field>
      <Form.Field className="w-full">
       <label>From</label>
       <DatePicker
        todayButton="Today"
        selected={dateFrom}
        onChange={(val: Date) => {
         if (!val) {
          setDateFrom(null);
         } else {
          setDateFrom(dayjs(val).startOf("day").toDate());
         }
        }}
        dateFormat="yyyy/MM/dd HH:mm"
       />
      </Form.Field>
      <Form.Field className="w-full">
       <label>To</label>
       <DatePicker
        todayButton="Today"
        selected={dateTo}
        onChange={(val: Date) => {
         if (!val) {
          setDateTo(null);
         } else {
          setDateTo(dayjs(val).endOf("day").toDate());
         }
        }}
        dateFormat="yyyy/MM/dd HH:mm"
       />
      </Form.Field>
      <div>
       <Form.Field>
        <label>Action</label>
        <Button
         loading={loading}
         color="blue"
         icon="search"
         content="Search"
         labelPosition="left"
         onClick={() => (page != 1 ? setPage(1) : loadData())}
        />
       </Form.Field>
      </div>
      <div>
       <Form.Field>
        <Button
         style={{ marginTop: "15px" }}
         loading={loading}
         color="teal"
         icon="download"
         content="Download CSV"
         labelPosition="left"
         onClick={download}
        />
       </Form.Field>
      </div>
     </Form>
    </Card.Content>
   </Card>
  </div>
 );
}
