import { Enum } from "components";
import GameConstant from "config/game-constant";
import { BodyPart, MongenRace, Rarity } from "interfaces";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Button, Select } from "semantic-ui-react";
import dataServices from "services/data";
import getMongenStat from "./get-mongen-stat";
import Anim from "./anim";
import StaticAnim from "./staticanim";
import { canBeAncester, canInherit } from "services/utils";
const RACES = ["Beast", "Tectos", "Mythic", "Celest", "Chaos"];

const RARITIES = [
    {
        code: "D",
        name: "Common"
    },
    {
        code: "C",
        name: "Uncommon"
    },
    {
        code: "B",
        name: "Rare"
    },
    {
        code: "A",
        name: "Epic"
    },
    {
        code: "S",
        name: "Legendary"
    },
]

const BodyParts = [
    BodyPart.Form,
    BodyPart.Head,
    BodyPart.Eyes,
    BodyPart.Horns,
    BodyPart.Tail,
    BodyPart.Back,
    BodyPart.FrontLeg,
    BodyPart.BackLeg,
    BodyPart.Mouth,
]

function Part({ idx, onChange, value, dna, bodyPart }: any) {
    return (
        <div style={{ display: "flex" }}>
            <div style={{ marginRight: 10 }}>
                <p style={{ margin: 0 }}>Race</p>
                <Select
                    value={value[0]}
                    options={
                        RACES.filter((_, race) => {
                            if (idx === BodyPart.FrontLeg || idx === BodyPart.BackLeg) {
                                return dna[0][0] === race
                            }
                            return true;
                        }).map((race, index) => {
                            return {
                                value: index,
                                text: race
                            }
                        })
                    }
                    disabled={idx === 6 || idx === 7}
                    style={{ width: 100 }}
                    onChange={(evt: any, val: any) => {
                        let tmp = [...value];
                        tmp[0] = Number(val.value);
                        tmp[1] = Rarity.Common
                        tmp[2] = 1;
                        onChange(tmp);
                    }}
                />
            </div>
            <div style={{ marginRight: 10 }}>
                <p style={{ margin: 0 }}>Rarity</p>
                <Select
                    value={value[1]}
                    style={{ width: 100 }}
                    onChange={(evt: any, val: any) => {
                        let tmp = [...value];
                        tmp[1] = Number(val.value);
                        tmp[2] = 1;
                        onChange(tmp);
                    }}
                    options={
                        RARITIES.map((rarity, index) => {
                            return {
                                value: index,
                                text: rarity.name
                            }
                        })
                    }
                />
            </div >
            <div>
                <p style={{ margin: 0 }}>ID</p>
                <Select
                    value={value[2]}
                    style={{ width: 100 }}
                    onChange={(evt: any, val: any) => {
                        let tmp = [...value];
                        tmp[2] = Number(val.value);
                        onChange(tmp);
                    }}
                    options={getIdsList(bodyPart, value[0], value[1]).map((name) => {
                        return {
                            value: name,
                            text: name
                        }
                    })}
                />
            </div>
        </div >
    );
}

function getIdsList(part: number, race: number, rarity: number) {
    let nums = Array.from(range(1, GameConstant.MaxBodyPart[part][race][rarity], 1));
    nums = nums.filter((id) => {
        return canBeAncester(part, rarity, id) || canInherit(part, rarity, id);
    })

    return nums;
}

function DNAMaker() {
    const [dnaText, setDNAText] = useState("");
    const [mongenRarityComponent, setMongenRarityComponent] = useState(<></>);
    const [dna, setDna] = useState([
        [0, 0, 1],
        [1, 0, 1],
        [0, 0, 1],
        [0, 1, 1],
        [2, 0, 2],
        [1, 0, 1],
        [0, 0, 2],
        [0, 0, 2],
        [0, 0, 1],
        [0],
    ]);
    const [troopList, setTroopList] = useState([])

    const [mongenDiv, setMongenDiv] = useState(<></>)

    const allTroops: any[] = useMemo<any[]>(() => {
        let rs: any[] = dataServices.getAllTroop();
        return rs;
    }, []);

    const options: any[][] = useMemo<any[][]>(() => {
        if (!dna) {
            return [[], [], []]
        }
        let troopsRarity = [
            dna[BodyPart.Horns][1],
            dna[BodyPart.Tail][1],
            dna[BodyPart.Back][1],
        ],
            troopRace = [
                dna[BodyPart.Horns][0],
                dna[BodyPart.Tail][0],
                dna[BodyPart.Back][0],
            ],
            troopsPool: any[][] = [];

        for (let i = 0; i < troopsRarity.length; i++) {
            troopsPool[i] = [];
            allTroops.forEach((troop) => {
                let partStat = troop.part_stat;
                let weight = partStat[troopsRarity[i]][troopRace[i]];
                if (weight > 0) {
                    troopsPool[i].push({
                        troop,
                        weight,
                    });
                }
            });
        }
        let init = (troopsPool.map((troopPool) => {
            return troopPool[0]?.troop?.id
        }))
        setTroopList(init)
        return troopsPool;
    }, [dna, allTroops])


    const [isCopied, setIsCopied] = useState<any>({});

    const handleCopy = (type: string, value: any) => {
        navigator.clipboard.writeText(JSON.stringify(value));
        setIsCopied({
            ...isCopied,
            [type]: true
        });
        setTimeout(() => {
            setIsCopied({
                ...isCopied,
                [type]: false
            })
        }, 3000)
    }

    useMemo(() => {
        let { rarity } = getMongenStat(dna, 1, 1);
        let newDna = null;
        if (dna[BodyPart.Aura][0] !== rarity) {
            newDna = _.cloneDeep(dna)
            newDna[BodyPart.Aura][0] = rarity;
        }
        if (newDna) {
            console.log({ newDna })
            setDna(newDna)
        }
    }, [dna])

    useEffect(() => {
        setDNAText(JSON.stringify(dna));
        let { rarity } = getMongenStat(dna, 1, 1);
        setMongenRarityComponent(
            <div className="flex gap-2 justify-center mt-4">
                <div>Mongen Rarity: </div>
                {Rarity[rarity]}
            </div>
        )
    }, [dna]);


    return (
        <div tabIndex={0} style={{ display: "flex" }}>
            <div style={{ flexGrow: 0 }}>
                {BodyParts.map((bodyPart, index) => (
                    <div
                        style={{
                            width: "100%",
                            padding: 5,
                            display: "flex",
                            border: "1px solid #ddd",
                            marginTop: 10,
                        }}
                    >
                        <label style={{ marginRight: 15, width: 100 }}>
                            <b>{BodyPart[bodyPart]}</b>
                        </label>
                        <Part
                            idx={index}
                            value={dna[index]}
                            onChange={(val: any) => {
                                let tmp = [...dna];
                                tmp[index] = val;
                                if (index === BodyPart.Form) {
                                    let raceForm = tmp[index][0];
                                    tmp[BodyPart.FrontLeg][0] = raceForm;
                                    tmp[BodyPart.BackLeg][0] = raceForm;
                                }
                                setDna(tmp);
                            }}
                            bodyPart={bodyPart}
                            dna={dna}
                        />
                    </div>
                ))}
                <div
                    style={{
                        width: "100%",
                        padding: 5,
                        display: "flex",
                        border: "1px solid #ddd",
                        marginTop: 10,
                    }}
                >
                    <label style={{ marginRight: 15, width: 100 }}>
                        <b>Aura Mark</b>
                    </label>
                    {<Enum
                        placeholder="Mark"
                        enumName="mongen-mark"
                        value={dna[BodyPart.Aura][1] ?? 0}
                        onChange={(val) => {
                            if (Number(val)) {
                                let tmp = [...dna];
                                tmp[BodyPart.Aura][1] = Number(val)
                                setDna(tmp)
                            } else {
                                let tmp = [...dna];
                                tmp[BodyPart.Aura] = [tmp[BodyPart.Aura][0]]
                                setDna(tmp)
                            }
                        }}
                    />}
                </div>
            </div>
            <div style={{ width: "100%" }} className="ml-4 mt-3">
                <div style={{ border: "1px solid #cecece", padding: 5 }} className="flex gap-2">
                    <div className="flex w-full">
                        <input
                            type="textarea"
                            value={dnaText}
                            style={{ width: "100%" }}
                            onChange={(evt) => {
                                try {
                                    let value = evt.target.value;
                                    let d = JSON.parse(value);
                                    setDna(d);
                                } catch (error) {
                                    console.log("invalid dna");
                                }
                            }}
                        />
                    </div>

                    <div>
                        <Button
                            className="w-[120px]"
                            color="green"
                            onClick={
                                () => {
                                    handleCopy("dna", dna)
                                }
                            }> {!isCopied["dna"] ? "Copy DNA" : "Copied"}
                        </Button>
                    </div>

                </div>
                <div className="dna-edit">
                    <StaticAnim
                        dna={dna}
                        lock={[]}
                    />
                </div>
                {/* <Anim
                    dna={dna}
                    lock={[]}
                    width={500}
                    height={500}
                    setAnim={null}
                    anim={"Idle"}
                /> */}
                <div>{mongenRarityComponent}</div>

                <div className="mt-10">
                    Troops list:
                </div>
                <div className="grid grid-cols-3 gap-2 w-full">
                    {[0, 1, 2].map((idx: number) => {
                        return (
                            <div style={{ marginRight: 10 }}>
                                <Select
                                    value={troopList[idx]}
                                    style={{ width: 100 }}
                                    onChange={(evt: any, val: any) => {
                                        console.log(val.value)
                                        let tmp = [...troopList];
                                        tmp[idx] = val.value;
                                        setTroopList(tmp)
                                    }}
                                    options={
                                        options[idx].map((option) => {
                                            return {
                                                value: option?.troop?.id,
                                                text:
                                                    <div className="w-full">
                                                        <img src={`/assets/Troop/${option?.troop?.code}.webp`} alt="troop" className="w-14 h-14" />
                                                        <div className="text-center">
                                                            {option?.troop?.code}
                                                        </div>
                                                    </div>
                                            }
                                        })
                                    }
                                />
                            </div >
                        )
                    })}
                </div>
                <div className="w-full mx-auto mt-2">
                    <Button
                        className="w-[200px]"
                        color="green"
                        onClick={
                            () => {
                                handleCopy("troop_list", troopList.map((troopList: any) => {
                                    return {
                                        id: troopList,
                                        quant: 0,
                                    }
                                }))
                            }
                        }> {!isCopied["troop_list"] ? "Copy Troop List" : "Copied"}
                    </Button>
                </div>
            </div>
        </div>
    );
}

function* range(start: number, end: number, step: number) {
    while (start <= end) {
        yield start;
        start += step;
    }
}

export default DNAMaker;

