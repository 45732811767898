import { useEffect, useState } from "react";
import { Button, Select } from "semantic-ui-react";
import { api } from "services";

export default function DecorationPatterns({
  value,
  onChange,
}: {
  value: any;
  onChange: Function;
}) {
  const [decorationStatList, setDecorationStatList] = useState([]);

  const addPattern = () => {
    let tmp: any[] = [...value];
    tmp.push(-1);
    onChange(tmp);
  };

  const removePattern = (index: number) => {
    let tmp: any[] = [...value];
    tmp.splice(index, 1);
    onChange(tmp);
  };

  useEffect(() => {
    async function loadDecorationStat() {
      let rs = await api.post("/operation/get-all-decoration-stat", {});
      setDecorationStatList(rs);
    }
    loadDecorationStat();
  }, []);

  function handleChange(index: number, val: any) {
    let tmp: any[] = [...value];
    //@ts-ignore
    tmp[index] = val;
    onChange(tmp);
  }
  return (
    <div>
      <div>
        {value.map((value: any, index: number) => {
          return (
            <div className="flex gap-2  border hover:border-gray-200 border-white p-1">
              <Select
                value={value}
                className=""
                placeholder={"Pattern"}
                options={decorationStatList.map((i) => {
                  return { text: i.id_stat, value: i.id };
                })}
                onChange={(evt: any, val: any) => {
                  handleChange(index, val.value);
                }}
              />
              <Button
                icon="times"
                color="red"
                onClick={() => {
                  removePattern(index);
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="mt-2">
        <Button
          icon="plus"
          content="Add"
          labelPosition="left"
          onClick={addPattern}
        />
      </div>
    </div>
  );
}
