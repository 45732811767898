import { PlotType } from "interfaces";

const Config = {
  ApiHost: process.env.REACT_APP_API_HOST,
  BattleHost:
    process.env.REACT_APP_BATTLE_HOST || "http://battle-dev.monsterra.io",
  AuctionHost: process.env.REACT_APP_AUCTION_HOST,
  BridgeHost: process.env.REACT_APP_BRIDGE_HOST,
  NofiticationTime: 5000,
  PageSize: 10,
  TransitionDuration: 2000,
  TimeBlockDuration: 30, //minutes
  WorkStart: 17, //block
  WorkEnd: 43,
  DayOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  TimeSheetDuration: 7, //days
  CancelTime: 2, //hour
  EncruptedKey: process.env.REACT_APP_REQUEST_ENCRYPT_KEY,
  SubablePlotType: [
    PlotType.Barracks,
    PlotType.Bombard,
    PlotType.Tower,
    PlotType.Trap,
    PlotType.Camp,
  ]
};
export default Config;
