import { SetStateAction, useEffect, useState } from "react";
import { Tabs, Tab, Form, Button, Card } from "react-bootstrap";
import api from "../services/api";
import Config from "./Config";
const WIDTH = 20,
    HEIGHT = 20;
function getPlotType(type: number, rarity: number, race: number) {
    if (type === 1) {
        return Config.PlotTypes.find(
            (i) => i.type === type && i.rarity === rarity && i.race === race
        );
    } else {
        return Config.PlotTypes.find((i) => i.type === type && i.rarity === rarity);
    }
}
export default function MapDesign() {
    const [stage, setStage] = useState(null);
    const [tile, setTile] = useState(null);
    const [map, setMap] = useState([]);
    async function createStage() {
        progressStageInfo({
            plots: []
        });
    }
    function progressStageInfo(stage: { plots: any[]; }) {
        setMap(generateMap(stage));
        setStage(stage);
    }
    function generateMap(stage: { plots: any[]; }) {
        let mp: any[] = [];
        for (var i = 0; i < WIDTH; i++) {
            mp[i] = [];
            for (var j = 0; j < HEIGHT; j++) {
                mp[i].push({ i, j, x: j - WIDTH / 2, y: HEIGHT / 2 - i });
            }
        }
        stage.plots.forEach((p: { y: number; x: number; }) => {
            mp[HEIGHT / 2 - p.y][p.x + WIDTH / 2].plot = p;
        });
        return mp;
    }
    function getPlotHPStat(type: any, rarity: any | number, level: number) {
        switch (type) {
            case Config.PlotEnumType.LandCore:
                return Config.LandcoreStats[rarity][Config.LandcoreStatsList.HP][level - 1];
            case Config.PlotEnumType.Pasture:
                return Config.PastureStats[rarity][Config.PastureStatsList.HP][level - 1];
            case Config.PlotEnumType.Breeding:
                return Config.BreedingStats[rarity][Config.BreedingStatsList.HP][level - 1];
            case Config.PlotEnumType.Hatching:
                return Config.HatchingStats[rarity][Config.HatchingStatsList.HP][level - 1];
            case Config.PlotEnumType.Production:
                return Config.ProductionStats[rarity][Config.ProductionStatsList.HP][
                    level - 1
                ];
            case Config.PlotEnumType.Storage:
                return Config.StorageStats[rarity][Config.StorageStatsList.HP][level - 1];
            case Config.PlotEnumType.Camp:
                return Config.CampStats[rarity][Config.CampStatsList.HP][level - 1];
            case Config.PlotEnumType.Tower:
                return Config.TowerStats[rarity][Config.TowerStatsList.HP][level - 1];
            case Config.PlotEnumType.Bombard:
                return Config.BombardStats[rarity][Config.BombardStatsList.HP][level - 1];
            default:
                return 0;
        }
    }
    function copyMap() {
        if (!stage) {
            return alert("stage is not loaded");
        }
        try {
            let plots: any[] = [];
            map.forEach((i) => {
                i.forEach((j: { plot: any; }) => {
                    if (j.plot) {
                        plots.push(j.plot);
                    }
                });
            });
            navigator.clipboard.writeText(JSON.stringify(plots));
        } catch (error) {
            console.error(error);
            alert("save fail");
        }
    }
    function getMapData() {
        if (!stage) {
            return [];
        }
        try {
            let plots: any[] = [];
            map.forEach((i) => {
                i.forEach((j: { plot: any; }) => {
                    if (j.plot) {
                        plots.push(j.plot);
                    }
                });
            });
            return plots;
        } catch (error) {
            console.error(error);
            alert("save fail");
        }
    }
    return (
        <div className="bootstrap-iso">
            <div className="row">
                <div className="col-8 p-2">
                    <Card>
                        <Card.Header as="h5">Map</Card.Header>
                        <Card.Body
                            style={{ overflow: "auto", height: "calc(100vh - 130px)" }}
                        >
                            <Map
                                map={map}
                                onSelect={(tile: any) => {
                                    const defaultPlot = { food: 0, level: 0, race: "-1", rarity: "-1", type: "-1" }
                                    if (tile.plot) {
                                        tile = { ...tile, plot: { ...defaultPlot, ...tile.plot } }
                                    }
                                    setTile(tile)
                                }}
                            />
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-4 p-2">
                    <Card>
                        <Card.Header as="h5">Properties</Card.Header>
                        <Card.Body>
                            <div className="position-relative">
                                <PlotInspect
                                    tile={tile}
                                    onChange={(t: { i: string | number; j: string | number; }) => {
                                        let tmp = JSON.parse(JSON.stringify(map));
                                        tmp[t.i][t.j] = t;
                                        setTile(t);
                                        setMap(tmp);
                                    }}
                                />
                                <div className="position-absolute bottom-0 left-0 w-full">
                                    <div className="py-4 px-2 border-1 my-4 border-black">
                                        {JSON.stringify(getMapData())}
                                    </div>
                                    <div className="gap-2 grid grid-cols-2 w-full">
                                        <Button
                                            variant="success"
                                            className="w-full"
                                            onClick={createStage}
                                        >
                                            Create new
                                        </Button>
                                        <Button
                                            className="w-ful"
                                            variant="primary"
                                            onClick={() => {
                                                copyMap();
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}
function PlotInspect({ tile, onChange }: any) {
    return (
        <div className="pb-5">
            <div style={{ height: "calc(100vh - 270px)", overflowY: "auto" }}>
                {tile ? (
                    <div>
                        <p>
                            Map Position: x:{tile.x} y:{tile.y}
                        </p>
                        {tile.plot ? (
                            <div>
                                <Card className="mt-4">
                                    <Card.Header>
                                        <div className="d-flex justify-content-between">
                                            <p>Plot Info</p>
                                            <Button
                                                variant="danger"
                                                onClick={() => {
                                                    let tmp = { ...tile };
                                                    tmp.plot = null;
                                                    onChange(tmp);
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="align-items-center justify-content-between">
                                            <div className="me-2 grid grid-cols-2 gap-4">
                                                <Form.Select
                                                    value={tile?.plot?.type}
                                                    onChange={(evt: { target: { value: any; }; }) => {
                                                        let tmp = { ...tile };
                                                        tmp.plot.type = Number(evt.target.value);
                                                        onChange(tmp);
                                                    }}
                                                >
                                                    <option value={-1}>Select plot type</option>
                                                    {Config.PlotType.map((i, index) => (
                                                        <option value={index}>{i}</option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Select
                                                    value={tile?.plot?.race}
                                                    onChange={(evt: { target: { value: any; }; }) => {
                                                        let tmp = { ...tile };
                                                        tmp.plot.race = Number(evt.target.value);
                                                        onChange(tmp);
                                                    }}
                                                >
                                                    <option value={-1}>Select plot race</option>
                                                    {Config.Race.map((i, index) => (
                                                        <option value={index}>{i}</option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Select
                                                    value={tile?.plot?.rarity}
                                                    onChange={(evt: { target: { value: any; }; }) => {
                                                        let tmp = { ...tile };
                                                        tmp.plot.rarity = Number(evt.target.value);
                                                        onChange(tmp);
                                                    }}
                                                >
                                                    <option value={-1}>Select plot rarity</option>
                                                    {Config.Rarity.map((i, index) => (
                                                        <option value={index}>{i}</option>
                                                    ))}
                                                </Form.Select>
                                                <input
                                                    value={tile.plot.level}
                                                    type="text"
                                                    placeholder="Level"
                                                    className="form-control"
                                                    onChange={(evt) => {
                                                        let tmp = { ...tile };
                                                        tmp.plot.level = Number(evt.target.value);
                                                        onChange(tmp);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        ) : (
                            <div className="mt-2">
                                <p>No plot here, add new?</p>
                                <Button
                                    className="mt-2"
                                    variant="success"
                                    onClick={() => {
                                        let tmp = { ...tile };
                                        tmp.plot = {
                                            type: 0,
                                            race: 0,
                                            rarity: 0,
                                            x: tile.x,
                                            y: tile.y,
                                        };
                                        onChange(tmp);
                                    }}
                                >
                                    Add plot
                                </Button>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <p>Please select tile map</p>
                    </div>
                )}
            </div>
        </div>
    );
}

function Map({ map, onSelect }: any) {
    return (
        <div>
            {map.map((i: any[]) => {
                return (
                    <div style={{ width: WIDTH * 50, height: 50 }}>
                        {i.map((j: { plot: { type: any; rarity: any; race: any; }; }) => {
                            let plotType = null;
                            if (j.plot) {
                                plotType = getPlotType(j.plot.type, j.plot.rarity, j.plot.race);
                            }
                            return (
                                <div
                                    className="map-tile position-relative"
                                    onClick={() => {
                                        onSelect(j);
                                    }}
                                >
                                    {plotType && (
                                        <div>
                                            <img
                                                alt="grass"
                                                src={`/assets/grass.png`}
                                                style={{
                                                    position: "absolute",
                                                    zIndex: 1,
                                                    right: 0,
                                                    top: 0,
                                                    width: plotType.size[0] * 50,
                                                    height: plotType.size[1] * 50,
                                                    maxWidth: "unset"
                                                }}
                                            />
                                            <img
                                                alt="grass"
                                                src={`/assets/Map Icon/${plotType.img}`}
                                                style={{
                                                    position: "absolute",
                                                    zIndex: 2,
                                                    right: 0,
                                                    top: 0,
                                                    objectFit: "contain",
                                                    width: plotType.size[0] * 50,
                                                    height: plotType.size[1] * 50,
                                                    maxWidth: "unset"

                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
}
