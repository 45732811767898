import _ from "lodash";
import { useState } from "react";
import ReactJson from "react-json-view";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Pagination,
  Table,
} from "semantic-ui-react";
import { api, ui } from "services";

export default function WorldcupBetHistory() {
  const pageSize = 10;
  const defaultPage = {
    Champion: 1,
    Match: 1,
  };
  const [inventory, setInventory] = useState(null);
  const [id, setId] = useState("");
  const [detail, setDetail] = useState(null);
  const [page, setPage] = useState<any>(defaultPage);
  async function loadBetData(page: any) {
    let rs = await api.post("/operation/get-worldcup-bet-history", {
      customer_id:id,
      page,
    });
    if (!rs) return;
    if (!inventory) setInventory(rs);
    else {
      let tmp: any = {
        ...inventory,
      };
      rs.labelTable.map((key: any, idx: number) => {
        const index = tmp.labelTable.indexOf(key);
        tmp.dataDetails[index] = rs.dataDetails[idx];
        tmp.tables[index] = rs.tables[idx];
        tmp.total[key] = rs.total[key];
      });
      setInventory(tmp);
    }
  }

  return (
    <div>
      <Card fluid>
        <Card.Content>
          <div className="flex justify-between">
            <p>
              Customer Data Bet <b>#{id}</b>
            </p>
          </div>
        </Card.Content>
        <Card.Content>
          <Form>
            <div className="flex w-full gap-2">
              <Form.Field className="w-full">
                <label>Customer ID</label>
                <Input
                  value={id}
                  type="text"
                  fluid
                  onChange={(evt: any) => {
                    setId(evt.target.value);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Action</label>
                <Button
                  color="blue"
                  icon="search"
                  content="Search"
                  labelPosition="left"
                  onClick={() => loadBetData(defaultPage)}
                />
              </Form.Field>
            </div>
          </Form>
        </Card.Content>
      </Card>

      {inventory &&
        inventory.labelTable.map((label: any, index: any) => {
          return (
            <Card key={`table_${index}`} fluid>
              <Card.Content>{label}</Card.Content>
              <Card.Content>
                <Table>
                  <Table.Header>
                    {inventory.tables[index].labels.map((labelTable: any) => {
                      return <Table.HeaderCell>{labelTable}</Table.HeaderCell>;
                    })}
                    <Table.HeaderCell>Action</Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    {inventory.tables[index].data.map((dataset: any) => {
                      return (
                        <Table.Row>
                          {dataset.map((data: any) => (
                            <Table.Cell>{data}</Table.Cell>
                          ))}
                          <Table.Cell>
                            <Button
                              icon="eye"
                              color="blue"
                              onClick={() => {
                                setDetail(
                                  inventory.dataDetails[index][dataset[0]]
                                );
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
                <Pagination
                  activePage={page[label]}
                  onPageChange={(page: any, rs: any) => {
                    setPage({
                      ...page,
                      [label]: rs.activePage,
                    });
                    loadBetData({
                      [label]: rs.activePage,
                    });
                  }}
                  size="mini"
                  totalPages={
                    inventory?.total[label]
                      ? Math.ceil(inventory?.total[label] / pageSize)
                      : 0
                  }
                />
              </Card.Content>
            </Card>
          );
        })}
      <Modal
        open={detail !== null}
        closeIcon
        onClose={() => {
          setDetail(null);
        }}
      >
        <Modal.Content>
          <div className="w-full flex gap-2">
            <ReactJson
              src={detail}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              name="input"
            />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
}
