import { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { api } from "services";

export default function BossConditionSelect({
  value,
  onChange,
}: {
  value: any;
  onChange: Function;
}) {
  const [conditions, setConditions] = useState([]);

  useEffect(() => {
    async function loadCondition() {
      let rs = await api.post(
        "/operation/handle-battle-operation/get-boss-conditions",
        {}
      );
      setConditions(rs.data);
    }
    loadCondition();
  }, []);

  return (
    <div>
      <Dropdown
        placeholder="Skills"
        fluid
        multiple
        selection
        value={value}
        options={conditions.map((cond) => {
          return { key: cond.id, value: cond.id, text: cond.condition_id };
        }) ?? []}
        onChange={(evt: any, val: any) => {
          onChange(val.value);
        }}
      />
    </div>
  );
}
