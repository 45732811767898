import BasicChart from "./basic-chart";
import { Form, Input, Select, Button } from "semantic-ui-react";
import React, { useState } from "react";
import { api } from "services";
import { DatePicker } from "components";
import dayjs from "dayjs";

export default function TokenGeneratedChart() {
  const [source, setSource] = useState<string>();
  const [amount, setAmount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>("mstr");
  const [date, setDate] = useState(dayjs().add(1, "day").toDate());
  const onSubmit = async () => {
    if (!source || !amount) {
      return;
    }
    setLoading(true);
    try {
      let rs = await api.post("/operation/create-analytic-token-generated", {
        token,
        source,
        amount,
        date,
      });
    } catch (error) {
    } finally {
      setAmount(0);
      setSource("");
      setLoading(false);
    }
  };
  return (
    <div>
      <Form className="flex gap-2 w-full">
        <Form.Field className="w-1/3">
          <label>Token</label>
          <Select
            placeholder={"Select token"}
            fluid
            value={token}
            options={["mstr", "mag"].map((i) => {
              return { text: i, value: i };
            })}
            onChange={(evt: any, val: any) => {
              setToken(val.value);
            }}
          />
        </Form.Field>
        <Form.Field className="w-1/3">
          <label>Source</label>
          <Select
            placeholder={"Select source"}
            fluid
            value={source}
            options={["battlepass_pool", "refund_iap"].map((i) => {
              return { text: i, value: i };
            })}
            onChange={(evt: any, val: any) => {
              setSource(val.value);
            }}
          />
        </Form.Field>
        <Form.Field required={true} className="w-1/3">
          <label>Amount</label>
          <Input
            fluid
            placeholder={"Number"}
            type="number"
            min={0}
            value={amount}
            defaultValue={amount}
            onChange={(evt: any, val: any) => {
              setAmount(val.value);
            }}
          />
        </Form.Field>
        <Form.Field className="w-full">
          <label>Date</label>
          <DatePicker
            todayButton="Today"
            selected={date}
            showTimeSelect={true}
            onChange={(val: Date) => {
              if (!val) {
                setDate(null);
              } else {
                setDate(dayjs(val).toDate());
              }
            }}
            dateFormat="yyyy/MM/dd HH:mm"
          />
        </Form.Field>
        <Form.Field>
          <label>Action</label>
          <Button
            loading={loading}
            color="blue"
            icon="add"
            content="Create"
            labelPosition="left"
            onClick={onSubmit}
          />
        </Form.Field>
      </Form>
      <BasicChart
        url="/operation/get-analytic-token-generated"
        showResources
        chartType="line"
        showDetail
        value={{ totalOnly: true }}
        source="analytic"
      />
    </div>
  );
}
