/**
 * eslint-disable no-restricted-globals
 *
 * @format
 */

import ConstConfig from "config";
import { SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Icon,
  Input,
  Select,
  TextArea,
} from "semantic-ui-react";
import api from "../services/api";
import Config, { getSubType } from "./Config";
import crypto from "crypto";
import CheatToken from "./token";
import { BodyPart, MongenMorale, PlotType, Rarity } from "interfaces";
import { DatePicker, Enum } from "components";
import dayjs from "dayjs";
import dataServices from "services/data";
export default function Cheat() {
  return <></>;
}
