import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { api } from "services";
export default function UploadImage({
  onChange,
  children,
  setUploading,
}: {
  setUploading?: Function;
  onChange: Function;
  children: any;
}) {
  const onDrop = (acceptedFiles: any[]) => {
    if (!acceptedFiles.length) return;
    uploadAvatar(acceptedFiles[0]);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  async function uploadAvatar(image: any) {
    setUploading(true);
    let rs = await api.postFormData(`/support-ticket/admin-upload-image`, {
      image,
    });
    setUploading(false);
    onChange(rs.url);
  }
  return (
    <div className="relative cursor-pointer" {...getRootProps()}>
      <input {...getInputProps()} />
      <div>{children}</div>
    </div>
  );
}
