import { BossConditionType } from "interfaces";
import { Input } from "semantic-ui-react";
import Enum from "./enum";

export default function BossConditionConfig({
  value,
  onChange,
}: {
  value: any;
  onChange: Function;
}) {
  function handleChange(name: string, val: any) {
    let tmp: any = { ...value };
    //@ts-ignore
    tmp[name] = val;
    onChange(tmp);
  }
  const disableBossConditions: any = {
    race: ["lower", "higher"],
    effect: ["equal", "lower", "higher"],
  };
  return (
    <div className="w-full flex gap-2 items-center">
      <div className="w-96">
        <Enum
          placeholder="Target"
          enumName="boss-condition-trg"
          value={value.trg}
          onChange={(val) => {
            handleChange("trg", val);
          }}
        />
      </div>
      <div className="w-96">
        <Enum
          placeholder="Config Condition Type"
          enumName="boss-condition-type"
          value={value.type}
          onChange={(val) => {
            handleChange("type", val);
          }}
        />
      </div>
      {[
        BossConditionType.Rarity,
        BossConditionType.Race,
        BossConditionType.Effect,
      ].includes(value.type) && (
        <div className="w-96">
          <Enum
            placeholder="operator"
            enumName="boss-condition-comparison-operator"
            value={value.cond}
            onChange={(val) => {
              handleChange("cond", val);
            }}
            disabledValue={disableBossConditions[value.type] ?? []}
          />
        </div>
      )}
      {value.type === BossConditionType.Rarity && (
        <div className="w-96">
          <Enum
            placeholder="Rarity"
            enumName="rarity"
            value={value.val}
            onChange={(val) => {
              handleChange("val", val);
            }}
          />
        </div>
      )}
      {value.type === BossConditionType.Race && (
        <div className="w-96">
          <Enum
            placeholder="Race"
            enumName="mongen-race"
            value={value.val}
            onChange={(val) => {
              handleChange("val", val);
            }}
          />
        </div>
      )}
      {value.type === BossConditionType.Effect && (
        <div className="w-96">
          <Enum
            placeholder="Effect"
            enumName="effect-battle"
            value={value.val}
            onChange={(val) => {
              handleChange("val", val);
            }}
          />
        </div>
      )}
      {value.type === BossConditionType.EffectBonusDmg && (
        <div className="w-96">
          <Enum
            placeholder="Effect"
            enumName="effect-battle"
            value={value.eff}
            onChange={(val) => {
              handleChange("eff", val);
            }}
          />
        </div>
      )}
      {value.type === BossConditionType.Stat && (
        <div className="w-96">
          <Enum
            placeholder="Stat"
            enumName="mongen-stat"
            value={value.stat_tp}
            onChange={(val) => {
              handleChange("stat_tp", val);
            }}
          />
        </div>
      )}
      {[
        BossConditionType.EffectBonusDmg,
        BossConditionType.CriticalBonusDmg,
        BossConditionType.CourseExplode,
        BossConditionType.Stat,
      ].includes(value.type) && (
        <Input
          className="w-32"
          value={value.val || ""}
          placeholder="Value"
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            handleChange("val", Number(evt.target.value));
          }}
        />
      )}
      {[BossConditionType.Regen].includes(value.type) && (
        <Input
          className="w-32"
          value={value.cd || ""}
          placeholder="Cooldown"
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            handleChange("cd", Number(evt.target.value));
          }}
        />
      )}
      {[BossConditionType.Regen].includes(value.type) && (
        <Input
          className="w-32"
          value={value.val || ""}
          placeholder="Value"
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            handleChange("val", Number(evt.target.value));
          }}
        />
      )}
    </div>
  );
}
