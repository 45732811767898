import { AlertType, Chain, MarketTransactionStatus, NFTType } from "interfaces";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Pagination,
  Select,
  Table,
} from "semantic-ui-react";
import { api, ui } from "services";

export default function ReportMarketTransaction() {
  const [customer_id, setCustomerId] = useState(null);
  const [nft_type, setNFTType] = useState(null);
  const [data, setData] = useState<any>([])
  const [header, setHeaders] = useState<any>([])
  const [page, setPage] = useState<any>(1);
  const [total, setTotal] = useState<any>(1);
  const [status, setStatus] = useState<any>(null);
  const [popupTxHash, setPopupTxHash] = useState(0);
  const [txHash, setHash] = useState('');
  const pageSize = 50;

  async function loadData() {
    let query: any = {
      offset: Math.max(0, (page - 1) * pageSize),
      limit: pageSize,
    }

    if (status !== null) {
      query.status = status;
    }

    if (customer_id) {
      query.customer_id = customer_id;
    }

    if (nft_type !== null) {
      query.nft_type = nft_type;
    }

    let rs = await api.post("/operation/get-market-transaction", query);
    let headers = ["id", "customer_id", "status", "tx_hash", "chain", "token_id", "rent_fee", "order_price", "quantity", "nft_type"];
    setHeaders(headers)
    let data = rs.rows
    setTotal(rs.count ?? 0)
    if (!data) {
      setData([])
    } else {
      setData(data)
    }
  }

  const retryBurn = async (market_order_id: number) => {
    try {
      let rs = await api.post("/operation/retry-burn", {
        market_order_id,
      })
      ui.alert("Success", AlertType.Success)
      await loadData();
    } catch (err) {
      ui.alert("Failed", AlertType.Warning)
    }
  }

  const fixImportByHash = async (market_order_id: number, txHash: string) => {
    try {
      let rs = await api.post("/operation/fix-import-by-hash", {
        market_order_id,
        tx_hash: txHash
      })
      ui.alert("Success", AlertType.Success)
      setPopupTxHash(0)
      await loadData();
    } catch (err) {
      ui.alert("Failed", AlertType.Warning)
    }
  }

  useEffect(() => {
    loadData();
  }, [customer_id, status, page])

  return (
    <div>
      <Modal open={!!popupTxHash} onClose={() => setPopupTxHash(0)} size="large">
        <Modal.Header>Fix import by hash</Modal.Header>
        <Modal.Content>
          <Input
            label="txHash"
            className="form-control w-full"
            type="text"
            onChange={(evt: any) => {
              setHash(evt.target.value);
            }}
          />
          <div className="mt-4">
            <Button
              color="green"
              className="ui green button btn btn-primary btn-lg mt-4"
              onClick={async () => { await fixImportByHash(popupTxHash, txHash) }}
              disabled={!txHash}
            >
              Confirm
            </Button>
          </div>
        </Modal.Content>
      </Modal>
      <Card fluid>
        <Card.Content>
          <Form className="flex gap-2 w-full">
            <Form.Field className="w-full">
              <label>Customer id</label>
              <Input value={customer_id} onChange={(e, data) => {
                setCustomerId(Number(data.value))
              }} />
            </Form.Field>
            <Form.Field className="w-full">
              <label>Status</label>
              <Select
                placeholder={"Select status"}
                fluid
                value={status}
                options={[
                  { text: "Pending", value: MarketTransactionStatus.Pending },
                  { text: "Buy Success", value: MarketTransactionStatus.BuySuccess },
                  { text: "Buy Failed", value: MarketTransactionStatus.BuyFailed },
                  { text: "Import Success", value: MarketTransactionStatus.Success },
                  { text: "Refunded", value: MarketTransactionStatus.Refunded },
                  { text: "Import Failed", value: MarketTransactionStatus.ImportFailed },
                ].map((i) => {
                  return i;
                })}
                onChange={(evt: any, val: any) => {
                  setStatus(val.value);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Action</label>
              <Button
                color="blue"
                icon="search"
                content="Search"
                labelPosition="left"
                onClick={() => loadData()}
              />
            </Form.Field>
          </Form>
        </Card.Content>

        <Card.Content>
          <Table celled sortable size="small">
            <Table.Header>
              {header.map((labelTable: any) => {
                return <Table.HeaderCell>{labelTable}</Table.HeaderCell>;
              })}
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {data.map((i: any) => {
                return (
                  <Table.Row>
                    {header.map((key: string) => {
                      if (key === 'nft_type') {
                        return (
                          <Table.Cell>{NFTType[i[key]]}</Table.Cell>
                        )
                      }
                      if (key === 'status') {
                        return (
                          <Table.Cell>{MarketTransactionStatus[i[key]]}</Table.Cell>
                        )
                      }
                      if (key === 'chain') {
                        return (
                          <Table.Cell>{Chain[i[key]]}</Table.Cell>
                        )
                      }
                      return (
                        <Table.Cell>{i[key]}</Table.Cell>
                      )
                    })}
                    <Table.Cell>
                      <div className="flex gap-3">
                        {
                          i.status === MarketTransactionStatus.ImportFailed &&
                          <Button onClick={async () => {
                            await retryBurn(i.id)
                          }}>Retry burn</Button>
                        }
                        {
                          i.status === MarketTransactionStatus.ImportFailed &&
                          <Button
                            color="red"
                            onClick={async () => {
                              await setPopupTxHash(i.id)
                            }}>Fix by txHash</Button>
                        }
                      </div>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
          <Pagination
            activePage={page}
            onPageChange={(page: any, rs: any) => {
              setPage(rs.activePage);
            }}
            size="mini"
            totalPages={Math.ceil(total / pageSize)}
          />
        </Card.Content>
      </Card>
    </div>
  );
}
