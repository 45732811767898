import { DatePicker } from "components";
import dayjs from "dayjs";
import { MongenRace, PlotType, RewardEntity, RewardType } from "interfaces";
import { useEffect, useState } from "react";
import { Button, Card, Checkbox, Input, Select } from "semantic-ui-react";
import Entity from "./entity";
import Enum from "./enum";

export default function ReferralSeasonInfo({
  value,
  onChange,
  viewOnly,
}: {
  viewOnly?: boolean;
  value: {
    is_active: boolean;
    start: Date;
    end: Date;
    rewards: number[][]; //point - percent_cashback;
    index?: number;
  }[];
  onChange: Function;
}) {
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [rewards, setRewards] = useState([]);

  const ReferralEventMilestoneData = [
    [0, 10],
    [20000, 11],
    [60000, 12],
    [180000, 15],
    [500000, 20],
  ];

  useEffect(() => {
    if (!value || !value.length) onChange([null, null, null, null]);
    if (value.length < 4) {
      for (let i = 0; i <= 4 - value.length; i++) {
        value.push(null);
      }
      onChange(value);
    }
    setRewards(
      value?.map((season) => {
        if (!season) return null;
        if (season.rewards && season.rewards.length) {
          return season.rewards.length - 1;
        }
        return null;
      })
    );

    let end: Date = null;
    let start: Date = null;
    value.forEach((season) => {
      if (!season || !season.start || !season.end) return;
      if (!(end && season.end <= end)) {
        end = season.end;
      }
      if (!(start && season.start >= start)) {
        start = season.start;
      }
    });
    console.log({ start, end });
    setStart(start);
    setEnd(end);
  }, [value]);

  function handleChange(index: number, name: string, val: any) {
    let tmp: any[] = [...value];
    if (!tmp[index]) {
      tmp[index] = {
        is_active: false,
        start: null,
        end: null,
        rewards: [],
      };
    }
    //@ts-ignore
    tmp[index][name] = val;
    onChange(tmp);
  }
  return (
    <div>
      <div>
        <label className="font-bold">Start At</label>
        <DatePicker
          isClearable
          showTimeSelect
          selected={
            start && typeof start === "string" ? new Date(start) : start
          }
          onChange={(val: Date) => {}}
          disabled={true}
          dateFormat="yyyy/MM/dd HH:mm"
        />
      </div>
      <div className="mt-4">
        <label className="font-bold">End At</label>
        <DatePicker
          isClearable
          showTimeSelect
          selected={end && typeof end === "string" ? new Date(end) : end}
          onChange={(val: Date) => {}}
          disabled={true}
          dateFormat="yyyy/MM/dd HH:mm"
        />
      </div>
      {value.map((season: any, index: number) => {
        return (
          <Card fluid className="mt-4" key={index}>
            <Card.Content>
              <Card.Header>
                <div className="flex">
                  Season {index + 1}
                  <Checkbox
                    className="ml-5 z-0"
                    checked={(season && season.is_active) ?? false}
                    toggle
                    onChange={(evt: any, { checked }) => {
                      handleChange(index, "is_active", checked);
                    }}
                  />
                </div>
              </Card.Header>
            </Card.Content>
            <Card.Content>
              <div>
                <label className="font-bold">Start At</label>
                <DatePicker
                  isClearable
                  showTimeSelect
                  selected={
                    typeof season?.start === "string"
                      ? new Date(season?.start)
                      : season?.start
                  }
                  onChange={(val: Date) => {
                    if (!val) {
                      handleChange(index, "start", null);
                    } else {
                      handleChange(index, "start", dayjs(val).add(1, "second").toDate());
                    }
                  }}
                  dateFormat="yyyy/MM/dd HH:mm"
                />
              </div>
              <div className="mt-4">
                <label className="font-bold">End At</label>
                <DatePicker
                  isClearable
                  showTimeSelect
                  selected={
                    typeof season?.end === "string"
                      ? new Date(season?.end)
                      : season?.end
                  }
                  onChange={(val: Date) => {
                    if (!val) {
                      handleChange(index, "end", null);
                    } else {
                      handleChange(index, "end", dayjs(val).toDate());
                    }
                  }}
                  dateFormat="yyyy/MM/dd HH:mm"
                />
              </div>
              <div className="mt-4">
                <label className="font-bold">Rewards</label>
                <Select
                  placeholder={"Select rewards"}
                  fluid
                  value={rewards[index]}
                  options={[
                    { text: "Bronze", value: 1 },
                    { text: "Silver", value: 2 },
                    { text: "Golden", value: 3 },
                    { text: "Platium", value: 4 },
                  ]}
                  onChange={(evt: any, val: any) => {
                    const value = ReferralEventMilestoneData.splice(
                      0,
                      val.value + 1
                    );
                    handleChange(index, "rewards", value);
                  }}
                />
              </div>
            </Card.Content>
          </Card>
        );
      })}
    </div>
  );
}
