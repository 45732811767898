import { DatePicker, RewardEditor } from "components";
import dayjs from "dayjs";
import { MongenRace, PlotType, Rarity } from "interfaces";
import { SetStateAction, useEffect, useState } from "react";
import { Tabs, Tab, Form, Button, Card } from "react-bootstrap";
import { Button as SemanticButton } from "semantic-ui-react";
import api from "../services/api";
import Config, { getSubType, getPlotIcon } from "./Config";
const WIDTH = 20,
  HEIGHT = 20;
function getPlotType(type: number, rarity: number, race: number) {
  if (type === 1) {
    return Config.PlotTypes.find(
      (i) => i.type === type && i.rarity === rarity && i.race === race
    );
  } else {
    return Config.PlotTypes.find((i) => i.type === type && i.rarity === rarity);
  }
}
export default function LevelDesign({ type }: { type: string }) {
  const [rewards, setRewards] = useState([]);
  const [stage, setStage] = useState(null);
  const [tile, setTile] = useState(null);
  const [stageId, setStageId] = useState(0);
  const [map, setMap] = useState([]);
  const [skills, setSkills] = useState([]);
  const [totalHp, setTotalHp] = useState(0);
  const [totalFood, setTotalFood] = useState(0);
  const [botLeague, setBotLeague] = useState(0);
  const [totalLootableFood, setTotalLootableFood] = useState(0);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [totalHpMap, setTotalHpMap] = useState(0);
  const [lastHitReward, setLastHitReward] = useState([]);
  async function createStage() {
    let rs = await api.battlePost("/level-design/create-blank-level-design", {
      type,
    });
    progressStageInfo(rs);
    alert(`create new stage id=${rs.id}`);
  }
  useEffect(() => {
    async function loadSkill() {
      let rs = await api.battlePost("/level-design/get-all-skills", {});
      setSkills(rs);
    }
    loadSkill();
  }, []);
  function progressStageInfo(stage: {
    rewards: SetStateAction<any[]>;
    plots: any[];
  }) {
    setRewards(stage.rewards);
    setMap(generateMap(stage));
    setStage(stage);
    let foods = 0;
    let hps = 0;
    stage.plots.forEach(
      (plot: { food: number; type: any; rarity: any; level: any }) => {
        if (plot.food) {
          foods += plot.food;
        }
        hps += getPlotHPStat(plot.type, plot.rarity, plot.level) as number;
      }
    );
    setTotalHp(hps);
    setTotalFood(foods);
  }
  function generateMap(stage: { plots: any[] }) {
    let mp: any[] = [];
    for (var i = 0; i < WIDTH; i++) {
      mp[i] = [];
      for (var j = 0; j < HEIGHT; j++) {
        mp[i].push({ i, j, x: j - WIDTH / 2, y: HEIGHT / 2 - i });
      }
    }
    stage.plots.forEach((p: { y: number; x: number }) => {
      mp[HEIGHT / 2 - p.y][p.x + WIDTH / 2].plot = p;
    });
    return mp;
  }
  function getPlotHPStat(type: any, rarity: any | number, level: number) {
    switch (type) {
      case Config.PlotEnumType.LandCore:
        return Config.LandcoreStats[rarity][Config.LandcoreStatsList.HP][level - 1];
      case Config.PlotEnumType.Pasture:
        return Config.PastureStats[rarity][Config.PastureStatsList.HP][level - 1];
      case Config.PlotEnumType.Breeding:
        return Config.BreedingStats[rarity][Config.BreedingStatsList.HP][level - 1];
      case Config.PlotEnumType.Hatching:
        return Config.HatchingStats[rarity][Config.HatchingStatsList.HP][level - 1];
      case Config.PlotEnumType.Production:
        return Config.ProductionStats[rarity][Config.ProductionStatsList.HP][
          level - 1
        ];
      case Config.PlotEnumType.Storage:
        return Config.StorageStats[rarity][Config.StorageStatsList.HP][level - 1];
      case Config.PlotEnumType.Camp:
        return Config.CampStats[rarity][Config.CampStatsList.HP][level - 1];
      case Config.PlotEnumType.Tower:
        return Config.TowerStats[rarity][Config.TowerStatsList.HP][level - 1];
      case Config.PlotEnumType.Bombard:
        return Config.BombardStats[rarity][Config.BombardStatsList.HP][level - 1];
      default:
        return 0;
    }
  }
  async function cloneStage() {
    let rs = await api.battlePost("/level-design/clone-level-design", {
      stage_id: stage.id,
      type,
    });
    alert(`clone success stage=${rs.id}`);
    progressStageInfo(rs);
  }
  async function loadStage() {
    if (!stageId) {
      return;
    }
    try {
      let rs = await api.battlePost("/level-design/get-level-design-by-id", {
        stage_id: stageId,
        type,
      });
      progressStageInfo(rs);
      if (rs.bot_league >= 0) setBotLeague(rs.bot_league);
      if (rs.total_lootable_food >= 0)
        setTotalLootableFood(rs.total_lootable_food);
      if (rs.start) setStart(rs.start);
      if (rs.end) setEnd(rs.end);
      if (rs.total_hp >= 0) setTotalHpMap(rs.total_hp);
      if (rs.last_hit_reward) setLastHitReward(rs.last_hit_reward);
      alert("load success");
    } catch (error: any) {
      console.log(error);
      alert(error.message);
    }
  }
  async function saveMap() {
    if (!stage) {
      return alert("stage is not loaded");
    }
    try {
      let plots: any[] = [];
      map.forEach((i) => {
        i.forEach((j: { plot: any }) => {
          if (j.plot) {
            plots.push(j.plot);
          }
        });
      });
      await api.battlePost("/level-design/edit-level-design", {
        rewards,
        plots,
        stage_id: stage.id,
        type,
        bot_league: botLeague,
        total_lootable_food: totalLootableFood,
        start,
        end,
        total_hp: totalHpMap ? totalHpMap : totalHp,
        total_food: totalFood,
        last_hit_reward: lastHitReward,
      });
      alert("save success");
    } catch (error) {
      console.error(error);
      alert("save fail");
    }
  }
  function StageInfo() {
    return (
      <div className="position-relative pb-5">
        <div
          style={{ height: "calc(100vh - 270px)", overflowY: "auto" }}
          className="position-relative"
        >
          <Card>
            <Card.Header>Stage Info</Card.Header>
            <Card.Body>
              <div className="d-flex">
                <p className="d-flex align-items-center me-2">
                  <label className="me-2">Id:</label>{" "}
                  <input
                    value={stageId}
                    type="text"
                    className="form-control"
                    onChange={(evt) => {
                      setStageId(Number(evt.target.value));
                    }}
                  />
                </p>
                <Button variant="primary" className="me-2" onClick={loadStage}>
                  Load
                </Button>
                <Button variant="success" className="me-2" onClick={createStage}>
                  Create new
                </Button>
              </div>
            </Card.Body>
          </Card>
          {type === "battlefront-bot" && (
            <div>
              <Card className="mt-4">
                <Card.Header>League</Card.Header>
                <Card.Body>
                  <div className="d-flex">
                    <p className="d-flex align-items-center me-2">
                      <label className="me-2">league:</label>{" "}
                      <Form.Select
                        value={botLeague}
                        onChange={(evt: { target: { value: any } }) => {
                          setBotLeague(Number(evt.target.value));
                        }}
                      >
                        <option value={0}>Select league</option>
                        {Object.keys(Config.League).map((i: string) => (
                          // @ts-ignore
                          <option value={Config.League[i]}>{i}</option>
                        ))}
                      </Form.Select>
                    </p>
                  </div>
                </Card.Body>
              </Card>
              <Card className="mt-4">
                <Card.Header>Total Lootable Food</Card.Header>
                <Card.Body>
                  <div className="d-flex">
                    <p className="d-flex align-items-center me-2">
                      <label className="me-2">Food:</label>{" "}
                      <input
                        value={totalLootableFood}
                        type="text"
                        placeholder="1-100"
                        className="form-control"
                        onChange={(evt) => {
                          setTotalLootableFood(Number(evt.target.value));
                        }}
                      />
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </div>
          )}
          {type === "adventure-stage" && (
            <div>
              <RewardInspect
                reward={rewards[0] || { res: [], requirement: 100 }}
                star={1}
                onChange={(r: any) => {
                  let tmp = [...rewards];
                  tmp[0] = r;
                  setRewards(tmp);
                }}
              />
              <RewardInspect
                reward={rewards[1] || { res: [], requirement: 100 }}
                star={2}
                onChange={(r: any) => {
                  let tmp = [...rewards];
                  tmp[1] = r;
                  setRewards(tmp);
                }}
              />
              <RewardInspect
                reward={rewards[2] || { res: [], requirement: 100 }}
                star={3}
                onChange={(r: any) => {
                  let tmp = [...rewards];
                  tmp[2] = r;
                  setRewards(tmp);
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="bootstrap-iso">
      {type === "adventure-event" && (
        <div>
          <Card className="mt-4">
            <Card.Header>Total HP Event</Card.Header>
            <Card.Body>
              <div className="d-flex w-full">
                <p className="d-flex align-items-center me-2 w-full">
                  <label className="me-2 w-1/5">Total HP Event:</label>{" "}
                  <input
                    value={totalHpMap}
                    type="text"
                    placeholder="1-100"
                    className="form-control"
                    onChange={(evt) => {
                      setTotalHpMap(Number(evt.target.value));
                    }}
                  />
                </p>
              </div>
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Header>Start Event</Card.Header>
            <Card.Body>
              <div className="d-flex w-full">
                <p className="d-flex align-items-center me-2 w-full">
                  <label className="me-2 w-1/5">Start Event:</label>{" "}
                  <DatePicker
                    placeholderText={"start"}
                    isClearable
                    selected={typeof start === "string" ? new Date(start) : start}
                    onChange={(val: Date) => {
                      if (!val) {
                        setStart(null);
                      } else {
                        setStart(dayjs(val).startOf("day").toDate());
                      }
                    }}
                    dateFormat="yyyy/MM/dd HH:mm"
                  />
                </p>
              </div>
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Header>End Event</Card.Header>
            <Card.Body>
              <div className="d-flex w-full">
                <p className="d-flex align-items-center me-2 w-full">
                  <label className="me-2 w-1/5">End Event:</label>{" "}
                  <DatePicker
                    placeholderText={"end"}
                    isClearable
                    selected={typeof end === "string" ? new Date(end) : end}
                    onChange={(val: Date) => {
                      if (!val) {
                        setEnd(null);
                      } else {
                        setEnd(dayjs(val).startOf("day").toDate());
                      }
                    }}
                    dateFormat="yyyy/MM/dd HH:mm"
                  />
                </p>
              </div>
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Header>Last Hit Reward</Card.Header>
            <Card.Body>
              <div className="d-flex w-full">
                <p className="d-flex align-items-center me-2 w-full">
                  <label className="me-2 w-1/5">Last Hit Reward:</label>{" "}
                  <RewardEditor
                    value={lastHitReward || []}
                    onChange={(_val: any) => {
                      setLastHitReward(_val);
                    }}
                  />
                </p>
              </div>
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Header>Rewards</Card.Header>
            <Card.Body>
              {rewards.map((data, index) => {
                return (
                  <div className="w-full" key={index}>
                    <Card className="mb-4">
                      <Card.Body>
                        <p className="d-flex align-items-center me-2 w-full">
                          <div className="d-flex align-items-center me-2 w-full">
                            <label className="me-2 w-1/5">Required:</label>{" "}
                            <input
                              value={data?.require ?? 0}
                              type="text"
                              placeholder="1-100"
                              className="form-control"
                              onChange={(evt) => {
                                let tmp = [...rewards];
                                tmp[index].require = Number(evt.target.value);
                                setRewards(tmp);
                              }}
                            />
                          </div>
                          <SemanticButton
                            icon="times"
                            color="red"
                            onClick={() => {
                              let tmp = [...rewards];
                              tmp.splice(index, 1);
                              setRewards(tmp);
                            }}
                          />
                        </p>
                        <RewardEditor
                          value={data?.rewards || []}
                          onChange={(_val: any) => {
                            let tmp = [...rewards];
                            tmp[index].rewards = _val;
                            setRewards(tmp);
                          }}
                        />
                      </Card.Body>
                    </Card>
                  </div>
                );
              })}
            </Card.Body>
            <Card.Footer>
              <SemanticButton
                icon="plus"
                content="Add"
                labelPosition="left"
                onClick={() => {
                  let tmp = [...rewards];
                  tmp.push({
                    rewards: [],
                    require: 0,
                  });
                  setRewards(tmp);
                }}
              />
            </Card.Footer>
          </Card>
        </div>
      )}
      <div className="row">
        <div className="col-8 p-2">
          <Card>
            <Card.Header as="h5">Map</Card.Header>
            <Card.Header as="h5">Total HP Plot: {totalHp}</Card.Header>
            <Card.Header as="h5">Total Food Plot: {totalFood}</Card.Header>
            <Card.Body style={{ overflow: "auto", height: "calc(100vh - 130px)" }}>
              <Map
                map={map}
                onSelect={(tile: any) => {
                  const defaultPlot = {
                    food: 0,
                    level: 0,
                    race: "-1",
                    rarity: "-1",
                    type: "-1",
                    subPlotType: 0
                  };
                  if (tile.plot) {
                    tile = { ...tile, plot: { ...defaultPlot, ...tile.plot } };
                  }
                  setTile(tile);
                }}
              />
            </Card.Body>
          </Card>
        </div>
        <div className="col-4 p-2">
          <Card>
            <Card.Header as="h5">Properties</Card.Header>
            <Card.Body>
              <div className="position-relative">
                <Tabs
                  defaultActiveKey="stage"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="stage" title="Stage Info">
                    {StageInfo()}
                  </Tab>
                  <Tab eventKey="land" title="Land Plots">
                    <PlotInspect
                      skills={skills}
                      tile={tile}
                      onChange={(t: { i: string | number; j: string | number }) => {
                        let tmp = JSON.parse(JSON.stringify(map));
                        tmp[t.i][t.j] = t;
                        setTile(t);
                        let foods = 0;
                        let hps = 0;
                        tmp.forEach((row: any[]) => {
                          row.forEach(
                            (p: {
                              plot: {
                                food: number;
                                type: any;
                                rarity: any;
                                level: any;
                              };
                            }) => {
                              if (p.plot && p.plot.food) {
                                foods += p.plot.food;
                              }
                              if (p.plot) {
                                hps += getPlotHPStat(p.plot.type, p.plot.rarity, p.plot.level) as number;
                              }
                            }
                          );
                        });
                        setTotalHp(hps);
                        setTotalFood(foods);
                        setMap(tmp);
                      }}
                    />
                  </Tab>
                </Tabs>
                <div className="position-absolute bottom-0 left-0 w-100">
                  <div className="d-flex gap-4">
                    <Button variant="info" className="w-100" onClick={cloneStage}>
                      Clone
                    </Button>
                    <Button variant="primary" className="w-100" onClick={saveMap}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}
function PlotInspect({ tile, onChange, skills }: any) {
  return (
    <div className="pb-5">
      <div style={{ height: "calc(100vh - 270px)", overflowY: "auto" }}>
        {tile ? (
          <div>
            <p>
              Map Position: x:{tile.x} y:{tile.y}
            </p>
            {tile.plot ? (
              <div>
                <Card className="mt-4">
                  <Card.Header>
                    <div className="d-flex justify-content-between">
                      <p>Plot Info</p>
                      <Button
                        variant="danger"
                        onClick={() => {
                          let tmp = { ...tile };
                          tmp.plot = null;
                          onChange(tmp);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="me-2">
                        <Form.Select
                          value={tile?.plot?.type}
                          onChange={(evt: { target: { value: any } }) => {
                            let tmp = { ...tile };
                            let plotType = Number(evt.target.value)
                            tmp.plot.type = plotType;
                            if (plotType == PlotType.Pasture) {
                              tmp.plot.race = MongenRace.Beast
                            } else {
                              tmp.plot.race = MongenRace.All
                            }
                            onChange(tmp);
                          }}
                        >
                          <option value={-1}>Select plot type</option>
                          {Config.PlotType.map((i, index) => (
                            <option value={index}>{i}</option>
                          ))}
                        </Form.Select>
                        <Form.Select
                          value={tile?.plot?.race}
                          className="mt-2"
                          onChange={(evt: { target: { value: any } }) => {
                            let tmp = { ...tile };
                            tmp.plot.race = Number(evt.target.value);
                            onChange(tmp);
                          }}
                        >
                          <option value={-1}>Select plot race</option>
                          {Config.Race.map((i, index) => (
                            <option value={index}>{i}</option>
                          ))}
                        </Form.Select>
                        <Form.Select
                          value={tile?.plot?.rarity}
                          className="mt-2"
                          onChange={(evt: { target: { value: any } }) => {
                            let tmp = { ...tile };
                            tmp.plot.rarity = Number(evt.target.value);
                            onChange(tmp);
                          }}
                        >
                          <option value={-1}>Select plot rarity</option>
                          {Config.Rarity.map((i, index) => (
                            <option value={index}>{i}</option>
                          ))}
                        </Form.Select>
                        {
                          getSubType(tile?.plot?.type) &&
                          <Form.Select
                            value={tile?.plot?.subPlotType}
                            className="mt-2"
                            onChange={(evt: { target: { value: any } }) => {
                              let tmp = { ...tile };
                              tmp.plot.subPlotType = Number(evt.target.value);
                              onChange(tmp);
                            }}
                          >
                            <option value={0}>Select Plot Sub Type</option>
                            {Object.keys(getSubType(tile?.plot?.type))?.map((value: any, index: number) => {
                              // @ts-ignore
                              return <option value={getSubType(tile.plot.type)[value]}>{value}</option>
                            })}
                          </Form.Select>
                        }
                      </div>
                      <div className="me-2">
                        <div className="d-flex">
                          <label className="me-2">Level</label>
                          <input
                            value={tile.plot.level}
                            type="text"
                            className="form-control"
                            onChange={(evt) => {
                              let tmp = { ...tile };
                              tmp.plot.level = Number(evt.target.value);
                              onChange(tmp);
                            }}
                          />
                        </div>
                        <div className="d-flex mt-2">
                          <label className="me-2">Food</label>
                          <input
                            value={tile.plot.food}
                            type="text"
                            className="form-control"
                            onChange={(evt) => {
                              let tmp = { ...tile };
                              tmp.plot.food = Number(evt.target.value);
                              onChange(tmp);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>

                <MongenList
                  skills={skills}
                  mongens={
                    tile?.plot.mongens?.map((e: any) => {
                      return {
                        basic: "-1",
                        dmg: 0,
                        evolution: 0,
                        level: 0,
                        race: "-1",
                        rarity: "-1",
                        stat: 0,
                        ultimate: "-1",
                        ...e,
                      };
                    }) || []
                  }
                  onChange={(mg: any) => {
                    let tmp = { ...tile };
                    tmp.plot.mongens = mg;
                    onChange(tmp);
                  }}
                />
              </div>
            ) : (
              <div className="mt-2">
                <p>No plot here, add new?</p>

                <Button
                  className="mt-2"
                  variant="success"
                  onClick={() => {
                    let tmp = { ...tile };
                    tmp.plot = {
                      type: PlotType.LandCore,
                      race: MongenRace.All,
                      rarity: Rarity.Common,
                      x: tile.x,
                      y: tile.y,
                      subPlotType: 0,
                      mongens: [],
                    };
                    onChange(tmp);
                  }}
                >
                  Add plot
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div>
            <p>Please select tile map</p>
          </div>
        )}
      </div>
    </div>
  );
}
function MongenList({ mongens, onChange, skills }: any) {
  return (
    <Card className="mt-4">
      <Card.Header>Mongen List</Card.Header>
      <Card.Body>
        {mongens.map((m: any, index: any) => (
          <Card className="mt-2">
            <Card.Body>
              <div className="d-flex justify-content-between">
                <b>#{index}</b>
                <Button
                  variant="danger"
                  onClick={() => {
                    let tmp = [...mongens];
                    tmp.splice(index, 1);
                    onChange(tmp);
                  }}
                >
                  Delete
                </Button>
              </div>
              <div className="d-flex mt-2 w-100">
                <div className="me-2 w-100">
                  <Form.Select
                    value={m.race}
                    onChange={(evt: { target: { value: any } }) => {
                      let tmp = [...mongens];
                      tmp[index].race = evt.target.value;
                      onChange(tmp);
                    }}
                  >
                    <option value={-1}>Select mongen race</option>
                    {Config.Race.map((i, index) => (
                      <option value={index}>{i}</option>
                    ))}
                  </Form.Select>
                  <Form.Select
                    value={m.rarity}
                    className="mt-2"
                    onChange={(evt: { target: { value: any } }) => {
                      let tmp = [...mongens];
                      tmp[index].rarity = evt.target.value;
                      onChange(tmp);
                    }}
                  >
                    <option value={-1}>Select plot rarity</option>
                    {Config.Rarity.map((i, index) => (
                      <option value={index}>{i}</option>
                    ))}
                  </Form.Select>
                </div>
                <div className="w-100">
                  <Form.Select
                    value={m.basic}
                    className=""
                    onChange={(evt: { target: { value: any } }) => {
                      let tmp = [...mongens];
                      tmp[index].basic = evt.target.value;
                      onChange(tmp);
                    }}
                  >
                    <option value={-1}>Select basic skill</option>
                    {skills
                      .filter((i: { speed: number }) => i.speed > 0)
                      .map((i: any, index: any) => (
                        <option value={i.id}>{i.name}</option>
                      ))}
                  </Form.Select>
                  <Form.Select
                    value={m.ultimate}
                    className="mt-2"
                    onChange={(evt: { target: { value: any } }) => {
                      let tmp = [...mongens];
                      tmp[index].ultimate = evt.target.value;
                      onChange(tmp);
                    }}
                  >
                    <option value={-1}>Select ultimate skill</option>
                    {skills
                      .filter((i: { speed: any }) => !i.speed)
                      .map((i: any, index: any) => (
                        <option value={i.id}>{i.name}</option>
                      ))}
                  </Form.Select>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="me-2 w-100">
                  <div className="d-flex align-items-center">
                    <label className="me-2">LVL</label>
                    <input
                      type="text"
                      className="form-control"
                      value={m.level}
                      onChange={(evt) => {
                        let tmp = [...mongens];
                        tmp[index].level = Number(evt.target.value);
                        onChange(tmp);
                      }}
                    />
                  </div>
                  <div className="d-flex mt-2 align-items-center">
                    <label className="me-2">EVO</label>
                    <input
                      type="text"
                      className="form-control"
                      value={m.evolution}
                      onChange={(evt) => {
                        let tmp = [...mongens];
                        tmp[index].evolution = Number(evt.target.value);
                        onChange(tmp);
                      }}
                    />
                  </div>
                </div>
                <div className="w-100">
                  <div className="d-flex align-items-center">
                    <label className="me-2">STAT</label>
                    <input
                      type="text"
                      className="form-control"
                      value={m.stat}
                      onChange={(evt) => {
                        let tmp = [...mongens];
                        tmp[index].stat = Number(evt.target.value);
                        onChange(tmp);
                      }}
                    />
                  </div>
                  <div className="d-flex mt-2 align-items-center">
                    <label className="me-2">DMG</label>
                    <input
                      type="text"
                      className="form-control"
                      value={m.dmg}
                      onChange={(evt) => {
                        let tmp = [...mongens];
                        tmp[index].dmg = Number(evt.target.value);
                        onChange(tmp);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
        <Button
          variant="success"
          className="mt-2 "
          onClick={() => {
            let tmp = [...mongens];
            tmp.push({
              race: 0,
              rarity: 0,
              basic: 1,
              ultimate: 2,
              level: 1,
              evolution: 1,
              stat: 100,
              dmg: 100,
            });
            onChange(tmp);
          }}
        >
          Add Mongen
        </Button>
      </Card.Body>
    </Card>
  );
}
function RewardInspect({ reward, star, onChange }: any) {
  return (
    <Card className="mt-4">
      <Card.Header>
        <div className="d-flex justify-content-between">
          <p>Reward</p>

          <p>
            {star} <i className="bi bi-star" style={{ fontSize: 20 }} />
          </p>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-flex align-items-center">
          <p className="me-2">Requirement</p>
          <input
            value={reward.requirement}
            type="text"
            placeholder="1-100"
            className="form-control"
            onChange={(evt) => {
              let tmp = { ...reward };
              tmp.requirement = Number(evt.target.value);
              onChange(tmp);
            }}
          />
        </div>
        {reward.res.length > 0 && (
          <div className="border p-2 bg-secondary bg-opacity-25 mt-2">
            {reward.res.map((i: any, index: string | number) => (
              <div className="d-flex justify-content-between mt-2 border p-2 align-items-center">
                <div className="d-flex align-items-center">
                  <Form.Select
                    value={reward.res[index].resource}
                    aria-label="Default select example"
                    className="me-2"
                    onChange={(evt: { target: { value: any } }) => {
                      let tmp = { ...reward };
                      tmp.res[index].resource = Number(evt.target.value);
                      onChange(tmp);
                    }}
                  >
                    <option value={-1}>Select resource</option>
                    {Config.RewardType.map((i, index) => (
                      <option value={index}>{i}</option>
                    ))}
                  </Form.Select>
                  <Form.Control
                    value={reward.res[index].qty}
                    onChange={(evt: { target: { value: any } }) => {
                      let tmp = { ...reward };
                      tmp.res[index].qty = Number(evt.target.value);
                      onChange(tmp);
                    }}
                    style={{ width: 100 }}
                    type="text"
                    className="me-2"
                    placeholder="Quantity"
                  />
                </div>
                <Button
                  variant="danger"
                  onClick={() => {
                    let tmp = { ...reward };
                    tmp.res.splice(index, 1);
                    onChange(tmp);
                  }}
                >
                  Delete
                </Button>
              </div>
            ))}
          </div>
        )}
        <div className="d-flex mt-2">
          <Button
            variant="success"
            onClick={() => {
              let tmp = { ...reward };
              tmp.res.push({ resource: -1, qty: 0 });
              onChange(tmp);
            }}
          >
            Add
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}
function Map({ map, onSelect }: any) {
  return (
    <div>
      {map.map((i: any[]) => {
        return (
          <div style={{ width: WIDTH * 50, height: 50 }}>
            {i.map((j: { plot: { type: any; rarity: any; race: any, subPlotType: any } }) => {
              let plotType = null;
              if (j.plot) {
                plotType = getPlotType(j.plot.type, j.plot.rarity, j.plot.race);
              }
              return (
                <div
                  className="map-tile position-relative"
                  onClick={() => {
                    onSelect(j);
                  }}
                >
                  {plotType && (
                    <div>
                      <img
                        alt="grass"
                        src={`/assets/grass.png`}
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          right: 0,
                          top: 0,
                          width: plotType.size[0] * 50,
                          height: plotType.size[1] * 50,
                          maxWidth: "unset",
                        }}
                      />
                      <img
                        alt="grass"
                        src={`${getPlotIcon(j.plot.type, j.plot.race, j.plot.subPlotType, j.plot.rarity)}`}
                        style={{
                          position: "absolute",
                          zIndex: 2,
                          right: 0,
                          top: 0,
                          objectFit: "contain",
                          width: plotType.size[0] * 50,
                          height: plotType.size[1] * 50,
                          maxWidth: "unset",
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
