import { DatePicker } from "components";
import dayjs from "dayjs";
import { AlertType, LockSource, LockSourceDisplay, NFTType } from "interfaces";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Pagination,
  Select,
  Table,
} from "semantic-ui-react";
import { api, ui } from "services";

export default function ReportScholarErrorLogs() {
  const [scholar_id, setScholarId] = useState(null);
  const [owner_id, setOwnerId] = useState(null);
  const [data, setData] = useState<any>([])
  const [header, setHeaders] = useState<any>([])
  const [page, setPage] = useState<any>(1);
  const [nft_type, setNFTType] = useState(NFTType.Plot)
  const [total, setTotal] = useState(NFTType.Plot)
  const pageSize = 50;

  async function loadData() {
    try {
      let rs = await api.post("/operation/get-scholar-error-nft", {
        owner_id: owner_id || null,
        scholar_id: scholar_id || null,
        offset: Math.max(0, (page - 1) * pageSize),
        limit: pageSize,
        type: nft_type,
      });
      let headers = ["id", "owner_id", "scholar_id", "lock_source", "region"];
      setHeaders(headers)
      let data = rs.data
      setTotal(rs.total ?? 0)
      if (!data) {
        setData([])
      } else {
        setData(data)
      }
    } catch (err) {
      setData([])
    }
  }

  const handleErrorNft = async (id: number) => {
    try {
      let rs = await api.post("/operation/handle-scholar-error-nft", {
        type: nft_type,
        id,
      });
      await loadData();
      ui.alert("success", AlertType.Success);
    } catch (err: any) {
      ui.alert(err?.message ?? err, AlertType.Danger);
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <div>
      <Card fluid>
        <Card.Content>
          <Form className="flex gap-2 w-full">
            <Form.Field className="w-full">
              <label>Owner id</label>
              <Input value={owner_id} onChange={(e, data) => {
                setOwnerId(data.value)
              }} />
            </Form.Field>
            <Form.Field className="w-full">
              <label>Scholar id</label>
              <Input value={scholar_id} onChange={(e, data) => {
                setScholarId(data.value)
              }} />
            </Form.Field>
            <Form.Field className="w-full">
              <label>NFT Type</label>
              <Select
                className="form-control"
                onChange={(evt: any, data: any) => {
                  setNFTType(data.value);
                }}
                value={nft_type}
                options={[
                  { text: 'Plot', value: NFTType.Plot },
                  { text: 'Mongen', value: NFTType.Mongen },
                  { text: 'Soulcore', value: NFTType.SoulCore },
                  { text: 'Box', value: NFTType.Box },
                ]}
              />
            </Form.Field>
            <Form.Field>
              <label>Action</label>
              <Button
                color="blue"
                icon="search"
                content="Search"
                labelPosition="left"
                onClick={() => loadData()}
              />
            </Form.Field>
          </Form>
        </Card.Content>

        <Card.Content>
          <Table celled sortable>
            <Table.Header>
              {header.map((labelTable: any) => {
                return <Table.HeaderCell>{labelTable}</Table.HeaderCell>;
              })}
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {data.map((i: any) => {
                return (
                  <Table.Row>
                    {header.map((key: string) => {
                      if (key === "lock_source") {
                        return (
                          <Table.Cell>{LockSourceDisplay[i['lock_source']].toLowerCase().replace('_', ' ')}</Table.Cell>
                        )
                      }
                      return (
                        <Table.Cell>{i[key]}</Table.Cell>
                      )
                    })}
                    <Table.Cell>
                      <Button onClick={async () => {
                        await handleErrorNft(i["id"])
                      }}>Handle action</Button>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
          <Pagination
            activePage={page}
            onPageChange={(page: any, rs: any) => {
              setPage(rs.activePage);
            }}
            size="mini"
            totalPages={Math.ceil(total / pageSize)}
          />
        </Card.Content>
      </Card>
    </div>
  );
}
