import LockFeature from "components/lock-feature";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, Card } from "semantic-ui-react";
import { api, ui } from "services";

export default function IAPLockFeature() {
  const [config, setConfig] = useState<any>(null);
  const [features, setFeatures] = useState<any>([]);
  useEffect(() => {
    async function loadConfig() {
      let rs = await api.post("/operation/api/get-config", {
        where: { name: "IAP_LOCK_FEATURES" },
      });
      setConfig(rs?.data?.[0]);
      setFeatures(JSON.parse(rs?.data?.[0]?.value));
    }
    console.log("dm");
    loadConfig();
  }, []);
  async function update() {
    config.value = JSON.stringify(config.value);
    let rs = await api.post("/operation/api/update-config", config);
    setConfig(rs);
    ui.alert("Update success");
  }
  function handleChange(name: string, value: any) {
    let tmp = _.cloneDeep(config);
    tmp[name] = value;
    if (name === "value") {
      setFeatures(value);
    }
    setConfig(tmp);
  }
  return (
    <div>
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <div className="flex justify-between">
              <p className="text-3xl font-semibold">IAP_LOCK_FEATURES</p>
              <Button
                icon="save"
                content="Save"
                primary
                onClick={() => {
                  update();
                }}
                labelPosition="left"
              />
            </div>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <LockFeature
            value={features ?? []}
            onChange={(val: any) => {
              handleChange("value", val);
            }}
          />
        </Card.Content>
      </Card>
    </div>
  );
}
