import { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement,
} from "chart.js";
import { api } from "services";
import dayjs from "dayjs";
import { Button, Card, Form, Select, Table } from "semantic-ui-react";
import { DatePicker } from "components";
import _ from "lodash";
import saveAs from "file-saver";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement
);
export default function UserSignupCountryReport() {
    const [loading, setLoading] = useState<boolean>(false);
    const [earn, setEarn] = useState<{ options: any; data: any }>(null);
    const [dateFrom, setDateFrom] = useState(
        dayjs().add(-1, "week").startOf("day").toDate()
    );
    const [dateTo, setDateTo] = useState(dayjs().add(1, "day").toDate());
    const [country, setCountry] = useState("");
    const [src, setSrc] = useState("");
    async function loadData() {
        setLoading(true);
        try {
            let rs = await api.post("/operation/get-signup-country-report", {
                dateFrom: dateFrom,
                dateTo: dateTo,
                geocountry: country,
                src
            });
            setEarn(rs);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }
    return (
        <div>
            <Card.Content>
                <Form className="flex gap-2 w-full">
                    <Form.Field className="w-full">
                        <label>Country</label>
                        <Select
                            placeholder={"Select country"}
                            fluid
                            value={country}
                            search
                            options={["", "VN", "ID",
                                "JP",
                                "RU",
                                "PH",
                                "BR",
                                "UA",
                                "US",
                                "BY",
                                "DE",
                                "AR",
                                "FR",
                                "ES",
                                "TR",
                                "HK",
                                "IT",
                                "TH",
                                "NL",
                                "GB",
                                "VE",
                                "IN",
                                "TW",
                                "SG",
                                "PT",
                                "CO",
                                "CN",
                                "PL",
                                "MA",
                                "MY",
                                "MX",
                                "CA",
                                "RO",
                                "BE",
                                "KZ",
                                "ZA",
                                "SA",
                                "BA",
                                "KR",
                                "IL",
                                "PK",
                                "CZ",
                                "LV",
                                "GE",
                                "IR",
                                "EG",
                                "AU",
                                "KG",
                                "BG",
                                "PE",
                                "CL",
                                "PY",
                                "CU",
                                "CH",
                                "DO",
                                "SE",
                                "SV",
                                "FI",
                                "IE",
                                "UY",
                                "NO",
                                "SK",
                                "LT",
                                "GR",
                                "UZ",
                                "HU",
                                "BO",
                                "AE",
                                "BD",
                                "PR",
                                "DZ",
                                "MT",
                                "SI",
                                "HR",
                                "LK",
                                "MU",
                                "TJ",
                                "GT",
                                "CR",
                                "MO",
                                "AO",
                                "AT",
                                "MN",
                                "KE",
                                "MG",
                                "CY",
                                "RS",
                                "DK",
                                "AM",
                                "TN",
                                "LU",
                                "AZ",
                                "BZ",
                            ].map((i) => {
                                return { text: i ? i : "All", value: i };
                            })}
                            onChange={(evt: any, val: any) => {
                                setCountry(val.value);
                            }}
                        />
                    </Form.Field>
                    <Form.Field className="w-full">
                        <label>Source</label>
                        <Select
                            placeholder={"Select source"}
                            fluid
                            value={src}
                            search
                            options={["", "organic", "google",].map((i) => {
                                return { text: i ? i : "All", value: i };
                            })}
                            onChange={(evt: any, val: any) => {
                                setSrc(val.value);
                            }}
                        />
                    </Form.Field>
                    <Form.Field className="w-full">
                        <label>From</label>
                        <DatePicker
                            todayButton="Today"
                            selected={dateFrom}
                            onChange={(val: Date) => {
                                if (!val) {
                                    setDateFrom(null);
                                } else {
                                    setDateFrom(dayjs(val).startOf("day").toDate());
                                }
                            }}
                            dateFormat="yyyy/MM/dd HH:mm"
                        />
                    </Form.Field>
                    <Form.Field className="w-full">
                        <label>To</label>
                        <DatePicker
                            todayButton="Today"
                            selected={dateTo}
                            onChange={(val: Date) => {
                                if (!val) {
                                    setDateTo(null);
                                } else {
                                    setDateTo(dayjs(val).endOf("day").toDate());
                                }
                            }}
                            dateFormat="yyyy/MM/dd HH:mm"
                        />
                    </Form.Field>
                    <div>
                        <Form.Field>
                            <label>Action</label>
                            <Button
                                loading={loading}
                                color="blue"
                                icon="search"
                                content="Search"
                                labelPosition="left"
                                onClick={loadData}
                            />
                        </Form.Field>
                    </div>
                    <div>
                        <Form.Field>
                            <Button
                                style={{ marginTop: "15px" }}
                                loading={loading}
                                color="teal"
                                icon="download"
                                content="Download CSV"
                                labelPosition="left"
                                onClick={() => {
                                    const labels = earn?.data?.labels ? [earn?.data?.labels] : [[]];
                                    const data = earn?.data?.datasets ?? [];
                                    console.log(labels.concat(data));
                                    let text = labels
                                        .concat(data)
                                        .map((s: string[]) => s.join(","))
                                        .join("\n");
                                    var blob = new Blob([text], {
                                        type: "text/plain;charset=utf-8;",
                                    });
                                    saveAs(blob, "report.csv");
                                }}
                            />
                        </Form.Field>
                    </div>
                </Form>
            </Card.Content>
            <Card fluid>
                {earn !== null && (
                    <Card.Content>
                        <Table>
                            <Table.Header>
                                {earn?.data?.labels.map((label: string) => (
                                    <Table.HeaderCell>{label}</Table.HeaderCell>
                                ))}
                            </Table.Header>
                            <Table.Body>
                                {earn?.data?.datasets.map((dataset: any) => {
                                    return (
                                        <Table.Row>
                                            {dataset.map((data: any) => (
                                                <Table.Cell>{data}</Table.Cell>
                                            ))}
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </Card.Content>
                )}
            </Card>
        </div>
    );
}
