import BasicChart from "./basic-chart";

export default function TokenBalanceChart(props: any) {
  return (
    <BasicChart
      {...props}
      url="/operation/get-token-balance-report"
      chartType="line"
      source="analytic"
    />
  );
}
