import { useParams } from "react-router-dom";
import MinerChart from "./miner";
import RPSChart from "./rps";
import Revenue from "./revenue";
import UserOnlineChart from "./online";
import LoginChart from "./login";
import UserRegisterChart from "./register";
import ItemChart from "./item";
import LockItemChart from "./lock";
import SessionChart from "./session";
import AdaptationChart from "./adaptation-chart";
import MarketPlaceChart from "./market-place-chart";
import NetworkChart from "./network-chart";
import FameChart from "./fame-chart";
import ArpuChart from "./arpu-chart";
import UserSpendMstrChart from "./user-spend-mstr";
import UserCompleteDailyQuestChart from "./user-complete-daily-quest";
import AverageUserTimePlayChart from "./average-user-time-play";
import ARPPU from "./arppu";
import UserProfileChart from "./user-profile";
import UserPlayChart from "./user-play";
import TokenBalanceChart from "./token-balance-chart";
import ReportChampionWorldcupChart from "./report-champion-worldcup-chart";
import AssetExchangeChart from "./asset-exchange-chart";
import ClickToEarnReportChart from "./click-to-earn-report-chart";
import UserRegisterChartByType from "./register-by-type";
import UserSignupCountryChart from "./user-signup-country-chart";
import TokenGeneratedChart from "./token-generated";
export default function Chart() {
  const { type } = useParams();
  switch (type) {
    case "token-generated":
      return <TokenGeneratedChart />;
    case "rps":
      return <RPSChart />;
    case "revenue":
      return <Revenue />;
    case "miner":
      return <MinerChart />;
    case "useronline":
      return <UserOnlineChart />;
    case "login":
      return <LoginChart />;
    case "user-play":
      return <UserPlayChart />;
    case "register":
      return <UserRegisterChart />;
    case "item-off-chain":
      return <ItemChart source={"OFF"} />;
    case "item-on-chain":
      return <ItemChart source={"ON"} />;
    case "lock":
      return <LockItemChart />;
    case "session":
      return <SessionChart />;
    case "adaptation":
      return <AdaptationChart />;
    case "marketplace":
      return <MarketPlaceChart />;
    case "network":
      return <NetworkChart />;
    case "userfame":
      return <FameChart />;
    case "arpu":
      return <ArpuChart />;
    case "user-spend-mstr":
      return <UserSpendMstrChart />;
    case "complete-daily-quest":
      return <UserCompleteDailyQuestChart />;
    case "average-user-time-play":
      return <AverageUserTimePlayChart />;
    case "arppu":
      return <ARPPU />;
    case "user-profile":
      return <UserProfileChart />;
    case "token-balance":
      return <TokenBalanceChart />;
    case "report-champion-worldcup":
      return <ReportChampionWorldcupChart />;
    case "asset-exchange-report":
      return <AssetExchangeChart />;
    case "c2e-report":
      return <ClickToEarnReportChart />;
    case "nru":
      return <UserRegisterChartByType />;
    case "user-signup-country-report":
      return <UserSignupCountryChart />;
    default:
      return <p>Chart {type}</p>;
  }
}
