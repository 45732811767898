import { useTranslation } from "react-i18next";
import { Input } from "semantic-ui-react";
import Entity from "./entity";
import ListRewardEditor from "./list-reward-editor";

export default function BossInfoSchedule({
  value,
  onChange,
  viewOnly,
}: {
  viewOnly?: boolean;
  value: any;
  onChange: Function;
}) {
  const { t } = useTranslation();
  const subLabelsRewards = [
    "Milestone 1",
    "Milestone 2",
    "Milestone 3",
    "Milestone 4",
    "Milestone 5",
  ];
  const subLabelsBoss = [
    "Day 1",
    "Day 2",
    "Day 3",
    "Day 4",
    "Day 5",
    "Day 6",
    "Day 7",
  ];

  function handleChange(index: number, field: string, val: any) {
    let tmp = Object.assign([], value);
    if (!tmp[index]) {
      tmp[index] = {};
    }
    //@ts-ignore
    tmp[index][field] = val;
    onChange(tmp);
  }
  return (
    <div>
      {subLabelsBoss.map((label, index) => {
        return (
          <div key={index}>
            <label className="text-center !text-xl w-full border block font-bold my-5">
              {t(label)}
            </label>
            <div className="flex gap-2 justify-between">
              <div className="w-1/2">
                <Entity
                  displayField={"id"}
                  value={value[index]?.boss_id}
                  gridName={"client-boss-pool"}
                  multiple={false}
                  onChange={(val: any) => {
                    handleChange(index, "boss_id", val);
                  }}
                />
              </div>
              <div className="w-1/2">
                <Input
                  className="w-32"
                  value={value[index]?.entry_cost || ""}
                  placeholder="Entry cost"
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(index, "entry_cost", Number(evt.target.value));
                  }}
                />
              </div>
            </div>
            <ListRewardEditor
              subLabels={subLabelsRewards}
              value={value[index]?.rewards || []}
              onChange={(val: any) => {
                handleChange(index, "rewards", val);
              }}
            />
          </div>
        );
      })}
    </div>
  );
}
