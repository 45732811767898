import { Loading } from "components";
import { EnumEntity, FormEntity, GridEntity, FormControl, SchemaControl, SchemaDataType } from "interfaces";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "reducer/store";
import { setUserInfo } from "reducer/user.slice";
import { data } from "services";
import api from "services/api";
import Header from "./header";
import Main from "./main";
import Sidebar from "./sidebar";

let AUCTION: any = {
  name: "bid-pool",
  label: "Bid pool",
  api: "/auction-items/get-auction-b2c-items",
  host: "auction",
  buttons: [
    {
      label: "Start",
      color: "yellow",
      confirmText: "",
      action: "api",
      position: "row",
      api: "/auction-items/start-auction"
    },
    {
      label: "Delete",
      color: "red",
      action: "api",
      position: "row",
      icon: "close",
      confirmText: "Are you sure want to delete this bid?",
      api: "/auction-items/delete-item"
    },
    {
      label: "Create",
      action: "redirect",
      icon: "plus",
      position: "top",
      redirectUrl: "/form/bid-pool/create"
    },
    {
      label: "Edit",
      action: "redirect",
      color:"yellow",
      icon: "edit",
      position: "row",
      redirectUrl: "/form/bid-pool/edit",
      redirectUrlEmbed:{ id: "$id" }
    },
    {
      action:"api",
      api:"/auction-items/download-auction-history",
      color:"red",
      confirmText: "Are you sure want to export bidder data to sheet?",
      icon:"close",
      label:"Export sheet",
      position:"row"
    }
  ],
  columns: [
    {
      display: "text",
      field: "_id",
      label: "ID"
    },
    {
      display: "text",
      field: "auction_name",
      label: "Name"
    },
    {
      display: "date",
      field: "start_time",
      label: "Start time"
    },
    {
      display: "date",
      field: "end_time",
      label: "End time"
    },
    {
      display: "text",
      field: "init_price",
      label: "Initial bid"
    },

  ],
}
let AUCTION_P2P: any = [{
  name: "auctionP2P",
  label: "Auction P2P",
  api: "/auction-analysis/winning-items",
  host: "auction",
  // buttons: [
  //   {
  //     label: "Approve",
  //     color: "red",
  //     confirmText: "",
  //     action: "connect",
  //     icon: "",
  //     position: "row"
  //   }
  // ],
  columns: [
    {
      display: "text",
      field: "_id",
      label: "Auction ID"
    },
    {
      display: "text",
      field: "start_time",
      label: "Start Time"
    },
    {
      display: "text",
      field: "end_time",
      label: "End Time"
    },
    {
      display: "text",
      field: "current_price",
      label: "Last Bid"
    },
    {
      display: "text",
      field: "user_id",
      label: "User Bid"
    }
  ],
}]
export default function Layout() {
  const [showMenu, setShowMenu] = useState(true);
  const user = useSelector((state: RootState) => state.user);
  const nav = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    async function loadUserInfo() {
      try {
        if (!localStorage.getItem("token")) {
          throw new Error("token_not_found");
        }
        let rs = await api.post("/operation/get-meta", {});
        let { userInfo } = rs;

        let grids: { [key: string]: GridEntity } = {};
        let enums: { [key: string]: EnumEntity } = {};
        rs.gridInfos.concat(AUCTION_P2P).forEach((grid: GridEntity) => {
          if (grid.name == "bid-pool") {
            grids[grid.name] = AUCTION;
          } else grids[grid.name] = grid;
        });
        rs.enumInfos.forEach((item: EnumEntity) => {
          enums[item.name] = item;
        });

        let forms: { [key: string]: FormEntity } = {};
        rs.formInfos.forEach((item: FormEntity) => {
          if (item.name == "bid-pool") {
            const arr: any[] = item.controls
            let newItem1 = {
              control: SchemaControl.Text,
              dataType: SchemaDataType.String,
              field: "short_description",
              label: "Short Description",
              placeholder: "Enter short description",
              maxLength: "33"
            }
            let newItem2 = {
              control: SchemaControl.TextArea,
              dataType: SchemaDataType.String,
              field: "long_description",
              label: "Long Description",
              placeholder: "Enter long description"
            }
            arr.push(newItem1, newItem2)
            forms[item.name] = {
              ...item,
              api: "/auction-items/get-auction-b2c-items",
              controls: arr
            };
          }
          forms[item.name] = item;
        });

        data.setMeta(grids, forms, enums, rs.menuInfos, rs.troopList, rs.skillList, rs.runeList, rs.newbieQuestList, rs.plotSkinList, rs.decorationList);
        dispatch(setUserInfo(userInfo));
      } catch (error) {
        localStorage.clear();
        nav("/login");
      }
    }
    loadUserInfo();
  }, []);
  return (
    <div>
      {user !== null ? (
        <div>
          <Header
            toggleMenu={() => {
              setShowMenu(!showMenu);
            }}
          />
          <Sidebar showMenu={showMenu} />
          <Main showMenu={showMenu} />
        </div>
      ) : (
        <div className="w-screen h-screen flex items-center justify-center">
          <Loading />
        </div>
      )}
    </div>
  );
}
