import BasicChart from "./basic-chart";

export default function ClickToEarnReportChart(props: any) {
  return (
    <BasicChart
      {...props}
      url="/operation/get-clicktoearn-report"
      chartType="line"
      source="analytic"
      showFusionResources={true}
      value={{ resource: "elixir" }}
    />
  );
}
