import { CustomerResource, RewardEntity } from "interfaces";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Button, Card, Input } from "semantic-ui-react";
import Enum from "./enum";
import RewardEditor from "./reward-editor";
import dataService from "../services/data";
import { DatePicker } from "components";
import dayjs from "dayjs";

export default function ArenaRoomTypeEditor({
  value,
  onChange,
  viewOnly,
}: {
  viewOnly?: boolean;
  value: {
    elo_buff: number;
    entry_cost: number;
    entry_type: CustomerResource;
    name: string;
  }[];
  onChange: Function;
}) {
  const { t } = useTranslation();
  function handleChange(index: number, val: any, key: string) {
    let tmp: {
      elo_buff: number;
      entry_cost: number;
      entry_type: CustomerResource;
      name: string;
    }[] = [...value];

    //@ts-ignore
    tmp[index][key] = val;
    onChange(tmp);
  }
  const addTop = () => {
    value.push({
      elo_buff: 0,
      entry_cost: 0,
      entry_type: 0,
      name: "",
    });
    onChange(value);
  };
  return (
    <div>
      {value.map((valTop: any, index) => {
        return (
          <div key={index} className="mt-5">
            <Card fluid>
              <Card.Content>
                <div>
                  <div className="ml-[calc(100%-40px)]">
                    <Button
                      icon="times"
                      color="red"
                      onClick={() => {
                        let tmp = [...value];
                        tmp.splice(index, 1);
                        onChange(tmp);
                      }}
                    />
                  </div>
                  <div>
                    <div className="font-bold">Name</div>
                    <Input
                      className="w-20"
                      value={valTop.name}
                      placeholder="Number"
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(index, evt.target.value, "name");
                      }}
                    />
                  </div>
                  <div className="mt-5">
                    <div className="font-bold">Entry Cost</div>
                    <Input
                      className="w-20"
                      value={valTop.entry_cost}
                      placeholder="Number"
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(
                          index,
                          Number(evt.target.value),
                          "entry_cost"
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="mt-5">
                  <div className="font-bold">Entry Cost Type</div>
                  <Enum
                    placeholder="Entry Cost Type"
                    enumName="customer-resources"
                    value={valTop.entry_type}
                    onChange={(val) => {
                      handleChange(index, Number(val), "entry_type");
                    }}
                  />
                </div>
                <div className="mt-5">
                  <div className="font-bold">
                    Fee <i>(%)</i>
                  </div>
                  <Input
                    className="w-20"
                    value={valTop.fee}
                    type="number"
                    placeholder="Number"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(index, evt.target.value, "fee");
                    }}
                  />
                </div>
                <div className="mt-5">
                  <div className="font-bold">
                    Battlepass Pool <i>(%)</i>
                  </div>
                  <Input
                    className="w-20"
                    value={valTop.battlepass_pool}
                    type="number"
                    placeholder="Number"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(index, evt.target.value, "battlepass_pool");
                    }}
                  />
                </div>
                <div className="mt-5">
                  <div className="font-bold">Elo Buff</div>
                  <Input
                    className="w-20"
                    value={valTop.elo_buff}
                    type="number"
                    placeholder="Number"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(index, evt.target.value, "elo_buff");
                    }}
                  />
                </div>
                <div className="mt-5">
                  <Card fluid>
                    <Card.Content>
                      <div className="font-bold">Time Open</div>
                      {valTop.open_times?.map((time: any, ind: number) => {
                        return (
                          <div className="flex" key={ind}>
                            <div className="w-1/2">
                              <div>From </div>
                              <DatePicker
                                onChange={(val) => {
                                  let tmp = valTop.open_times;
                                  tmp[ind].from = {
                                    hour: dayjs(val).get("hours"),
                                    minute: dayjs(val).get("minutes"),
                                  };
                                  handleChange(index, tmp, "open_times");
                                }}
                                selected={
                                  time.from
                                    ? new Date(
                                        dayjs()
                                          .set("hour", time.from.hour)
                                          .set("minute", time.from.minute)
                                          .toString()
                                      )
                                    : new Date()
                                }
                                showTimeSelect
                                dateFormat="h:mm aa"
                                timeIntervals={15}
                                showTimeSelectOnly
                              />
                            </div>
                            <div className="w-1/2">
                              <div>To</div>
                              <DatePicker
                                onChange={(val) => {
                                  let tmp = valTop.open_times;
                                  tmp[ind].to = {
                                    hour: dayjs(val).get("hours"),
                                    minute: dayjs(val).get("minutes"),
                                  };
                                  handleChange(index, tmp, "open_times");
                                }}
                                selected={
                                  time.to
                                    ? new Date(
                                        dayjs()
                                          .set("hour", time.to.hour)
                                          .set("minute", time.to.minute)
                                          .toString()
                                      )
                                    : new Date()
                                }
                                showTimeSelect
                                dateFormat="h:mm aa"
                                timeIntervals={15}
                                showTimeSelectOnly
                              />
                            </div>
                            <div>
                              <div> &#160;</div>
                              <Button
                                icon="times"
                                color="red"
                                onClick={() => {
                                  let tmp = valTop.open_times;
                                  if (!tmp) tmp = [];
                                  tmp.splice(ind, 1);
                                  handleChange(index, tmp, "open_times");
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                      <div className="mt-5 text-right">
                        <Button
                          icon="plus"
                          content="Add"
                          labelPosition="left"
                          onClick={() => {
                            let tmp = valTop.open_times;
                            if (!tmp) tmp = [];
                            tmp.push({
                              from: {
                                hour: dayjs().get("hours"),
                                minute: dayjs().get("minutes"),
                              },
                              to: {
                                hour: dayjs().get("hours"),
                                minute: dayjs().get("minutes"),
                              },
                            });
                            handleChange(index, tmp, "open_times");
                          }}
                        />
                      </div>
                    </Card.Content>
                  </Card>
                </div>
              </Card.Content>
            </Card>
          </div>
        );
      })}
      <div className="mt-5 text-right">
        <Button
          icon="plus"
          content="Add"
          labelPosition="left"
          onClick={addTop}
        />
      </div>
    </div>
  );
}
