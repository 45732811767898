import { Enum } from "components";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Form, Input, Select, TextArea } from "semantic-ui-react";
import { api } from "services";
export default function UploadGameBundle() {
  const [status, setStatus] = useState<
    "pending" | "loading" | "success" | "fail"
  >("pending");
  const [code, setCode] = useState<string>("");
  const [name, setName] = useState<string>('');
  const [platform, setPlatform] = useState<number>(null);
  const [files, setFiles] = useState<string[]>([]);
  let [raw, setRaw] = useState<any>([]);
  const [type, setType] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const onDrop = (acceptedFiles: any[]) => {
    if (!acceptedFiles.length) return;
    console.log({ acceptedFiles });
    setFiles(acceptedFiles.map((i: any) => i.name));
    setRaw(acceptedFiles);
  };
  async function uploadFileBuild() {
    if (!(code && description && name && platform !== null)) {
      return alert("Not enough input");
    }
    try {
      setStatus("loading");
      var formdata = new FormData();
      raw.forEach((f: any) => {
        formdata.append("files", f);
      });
      formdata.append("code", code);
      formdata.append("name", name);
      formdata.append("platform", platform + '');
      formdata.append("description", description);
      let rs = await api.postFormDataRaw(
        `/operation/upload-game-bundle`,
        formdata
      );
      setStatus("success");
    } catch (error) {
      setStatus("fail");
    }
  }
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div>
      <p className="text-2xl font-semibold">Upload Game Bundle</p>
      <Form>
        <p className=" mt-4">Name</p>
        <Input
          type="text"
          onChange={(evt: any) => {
            setName(evt.target.value);
          }}
        />
        <p className=" mt-4">Code</p>
        <Input
          type="text"
          onChange={(evt: any) => {
            setCode(evt.target.value);
          }}
        />
        <p className=" mt-4">Description</p>
        <Form.Field>
          <TextArea
            type="text"
            onChange={(evt: any) => {
              setDescription(evt.target.value);
            }}
          />
        </Form.Field>
        <p className=" mt-4">Platform</p>
        <Enum
          value={platform}
          enumName={'platforms'}
          multiple={false}
          onChange={(val) => {
            setPlatform(Number(val));
          }}
        />
      </Form>

      <p className=" mt-4">Game build files</p>
      <div className="relative w-44 cursor-pointer" {...getRootProps()}>
        <input {...getInputProps()} />
        <Button icon="file" content="Select file" />
      </div>
      <div className="text-2xl font-semibold mt-4">
        {status === "pending" && <p>Waiting for file</p>}
        {status === "loading" && <p>Uploading</p>}
        {status === "success" && <p>Success</p>}
        {status === "fail" && <p>Fail</p>}
      </div>
      <div>
        {files.map((f: string) => (
          <p>{f}</p>
        ))}
      </div>
      <Button
        loading={status === "loading"}
        icon="upload"
        content="Submit"
        primary
        onClick={() => {
          uploadFileBuild();
        }}
      />
    </div>
  );
}
