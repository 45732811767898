import _ from "lodash";
import { stringify } from "querystring";
import { Button, Card, Checkbox, Input } from "semantic-ui-react";
import Enum from "./enum";
import RewardEditor from "./reward-editor";

export default function Milestone({
  value,
  onChange,
}: {
  value: any[];
  onChange: Function;
}) {
  return (
    <div>
      {value.map((item: any, index: number) => (
        <MilestoneItem
          index={index}
          value={item}
          onRemove={() => {
            let tmp = _.cloneDeep(value);
            tmp.splice(index, 1);
            onChange(tmp);
          }}
          onChange={(_val: any) => {
            let tmp = _.cloneDeep(value);
            tmp[index] = _val;
            onChange(tmp);
          }}
        />
      ))}
      <Button
        content="Add"
        icon="plus"
        labelPosition="left"
        onClick={() => {
          let tmp = _.cloneDeep(value);
          tmp.push({});
          onChange(tmp);
        }}
      />
    </div>
  );
}
function MilestoneItem({
  value,
  onChange,
  index,
  onRemove,
}: {
  value: any;
  onChange: Function;
  onRemove: Function;
  index: number;
}) {
  function handleChange(name: string, val: any) {
    let tmp = _.cloneDeep(value);
    tmp[name] = val;
    onChange(tmp);
  }
  return (
    <Card fluid>
      <Card.Content>
        <div className="flex justify-between">
          <p className="font-semibold">Milestone {index + 1}</p>
          <Button
            icon="trash"
            color="red"
            onClick={() => {
              onRemove();
            }}
          />
        </div>
        <div className="flex gap-4 items-center mt-2">
          <div className="flex items-center gap-2">
            <span className="m-0">Name</span>
            <div className="w-56">
              <Input
                type="text"
                value={value?.name}
                onChange={(evt: any) => {
                  handleChange("name", evt.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <span>Require</span>
            <Input
              value={value?.require}
              onChange={(evt: any) => {
                handleChange("require", Number(evt.target.value));
              }}
            />
          </div>
          <div className="flex items-center gap-2">
            <span>Reward Open Type</span>
            <Enum
                placeholder="Reward"
                enumName="reward-open-type"
                value={value?.open_type}
                onChange={(val) => {
                  handleChange("open_type", Number(val));
                }}
              />
          </div>
        </div>

        <div className="mt-2">
          <p>Reward</p>
          <RewardEditor
            value={value?.rewards || []}
            onChange={(_val: any) => {
              handleChange("rewards", _val);
            }}
          />
        </div>
      </Card.Content>
    </Card>
  );
}
