import { RewardEditor } from "components";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, Card, Input, Label } from "semantic-ui-react";
import { api, ui } from "services";

export default function NewbieProcessRewards() {
  const [config, setConfig] = useState<any>(null);

  useEffect(() => {
    async function loadConfig() {
      let rs = await api.post("/operation/api/get-config", {
        where: { name: "NEWBIE_PROCESS_REWARDS" },
      });
      setConfig(rs?.data?.[0]);
    }
    console.log("dm");
    loadConfig();
  }, []);
  async function update() {
    let rs = await api.post("/operation/api/update-config", config);
    let configValue = JSON.parse(config.value)
    let data = configValue.sort((a: any, b: any) => a.require_points - b.require_points)
    config.value = JSON.stringify(data)

    setConfig(rs);
    ui.alert("Update success");
  }

  const setChange = (data: any) => {
    setConfig({ ...config, value: JSON.stringify(data) });
  }

  return (
    <div>
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <div className="flex justify-between">
              <p className="text-3xl font-semibold">NEWBIE QUESTS MILESTONE REWARDS</p>
              <Button
                icon="save"
                content="Save"
                primary
                onClick={() => {
                  update();
                }}
                labelPosition="left"
              />
            </div>
          </Card.Header>
        </Card.Content>
        {config?.value && <Card.Content>
          {JSON.parse(config.value).map((v: any, index: number) => {
            return (
              <div className="flex gap-2">
                <MilestoneItem
                  value={v}
                  onRemove={() => {
                    let tmp = _.cloneDeep(JSON.parse(config.value));
                    tmp.splice(index, 1);
                    setChange(tmp)
                  }}
                  onChange={(_val: any) => {
                    let tmp = _.cloneDeep(JSON.parse(config.value));
                    tmp[index] = _val;
                    setChange(tmp)
                  }}
                  index={index}
                />
              </div>
            )
          })}
        </Card.Content>}
        <div className="ml-2 mt-2 pb-10">
          <Button
            content="Add"
            icon="plus"
            labelPosition="left"
            onClick={() => {
              let tmp = _.cloneDeep(JSON.parse(config.value));
              tmp.push({
                require_points: tmp.length * 100,
                rewards: []
              });
              setChange(tmp)
            }}
          />
        </div>
      </Card>
    </div>
  );
}

function MilestoneItem({
  value,
  onChange,
  index,
  onRemove,
}: {
  value: any;
  onChange: Function;
  onRemove: Function;
  index: number;
}) {
  function handleChange(name: string, val: any) {
    let tmp = _.cloneDeep(value);
    tmp[name] = val;
    onChange(tmp);
  }
  return (
    <Card fluid>
      <Card.Content>
        <div className="flex justify-between">
          <p className="font-semibold">Milestone {index}</p>
          <Button
            icon="trash"
            color="red"
            onClick={() => {
              onRemove();
            }}
          />
        </div>
        <div className="flex gap-4 items-center mt-2">
          <div className="flex items-center gap-2">
            <span>Require points</span>
            <Input
              value={value?.require_points}
              onChange={(evt: any) => {
                handleChange("require_points", Number(evt.target.value));
              }}
            />
          </div>
        </div>

        <div className="mt-2">
          <p>Reward</p>
          <RewardEditor
            value={value?.rewards || []}
            onChange={(_val: any) => {
              handleChange("rewards", _val);
            }}
          />
        </div>
      </Card.Content>
    </Card>
  );
}
