import { DatePicker } from "components";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Pagination,
  Select,
  Table,
} from "semantic-ui-react";
import { api } from "services";

export default function ReportScholarClaimLogs() {
  const [scholar_id, setScholarId] = useState(null);
  const [owner_id, setOwnerId] = useState(null);
  const [data, setData] = useState<any>([])
  const [header, setHeaders] = useState<any>([])
  const [page, setPage] = useState<any>(1);
  const [total, setTotal] = useState<any>(1);
  const pageSize = 50;

  async function loadData() {
    let rs = await api.post("/operation/get-scholar-claim-sessions", {
      owner_id: owner_id,
      scholar_id: scholar_id,
      offset: Math.max(0, (page - 1) * pageSize),
      limit: pageSize
    });
    let headers = ["owner_id", "scholar_id", "mag", "mstr", "elixir", "mutategen", "createdAt"];
    setHeaders(headers)
    let data = rs.data
    setTotal(rs.total ?? 0)
    if (!data) {
      setData([])
    } else {
      setData(data)
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <div>
      <Card fluid>
        <Card.Content>
          <Form className="flex gap-2 w-full">
            <Form.Field className="w-full">
              <label>Owner id</label>
              <Input value={owner_id} onChange={(e, data) => {
                setOwnerId(data.value)
              }} />
            </Form.Field>
            <Form.Field className="w-full">
              <label>Scholar id</label>
              <Input value={scholar_id} onChange={(e, data) => {
                setScholarId(data.value)
              }} />
            </Form.Field>
            <Form.Field>
              <label>Action</label>
              <Button
                color="blue"
                icon="search"
                content="Search"
                labelPosition="left"
                onClick={() => loadData()}
              />
            </Form.Field>
          </Form>
        </Card.Content>

        <Card.Content>
          <Table celled sortable>
            <Table.Header>
              {header.map((labelTable: any) => {
                return <Table.HeaderCell>{labelTable}</Table.HeaderCell>;
              })}
            </Table.Header>
            <Table.Body>
              {data.map((i: any) => {
                return (
                  <Table.Row>
                    {header.map((key: string) => {
                      if (key === "resource_type" && !i[key]) {
                        return (
                          <Table.Cell>{i['nft_type']}</Table.Cell>
                        )
                      }
                      if (key === "nft_type" && !i[key]) {
                        return (
                          <Table.Cell>{i['resource_type']}</Table.Cell>
                        )
                      }
                      if (key === "amount" && !i[key]) {
                        return (
                          <Table.Cell>{i['nft_ids'].length}</Table.Cell>
                        )
                      }
                      if (key === "nft_ids" && !i[key]) {
                        return (
                          <Table.Cell>{i['nft_ids'].join(',')}</Table.Cell>
                        )
                      }
                      return (
                        <Table.Cell>{i[key]}</Table.Cell>
                      )
                    })}
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
          <Pagination
            activePage={page}
            onPageChange={(page: any, rs: any) => {
              setPage(rs.activePage);
            }}
            size="mini"
            totalPages={Math.ceil(total / pageSize)}
          />
        </Card.Content>
      </Card>
    </div>
  );
}
