import { RewardEntity } from "interfaces";
import _ from "lodash";
import { Button, Card, Input } from "semantic-ui-react";
import RewardEditor from "./reward-editor";

export default function TournamentClanRewards({
  value,
  onChange,
}: {
  value: {
    rewardsTop: RewardEntity[];
    rewardsOther: RewardEntity[];
    percentPool: number;
    top: number;
  }[];
  onChange: Function;
}) {
  function handleChange(index: number, val: any, key: string, ind?: number) {
    let tmp: {
      rewardsTop: RewardEntity[];
      rewardsOther: RewardEntity[];
      percentPool: number;
      top: number;
    }[] = [...value];

    //@ts-ignore
    tmp[index][key] = val;
    onChange(tmp);
  }
  const addRewards = () => {
    if (!value) {
      value = [];
    }
    value.push({
      rewardsTop: [],
      rewardsOther: [],
      percentPool: 0,
      top: 0,
    });
    onChange(value);
  };
  return (
    <div>
      {value.map((valTop: any, index) => {
        return (
          <div key={index} className="mt-5">
            <Card fluid>
              <Card.Content>
                <Card.Header>
                  <div className="flex items-center">
                    <div className="mr-5 text-lg">{`Clan Top ${
                      index + 1
                    } `}</div>
                    <Button
                      icon="trash"
                      color="red"
                      onClick={() => {
                        let tmp = _.cloneDeep(value);
                        tmp.splice(index, 1);
                        onChange(tmp);
                      }}
                    />
                  </div>
                </Card.Header>
              </Card.Content>
              <Card.Content>
                <div className="mt-5">
                  <div>
                    <div className="font-bold">Percent Pool MSTR Rewards</div>
                    <Input
                      className="w-20"
                      value={valTop.percentPool}
                      placeholder="Number"
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(
                          index,
                          Number(evt.target.value),
                          "percentPool"
                        );
                      }}
                    />
                  </div>
                  <div>
                    <div className="font-bold">TOP</div>
                    <Input
                      className="w-20"
                      value={valTop.top}
                      placeholder="Number"
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(index, Number(evt.target.value), "top");
                      }}
                    />
                  </div>
                  <div className="font-bold">Rewards Top</div>
                  <div className="">
                    <RewardEditor
                      value={valTop.rewardsTop || []}
                      onChange={(val: any) => {
                        handleChange(index, val, "rewardsTop");
                      }}
                    />
                  </div>
                  <div className="font-bold">Rewards Other</div>
                  <div className="">
                    <RewardEditor
                      value={valTop.rewardsOther || []}
                      onChange={(val: any) => {
                        handleChange(index, val, "rewardsOther");
                      }}
                    />
                  </div>
                </div>
              </Card.Content>
            </Card>
          </div>
        );
      })}
      <div className="mt-5 text-right">
        <Button
          icon="plus"
          content="Add"
          labelPosition="left"
          onClick={addRewards}
        />
      </div>
    </div>
  );
}
