import { Chain, MarketSaleStatus, NFTType } from "interfaces";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Pagination,
  Select,
  Table,
} from "semantic-ui-react";
import { api } from "services";

export default function ReportMarketSaleTransaction() {
  const [customer_id, setCustomerId] = useState(null);
  const [nft_type, setNFTType] = useState(null);
  const [data, setData] = useState<any>([])
  const [header, setHeaders] = useState<any>([])
  const [page, setPage] = useState<any>(1);
  const [total, setTotal] = useState<any>(1);
  const [status, setStatus] = useState<any>(null);
  const pageSize = 50;

  async function loadData() {
    let query: any = {
      offset: Math.max(0, (page - 1) * pageSize),
      limit: pageSize,
    }

    if (status !== null) {
      query.status = status;
    }

    if (customer_id) {
      query.customer_id = customer_id;
    }

    if (nft_type !== null) {
      query.nft_type = nft_type;
    }

    let rs = await api.post("/operation/get-market-sale-transaction", query);
    let headers = ["id", "customer_id", "status", "tx_hash", "chain", "token_id", "rent_fee", "order_price", "quantity", "init_quantity", "nft_type", "order_id"];
    setHeaders(headers)
    let data = rs.rows
    setTotal(rs.count ?? 0)
    if (!data) {
      setData([])
    } else {
      setData(data)
    }
  }

  useEffect(() => {
    loadData();
  }, [customer_id, status, page])

  return (
    <div>
      <Card fluid>
        <Card.Content>
          <Form className="flex gap-2 w-full">
            <Form.Field className="w-full">
              <label>Customer id</label>
              <Input value={customer_id} onChange={(e, data) => {
                setCustomerId(data.value ? Number(data.value) : "")
              }} />
            </Form.Field>
            <Form.Field className="w-full">
              <label>Status</label>
              <Select
                placeholder={"Select status"}
                fluid
                value={status}
                options={[
                  {text: 'Lock Item Success', value: MarketSaleStatus.LockItemSuccess},
                  {text: 'Mint Item Success', value: MarketSaleStatus.MintItemSuccess},
                  {text: 'Mint Item Failed', value: MarketSaleStatus.MintItemFailed},
                  {text: 'Put Order Success', value: MarketSaleStatus.PutOrderSuccess},
                  {text: 'Put Order Failed', value: MarketSaleStatus.PutOrderFailed},
                  {text: 'Sell Order Success', value: MarketSaleStatus.SellOrderSuccess},
                  {text: 'Cancel Order Success', value: MarketSaleStatus.CancelOrderSuccess},
                  {text: 'Stake Item Success', value: MarketSaleStatus.StakeItemSuccess},
                  {text: 'Stake Item Failed', value: MarketSaleStatus.StakeItemFailed},
                  {text: 'Return Item Success', value: MarketSaleStatus.ReturnItemSuccess},
                  {text: 'Return Item Failed', value: MarketSaleStatus.ReturnItemFailed},
                ].map((i) => {
                  return i;
                })}
                onChange={(evt: any, val: any) => {
                  setStatus(val.value);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Action</label>
              <Button
                color="blue"
                icon="search"
                content="Search"
                labelPosition="left"
                onClick={() => loadData()}
              />
            </Form.Field>
          </Form>
        </Card.Content>

        <Card.Content>
          <Table celled sortable size="small">
            <Table.Header>
              {header.map((labelTable: any) => {
                return <Table.HeaderCell>{labelTable}</Table.HeaderCell>;
              })}
            </Table.Header>
            <Table.Body>
              {data.map((i: any) => {
                return (
                  <Table.Row>
                    {header.map((key: string) => {
                      if (key === 'nft_type') {
                        return (
                          <Table.Cell>{NFTType[i[key]]}</Table.Cell>
                        )
                      }
                      if (key === 'status') {
                        return (
                          <Table.Cell>{MarketSaleStatus[i[key]]}</Table.Cell>
                        )
                      }
                      if (key === 'chain') {
                        return (
                          <Table.Cell>{Chain[i[key]]}</Table.Cell>
                        )
                      }
                      return (
                        <Table.Cell>{i[key]}</Table.Cell>
                      )
                    })}
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
          <Pagination
            activePage={page}
            onPageChange={(page: any, rs: any) => {
              setPage(rs.activePage);
            }}
            size="mini"
            totalPages={Math.ceil(total / pageSize)}
          />
        </Card.Content>
      </Card>
    </div>
  );
}
