import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, Card, Input, Label } from "semantic-ui-react";
import { api, ui } from "services";
import dataServices from "services/data";

export default function NewbieQuestTrigger() {
  const [config, setConfig] = useState<any>(null);
  const newbieQuests = dataServices.getAllNewbieQuests();

  useEffect(() => {
    async function loadConfig() {
      let rs = await api.post("/operation/api/get-config", {
        where: { name: "NEWBIE_QUEST_TRIGGER" },
      });
      setConfig(rs?.data?.[0]);
    }
    console.log("dm");
    loadConfig();
  }, []);
  async function update() {
    try {
      config.value = JSON.parse(config.value)
    } catch (err) {}

    config.value = JSON.stringify(config.value)
    let rs = await api.post("/operation/api/update-config", config);
    setConfig(rs);
    ui.alert("Update success");
  }

  return (
    <div>
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <div className="flex justify-between">
              <p className="text-3xl font-semibold">NEWBIE QUEST TRIGGER</p>
              <Button
                icon="save"
                content="Save"
                primary
                onClick={() => {
                  update();
                }}
                labelPosition="left"
              />
            </div>
          </Card.Header>
        </Card.Content>
        {config?.value && <Card.Content>
          {JSON.parse(config.value).map((v: any, index: number) => {
            let questInfo = newbieQuests.find((i) => i.id === index + 1);
            return (
              <div className="flex gap-2 mb-3">
                <Input
                  type="number"
                  value={v}
                  label={`Quest #${index + 1}: ${questInfo?.description_admin?.replace("{0}", v)}`}
                  onChange={(e, data) => {
                    let dataValue = JSON.parse(config.value)
                    dataValue[index] = Number(data.value)
                    setConfig({
                      ...config,
                      value: JSON.stringify(dataValue)
                    })
                  }}
                />
              </div>
            )
          })}
        </Card.Content>}
      </Card>
    </div>
  );
}
