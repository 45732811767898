import BasicChart from "./basic-chart";

export default function FameChart(props: any) {
  return (
    <BasicChart
      {...props}
      url="/operation/get-user-group-report"
      chartType="pie"
    />
  );
}
