import { BarrackType, BombardType, CampType, MongenRace, PlotType, Rarity as MongenRarity, TowerType, TrapType } from "interfaces";

const Rarity = { Common: 0, Uncommon: 1, Rare: 2, Epic: 3, Legend: 4 };

const Config = {
  PlotTypes: [
    {
      name: "Landcore",
      rarity: Rarity.Common,
      img: "Landcore/Core_1.png",
      size: [2, 2],
      type: 0,
      race: 5,
    },
    {
      name: "Landcore",
      rarity: Rarity.Uncommon,
      img: "Landcore/Core_2.png",
      size: [2, 2],
      type: 0,
      race: 5,
    },
    {
      name: "Landcore",
      rarity: Rarity.Rare,
      img: "Landcore/Core_3.png",
      size: [2, 2],
      type: 0,
      race: 5,
    },
    {
      name: "Landcore",
      rarity: Rarity.Epic,
      img: "Landcore/Core_4.webp",
      size: [2, 2],
      type: 0,
      race: 5,
    },
    {
      name: "Hatching",
      rarity: Rarity.Common,
      img: "Hatching/Hatch_1.png",
      size: [2, 2],
      type: 3,
      race: 5,
    },
    {
      name: "Hatching",
      rarity: Rarity.Uncommon,
      img: "Hatching/Hatch_2.png",
      size: [2, 2],
      type: 3,
      race: 5,
    },
    {
      name: "Hatching",
      rarity: Rarity.Rare,
      img: "Hatching/Hatch_3.png",
      size: [2, 2],
      type: 3,
      race: 5,
    },
    {
      name: "Hatching",
      rarity: Rarity.Epic,
      img: "Hatching/Hatch_4.webp",
      size: [2, 2],
      type: 3,
      race: 5,
    },
    {
      name: "Breeding",
      rarity: Rarity.Common,
      img: "Breed/Breed_1.png",
      size: [2, 2],
      type: 2,
      race: 5,
    },
    {
      name: "Breeding",
      rarity: Rarity.Uncommon,
      img: "Breed/Breed_2.png",
      size: [2, 2],
      type: 2,
      race: 5,
    },
    {
      name: "Breeding",
      rarity: Rarity.Rare,
      img: "Breed/Breed_3.png",
      size: [2, 2],
      type: 2,
      race: 5,
    },
    {
      name: "Breeding",
      rarity: Rarity.Epic,
      img: "Breed/Breed_4.webp",
      size: [2, 2],
      type: 2,
      race: 5,
    },
    {
      name: "Production",
      rarity: Rarity.Common,
      img: "Production/Prodcution_1.png",
      size: [1, 1],
      type: 4,
      race: 5,
    },
    {
      name: "Production",
      rarity: Rarity.Uncommon,
      img: "Production/Production_2.png",
      size: [2, 1],
      type: 4,
      race: 5,
    },
    {
      name: "Production",
      rarity: Rarity.Rare,
      img: "Production/Production_3.png",
      size: [2, 2],
      type: 4,
      race: 5,
    },
    {
      name: "Production",
      rarity: Rarity.Epic,
      img: "Production/Production_4.webp",
      size: [2, 2],
      type: 4,
      race: 5,
    },
    {
      name: "Storage",
      rarity: Rarity.Common,
      img: "Storage/Storage_1.png",
      size: [2, 2],
      type: 5,
      race: 5,
    },
    {
      name: "Storage",
      rarity: Rarity.Uncommon,
      img: "Storage/Storage_2.png",
      size: [2, 2],
      type: 5,
      race: 5,
    },
    {
      name: "Storage",
      rarity: Rarity.Rare,
      img: "Storage/Storage_3.png",
      size: [2, 2],
      type: 5,
      race: 5,
    },
    {
      name: "Storage",
      rarity: Rarity.Epic,
      img: "Storage/Storage_4.webp",
      size: [2, 2],
      type: 5,
      race: 5,
    },
    {
      name: "Pasture Beast",
      rarity: Rarity.Common,
      img: "Beast/Beast_1.png",
      size: [1, 1],
      type: 1,
      race: MongenRace.Beast,
    },
    {
      name: "Pasture Beast",
      rarity: Rarity.Uncommon,
      img: "Beast/Beast_2.png",
      size: [2, 1],
      type: 1,
      race: MongenRace.Beast,
    },
    {
      name: "Pasture Beast",
      rarity: Rarity.Rare,
      img: "Beast/Beast_3.png",
      size: [2, 2],
      type: 1,
      race: MongenRace.Beast,
    },
    {
      name: "Pasture Beast",
      rarity: Rarity.Epic,
      img: "Beast/Beast_4.webp",
      size: [2, 2],
      type: 1,
      race: MongenRace.Beast,
    },
    {
      name: "Pasture Mystic",
      rarity: Rarity.Common,
      img: "Mystic/Mystic_1.png",
      size: [1, 1],
      type: 1,
      race: MongenRace.Mystic,
    },
    {
      name: "Pasture Mystic",
      rarity: Rarity.Uncommon,
      img: "Mystic/Mystic_2.png",
      size: [2, 1],
      type: 1,
      race: MongenRace.Mystic,
    },
    {
      name: "Pasture Mystic",
      rarity: Rarity.Rare,
      img: "Mystic/Mystic_3.png",
      size: [2, 2],
      type: 1,
      race: MongenRace.Mystic,
    },
    {
      name: "Pasture Mystic",
      rarity: Rarity.Epic,
      img: "Mystic/Mystic_4.webp",
      size: [2, 2],
      type: 1,
      race: MongenRace.Mystic,
    },
    {
      name: "Pasture Tectos",
      rarity: Rarity.Common,
      img: "Tectos/Tectos_1.png",
      size: [1, 1],
      type: 1,
      race: MongenRace.Tectos,
    },
    {
      name: "Pasture Tectos",
      rarity: Rarity.Uncommon,
      img: "Tectos/Tectos_2.png",
      size: [2, 1],
      type: 1,
      race: MongenRace.Tectos,
    },
    {
      name: "Pasture Tectos",
      rarity: Rarity.Rare,
      img: "Tectos/Tectos_3.png",
      size: [2, 2],
      type: 1,
      race: MongenRace.Tectos,
    },
    {
      name: "Pasture Tectos",
      rarity: Rarity.Epic,
      img: "Tectos/Tectos_4.webp",
      size: [2, 2],
      type: 1,
      race: MongenRace.Tectos,
    },
    {
      name: "Pasture Celest",
      rarity: Rarity.Common,
      img: "Celest/Celest_1.png",
      size: [1, 1],
      type: 1,
      race: MongenRace.Celest,
    },
    {
      name: "Pasture Celest",
      rarity: Rarity.Uncommon,
      img: "Celest/Celest_2.png",
      size: [2, 1],
      type: 1,
      race: MongenRace.Celest,
    },
    {
      name: "Pasture Celest",
      rarity: Rarity.Rare,
      img: "Celest/Celest_3.png",
      size: [2, 2],
      type: 1,
      race: MongenRace.Celest,
    },
    {
      name: "Pasture Celest",
      rarity: Rarity.Epic,
      img: "Celest/Celest_4.png",
      size: [2, 2],
      type: 1,
      race: MongenRace.Celest,
    },
    {
      name: "Pasture Chaos",
      rarity: Rarity.Common,
      img: "Chaos/Chaos_1.png",
      size: [1, 1],
      type: 1,
      race: MongenRace.Chaos,
    },
    {
      name: "Pasture Chaos",
      rarity: Rarity.Uncommon,
      img: "Chaos/Chaos_2.png",
      size: [2, 1],
      type: 1,
      race: MongenRace.Chaos,
    },
    {
      name: "Pasture Chaos",
      rarity: Rarity.Rare,
      img: "Chaos/Chaos_3.png",
      size: [2, 2],
      type: 1,
      race: MongenRace.Chaos,
    },
    {
      name: "Pasture Chaos",
      rarity: Rarity.Epic,
      img: "Chaos/Chaos_4.png",
      size: [2, 2],
      type: 1,
      race: MongenRace.Chaos,
    },
    {
      name: "Camp",
      rarity: Rarity.Common,
      img: "Camp/0.png",
      size: [2, 2],
      type: 6,
      race: 5,
    },
    {
      name: "Camp",
      rarity: Rarity.Uncommon,
      img: "Camp/1.png",
      size: [2, 2],
      type: 6,
      race: 5,
    },
    {
      name: "Camp",
      rarity: Rarity.Rare,
      img: "Camp/2.png",
      size: [2, 2],
      type: 6,
      race: 5,
    },
    {
      name: "Camp",
      rarity: Rarity.Epic,
      img: "Camp/3.png",
      size: [2, 2],
      type: 6,
      race: 5,
    },
    {
      name: "Camp",
      rarity: Rarity.Legend,
      img: "Camp/4.png",
      size: [2, 2],
      type: 6,
      race: 5,
    },
    {
      name: "Tower",
      rarity: Rarity.Common,
      img: "Tower/0.png",
      size: [2, 2],
      type: 7,
      race: 5,
    },
    {
      name: "Tower",
      rarity: Rarity.Uncommon,
      img: "Tower/1.png",
      size: [2, 2],
      type: 7,
      race: 5,
    },
    {
      name: "Tower",
      rarity: Rarity.Rare,
      img: "Tower/2.png",
      size: [2, 2],
      type: 7,
      race: 5,
    },
    {
      name: "Tower",
      rarity: Rarity.Epic,
      img: "Tower/3.png",
      size: [2, 2],
      type: 7,
      race: 5,
    },
    {
      name: "Tower",
      rarity: Rarity.Legend,
      img: "Tower/4.png",
      size: [2, 2],
      type: 7,
      race: 5,
    },
    {
      name: "Bombard",
      rarity: Rarity.Common,
      img: "Bombard/0.png",
      size: [2, 2],
      type: 8,
      race: 5,
    },
    {
      name: "Bombard",
      rarity: Rarity.Uncommon,
      img: "Bombard/1.png",
      size: [2, 2],
      type: 8,
      race: 5,
    },
    {
      name: "Bombard",
      rarity: Rarity.Rare,
      img: "Bombard/2.png",
      size: [2, 2],
      type: 8,
      race: 5,
    },
    {
      name: "Bombard",
      rarity: Rarity.Epic,
      img: "Bombard/3.png",
      size: [2, 2],
      type: 8,
      race: 5,
    },
    {
      name: "Bombard",
      rarity: Rarity.Legend,
      img: "Bombard/4.png",
      size: [2, 2],
      type: 8,
      race: 5,
    },
    {
      name: "Trap",
      rarity: Rarity.Common,
      img: "Trap/0.png",
      size: [1, 1],
      type: 9,
      race: 5,
    },
    {
      name: "Trap",
      rarity: Rarity.Uncommon,
      img: "Trap/1.png",
      size: [1, 1],
      type: 9,
      race: 5,
    },
    {
      name: "Trap",
      rarity: Rarity.Rare,
      img: "Trap/2.png",
      size: [1, 1],
      type: 9,
      race: 5,
    },
    {
      name: "Trap",
      rarity: Rarity.Epic,
      img: "Trap/3.png",
      size: [1, 1],
      type: 9,
      race: 5,
    },
    {
      name: "Trap",
      rarity: Rarity.Legend,
      img: "Trap/4.png",
      size: [1, 1],
      type: 9,
      race: 5,
    },
    {
      name: "Barrack",
      rarity: Rarity.Common,
      img: "Barrack/0.png",
      size: [1, 1],
      type: 10,
      race: 5,
    },
    {
      name: "Barrack",
      rarity: Rarity.Uncommon,
      img: "Barrack/1.png",
      size: [2, 1],
      type: 10,
      race: 5,
    },
    {
      name: "Barrack",
      rarity: Rarity.Rare,
      img: "Barrack/2.png",
      size: [2, 2],
      type: 10,
      race: 5,
    },
    {
      name: "Barrack",
      rarity: Rarity.Epic,
      img: "Barrack/3.png",
      size: [3, 3],
      type: 10,
      race: 5,
    },
    {
      name: "Barrack",
      rarity: Rarity.Legend,
      img: "Barrack/4.png",
      size: [3, 3],
      type: 10,
      race: 5,
    },
  ],
  MongenState: ["Egg", "Idle", "Breeding", "Working"],
  PlotType: [
    "LandCore",
    "Pasture",
    "Breeding",
    "Hatching",
    "Production",
    "Storage",
    "Camp",
    "Tower",
    "Bombard",
    "Trap",
    "Barracks",
    "Decor",
    "All",
    "Tree"
  ],
  NFTType: ["MONGEN", "SOUL_CORE", "LAND", "PLOT", "EVOL_ITEM", "SKILL_STONE"],
  Rarity: ["Common", "Uncommon", "Rare", "Epic", "Legendary"],
  Race: ["Beast", "Tectos", "Mystic", "Celest", "Chaos", "All"],
  CustomerResources: [
    "Food",
    "LOCK_MAG",
    "MSTR",
    "EvolutionItem",
    "NormalStone",
    "RerollStone",
    "EliteStone",
    "NormalRuneStone",
    "RerollRuneStone",
    "EliteRuneStone",
    "Experience",
    "TicketCO",
    "TicketUC",
    "TicketRA",
    "TicketEP",
    "TicketLE",
    "UNLOCK_MAG",
    "TicketGE",
    "TicketGL",
    "Energy",
    "Pigment",
    "ActionPoint",
    "BATTLEPASS_EXP",
    "ElectiveStoneCO",
    "ElectiveRuneStoneCO",
    "ElectiveStoneUC",
    "ElectiveRuneStoneUC",
    "ElectiveStoneRA",
    "ElectiveRuneStoneRA",
    "ElectiveStoneEP",
    "ElectiveRuneStoneEP",
    "ElectiveStoneLE",
    "ElectiveRuneStoneLE",
    "Elixir",
    "MutatedGen",
    "BATTLEPASS_EXP_ARENA",
  ],
  RewardType: [
    "FOOD",
    "EVOLUTION_ITEM",
    "LOCK_MAG",
    "EXP",
    "SOULCORE",
    "MSTR",
    "PLOT",
    "BOX",
    "TICKET",
    "NormalStone",
    "RerollStone",
    "EliteStone",
    "NormalRuneStone",
    "RerollRuneStone",
    "EliteRuneStone",
    "TROPHY_SCORE",
    "BattleFrontShield",
    "Energy",
    "Avatar",
    "DecorationList",
    "Pigment",
    "DailyQuestPoint",
    "UNLOCK_MAG",
    "BLESSING",
    "EMOJI",
    "BATTLEPASS_EXP",
    "ElectiveStoneCO",
    "ElectiveRuneStoneCO",
    "ElectiveStoneUC",
    "ElectiveRuneStoneUC",
    "ElectiveStoneRA",
    "ElectiveRuneStoneRA",
    "ElectiveStoneEP",
    "ElectiveRuneStoneEP",
    "ElectiveStoneLE",
    "ElectiveRuneStoneLE",
    "BattleQuestMongenAlive",
    "BattleQuestMongenDefeat",
  ],
  PlotEnumType: {
    LandCore: 0,
    Pasture: 1,
    Breeding: 2,
    Hatching: 3,
    Production: 4,
    Storage: 5,
    Camp: 6,
    Tower: 7,
    Bombard: 8,
    Trap: 9,
    Barracks: 10,
    Decor: 11,
    All: 12,
  },
  BreedingStats: [
    // statsList: HP - Duration - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [1250, 1675, 2240, 3005, 4030],
      [0, 0, 0, 0, 0],
      [0, 155, 465, 1395, 4195],
      [0, 0, 0, 0, 0],
      [0, 15552, 46656, 139968, 419904],
      [1, 3, 5, 7, 10],
    ],
    [
      [2000, 2680, 3590, 4810, 6445],
      [1, 2, 3, 4, 5],
      [0, 310, 930, 2795, 8395],
      [0, 0, 0, 0, 0],
      [0, 31104, 93312, 279936, 839808],
      [1, 3, 5, 7, 10],
    ],
    [
      [2750, 3685, 4935, 6615, 8865],
      [2, 4, 6, 8, 10],
      [0, 465, 1395, 4195, 12595],
      [0, 0, 0, 0, 0],
      [0, 46656, 139968, 419904, 1259712],
      [1, 3, 5, 7, 10],
    ],
    [
      [3500, 4690, 6280, 8420, 11280],
      [5, 10, 15, 20, 25],
      [0, 620, 1865, 5595, 16795],
      [0, 0, 0, 0, 0],
      [0, 62208, 186624, 559872, 1679616],
      [1, 3, 5, 7, 10],
    ],
    [
      [4000, 5360, 7180, 9620, 12895],
      [10, 20, 30, 40, 50],
      [0, 775, 2330, 6995, 20995],
      [0, 0, 0, 0, 0],
      [0, 77760, 233280, 699840, 2099520],
      [1, 3, 5, 7, 10],
    ],
  ],

  // [Rarity [statsList [level]]]
  // hatching plot stats
  HatchingStats: [
    // statsList: HP - Duration - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [1250, 1675, 2240, 3005, 4030],
      [0, 0, 0, 0, 0],
      [0, 135, 410, 1240, 3730],
      [0, 0, 0, 0, 0],
      [0, 13824, 41472, 124416, 373248],
      [1, 3, 5, 7, 10],
    ],
    [
      [2000, 2680, 3590, 4810, 6445],
      [1, 2, 3, 4, 5],
      [0, 275, 825, 2485, 7460],
      [0, 0, 0, 0, 0],
      [0, 27648, 82944, 248832, 746496],
      [1, 3, 5, 7, 10],
    ],
    [
      [2750, 3685, 4935, 6615, 8865],
      [2, 4, 6, 8, 10],
      [0, 410, 1240, 3730, 11195],
      [0, 0, 0, 0, 0],
      [0, 41472, 124416, 373248, 1119744],
      [1, 3, 5, 7, 10],
    ],
    [
      [3500, 4690, 6280, 8420, 11280],
      [5, 10, 15, 20, 25],
      [0, 550, 1655, 4975, 14925],
      [0, 0, 0, 0, 0],
      [0, 55296, 165888, 497664, 1492992],
      [1, 3, 5, 7, 10],
    ],
    [
      [4000, 5360, 7180, 9620, 12895],
      [10, 20, 30, 40, 50],
      [0, 690, 2070, 6220, 18660],
      [0, 0, 0, 0, 0],
      [0, 69120, 207360, 622080, 1866240],
      [1, 3, 5, 7, 10],
    ],
  ],

  // [Rarity [statsList [level]]]
  // landcore plot stats
  LandcoreStats: [
    // statsList: HP - Limit - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time
    [
      [6250, 7100, 8100, 9250, 10550, 12000, 13700, 15600, 17800, 20300],
      [22, 26, 32, 36, 42, 46, 52, 58, 62, 68],
      [0, 20, 85, 125, 412, 828, 1036, 1380, 2208, 2764],
      [0, 0, 0, 0, 3, 6, 7, 10, 15, 19],
      [0, 2160, 8640, 12960, 51840, 103680, 129600, 172800, 276480, 345600],
    ],
    [
      [8000, 9100, 10350, 11850, 13500, 15400, 17550, 20000, 22800, 26000],
      [30, 36, 44, 50, 58, 64, 72, 78, 86, 92],
      [0, 40, 170, 255, 828, 1656, 2072, 2764, 4420, 5528],
      [0, 0, 0, 0, 6, 12, 14, 19, 31, 38],
      [0, 4320, 17280, 25920, 103680, 207360, 259200, 345600, 552960, 691200],
    ],
    [
      [10000, 11400, 12950, 14800, 16850, 19250, 21900, 25000, 28500, 32500],
      [40, 48, 58, 68, 76, 86, 96, 104, 114, 124],
      [0, 60, 255, 385, 1244, 2488, 3108, 4144, 6632, 8292],
      [0, 0, 0, 0, 9, 17, 22, 29, 46, 58],
      [0, 6480, 25920, 38880, 155520, 311040, 388800, 518400, 829440, 1036800],
    ],
    [
      [12500, 14250, 16200, 18500, 21100, 24050, 27400, 31250, 35650, 40600],
      [52, 64, 76, 88, 100, 112, 124, 136, 148, 160],
      [0, 85, 345, 515, 1656, 3316, 4144, 5528, 8844, 11056],
      [0, 0, 0, 0, 12, 23, 29, 38, 61, 77],
      [0, 8640, 34560, 51840, 207360, 414720, 518400, 691200, 1105920, 1382400],
    ],
    [
      [15750, 17950, 20450, 23300, 26600, 30300, 34550, 39400, 44900, 51200],
      [68, 82, 98, 114, 130, 146, 162, 178, 194, 210],
      [0, 105, 430, 645, 2072, 4144, 5184, 6912, 11056, 13824],
      [0, 0, 0, 0, 14, 29, 36, 48, 77, 96],
      [0, 10800, 43200, 64800, 259200, 518400, 648000, 864000, 1382400, 1728000],
    ],
  ],

  // [Rarity [statsList [level]]]
  // production plot stats
  ProductionStats: [
    // statsList: HP - Unlock food - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [1400, 1510, 1630, 1760, 1900, 2055, 2220, 2395, 2590, 2795, 3020, 3260, 3525, 3805, 4110],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3],
      [0, 5, 5, 5, 20, 50, 105, 140, 180, 215, 285, 360, 720, 1440, 2590],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 60, 240, 600, 2400, 5400, 10800, 14400, 18000, 21600, 28800, 36000, 72000, 144000, 259200],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [1750, 1890, 2040, 2200, 2380, 2570, 2775, 2995, 3235, 3495, 3775, 4080, 4405, 4755, 5140],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3],
      [0, 5, 5, 10, 45, 105, 215, 285, 360, 430, 575, 720, 1440, 2880, 5180],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 120, 480, 1200, 4800, 10800, 21600, 28800, 36000, 43200, 57600, 72000, 144000, 288000, 518400],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [2100, 2265, 2445, 2645, 2855, 3085, 3330, 3595, 3885, 4195, 4530, 4895, 5285, 5710, 6165],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3],
      [0, 5, 5, 15, 70, 160, 320, 430, 540, 645, 860, 1080, 2160, 4320, 7775],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 180, 720, 1800, 7200, 16200, 32400, 43200, 54000, 64800, 86400, 108000, 216000, 432000, 777600],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [2450, 2645, 2855, 3085, 3330, 3595, 3885, 4195, 4530, 4895, 5285, 5710, 6165, 6660, 7195],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3],
      [0, 5, 5, 20, 95, 215, 430, 575, 720, 860, 1150, 1440, 2880, 5760, 10365],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 240, 960, 2400, 9600, 21600, 43200, 57600, 72000, 86400, 115200, 144000, 288000, 576000, 1036800],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [2800, 3020, 3265, 3525, 3805, 4110, 4440, 4795, 5180, 5595, 6040, 6525, 7050, 7610, 8220],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3],
      [0, 5, 10, 30, 120, 270, 540, 720, 900, 1080, 1440, 1800, 3600, 7200, 12960],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 300, 1200, 3000, 12000, 27000, 54000, 72000, 90000, 108000, 144000, 180000, 360000, 720000, 1296000],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
  ],

  // [Rarity [statsList [level]]]
  // statsList: HP - Slot - Feed bonus - Main Race [MongenRace type] - Sub Race [MongenRace type] - Upgrade cost - Upgrade time - Landcore level
  // pasture plot stats
  PastureStats: [
    // statsList: HP - Slot - Feed bonus - Main Race - Sub Race - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [1250, 1425, 1620, 1850, 2110, 2405, 2740, 3125, 3565, 4060],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [[0, 1, 2, 3, 4], [], [], [], [], [], [], [], [], []],
      [[], [], [], [], [], [], [], [], [], []],
      [0, 20, 55, 90, 108, 440, 772, 1244, 1932, 2208],
      [0, 0, 0, 0, 1, 3, 5, 9, 13, 15],
      [0, 2304, 5760, 9216, 13824, 55296, 96768, 155520, 241920, 276480],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ],
    [
      [2500, 2850, 3245, 3700, 4220, 4810, 5485, 6255, 7130, 8125],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 3],
      [[0, 1, 2, 3, 4], [], [], [], [], [], [], [], [], []],
      [[], [], [], [], [], [], [], [], [], []],
      [0, 45, 115, 180, 220, 884, 1548, 2488, 3868, 4420],
      [0, 0, 0, 0, 2, 6, 11, 17, 27, 31],
      [0, 4608, 11520, 18432, 27648, 110592, 193536, 311040, 483840, 552960],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ],
    [
      [3000, 3420, 3895, 4440, 5065, 5775, 6580, 7505, 8555, 9755],
      [4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [1.5, 1.5, 1.5, 1.5, 1.5, 2.5, 2.5, 2.5, 2.5, 5],
      [[0, 1, 2, 3, 4], [], [], [], [], [], [], [], [], []],
      [[], [], [], [], [], [], [], [], [], []],
      [0, 65, 170, 275, 328, 1324, 2320, 3732, 5804, 6632],
      [0, 0, 0, 0, 2, 9, 16, 26, 40, 46],
      [0, 6912, 17280, 27648, 41472, 165888, 290304, 466560, 725760, 829440],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ],
    [
      [3500, 3990, 4545, 5185, 5910, 6735, 7680, 8755, 9980, 11380],
      [4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [2, 2, 2, 2, 2, 4, 4, 4, 4, 8],
      [[0, 1, 2, 3, 4], [], [], [], [], [], [], [], [], []],
      [[], [], [], [], [], [1, 2, 0, 4, 3], [1, 2, 0, 4, 3], [1, 2, 0, 4, 3], [1, 2, 0, 4, 3], [1, 2, 0, 4, 3]],
      [0, 90, 230, 365, 440, 1768, 3096, 4976, 7740, 8844],
      [0, 0, 0, 0, 3, 12, 22, 35, 54, 61],
      [0, 9216, 23040, 36864, 55296, 221184, 387072, 622080, 967680, 1105920],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ],
    [
      [4000, 4560, 5195, 5925, 6755, 7700, 8775, 10005, 11410, 13005],
      [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [2.5, 2.5, 2.5, 2.5, 2.5, 5, 5, 5, 5, 10],
      [[0, 1, 2, 3, 4], [], [], [], [], [], [], [], [], []],
      [[], [], [], [], [], [1, 2, 0, 4, 3], [1, 2, 0, 4, 3], [1, 2, 0, 4, 3], [1, 2, 0, 4, 3], [1, 2, 0, 4, 3]],
      [0, 115, 285, 460, 552, 2208, 3868, 6220, 9676, 11056],
      [0, 0, 0, 0, 4, 15, 27, 43, 67, 77],
      [0, 11520, 28800, 46080, 69120, 276480, 483840, 777600, 1209600, 1382400],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ],
  ],
  // [Rarity [statsList [level]]]
  // pasture plot stats
  CampStats: [
    // statsList: HP - Regen - Radius - Slot - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [2500, 2850, 3245, 3700, 4220, 4810, 5485, 6255, 7130, 8125],
      [1, 1.08, 1.17, 1.26, 1.36, 1.47, 1.59, 1.71, 1.85, 2],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 35, 90, 140, 172, 276, 480, 1244, 1932, 2208],
      [0, 0, 0, 0, 1, 2, 3, 9, 13, 15],
      [0, 3600, 9000, 14400, 21600, 34560, 60480, 155520, 241920, 276480],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [4000, 4560, 5195, 5925, 6755, 7700, 8775, 10005, 11410, 13005],
      [1.5, 1.62, 1.75, 1.89, 2.04, 2.2, 2.38, 2.57, 2.78, 3],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 70, 180, 285, 344, 552, 964, 2488, 3868, 4420],
      [0, 0, 0, 0, 2, 4, 7, 17, 27, 31],
      [0, 7200, 18000, 28800, 43200, 69120, 120960, 311040, 483840, 552960],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [5500, 6270, 7145, 8145, 9285, 10585, 12070, 13760, 15685, 17885],
      [2, 2.16, 2.33, 2.52, 2.72, 2.94, 3.17, 3.43, 3.7, 4],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 105, 270, 430, 516, 828, 1448, 3732, 5804, 6632],
      [0, 0, 0, 0, 4, 6, 10, 26, 40, 46],
      [0, 10800, 27000, 43200, 64800, 103680, 181440, 466560, 725760, 829440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [7000, 7980, 9095, 10370, 11820, 13475, 15360, 17515, 19965, 22760],
      [2, 2.16, 2.33, 2.52, 2.72, 2.94, 3.17, 3.43, 3.7, 4],
      [3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 140, 360, 575, 688, 1104, 1932, 4976, 7740, 8844],
      [0, 0, 0, 0, 5, 8, 13, 35, 54, 61],
      [0, 14400, 36000, 57600, 86400, 138240, 241920, 622080, 967680, 1105920],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [8000, 9120, 10395, 11850, 13510, 15400, 17555, 20015, 22820, 26015],
      [2.5, 2.7, 2.92, 3.15, 3.4, 3.67, 3.97, 4.28, 4.63, 5],
      [6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 180, 450, 720, 864, 1380, 2416, 6220, 9676, 11056],
      [0, 0, 0, 0, 6, 10, 17, 43, 67, 77],
      [0, 18000, 45000, 72000, 108000, 172800, 302400, 777600, 1209600, 1382400],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
  ],
  // [Rarity [statsList [level]]]
  // barrack plot stats
  BarrackStats: [
    // statsList: HP - Trap rarities (RarityType[]) - Duration (%) - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [2750, 3120, 3540, 4020, 4560, 5175, 5875, 6670, 7570, 8595],
      [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 25, 70, 115, 136, 276, 480, 1244, 1932, 2208],
      [0, 0, 0, 0, 1, 2, 3, 9, 13, 15],
      [0, 2880, 7200, 11520, 17280, 34560, 60480, 155520, 241920, 276480],
      [1, 1, 2, 2, 3, 4, 5, 6, 7, 8],
    ],
    [
      [3300, 3745, 4250, 4825, 5475, 6215, 7050, 8005, 9085, 10315],
      [
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
      ],
      [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
      [0, 55, 140, 230, 276, 552, 964, 2488, 3868, 4420],
      [0, 0, 0, 0, 2, 4, 7, 17, 27, 31],
      [0, 5760, 14400, 23040, 34560, 69120, 120960, 311040, 483840, 552960],
      [1, 1, 2, 2, 3, 4, 5, 6, 7, 8],
    ],
    [
      [3850, 4365, 4955, 5625, 6385, 7250, 8230, 9340, 10600, 12030],
      [
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
      ],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      [0, 85, 215, 345, 412, 828, 1448, 3732, 5804, 6632],
      [0, 0, 0, 0, 3, 6, 10, 26, 40, 46],
      [0, 8640, 21600, 34560, 51840, 103680, 181440, 466560, 725760, 829440],
      [1, 1, 2, 2, 3, 4, 5, 6, 7, 8],
    ],
    [
      [4400, 4990, 5665, 6430, 7300, 8285, 9405, 10675, 12115, 13750],
      [
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
      ],
      [2.5, 5, 7.5, 10, 12.5, 15, 17.5, 20, 22.5, 25],
      [0, 115, 285, 460, 552, 1104, 1932, 4976, 7740, 8844],
      [0, 0, 0, 0, 4, 8, 13, 35, 54, 61],
      [0, 11520, 28800, 46080, 69120, 138240, 241920, 622080, 967680, 1105920],
      [1, 1, 2, 2, 3, 4, 5, 6, 7, 8],
    ],
    [
      [4950, 5615, 6375, 7235, 8210, 9320, 10580, 12010, 13630, 15470],
      [
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
      ],
      [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
      [0, 140, 360, 575, 688, 1380, 2416, 6220, 9676, 11056],
      [0, 0, 0, 0, 5, 10, 17, 43, 67, 77],
      [0, 14400, 36000, 57600, 86400, 172800, 302400, 777600, 1209600, 1382400],
      [1, 1, 2, 2, 3, 4, 5, 6, 7, 8],
    ],
  ],
  // [Rarity [statsList [level]]]
  // pasture plot stats
  TowerStats: [
    // statsList: HP - Bonus DMG - Slot - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [6300, 7180, 8185, 9330, 10640, 12130, 13825, 15760, 17970, 20485],
      [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 25, 70, 115, 136, 248, 432, 1116, 1740, 1988],
      [0, 0, 0, 0, 1, 2, 3, 8, 12, 14],
      [0, 2880, 7200, 11520, 17280, 31104, 54432, 139968, 217728, 248832],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [7550, 8605, 9810, 11185, 12750, 14535, 16570, 18890, 21535, 24550],
      [5, 5.4, 5.83, 6.3, 6.8, 7.35, 7.93, 8.57, 9.25, 10],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 55, 140, 230, 276, 496, 868, 2236, 3480, 3980],
      [0, 0, 0, 0, 2, 3, 6, 16, 24, 28],
      [0, 5760, 14400, 23040, 34560, 62208, 108864, 279936, 435456, 497664],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [8820, 10050, 11460, 13065, 14895, 16980, 19355, 22070, 25155, 28680],
      [5, 5.65, 6.38, 7.21, 8.15, 9.2, 10.4, 11.75, 13.27, 15],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 85, 215, 345, 412, 744, 1304, 3356, 5224, 5968],
      [0, 0, 0, 0, 3, 5, 9, 23, 36, 41],
      [0, 8640, 21600, 34560, 51840, 93312, 163296, 419904, 653184, 746496],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [10080, 11490, 13095, 14930, 17020, 19405, 22125, 25220, 28750, 32775],
      [5, 5.83, 6.8, 7.94, 9.26, 10.8, 12.6, 14.69, 17.14, 20],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 115, 285, 460, 552, 992, 1740, 4476, 6964, 7960],
      [0, 0, 0, 0, 4, 7, 12, 31, 48, 55],
      [0, 11520, 28800, 46080, 69120, 124416, 217728, 559872, 870912, 995328],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [11340, 12925, 14735, 16800, 19150, 21830, 24890, 28375, 32345, 36875],
      [5, 5.98, 7.15, 8.55, 10.22, 12.23, 14.62, 17.48, 20.9, 25],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 140, 360, 575, 688, 1244, 2176, 5596, 8708, 9952],
      [0, 0, 0, 0, 5, 9, 15, 39, 60, 69],
      [0, 14400, 36000, 57600, 86400, 155520, 272160, 699840, 1088640, 1244160],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
  ],
  // [Rarity [statsList [level]]]
  // pasture plot stats
  BombardStats: [
    // statsList: HP - Bonus DMG - Slot - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [7200, 8170, 9275, 10525, 11945, 13560, 15390, 17470, 19825, 22505],
      [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 30, 80, 125, 152, 260, 456, 1180, 1836, 2100],
      [0, 0, 0, 0, 1, 2, 3, 8, 13, 15],
      [0, 3240, 8100, 12960, 19440, 32832, 57456, 147744, 229824, 262656],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [8640, 9805, 11225, 12800, 14590, 16635, 18960, 21615, 24645, 28095],
      [5, 5.4, 5.83, 6.3, 6.8, 7.35, 7.93, 8.57, 9.25, 10],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 60, 160, 255, 308, 524, 916, 2360, 3676, 4200],
      [0, 0, 0, 0, 2, 4, 6, 16, 26, 29],
      [0, 6480, 16200, 25920, 38880, 65664, 114912, 295488, 459648, 525312],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [10080, 11440, 12985, 14735, 16725, 18985, 21545, 24455, 27760, 31505],
      [5, 5.65, 6.38, 7.21, 8.15, 9.2, 10.4, 11.75, 13.27, 15],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 95, 240, 385, 464, 784, 1376, 3544, 5512, 6300],
      [0, 0, 0, 0, 3, 5, 10, 25, 38, 44],
      [0, 9720, 24300, 38880, 58320, 98496, 172368, 443232, 689472, 787968],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [11520, 13075, 14840, 16840, 19115, 21695, 24625, 27950, 31725, 36005],
      [5, 5.83, 6.8, 7.94, 9.26, 10.8, 12.6, 14.69, 17.14, 20],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 125, 320, 515, 620, 1048, 1836, 4724, 7352, 8404],
      [0, 0, 0, 0, 4, 7, 13, 33, 51, 58],
      [0, 12960, 32400, 51840, 77760, 131328, 229824, 590976, 919296, 1050624],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [12960, 14705, 16695, 18945, 21505, 24410, 27705, 31445, 35690, 40510],
      [5, 5.98, 7.15, 8.55, 10.22, 12.23, 14.62, 17.48, 20.9, 25],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 160, 405, 645, 776, 1312, 2296, 5908, 9192, 10504],
      [0, 0, 0, 0, 5, 9, 16, 41, 64, 73],
      [0, 16200, 40500, 64800, 97200, 164160, 287280, 738720, 1149120, 1313280],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
  ],

  // [Rarity [statsList [level]]]
  // pasture plot stats
  TrapStats: [
    // statsList: Explosion Zone - DMG - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [800, 992, 1228, 1522, 1885, 2335, 2893, 3583, 4439, 5500],
      [0, 5, 5, 5, 10, 20, 25, 35, 55, 85],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 150, 600, 1800, 3600, 4860, 6480, 8100, 12960, 19440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [800, 1112, 1546, 2149, 2987, 4152, 5771, 8021, 11149, 15497],
      [0, 5, 5, 10, 25, 40, 55, 70, 115, 170],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 300, 1200, 3600, 7200, 9720, 12960, 16200, 25920, 38880],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [800, 1112, 1546, 2149, 2987, 4152, 5771, 8021, 11149, 15497],
      [0, 5, 5, 20, 40, 60, 85, 105, 170, 255],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 450, 1800, 5400, 10800, 14580, 19440, 24300, 38880, 58320],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [800, 1144, 1636, 2339, 3345, 4783, 6838, 9778, 13981, 19992],
      [0, 5, 5, 25, 55, 85, 115, 140, 230, 345],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 600, 2400, 7200, 14400, 19440, 25920, 32400, 51840, 77760],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [9, 9, 9, 9, 9, 9, 9, 9, 9, 9],
      [800, 1144, 1636, 2339, 3345, 4783, 6838, 9778, 13981, 19992],
      [0, 5, 5, 5, 20, 105, 215, 285, 360, 430],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 800, 3200, 9600, 19200, 25920, 34560, 43200, 69120, 103680],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
  ],

  // [Rarity [statsList [level]]]
  // storage plot stats
  StorageStats: [
    // statsList: HP - Capacity - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [4800, 5200, 5650, 6100, 6650, 7200, 7800, 8450, 9200, 10000, 10850, 11750, 12750, 13850, 15000],
      [880, 1350, 2150, 3400, 5400, 8400, 13250, 21000, 32500, 52000, 81000, 127500, 200000, 315000, 500000],
      [0, 5, 10, 15, 35, 50, 70, 90, 115, 170, 230, 1035, 2070, 3110, 4145],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 300, 1200, 1800, 3600, 5400, 7200, 9000, 11520, 17280, 23040, 103680, 207360, 311040, 414720],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [5400, 5850, 6350, 6850, 7450, 8100, 8800, 9550, 10350, 11250, 12200, 13200, 14350, 15550, 16900],
      [1780, 2750, 4350, 6900, 10800, 17000, 26850, 42000, 66500, 105000, 164000, 258000, 405000, 637000, 1000000],
      [0, 5, 20, 35, 70, 105, 140, 180, 230, 345, 460, 2070, 4145, 6220, 8290],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 600, 2400, 3600, 7200, 10800, 14400, 18000, 23040, 34560, 46080, 207360, 414720, 622080, 829440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [6000, 6500, 7050, 7650, 8300, 9000, 9750, 10600, 11500, 12500, 13550, 14700, 15950, 17300, 18800],
      [3500, 5600, 8800, 14000, 21750, 34000, 54000, 85000, 133000, 209000, 328500, 516500, 812000, 1276000, 2000000],
      [0, 5, 35, 50, 105, 160, 215, 270, 345, 515, 690, 3110, 6220, 9330, 12440],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 900, 3600, 5400, 10800, 16200, 21600, 27000, 34560, 51840, 69120, 311040, 622080, 933120, 1244160],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [6600, 7150, 7750, 8400, 9100, 9900, 10750, 11650, 12650, 13750, 14900, 16150, 17550, 19050, 20650],
      [5250, 8250, 12950, 20350, 32000, 50250, 79000, 124500, 196000, 308000, 483800, 760000, 1196000, 1879500, 3000000],
      [0, 10, 45, 70, 140, 215, 285, 360, 460, 690, 920, 4145, 8290, 12440, 16585],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 1200, 4800, 7200, 14400, 21600, 28800, 36000, 46080, 69120, 92160, 414720, 829440, 1244160, 1658880],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [7200, 7800, 8450, 9150, 9950, 10800, 11700, 12700, 13800, 15000, 16250, 17650, 19150, 20750, 22550],
      [7250, 11350, 17900, 28000, 44250, 70000, 109400, 172000, 270000, 425000, 668000, 1050000, 1650000, 2550000, 4000000],
      [0, 15, 60, 90, 180, 270, 360, 450, 575, 860, 1150, 5180, 10365, 15550, 20735],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 1500, 6000, 9000, 18000, 27000, 36000, 45000, 57600, 86400, 115200, 518400, 1036800, 1555200, 2073600],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
  ],

  LandcoreStatsList: {
    HP: 0,
    Limit: 1,
    UpgradeCostMag: 2,
    UpgradeCostMstr: 3,
    UpgradeTime: 4,
  },

  PastureStatsList: {
    HP: 0,
    Slot: 1,
    FeedBonus: 2,
    MainRace: 3,
    SubRace: 4,
    UpgradeCostMag: 5,
    UpgradeCostMstr: 6,
    UpgradeTime: 7,
    LandcoreLevel: 8,
  },

  BreedingStatsList: {
    HP: 0,
    Duration: 1,
    UpgradeCostMag: 2,
    UpgradeCostMstr: 3,
    UpgradeTime: 4,
    LandcoreLevel: 5,
  },

  HatchingStatsList: {
    HP: 0,
    Duration: 1,
    UpgradeCostMag: 2,
    UpgradeCostMstr: 3,
    UpgradeTime: 4,
    LandcoreLevel: 5,
  },

  ProductionStatsList: {
    HP: 0,
    UnlockFood: 1,
    UpgradeCostMag: 2,
    UpgradeCostMstr: 3,
    UpgradeTime: 4,
    LandcoreLevel: 5,
  },

  StorageStatsList: {
    HP: 0,
    Capacity: 1,
    UpgradeCostMag: 2,
    UpgradeCostMstr: 3,
    UpgradeTime: 4,
    LandcoreLevel: 5,
  },

  CampStatsList: {
    HP: 0,
    Regen: 1,
    Radius: 2,
    Slot: 3,
    UpgradeCostMag: 4,
    UpgradeCostMstr: 5,
    UpgradeTime: 6,
    LandcoreLevel: 7,
  },

  TowerStatsList: {
    HP: 0,
    BonusDMG: 1,
    Slot: 2,
    UpgradeCostMag: 3,
    UpgradeCostMstr: 4,
    UpgradeTime: 5,
    LandcoreLevel: 6,
  },

  BombardStatsList: {
    HP: 0,
    BonusDMG: 1,
    Slot: 2,
    UpgradeCostMag: 3,
    UpgradeCostMstr: 4,
    UpgradeTime: 5,
    LandcoreLevel: 6,
  },

  TrapStatsList: {
    ExplosionZone: 0,
    DMG: 1,
    UpgradeCostMag: 2,
    UpgradeCostMstr: 3,
    UpgradeTime: 4,
    LandcoreLevel: 5,
  },

  BarracksStatsList: {
    HP: 0,
    RarityType: 1,
    Duration: 2,
    UpgradeCostMag: 3,
    UpgradeCostMstr: 4,
    UpgradeTime: 5,
    LandcoreLevel: 6,
  },

  League: {
    'No League': 1,
    'Bronze III': 2,
    'Bronze II': 3,
    'Bronze I': 4,
    'Silver III': 5,
    'Silver II': 6,
    'Silver I': 7,
    'Gold III': 8,
    'Gold II': 9,
    'Gold I': 10,
    'Platinum III': 11,
    'Platinum II': 12,
    'Platinum I': 13,
    'Diamond': 14,
  },


  TrapType: {
    Default: 0,
    Poison: 1,
    Slowed: 2,
    Paralyzed: 3,
    Confused: 4,
    Bleeding: 5,
    Silence: 6,
    Burned: 7,
    MG: 8,
    STD: 9,
    AGR: 10,
  },

  BombardType: {
    Default: 0,
    RNG: 1,
    ENG: 2,
  },

  BarrackType: {
    Default: 0,
    Food: 1,
  },

  TowerType: {
    Default: 0,
    RNG: 1,
    CD: 2,
  },

  CampType: {
    Default: 0,
    HP: 1,
    MG: 2,
    STD: 3,
    AGR: 4,
  }
};
export default Config;

export const getSubType = (plotType: PlotType) => {
  switch (plotType) {
    case PlotType.Tower:
      return Config.TowerType
    case PlotType.Camp:
      return Config.CampType
    case PlotType.Barracks:
      return Config.BarrackType
    case PlotType.Trap:
      return Config.TrapType;
    case PlotType.Bombard:
      return Config.BombardType
    default:
      console.log("plotType not found")
      return null;
  }
}

export const getPlotIcon = (plot_type: PlotType, race: MongenRace, sub_plot_type: any, rarity: MongenRarity) => {
  let src = `/assets/plots/${PlotType[
    plot_type
  ].toLowerCase()}-${rarity}.webp`;

  switch (plot_type) {
    case PlotType.Pasture:
      src = `/assets/plots/${PlotType[
        plot_type
      ].toLowerCase()}-${MongenRace[race].toLowerCase()}-${rarity}.webp`;
      break;
    case PlotType.Trap:
      if (sub_plot_type) {
        src = `/assets/plots/${TrapType[
          sub_plot_type
        ].toLowerCase()}-${PlotType[
          plot_type
        ].toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Bombard:
      if (sub_plot_type) {
        src = `/assets/plots/${BombardType[
          sub_plot_type
        ].toLowerCase()}-${PlotType[
          plot_type
        ].toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Barracks:
      if (sub_plot_type) {
        src = `/assets/plots/${BarrackType[
          sub_plot_type
        ].toLowerCase()}-${PlotType[
          plot_type
        ].toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Tower:
      if (sub_plot_type) {
        src = `/assets/plots/${TowerType[
          sub_plot_type
        ].toLowerCase()}-${PlotType[
          plot_type
        ].toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Camp:
      if (sub_plot_type) {
        src = `/assets/plots/${CampType[
          sub_plot_type
        ].toLowerCase()}-${PlotType[
          plot_type
        ].toLowerCase()}-${rarity}.webp`;
      }
      break;
  }
  return src;
};