import ReactDOM from "react-dom";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-lightbox/style.css";
import "@fortawesome/fontawesome-free";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./reducer/store";
import { Provider } from "react-redux";
import { Suspense } from "react";
import "./i18n";
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<p>Loading</p>}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
