/** @format */

import { Routes, Route } from "react-router-dom";
import Profile from "profile";
import Home from "home";
import List from "list";
import Form from "form";
import Chart from "chart";
import Cheat from "cheat/cheat";
import LevelDesign from "cheat/levelDesign";
import MapDesign from "cheat/mapDesign";
import DnaSample from "cheat/dna";
import InitData from "cheat/editLand";
import Setting from "setting";
import CustomerInfo from "customer-info";
import SupportStatistic from "support-statistic";
import DNAMaker from "dna";
import Retention from "retention";
import TicketDetailHome from "ticket-detail/home";
import UploadGameBuild from "upload-game-build";
import Table from "table";
import CustomerInventory from "customer-inventory";
import QueryTool from "cheat/queryTool";
import DailyquestItems from "components/dailyquest-items";
import UploadGameBundle from "upload-game-bundle";
import WorldcupBetHistory from "world-cup-bet-history";
import SettingDaoConfig from "setting/setting-dao-config";
import Staking from "staking";
import ReportMongenRacing from "components/report-mongen-racing";
import ReportMongenRacingTransaction from "components/report-mongen-racing-transaction";
import ReportMongenWheel from "components/report-mongen-wheel";
import ReportMongenWheelTransaction from "components/report-mongen-wheel-transaction";
import IAPLockFeature from "setting/iap-lock-feature";
import ReportScholarLogs from "components/report-scholar-logs";
import ReportScholarEarns from "components/report-scholar-earns";
import ReportScholarClaimLogs from "components/report-scholar-claim-logs";
import ReportScholarErrorLogs from "components/report-scholar-error-logs";
import IAPTransaction from "iap-transaction";
import ReportIapTransaction from "components/report-iap-transaction";
import ArenaBot from "cheat/ArenaBot";
import ReportMarketTransaction from "components/report-market-transaction";
import NewbieQuestTrigger from "setting/newbie-quest-trigger";
import NewbieProcessRewards from "setting/newbie-milestone-rewards";
import ReportMarketSaleTransaction from "components/report-market-sale-transaction";
import TournamentSimulator from "components/tournament-simulator";
function Default() {
  return (
    <div className="w-full h-full">
      <p className="text-center text-2xl font-semibold">Monsterra!</p>
    </div>
  );
}
export default function Main({ showMenu }: { showMenu: boolean }) {
  return (
    <div>
      <div className={`p-4 pt-20 duration-200 ${showMenu && "pl-[260px]"}`}>
        <Routes>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/dashboard" element={<Home />}></Route>
          <Route path="/list/:name" element={<List />}></Route>
          <Route path="/form/:name/:mode" element={<Form />}></Route>
          <Route path="/chart/:type" element={<Chart />}></Route>
          <Route path="/table/:type" element={<Table />}></Route>
          <Route path="/cheat" element={<Cheat />}></Route>
          <Route path="/dna-editor" element={<DNAMaker />}></Route>
          <Route
            path="/leveldesign"
            element={<LevelDesign type={"adventure-stage"} />}
          ></Route>
          <Route path="/query-tool" element={<QueryTool />}></Route>
          <Route
            path="/battlefront-bot"
            element={<LevelDesign type={"battlefront-bot"} />}
          ></Route>
          <Route path="/arena-bot" element={<ArenaBot />}></Route>
          <Route
            path="/adventure-event"
            element={<LevelDesign type={"adventure-event"} />}
          ></Route>
          <Route path="/mapdesign" element={<MapDesign />}></Route>
          <Route path="/dna" element={<DnaSample />}></Route>
          <Route path="/edit-landbase" element={<InitData />}></Route>
          <Route path="/setting/:name" element={<Setting />}></Route>
          <Route
            path="/setting-dao/DAO_STAKES_CONFIG"
            element={<SettingDaoConfig />}
          ></Route>
          <Route
            path="/setting-custom/IAP_LOCK_FEATURES"
            element={<IAPLockFeature />}
          ></Route>
          <Route
            path="/setting-custom/NEWBIE_QUEST_TRIGGER"
            element={<NewbieQuestTrigger />}
          ></Route>
          <Route
            path="/setting-custom/NEWBIE_PROCESS_REWARDS"
            element={<NewbieProcessRewards />}
          ></Route>
          <Route path="/ticket-detail" element={<TicketDetailHome />}></Route>
          <Route path="/customer-info" element={<CustomerInfo />}></Route>
          <Route path="/retention" element={<Retention />}></Route>
          <Route path="/dailyquest-items" element={<DailyquestItems />}></Route>
          <Route
            path="/report-mongen-racing"
            element={<ReportMongenRacing />}
          ></Route>
          <Route
            path="/report-racing-transaction"
            element={<ReportMongenRacingTransaction />}
          ></Route>
          <Route
            path="/report-mongen-wheel"
            element={<ReportMongenWheel />}
          ></Route>
          <Route
            path="/report-wheel-transaction"
            element={<ReportMongenWheelTransaction />}
          ></Route>
          <Route
            path="/report-scholar-statistics"
            element={<ReportScholarEarns />}
          ></Route>
          <Route
            path="/report-scholar-logs"
            element={<ReportScholarLogs />}
          ></Route>
          <Route
            path="/scholar-action-error-logs"
            element={<ReportScholarErrorLogs />}
          ></Route>
          <Route
            path="/report-scholar-claim-logs"
            element={<ReportScholarClaimLogs />}
          ></Route>
          <Route
            path="/report-iap-transaction"
            element={<ReportIapTransaction />}
          ></Route>
          <Route
            path="/report-market-transaction"
            element={<ReportMarketTransaction />}
          ></Route>
          <Route
            path="/report-market-sale-transaction"
            element={<ReportMarketSaleTransaction />}
          ></Route>
          <Route
            path="/tournament-simulator"
            element={<TournamentSimulator />}
          ></Route>
          <Route
            path="/customer-inventory"
            element={<CustomerInventory />}
          ></Route>
          <Route path="/iap-transaction" element={<IAPTransaction />}></Route>
          <Route
            path="/world-cup-bet-history"
            element={<WorldcupBetHistory />}
          ></Route>
          <Route
            path="/upload-game-build"
            element={<UploadGameBuild />}
          ></Route>
          <Route
            path="/upload-game-bundle"
            element={<UploadGameBundle />}
          ></Route>
          <Route path="/" element={<Default />}></Route>

          <Route
            path="/support-statistic"
            element={<SupportStatistic />}
          ></Route>
          <Route path="/staking" element={<Staking />}></Route>
        </Routes>
      </div>
    </div>
  );
}
