import GameConstant from "config/game-constant";
import { BodyPart, MongenStatType } from "interfaces";

export default function getMongenStat(
  dna: number[][],
  level: number,
  evolution: number,
  morale_eff_value = 1
): {
  base: number[];
  max: number[];
  ultimateMax: number[];
  rarity: number;
  basePower: number;
  currentPower: number;
  maxPower: number;
} {
  const bodyPartsStat: number[][] = [
    GameConstant.BaseFormStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Form][0]] *
        GameConstant.FormRarityBaseMultiply[statIndex][dna[BodyPart.Form][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Head][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Head][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Eyes][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Eyes][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Horns][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Horns][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Tail][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Tail][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Back][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Back][1]
        ]
    ),
  ];
  let baseStat = bodyPartsStat.reduce((a: number[], b: number[]) => {
    let tmp = [];
    for (var i = 0; i < b.length; i++) {
      tmp.push((a[i] || 0) + b[i]);
    }
    return tmp;
  }, []);
  //calculate max stat

  // baseStat = baseStat.map((i) => Math.round(i));
  let race = dna[BodyPart.Form][0];
  let currentStat: number[] = [
    //health
    baseStat[MongenStatType.Health] * 5 +
      150 +
      20 *
        baseStat[MongenStatType.Health] *
        (GameConstant.LevelStatMultiply[MongenStatType.Health][race] / 100) *
        (level + evolution - 2) *
        10,
    //sturdiness
    baseStat[MongenStatType.Sturdiness] *
      (1 +
        4 *
          (GameConstant.LevelStatMultiply[MongenStatType.Sturdiness][race] /
            100) *
          (level + evolution - 2)),
    //magic
    baseStat[MongenStatType.Magic] *
      (1 +
        6 *
          (GameConstant.LevelStatMultiply[MongenStatType.Magic][race] / 100) *
          (level + evolution - 2)),
    //aggresion
    baseStat[MongenStatType.Aggresion] *
      (1 +
        3 *
          (GameConstant.LevelStatMultiply[MongenStatType.Aggresion][race] /
            100) *
          (level + evolution - 2)),
  ];

  currentStat = currentStat.map((stat) => stat * morale_eff_value);

  let MAX_LEVEL = 100;
  let MAX_EVOL = 5;
  let ultimateMaxStat: number[] = [
    //health
    baseStat[MongenStatType.Health] * 5 +
      150 +
      20 *
        baseStat[MongenStatType.Health] *
        (GameConstant.LevelStatMultiply[MongenStatType.Health][race] / 100) *
        (MAX_LEVEL + MAX_EVOL - 2) *
        10,
    //sturdiness
    baseStat[MongenStatType.Sturdiness] *
      (1 +
        4 *
          (GameConstant.LevelStatMultiply[MongenStatType.Sturdiness][race] /
            100) *
          (MAX_LEVEL + MAX_EVOL - 2)),
    //magic
    baseStat[MongenStatType.Magic] *
      (1 +
        6 *
          (GameConstant.LevelStatMultiply[MongenStatType.Magic][race] / 100) *
          (MAX_LEVEL + MAX_EVOL - 2)),
    //aggresion
    baseStat[MongenStatType.Aggresion] *
      (1 +
        3 *
          (GameConstant.LevelStatMultiply[MongenStatType.Aggresion][race] /
            100) *
          (MAX_LEVEL + MAX_EVOL - 2)),
  ];
  //calculate power
  let basePower = Math.round(
    (Math.pow(baseStat[0], 0.525) *
      Math.pow(baseStat[1], 0.5) *
      Math.pow(baseStat[2], 0.42) *
      Math.pow(baseStat[3], 0.5)) /
      5
  );
  let currentPower = Math.round(
    (Math.pow(currentStat[0] / 10, 0.525) *
      Math.pow(currentStat[1], 0.5) *
      Math.pow(currentStat[2], 0.42) *
      Math.pow(currentStat[3], 0.5)) /
      10
  );

  let maxPower = Math.round(
    (Math.pow(ultimateMaxStat[0] / 10, 0.525) *
      Math.pow(ultimateMaxStat[1], 0.5) *
      Math.pow(ultimateMaxStat[2], 0.42) *
      Math.pow(ultimateMaxStat[3], 0.5)) /
      10
  );
  let rarity = 0;
  for (var i = GameConstant.RarityPowerRange.length - 1; i >= 0; i--) {
    if (basePower > GameConstant.RarityPowerRange[i]) {
      rarity = i;
      break;
    }
  }
  baseStat = baseStat.map((i: number) => Math.round(i));
  currentStat = currentStat.map((i: number) => Math.round(i));
  ultimateMaxStat = ultimateMaxStat.map((i: number) => Math.round(i));
  return {
    base: baseStat,
    max: currentStat,
    ultimateMax: ultimateMaxStat,
    rarity,
    basePower,
    currentPower,
    maxPower,
  };
}
