import { WALLET_PROVIDER } from "interfaces";
import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input } from "semantic-ui-react";
import { api, ui, contract } from "services";
import keplrService from "services/keplr";

export default function BtnConnect({
  btn,
  index,
  item,
  setTimestamp,
  tokens
}: any): React.ReactElement {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { t } = useTranslation();

  return (
    <Button
      //@ts-ignore
      color={btn.color || "blue"}
      className="mr-1"
      content={t(btn.label)}
      size={btn.position === "top" ? "medium" : "mini"}
      key={index}
      primary
      loading={loadingBtn}
      onClick={async () => {
        try {
          setLoadingBtn(true)
          const listChain = await api.contractBridgeGet()
          const chain = listChain.find((el: any) => el.chainId == item.chainIdTo)
          const chain2 = tokens?.find((el: any) => el.chainId == item.chainIdTo)
          console.log({ chain2, item })
          if (chain2?.name === 'AURA') {
            let address = await keplrService.connect(WALLET_PROVIDER.KEPLR);
            if (address) {
              console.log(address, chain.contractProxy, {
                set_approve_transaction: {
                  status: true,
                  transaction_id: item._id.toString()
                }
              })
              let rs = await keplrService.execute(address, chain.contractProxy, {
                set_approve_transaction: {
                  status: true,
                  transaction_id: item._id.toString()
                }
              })
              const { transactionHash } = rs;
              await api.approveBridgePut({
                transactionId: item._id,
                txHash: transactionHash,
              });
              setTimeout(() => setTimestamp(new Date().getTime()), 2000);
              ui.alert(t(btn.successMessage || "Success"));
            }
          } else {
            let check = await contract.networkValidation(chain2);
            if (check) {
              await contract.setApproveTxByAmount(item._id, chain)
              setTimeout(() => setTimestamp(new Date().getTime()), 2000);
              ui.alert(t(btn.successMessage || "Success"));
            }
          }
        } catch (error: any) {
          ui.alert(t(btn.failMessage || t(error.message) || "Fail"));
        } finally {
          setLoadingBtn(false);
        }
      }}
    />
  )
}
