import { DatePicker } from "components";
import dayjs from "dayjs";
import _ from "lodash";
import { useState } from "react";
import ReactJson from "react-json-view";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Pagination,
  Select,
  Table,
} from "semantic-ui-react";
import { api, ui } from "services";

export default function DailyquestItems() {
  const pageSize = 50;
  const [inventory, setInventory] = useState(null);
  const [detail, setDetail] = useState(null);
  const [page, setPage] = useState<any>(1);
  const [dateFrom, setDateFrom] = useState(
    dayjs().add(-1, "week").startOf("day").toDate()
  );
  const [dateTo, setDateTo] = useState(dayjs().add(1, "day").toDate());
  const [group, setGroup] = useState<string>("date");
  async function loadData(page: any) {
    let rs = await api.post("/operation/get-dailyquest-item", {
      dateFrom: dateFrom,
      dateTo: dateTo,
      groupBy: group,
      limit: pageSize,
      offset: (page - 1) * pageSize,
      page,
    });
    if (!rs) return;
    const labelTable = ["Summary", "Detail"];
    setInventory({ ...rs, labelTable });
  }

  return (
    <div>
      <Card fluid>
        <Card.Content>
          <Form className="flex gap-2 w-full">
            <Form.Field className="w-full">
              <label>View by</label>
              <Select
                placeholder={"Select view time"}
                fluid
                value={group}
                options={["date", "week", "month"].map((i) => {
                  return { text: i, value: i };
                })}
                onChange={(evt: any, val: any) => {
                  setGroup(val.value);
                }}
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>From</label>
              <DatePicker
                todayButton="Today"
                selected={dateFrom}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateFrom(null);
                  } else {
                    setDateFrom(dayjs(val).startOf("day").toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>To</label>
              <DatePicker
                todayButton="Today"
                selected={dateTo}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateTo(null);
                  } else {
                    setDateTo(dayjs(val).endOf("day").toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </Form.Field>
            <Form.Field>
              <label>Action</label>
              <Button
                color="blue"
                icon="search"
                content="Search"
                labelPosition="left"
                onClick={() => loadData(1)}
              />
            </Form.Field>
          </Form>
        </Card.Content>
      </Card>

      {inventory &&
        inventory.labelTable.map((label: any, index: any) => {
          return (
            <Card key={`table_${index}`} fluid>
              <Card.Content>{label}</Card.Content>
              <Card.Content>
                <Table>
                  <Table.Header>
                    {inventory.tables[index].labels.map((labelTable: any) => {
                      return <Table.HeaderCell>{labelTable}</Table.HeaderCell>;
                    })}
                  </Table.Header>
                  <Table.Body>
                    {inventory.tables[index].data
                      .sort(
                        (a: any, b: any) =>
                          dayjs(b[3]).valueOf() - dayjs(a[3]).valueOf()
                      )
                      .map((dataset: any) => {
                        return (
                          <Table.Row>
                            {dataset.map((data: any) => (
                              <Table.Cell>{data}</Table.Cell>
                            ))}
                          </Table.Row>
                        );
                      })}
                  </Table.Body>
                </Table>
                {label !== "Summary" && (
                  <Pagination
                    activePage={page}
                    onPageChange={(page: any, rs: any) => {
                      setPage(rs.activePage);
                      loadData(rs.activePage);
                    }}
                    size="mini"
                    totalPages={
                      inventory?.total
                        ? Math.ceil(inventory?.total / pageSize)
                        : 0
                    }
                  />
                )}
              </Card.Content>
            </Card>
          );
        })}
      <Modal
        open={detail !== null}
        closeIcon
        onClose={() => {
          setDetail(null);
        }}
      >
        <Modal.Content>
          <div className="w-full flex gap-2">
            <ReactJson
              src={detail}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              name="input"
            />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
}
