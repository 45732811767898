/** @format */

const GameConstant = {
  GameEventInterval: 20,
  // rows: body, head, eyes, horns, tail, back
  // columns: ancient mom, ancient dad, grandma mom, grandpa mom, grandma dad, grandpa dad, mom, dad
  BreedingDistribute: [
    [0, 0, 25, 25, 25, 25, 50, 50],
    [40, 40, 25, 25, 25, 25, 10, 10],
    [10, 10, 25, 25, 30, 30, 35, 35],
    [35, 35, 25, 25, 30, 30, 10, 10],
    [10, 10, 25, 25, 30, 30, 35, 35],
    [30, 30, 20, 20, 20, 20, 30, 30],
  ],

  //  health - sturdiness - magic -aggrestion - rarity
  FormRarityBaseMultiply: [
    [1, 1.25, 1.5, 1.75, 2.25],
    [1, 1.25, 1.5, 1.75, 2.25],
    [1, 1.25, 1.5, 1.75, 2.25],
    [1, 1.25, 1.5, 1.75, 2.25],
  ],
  //  health - sturdiness - magic -aggrestion - rarity
  BodyPartRarityBaseMultiply: [
    [1, 1.25, 1.5, 1.75, 2.25],
    [1, 1.25, 1.5, 1.75, 2.25],
    [1, 1.25, 1.5, 1.75, 2.25],
    [1, 1.25, 1.5, 1.75, 2.25],
  ],

  //  health - sturdiness - magic -aggrestion - rarity
  LevelStatMultiply: [
    [1.5, 1.65, 1.65, 1.6, 1.6],
    [0.4, 0.65, 0.3, 0.65, 0.2],
    [0.2, 0.65, 0.75, 0.5, 0.6],
    [0.4, 0.4, 1.4, 0.2, 2],
  ],

  //level total base
  LevelTotalBase: 10,

  // feed per level
  FeedPerLevel: 5,
  // evolution level
  EvolutionLevel: [10, 20, 35, 60, 100],

  // max evolution
  MaxEvolution: 5,
  //rarity evolution
  RarityMaxEvolution: [0, 1, 2, 3, 4],

  // [health, spokiness, magic, aggresion] - race
  BaseFormStat: [
    [41, 35, 31, 39, 35],
    [39, 35, 39, 39, 31],
    [30, 39, 43, 35, 35],
    [30, 31, 27, 27, 39],
  ],

  // [health, spokiness, magic, aggresion] - race
  BaseBodyPartStat: [
    [3, 0, 0, 1, 1],
    [1, 3, 1, 3, 0],
    [0, 0, 0, 0, 0],
    [0, 1, 3, 0, 3],
  ],

  // Maximum value of statistic based on rarity
  // rarity[health, spokiness, magic, aggresion]
  MaximumRarityBase: [
    [778, 151, 70, 146],
    [943, 189, 87, 182],
    [1100, 226, 105, 218],
    [1233, 251, 122, 251],
    [1342, 275, 140, 275],
  ],
  // common - uncommon - rare - epic - legendary
  RarityPowerRange: [0, 264, 407, 580, 782],

  // LandCore,Pasture,Breeding,Hatching,Production,Storage,Camp,Tower,Bombard,Trap,Barracks,Decor
  PlotSkipFee: [0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0],
  // [rare [mode][food - time]]
  ProductionPlotConfig: [
    [
      [12, 120],
      [45, 300],
      [190, 900],
    ],
    [
      [490, 1800],
      [1200, 3600],
      [2840, 7200],
    ],
    [
      [3280, 7200],
      [11200, 21600],
      [25000, 43200],
    ],
    [
      [55400, 86400],
      [120500, 172800],
      [32600, 43200],
    ],
    [
      [70000, 86400],
      [150000, 172800],
      [40000, 43200],
    ],
  ],
  // common, uncommon, rare, epic, legendary
  rarityAncientDistribute: [68, 68, 8, 0, 0],

  // food rate per level
  FoodRateLevel: [
    0, 2, 4, 6, 10, 20, 30, 40, 50, 60, 120, 180, 240, 300, 360, 560, 760, 960,
    1160, 1360, 1860, 2360, 2860, 3360, 3860, 4860, 5860, 6860, 7860, 8860,
    9860, 10860, 11860, 12860, 13860, 16360, 18860, 21360, 23860, 26360, 31360,
    36360, 41360, 46360, 51360, 56360, 61360, 66360, 71360, 76360, 91360,
    106360, 121360, 136360, 151360, 166360, 181360, 196360, 211360, 226360,
    251360, 276360, 301360, 326360, 351360, 376360, 401360, 426360, 451360,
    476360, 516360, 556360, 596360, 636360, 676360, 716360, 756360, 796360,
    836360, 876360, 921360, 966360, 1011360, 1056360, 1101360, 1146360, 1191360,
    1236360, 1281360, 1326360, 1376360, 1426360, 1476360, 1526360, 1576360,
    1626360, 1676360, 1726360, 1776360, 1826360,
  ],
  // rare [food cost per click]
  FoodCostPerClick: [
    [0, 0, 0, 0, 0],
    [2, 3, 3, 4, 5],
    [4, 5, 6, 7, 9],
    [6, 8, 9, 11, 14],
    [10, 13, 15, 18, 23],
    [20, 25, 30, 35, 45],
    [30, 38, 45, 53, 68],
    [40, 50, 60, 70, 90],
    [50, 63, 75, 88, 113],
    [60, 75, 90, 105, 135],
    [120, 150, 180, 210, 270],
    [180, 225, 270, 315, 405],
    [240, 300, 360, 420, 540],
    [300, 375, 450, 525, 675],
    [360, 450, 540, 630, 810],
    [560, 700, 840, 980, 1260],
    [760, 950, 1140, 1330, 1710],
    [960, 1200, 1440, 1680, 2160],
    [1160, 1450, 1740, 2030, 2610],
    [1360, 1700, 2040, 2380, 3060],
    [1860, 2325, 2790, 3255, 4185],
    [2360, 2950, 3540, 4130, 5310],
    [2860, 3575, 4290, 5005, 6435],
    [3360, 4200, 5040, 5880, 7560],
    [3860, 4825, 5790, 6755, 8685],
    [4860, 6075, 7290, 8505, 10935],
    [5860, 7325, 8790, 10255, 13185],
    [6860, 8575, 10290, 12005, 15435],
    [7860, 9825, 11790, 13755, 17685],
    [8860, 11075, 13290, 15505, 19935],
    [9860, 12325, 14790, 17255, 22185],
    [10860, 13575, 16290, 19005, 24435],
    [11860, 14825, 17790, 20755, 26685],
    [12860, 16075, 19290, 22505, 28935],
    [13860, 17325, 20790, 24255, 31185],
    [16360, 20450, 24540, 28630, 36810],
    [18860, 23575, 28290, 33005, 42435],
    [21360, 26700, 32040, 37380, 48060],
    [23860, 29825, 35790, 41755, 53685],
    [26360, 32950, 39540, 46130, 59310],
    [31360, 39200, 47040, 54880, 70560],
    [36360, 45450, 54540, 63630, 81810],
    [41360, 51700, 62040, 72380, 93060],
    [46360, 57950, 69540, 81130, 104310],
    [51360, 64200, 77040, 89880, 115560],
    [56360, 70450, 84540, 98630, 126810],
    [61360, 76700, 92040, 107380, 138060],
    [66360, 82950, 99540, 116130, 149310],
    [71360, 89200, 107040, 124880, 160560],
    [76360, 95450, 114540, 133630, 171810],
    [91360, 114200, 137040, 159880, 205560],
    [106360, 132950, 159540, 186130, 239310],
    [121360, 151700, 182040, 212380, 273060],
    [136360, 170450, 204540, 238630, 306810],
    [151360, 189200, 227040, 264880, 340560],
    [166360, 207950, 249540, 291130, 374310],
    [181360, 226700, 272040, 317380, 408060],
    [196360, 245450, 294540, 343630, 441810],
    [211360, 264200, 317040, 369880, 475560],
    [226360, 282950, 339540, 396130, 509310],
    [251360, 314200, 377040, 439880, 565560],
    [276360, 345450, 414540, 483630, 621810],
    [301360, 376700, 452040, 527380, 678060],
    [326360, 407950, 489540, 571130, 734310],
    [351360, 439200, 527040, 614880, 790560],
    [376360, 470450, 564540, 658630, 846810],
    [401360, 501700, 602040, 702380, 903060],
    [426360, 532950, 639540, 746130, 959310],
    [451360, 564200, 677040, 789880, 1015560],
    [476360, 595450, 714540, 833630, 1071810],
    [516360, 645450, 774540, 903630, 1161810],
    [556360, 695450, 834540, 973630, 1251810],
    [596360, 745450, 894540, 1043630, 1341810],
    [636360, 795450, 954540, 1113630, 1431810],
    [676360, 845450, 1014540, 1183630, 1521810],
    [716360, 895450, 1074540, 1253630, 1611810],
    [756360, 945450, 1134540, 1323630, 1701810],
    [796360, 995450, 1194540, 1393630, 1791810],
    [836360, 1045450, 1254540, 1463630, 1881810],
    [876360, 1095450, 1314540, 1533630, 1971810],
    [921360, 1151700, 1382040, 1612380, 2073060],
    [966360, 1207950, 1449540, 1691130, 2174310],
    [1011360, 1264200, 1517040, 1769880, 2275560],
    [1056360, 1320450, 1584540, 1848630, 2376810],
    [1101360, 1376700, 1652040, 1927380, 2478060],
    [1146360, 1432950, 1719540, 2006130, 2579310],
    [1191360, 1489200, 1787040, 2084880, 2680560],
    [1236360, 1545450, 1854540, 2163630, 2781810],
    [1281360, 1601700, 1922040, 2242380, 2883060],
    [1326360, 1657950, 1989540, 2321130, 2984310],
    [1376360, 1720450, 2064540, 2408630, 3096810],
    [1426360, 1782950, 2139540, 2496130, 3209310],
    [1476360, 1845450, 2214540, 2583630, 3321810],
    [1526360, 1907950, 2289540, 2671130, 3434310],
    [1576360, 1970450, 2364540, 2758630, 3546810],
    [1626360, 2032950, 2439540, 2846130, 3659310],
    [1676360, 2095450, 2514540, 2933630, 3771810],
    [1726360, 2157950, 2589540, 3021130, 3884310],
    [1776360, 2220450, 2664540, 3108630, 3996810],
    [1826360, 2282950, 2739540, 3196130, 4109310],
  ],
  // rare [point cost per evolution]
  EvoPointsCost: [
    [0, 0, 0, 0, 0],
    [2250, 2813, 3375, 3938, 5063],
    [3750, 4688, 5625, 6563, 8438],
    [6000, 7500, 9000, 10500, 13500],
    [9000, 11250, 13500, 15750, 20250],
  ],
  // rare [MAG cost per evolution]
  EvoMAGCost: [
    [0, 0, 0, 0, 0],
    [1500, 1875, 2250, 2625, 3375],
    [3600, 4500, 5400, 6300, 8100],
    [18000, 22500, 27000, 31500, 40500],
    [36000, 45000, 54000, 63000, 81000],
  ],
  // rare [MSTR cost per evolution]
  EvoMSTRCost: [
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [10, 13, 15, 18, 23],
    [55, 69, 83, 96, 124],
    [110, 138, 165, 193, 248],
  ],
  // food rate based on rarity
  // Common - Uncommon -Rare - Epic -Legendary
  FoodRateRarity: [1, 1.25, 1.5, 1.75, 2.25],
  // Evolution [evolution_items - points_conversion - evolution_points - MAG - MSTR]
  EvolutionPrice: [
    [0, 150, 0, 0, 0],
    [15, 150, 2250, 1500, 0],
    [25, 150, 3750, 3600, 10],
    [40, 150, 6000, 18000, 55],
    [60, 150, 9000, 36000, 110],
  ],
  // skiptime threshold
  SkiptimeThreshold: [
    [30, 0],
    [300, 8],
    [21600, 12],
    [43200, 24],
    [43200, 20],
  ],
  // breeding fee
  // mag [births [mongen's rarity]]
  MAGSingleBreedingFee: [
    [300, 1460, 2965, 6050, 13055],
    [400, 2370, 4790, 9790, 21120],
    [655, 3830, 7750, 15840, 0],
    [1060, 6200, 12540, 0, 0],
    [1710, 10030, 0, 0, 0],
    [2770, 0, 0, 0, 0],
  ],
  // mstr [births [mongen's rarity]]
  MSTRSingleBreedingFee: [
    [0, 11.5, 23, 47, 103.25],
    [4, 19, 37, 75.5, 171.75],
    [7, 29, 58.75, 120, 0],
    [9.75, 46.75, 93.25, 0, 0],
    [15.75, 74.75, 0, 0, 0],
    [24.25, 0, 0, 0, 0],
  ],
  // breeding time base on [mongen's rarity]
  DurationSingleBreedingFee: [5400, 21600, 43200, 86400, 172800],
  // breeding limit base on [mongen's rarity]
  BreedingLimit: [6, 5, 4, 3, 2],

  // [Rarity [statsList [level]]]
  // breeding plot stats
  BreedingStats: [
    // statsList: HP - Duration - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [1250, 1675, 2240, 3005, 4030],
      [0, 0, 0, 0, 0],
      [0, 155, 465, 1395, 4195],
      [0, 0, 0, 0, 0],
      [0, 15552, 46656, 139968, 419904],
      [1, 3, 5, 7, 10],
    ],
    [
      [2000, 2680, 3590, 4810, 6445],
      [1, 2, 3, 4, 5],
      [0, 310, 930, 2795, 8395],
      [0, 0, 0, 0, 0],
      [0, 31104, 93312, 279936, 839808],
      [1, 3, 5, 7, 10],
    ],
    [
      [2750, 3685, 4935, 6615, 8865],
      [2, 4, 6, 8, 10],
      [0, 465, 1395, 4195, 12595],
      [0, 0, 0, 0, 0],
      [0, 46656, 139968, 419904, 1259712],
      [1, 3, 5, 7, 10],
    ],
    [
      [3500, 4690, 6280, 8420, 11280],
      [5, 10, 15, 20, 25],
      [0, 620, 1865, 5595, 16795],
      [0, 0, 0, 0, 0],
      [0, 62208, 186624, 559872, 1679616],
      [1, 3, 5, 7, 10],
    ],
    [
      [4000, 5360, 7180, 9620, 12895],
      [10, 20, 30, 40, 50],
      [0, 775, 2330, 6995, 20995],
      [0, 0, 0, 0, 0],
      [0, 77760, 233280, 699840, 2099520],
      [1, 3, 5, 7, 10],
    ],
  ],

  // [Rarity [statsList [level]]]
  // hatching plot stats
  HatchingStats: [
    // statsList: HP - Duration - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [1250, 1675, 2240, 3005, 4030],
      [0, 0, 0, 0, 0],
      [0, 135, 410, 1240, 3730],
      [0, 0, 0, 0, 0],
      [0, 13824, 41472, 124416, 373248],
      [1, 3, 5, 7, 10],
    ],
    [
      [2000, 2680, 3590, 4810, 6445],
      [1, 2, 3, 4, 5],
      [0, 275, 825, 2485, 7460],
      [0, 0, 0, 0, 0],
      [0, 27648, 82944, 248832, 746496],
      [1, 3, 5, 7, 10],
    ],
    [
      [2750, 3685, 4935, 6615, 8865],
      [2, 4, 6, 8, 10],
      [0, 410, 1240, 3730, 11195],
      [0, 0, 0, 0, 0],
      [0, 41472, 124416, 373248, 1119744],
      [1, 3, 5, 7, 10],
    ],
    [
      [3500, 4690, 6280, 8420, 11280],
      [5, 10, 15, 20, 25],
      [0, 550, 1655, 4975, 14925],
      [0, 0, 0, 0, 0],
      [0, 55296, 165888, 497664, 1492992],
      [1, 3, 5, 7, 10],
    ],
    [
      [4000, 5360, 7180, 9620, 12895],
      [10, 20, 30, 40, 50],
      [0, 690, 2070, 6220, 18660],
      [0, 0, 0, 0, 0],
      [0, 69120, 207360, 622080, 1866240],
      [1, 3, 5, 7, 10],
    ],
  ],

  // [Rarity [statsList [level]]]
  // landcore plot stats
  LandcoreStats: [
    // statsList: HP - Limit - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - sMAG safe rate
    [
      [6250, 7100, 8100, 9250, 10550, 12000, 13700, 15600, 17800, 20300],
      [22, 26, 32, 36, 42, 46, 52, 58, 62, 68],
      [0, 20, 85, 125, 412, 828, 1036, 1380, 2208, 2764],
      [0, 0, 0, 0, 3, 6, 7, 10, 15, 19],
      [0, 2160, 8640, 12960, 51840, 103680, 129600, 172800, 276480, 345600],
      [5, 5, 10, 10, 15, 15, 20, 20, 25, 25],
    ],
    [
      [8000, 9100, 10350, 11850, 13500, 15400, 17550, 20000, 22800, 26000],
      [30, 36, 44, 50, 58, 64, 72, 78, 86, 92],
      [0, 40, 170, 255, 828, 1656, 2072, 2764, 4420, 5528],
      [0, 0, 0, 0, 6, 12, 14, 19, 31, 38],
      [0, 4320, 17280, 25920, 103680, 207360, 259200, 345600, 552960, 691200],
      [5, 10, 10, 15, 15, 20, 20, 25, 30, 35],
    ],
    [
      [10000, 11400, 12950, 14800, 16850, 19250, 21900, 25000, 28500, 32500],
      [40, 48, 58, 68, 76, 86, 96, 104, 114, 124],
      [0, 60, 255, 385, 1244, 2488, 3108, 4144, 6632, 8292],
      [0, 0, 0, 0, 9, 17, 22, 29, 46, 58],
      [0, 6480, 25920, 38880, 155520, 311040, 388800, 518400, 829440, 1036800],
      [10, 10, 15, 15, 20, 25, 30, 35, 40, 45],
    ],
    [
      [12500, 14250, 16200, 18500, 21100, 24050, 27400, 31250, 35650, 40600],
      [52, 64, 76, 88, 100, 112, 124, 136, 148, 160],
      [0, 85, 345, 515, 1656, 3316, 4144, 5528, 8844, 11056],
      [0, 0, 0, 0, 12, 23, 29, 38, 61, 77],
      [0, 8640, 34560, 51840, 207360, 414720, 518400, 691200, 1105920, 1382400],
      [10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
    ],
    [
      [15750, 17950, 20450, 23300, 26600, 30300, 34550, 39400, 44900, 51200],
      [68, 82, 98, 114, 130, 146, 162, 178, 194, 210],
      [0, 105, 430, 645, 2072, 4144, 5184, 6912, 11056, 13824],
      [0, 0, 0, 0, 14, 29, 36, 48, 77, 96],
      [
        0, 10800, 43200, 64800, 259200, 518400, 648000, 864000, 1382400,
        1728000,
      ],
      [20, 25, 30, 35, 40, 45, 50, 55, 60, 65],
    ],
  ],

  // [Rarity [statsList [level]]]
  // production plot stats
  ProductionStats: [
    // statsList: HP - Unlock food - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [
        1400, 1510, 1630, 1760, 1900, 2055, 2220, 2395, 2590, 2795, 3020, 3260,
        3525, 3805, 4110,
      ],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3],
      [0, 5, 5, 5, 20, 50, 105, 140, 180, 215, 285, 360, 720, 1440, 2590],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [
        0, 60, 240, 600, 2400, 5400, 10800, 14400, 18000, 21600, 28800, 36000,
        72000, 144000, 259200,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        1750, 1890, 2040, 2200, 2380, 2570, 2775, 2995, 3235, 3495, 3775, 4080,
        4405, 4755, 5140,
      ],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3],
      [0, 5, 5, 10, 45, 105, 215, 285, 360, 430, 575, 720, 1440, 2880, 5180],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [
        0, 120, 480, 1200, 4800, 10800, 21600, 28800, 36000, 43200, 57600,
        72000, 144000, 288000, 518400,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        2100, 2265, 2445, 2645, 2855, 3085, 3330, 3595, 3885, 4195, 4530, 4895,
        5285, 5710, 6165,
      ],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3],
      [0, 5, 5, 15, 70, 160, 320, 430, 540, 645, 860, 1080, 2160, 4320, 7775],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [
        0, 180, 720, 1800, 7200, 16200, 32400, 43200, 54000, 64800, 86400,
        108000, 216000, 432000, 777600,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        2450, 2645, 2855, 3085, 3330, 3595, 3885, 4195, 4530, 4895, 5285, 5710,
        6165, 6660, 7195,
      ],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3],
      [0, 5, 5, 20, 95, 215, 430, 575, 720, 860, 1150, 1440, 2880, 5760, 10365],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [
        0, 240, 960, 2400, 9600, 21600, 43200, 57600, 72000, 86400, 115200,
        144000, 288000, 576000, 1036800,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        2800, 3020, 3265, 3525, 3805, 4110, 4440, 4795, 5180, 5595, 6040, 6525,
        7050, 7610, 8220,
      ],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3],
      [
        0, 5, 10, 30, 120, 270, 540, 720, 900, 1080, 1440, 1800, 3600, 7200,
        12960,
      ],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [
        0, 300, 1200, 3000, 12000, 27000, 54000, 72000, 90000, 108000, 144000,
        180000, 360000, 720000, 1296000,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
  ],

  // [Rarity [statsList [level]]]
  // statsList: HP - Slot - Feed bonus - Main Race [MongenRace type] - Sub Race [MongenRace type] - Upgrade cost - Upgrade time - Landcore level
  // pasture plot stats
  PastureStats: [
    // statsList: HP - Slot - Feed bonus - Main Race - Sub Race - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [905, 1030, 1175, 1340, 1525, 1740, 1985, 2260, 2580, 2940],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [[0, 1, 2, 3, 4], [], [], [], [], [], [], [], [], []],
      [[], [], [], [], [], [], [], [], [], []],
      [0, 20, 55, 90, 108, 440, 772, 1244, 1932, 2208],
      [0, 0, 0, 0, 1, 3, 5, 9, 13, 15],
      [0, 2304, 5760, 9216, 13824, 55296, 96768, 155520, 241920, 276480],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ],
    [
      [1890, 2150, 2455, 2800, 3190, 3635, 4145, 4725, 5390, 6145],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 3],
      [[0, 1, 2, 3, 4], [], [], [], [], [], [], [], [], []],
      [[], [], [], [], [], [], [], [], [], []],
      [0, 45, 115, 180, 220, 884, 1548, 2488, 3868, 4420],
      [0, 0, 0, 0, 2, 6, 11, 17, 27, 31],
      [0, 4608, 11520, 18432, 27648, 110592, 193536, 311040, 483840, 552960],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ],
    [
      [3955, 4505, 5135, 5855, 6675, 7615, 8680, 9895, 11280, 12860],
      [4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [1.5, 1.5, 1.5, 1.5, 1.5, 2.5, 2.5, 2.5, 2.5, 5],
      [[0, 1, 2, 3, 4], [], [], [], [], [], [], [], [], []],
      [[], [], [], [], [], [], [], [], [], []],
      [0, 65, 170, 275, 328, 1324, 2320, 3732, 5804, 6632],
      [0, 0, 0, 0, 2, 9, 16, 26, 40, 46],
      [0, 6912, 17280, 27648, 41472, 165888, 290304, 466560, 725760, 829440],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ],
    [
      [4130, 4705, 5365, 6115, 6975, 7950, 9065, 10330, 11780, 13430],
      [4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [2, 2, 2, 2, 2, 4, 4, 4, 4, 8],
      [[0, 1, 2, 3, 4], [], [], [], [], [], [], [], [], []],
      [
        [],
        [],
        [],
        [],
        [],
        [1, 2, 3, 4, 0],
        [1, 2, 3, 4, 0],
        [1, 2, 3, 4, 0],
        [1, 2, 3, 4, 0],
        [1, 2, 3, 4, 0],
      ],
      [0, 90, 230, 365, 440, 1768, 3096, 4976, 7740, 8844],
      [0, 0, 0, 0, 3, 12, 22, 35, 54, 61],
      [0, 9216, 23040, 36864, 55296, 221184, 387072, 622080, 967680, 1105920],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ],
    [
      [4300, 4900, 5585, 6370, 7260, 8275, 9435, 10755, 12265, 13980],
      [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [2.5, 2.5, 2.5, 2.5, 2.5, 5, 5, 5, 5, 10],
      [[0, 1, 2, 3, 4], [], [], [], [], [], [], [], [], []],
      [
        [],
        [],
        [],
        [],
        [],
        [1, 2, 3, 4, 0],
        [1, 2, 3, 4, 0],
        [1, 2, 3, 4, 0],
        [1, 2, 3, 4, 0],
        [1, 2, 3, 4, 0],
      ],
      [0, 115, 285, 460, 552, 2208, 3868, 6220, 9676, 11056],
      [0, 0, 0, 0, 4, 15, 27, 43, 67, 77],
      [0, 11520, 28800, 46080, 69120, 276480, 483840, 777600, 1209600, 1382400],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ],
  ],
  // [Rarity [statsList [level]]]
  // pasture plot stats
  CampStats: [
    // statsList: HP - Regen - Radius - Slot - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [2500, 2850, 3245, 3700, 4220, 4810, 5485, 6255, 7130, 8125],
      [1, 1.08, 1.17, 1.26, 1.36, 1.47, 1.59, 1.71, 1.85, 2],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 35, 90, 140, 172, 276, 480, 1244, 1932, 2208],
      [0, 0, 0, 0, 1, 2, 3, 9, 13, 15],
      [0, 3600, 9000, 14400, 21600, 34560, 60480, 155520, 241920, 276480],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [4000, 4560, 5195, 5925, 6755, 7700, 8775, 10005, 11410, 13005],
      [3, 3.24, 3.5, 3.78, 4.08, 4.41, 4.76, 5.14, 5.55, 6],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 70, 180, 285, 344, 552, 964, 2488, 3868, 4420],
      [0, 0, 0, 0, 2, 4, 7, 17, 27, 31],
      [0, 7200, 18000, 28800, 43200, 69120, 120960, 311040, 483840, 552960],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [5500, 6270, 7145, 8145, 9285, 10585, 12070, 13760, 15685, 17885],
      [3, 3.24, 3.5, 3.78, 4.08, 4.41, 4.76, 5.14, 5.55, 6],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 105, 270, 430, 516, 828, 1448, 3732, 5804, 6632],
      [0, 0, 0, 0, 4, 6, 10, 26, 40, 46],
      [0, 10800, 27000, 43200, 64800, 103680, 181440, 466560, 725760, 829440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [7000, 7980, 9095, 10370, 11820, 13475, 15360, 17515, 19965, 22760],
      [4, 4.32, 4.67, 5.04, 5.44, 5.88, 6.35, 6.86, 7.4, 8],
      [3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 140, 360, 575, 688, 1104, 1932, 4976, 7740, 8844],
      [0, 0, 0, 0, 5, 8, 13, 35, 54, 61],
      [0, 14400, 36000, 57600, 86400, 138240, 241920, 622080, 967680, 1105920],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [8000, 9120, 10395, 11850, 13510, 15400, 17555, 20015, 22820, 26015],
      [5, 5.4, 5.83, 6.3, 6.8, 7.35, 7.93, 8.57, 9.25, 10],
      [6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 180, 450, 720, 864, 1380, 2416, 6220, 9676, 11056],
      [0, 0, 0, 0, 6, 10, 17, 43, 67, 77],
      [
        0, 18000, 45000, 72000, 108000, 172800, 302400, 777600, 1209600,
        1382400,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
  ],
  // [Rarity [statsList [level]]]
  // barrack plot stats
  BarrackStats: [
    // statsList: HP - Trap rarities (RarityType[]) - Duration (%) - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [2750, 3120, 3540, 4020, 4560, 5175, 5875, 6670, 7570, 8595],
      [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 25, 70, 115, 136, 276, 480, 1244, 1932, 2208],
      [0, 0, 0, 0, 1, 2, 3, 9, 13, 15],
      [0, 2880, 7200, 11520, 17280, 34560, 60480, 155520, 241920, 276480],
      [1, 1, 2, 2, 3, 4, 5, 6, 7, 8],
    ],
    [
      [3300, 3745, 4250, 4825, 5475, 6215, 7050, 8005, 9085, 10315],
      [
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
      ],
      [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
      [0, 55, 140, 230, 276, 552, 964, 2488, 3868, 4420],
      [0, 0, 0, 0, 2, 4, 7, 17, 27, 31],
      [0, 5760, 14400, 23040, 34560, 69120, 120960, 311040, 483840, 552960],
      [1, 1, 2, 2, 3, 4, 5, 6, 7, 8],
    ],
    [
      [3850, 4365, 4955, 5625, 6385, 7250, 8230, 9340, 10600, 12030],
      [
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
      ],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      [0, 85, 215, 345, 412, 828, 1448, 3732, 5804, 6632],
      [0, 0, 0, 0, 3, 6, 10, 26, 40, 46],
      [0, 8640, 21600, 34560, 51840, 103680, 181440, 466560, 725760, 829440],
      [1, 1, 2, 2, 3, 4, 5, 6, 7, 8],
    ],
    [
      [4400, 4990, 5665, 6430, 7300, 8285, 9405, 10675, 12115, 13750],
      [
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
      ],
      [2.5, 5, 7.5, 10, 12.5, 15, 17.5, 20, 22.5, 25],
      [0, 115, 285, 460, 552, 1104, 1932, 4976, 7740, 8844],
      [0, 0, 0, 0, 4, 8, 13, 35, 54, 61],
      [0, 11520, 28800, 46080, 69120, 138240, 241920, 622080, 967680, 1105920],
      [1, 1, 2, 2, 3, 4, 5, 6, 7, 8],
    ],
    [
      [4950, 5615, 6375, 7235, 8210, 9320, 10580, 12010, 13630, 15470],
      [
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
      ],
      [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
      [0, 140, 360, 575, 688, 1380, 2416, 6220, 9676, 11056],
      [0, 0, 0, 0, 5, 10, 17, 43, 67, 77],
      [0, 14400, 36000, 57600, 86400, 172800, 302400, 777600, 1209600, 1382400],
      [1, 1, 2, 2, 3, 4, 5, 6, 7, 8],
    ],
  ],
  // [Rarity [statsList [level]]]
  // pasture plot stats
  TowerStats: [
    // statsList: HP - Bonus DMG - Slot - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [6300, 7180, 8185, 9330, 10640, 12130, 13825, 15760, 17970, 20485],
      [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 25, 70, 115, 136, 248, 432, 1116, 1740, 1988],
      [0, 0, 0, 0, 1, 2, 3, 8, 12, 14],
      [0, 2880, 7200, 11520, 17280, 31104, 54432, 139968, 217728, 248832],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [7550, 8605, 9810, 11185, 12750, 14535, 16570, 18890, 21535, 24550],
      [10, 10.8, 11.66, 12.6, 13.6, 14.69, 15.87, 17.14, 18.51, 19.99],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 55, 140, 230, 276, 496, 868, 2236, 3480, 3980],
      [0, 0, 0, 0, 2, 3, 6, 16, 24, 28],
      [0, 5760, 14400, 23040, 34560, 62208, 108864, 279936, 435456, 497664],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [8820, 10050, 11460, 13065, 14895, 16980, 19355, 22070, 25155, 28680],
      [10, 11.3, 12.76, 14.42, 16.29, 18.41, 20.8, 23.5, 26.55, 29.99],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 85, 215, 345, 412, 744, 1304, 3356, 5224, 5968],
      [0, 0, 0, 0, 3, 5, 9, 23, 36, 41],
      [0, 8640, 21600, 34560, 51840, 93312, 163296, 419904, 653184, 746496],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [10080, 11490, 13095, 14930, 17020, 19405, 22125, 25220, 28750, 32775],
      [10, 11.67, 13.61, 15.87, 18.52, 21.6, 25.19, 29.39, 34.28, 39.99],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 115, 285, 460, 552, 992, 1740, 4476, 6964, 7960],
      [0, 0, 0, 0, 4, 7, 12, 31, 48, 55],
      [0, 11520, 28800, 46080, 69120, 124416, 217728, 559872, 870912, 995328],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [11340, 12925, 14735, 16800, 19150, 21830, 24890, 28375, 32345, 36875],
      [15, 17.94, 21.45, 25.65, 30.67, 36.68, 43.86, 52.44, 62.71, 74.99],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 140, 360, 575, 688, 1244, 2176, 5596, 8708, 9952],
      [0, 0, 0, 0, 5, 9, 15, 39, 60, 69],
      [0, 14400, 36000, 57600, 86400, 155520, 272160, 699840, 1088640, 1244160],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
  ],
  // [Rarity [statsList [level]]]
  // pasture plot stats
  BombardStats: [
    // statsList: HP - Bonus DMG - Slot - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [7200, 8170, 9275, 10525, 11945, 13560, 15390, 17470, 19825, 22505],
      [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 30, 80, 125, 152, 260, 456, 1180, 1836, 2100],
      [0, 0, 0, 0, 1, 2, 3, 8, 13, 15],
      [0, 3240, 8100, 12960, 19440, 32832, 57456, 147744, 229824, 262656],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [8640, 9805, 11225, 12800, 14590, 16635, 18960, 21615, 24645, 28095],
      [10, 10.8, 11.66, 12.6, 13.6, 14.69, 15.87, 17.14, 18.51, 19.99],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 60, 160, 255, 308, 524, 916, 2360, 3676, 4200],
      [0, 0, 0, 0, 2, 4, 6, 16, 26, 29],
      [0, 6480, 16200, 25920, 38880, 65664, 114912, 295488, 459648, 525312],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [10080, 11440, 12985, 14735, 16725, 18985, 21545, 24455, 27760, 31505],
      [10, 11.3, 12.76, 14.42, 16.29, 18.41, 20.8, 23.5, 26.55, 29.99],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 95, 240, 385, 464, 784, 1376, 3544, 5512, 6300],
      [0, 0, 0, 0, 3, 5, 10, 25, 38, 44],
      [0, 9720, 24300, 38880, 58320, 98496, 172368, 443232, 689472, 787968],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [11520, 13075, 14840, 16840, 19115, 21695, 24625, 27950, 31725, 36005],
      [10, 11.67, 13.61, 15.87, 18.52, 21.6, 25.19, 29.39, 34.28, 39.99],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 125, 320, 515, 620, 1048, 1836, 4724, 7352, 8404],
      [0, 0, 0, 0, 4, 7, 13, 33, 51, 58],
      [0, 12960, 32400, 51840, 77760, 131328, 229824, 590976, 919296, 1050624],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [12960, 14705, 16695, 18945, 21505, 24410, 27705, 31445, 35690, 40510],
      [15, 17.94, 21.45, 25.65, 30.67, 36.68, 43.86, 52.44, 62.71, 74.99],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 160, 405, 645, 776, 1312, 2296, 5908, 9192, 10504],
      [0, 0, 0, 0, 5, 9, 16, 41, 64, 73],
      [0, 16200, 40500, 64800, 97200, 164160, 287280, 738720, 1149120, 1313280],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
  ],

  // [Rarity [statsList [level]]]
  // trap plot stats
  TrapStats: [
    // statsList: Explosion Zone - DMG - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [800, 992, 1228, 1522, 1885, 2335, 2893, 3583, 4439, 5500],
      [0, 5, 5, 5, 10, 20, 25, 35, 55, 85],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 150, 600, 1800, 3600, 4860, 6480, 8100, 12960, 19440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [800, 1112, 1546, 2149, 2987, 4152, 5771, 8021, 11149, 15497],
      [0, 5, 5, 10, 25, 40, 55, 70, 115, 170],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 300, 1200, 3600, 7200, 9720, 12960, 16200, 25920, 38880],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [800, 1112, 1546, 2149, 2987, 4152, 5771, 8021, 11149, 15497],
      [0, 5, 5, 20, 40, 60, 85, 105, 170, 255],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 450, 1800, 5400, 10800, 14580, 19440, 24300, 38880, 58320],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [800, 1144, 1636, 2339, 3345, 4783, 6838, 9778, 13981, 19992],
      [0, 5, 5, 25, 55, 85, 115, 140, 230, 345],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 600, 2400, 7200, 14400, 19440, 25920, 32400, 51840, 77760],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [9, 9, 9, 9, 9, 9, 9, 9, 9, 9],
      [800, 1144, 1636, 2339, 3345, 4783, 6838, 9778, 13981, 19992],
      [0, 5, 5, 5, 20, 105, 215, 285, 360, 430],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 800, 3200, 9600, 19200, 25920, 34560, 43200, 69120, 103680],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
  ],

  PoisonTrapStats: [
    // statsList: Explosion Zone - Duration - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [5, 6, 6, 7, 7, 8, 8, 9, 10, 10],
      [0, 5, 5, 5, 10, 20, 25, 35, 55, 85],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 150, 600, 1800, 3600, 4860, 6480, 8100, 12960, 19440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 1, 1, 1, 5, 5, 5],
      [10, 11, 12, 13, 14, 15, 16, 18, 19, 20],
      [0, 5, 5, 10, 25, 40, 55, 70, 115, 170],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 300, 1200, 3600, 7200, 9720, 12960, 16200, 25920, 38880],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 5, 5, 5, 5, 5, 5],
      [10, 12, 13, 15, 17, 19, 21, 24, 27, 30],
      [0, 5, 5, 20, 40, 60, 85, 105, 170, 255],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 450, 1800, 5400, 10800, 14580, 19440, 24300, 38880, 58320],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 5, 5, 5, 5, 9, 9, 9],
      [10, 12, 14, 16, 19, 22, 26, 30, 35, 40],
      [0, 5, 5, 25, 55, 85, 115, 140, 230, 345],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 600, 2400, 7200, 14400, 19440, 25920, 32400, 51840, 77760],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [5, 5, 5, 5, 5, 9, 9, 9, 9, 9],
      [10, 12, 15, 18, 21, 25, 30, 35, 42, 50],
      [0, 5, 5, 5, 20, 105, 215, 285, 360, 430],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 800, 3200, 9600, 19200, 25920, 34560, 43200, 69120, 103680],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
  ],
  SlowedTrapStats: [
    // statsList: Explosion Zone - Duration - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [15, 17, 18, 19, 21, 23, 24, 26, 28, 30],
      [0, 5, 5, 5, 10, 20, 25, 35, 55, 85],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 150, 600, 1800, 3600, 4860, 6480, 8100, 12960, 19440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 1, 1, 1, 5, 5, 5],
      [30, 33, 35, 38, 41, 45, 48, 52, 56, 60],
      [0, 5, 5, 10, 25, 40, 55, 70, 115, 170],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 300, 1200, 3600, 7200, 9720, 12960, 16200, 25920, 38880],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 5, 5, 5, 5, 5, 5],
      [30, 34, 39, 44, 49, 56, 63, 71, 80, 90],
      [0, 5, 5, 20, 40, 60, 85, 105, 170, 255],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 450, 1800, 5400, 10800, 14580, 19440, 24300, 38880, 58320],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 5, 5, 5, 5, 9, 9, 9],
      [30, 35, 41, 48, 56, 65, 76, 89, 103, 120],
      [0, 5, 5, 25, 55, 85, 115, 140, 230, 345],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 600, 2400, 7200, 14400, 19440, 25920, 32400, 51840, 77760],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [5, 5, 5, 5, 5, 9, 9, 9, 9, 9],
      [30, 36, 43, 52, 62, 74, 88, 105, 126, 150],
      [0, 5, 5, 5, 20, 105, 215, 285, 360, 430],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 800, 3200, 9600, 19200, 25920, 34560, 43200, 69120, 103680],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
  ],
  ParalyzedTrapStats: [
    // statsList: Explosion Zone - Duration - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [5, 6, 6, 7, 7, 8, 8, 9, 10, 10],
      [0, 5, 5, 5, 10, 20, 25, 35, 55, 85],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 150, 600, 1800, 3600, 4860, 6480, 8100, 12960, 19440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 1, 1, 1, 5, 5, 5],
      [10, 11, 12, 13, 14, 15, 16, 18, 19, 20],
      [0, 5, 5, 10, 25, 40, 55, 70, 115, 170],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 300, 1200, 3600, 7200, 9720, 12960, 16200, 25920, 38880],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 5, 5, 5, 5, 5, 5],
      [10, 12, 13, 15, 17, 19, 21, 24, 27, 30],
      [0, 5, 5, 20, 40, 60, 85, 105, 170, 255],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 450, 1800, 5400, 10800, 14580, 19440, 24300, 38880, 58320],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 5, 5, 5, 5, 9, 9, 9],
      [10, 12, 14, 16, 19, 22, 26, 30, 35, 40],
      [0, 5, 5, 25, 55, 85, 115, 140, 230, 345],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 600, 2400, 7200, 14400, 19440, 25920, 32400, 51840, 77760],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [5, 5, 5, 5, 5, 9, 9, 9, 9, 9],
      [10, 12, 15, 18, 21, 25, 30, 35, 42, 50],
      [0, 5, 5, 5, 20, 105, 215, 285, 360, 430],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 800, 3200, 9600, 19200, 25920, 34560, 43200, 69120, 103680],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
  ],
  ConfusedTrapStats: [
    // statsList: Explosion Zone - Duration - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [10, 11, 12, 13, 14, 15, 16, 18, 19, 20],
      [0, 5, 5, 5, 10, 20, 25, 35, 55, 85],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 150, 600, 1800, 3600, 4860, 6480, 8100, 12960, 19440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 1, 1, 1, 5, 5, 5],
      [20, 22, 24, 26, 28, 30, 32, 35, 38, 40],
      [0, 5, 5, 10, 25, 40, 55, 70, 115, 170],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 300, 1200, 3600, 7200, 9720, 12960, 16200, 25920, 38880],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 5, 5, 5, 5, 5, 5],
      [20, 23, 26, 29, 33, 37, 42, 47, 54, 60],
      [0, 5, 5, 20, 40, 60, 85, 105, 170, 255],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 450, 1800, 5400, 10800, 14580, 19440, 24300, 38880, 58320],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 5, 5, 5, 5, 9, 9, 9],
      [20, 24, 28, 32, 38, 44, 51, 59, 69, 80],
      [0, 5, 5, 25, 55, 85, 115, 140, 230, 345],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 600, 2400, 7200, 14400, 19440, 25920, 32400, 51840, 77760],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [5, 5, 5, 5, 5, 9, 9, 9, 9, 9],
      [20, 24, 29, 35, 41, 49, 59, 70, 84, 100],
      [0, 5, 5, 5, 20, 105, 215, 285, 360, 430],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 800, 3200, 9600, 19200, 25920, 34560, 43200, 69120, 103680],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
  ],
  BleedingTrapStats: [
    // statsList: Explosion Zone - Duration - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [15, 17, 18, 19, 21, 23, 24, 26, 28, 30],
      [0, 5, 5, 5, 10, 20, 25, 35, 55, 85],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 150, 600, 1800, 3600, 4860, 6480, 8100, 12960, 19440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 1, 1, 1, 5, 5, 5],
      [30, 33, 35, 38, 41, 45, 48, 52, 56, 60],
      [0, 5, 5, 10, 25, 40, 55, 70, 115, 170],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 300, 1200, 3600, 7200, 9720, 12960, 16200, 25920, 38880],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 5, 5, 5, 5, 5, 5],
      [30, 34, 39, 44, 49, 56, 63, 71, 80, 90],
      [0, 5, 5, 20, 40, 60, 85, 105, 170, 255],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 450, 1800, 5400, 10800, 14580, 19440, 24300, 38880, 58320],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 5, 5, 5, 5, 9, 9, 9],
      [30, 35, 41, 48, 56, 65, 76, 89, 103, 120],
      [0, 5, 5, 25, 55, 85, 115, 140, 230, 345],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 600, 2400, 7200, 14400, 19440, 25920, 32400, 51840, 77760],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [5, 5, 5, 5, 5, 9, 9, 9, 9, 9],
      [30, 36, 43, 52, 62, 74, 88, 105, 126, 150],
      [0, 5, 5, 5, 20, 105, 215, 285, 360, 430],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 800, 3200, 9600, 19200, 25920, 34560, 43200, 69120, 103680],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
  ],
  SilenceTrapStats: [
    // statsList: Explosion Zone - Duration - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [7, 8, 9, 9, 10, 11, 12, 12, 13, 14],
      [0, 5, 5, 5, 10, 20, 25, 35, 55, 85],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 150, 600, 1800, 3600, 4860, 6480, 8100, 12960, 19440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 1, 1, 1, 5, 5, 5],
      [15, 17, 18, 19, 21, 23, 24, 26, 28, 30],
      [0, 5, 5, 10, 25, 40, 55, 70, 115, 170],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 300, 1200, 3600, 7200, 9720, 12960, 16200, 25920, 38880],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 5, 5, 5, 5, 5, 5],
      [15, 17, 20, 22, 25, 28, 32, 36, 40, 45],
      [0, 5, 5, 20, 40, 60, 85, 105, 170, 255],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 450, 1800, 5400, 10800, 14580, 19440, 24300, 38880, 58320],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 5, 5, 5, 5, 9, 9, 9],
      [15, 18, 21, 24, 28, 33, 38, 45, 52, 60],
      [0, 5, 5, 25, 55, 85, 115, 140, 230, 345],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 600, 2400, 7200, 14400, 19440, 25920, 32400, 51840, 77760],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [5, 5, 5, 5, 5, 9, 9, 9, 9, 9],
      [15, 18, 22, 26, 31, 37, 44, 53, 63, 75],
      [0, 5, 5, 5, 20, 105, 215, 285, 360, 430],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 800, 3200, 9600, 19200, 25920, 34560, 43200, 69120, 103680],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
  ],
  BurnedTrapStats: [
    // statsList: Explosion Zone - Duration - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [15, 17, 18, 19, 21, 23, 24, 26, 28, 30],
      [0, 5, 5, 5, 10, 20, 25, 35, 55, 85],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 150, 600, 1800, 3600, 4860, 6480, 8100, 12960, 19440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 1, 1, 1, 5, 5, 5],
      [30, 33, 35, 38, 41, 45, 48, 52, 56, 60],
      [0, 5, 5, 10, 25, 40, 55, 70, 115, 170],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 300, 1200, 3600, 7200, 9720, 12960, 16200, 25920, 38880],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 1, 5, 5, 5, 5, 5, 5],
      [30, 34, 39, 44, 49, 56, 63, 71, 80, 90],
      [0, 5, 5, 20, 40, 60, 85, 105, 170, 255],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 450, 1800, 5400, 10800, 14580, 19440, 24300, 38880, 58320],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [1, 1, 1, 5, 5, 5, 5, 9, 9, 9],
      [30, 35, 41, 48, 56, 65, 76, 89, 103, 120],
      [0, 5, 5, 25, 55, 85, 115, 140, 230, 345],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 600, 2400, 7200, 14400, 19440, 25920, 32400, 51840, 77760],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
    [
      [5, 5, 5, 5, 5, 9, 9, 9, 9, 9],
      [30, 36, 43, 52, 62, 74, 88, 105, 126, 150],
      [0, 5, 5, 5, 20, 105, 215, 285, 360, 430],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 800, 3200, 9600, 19200, 25920, 34560, 43200, 69120, 103680],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
    ],
  ],
  MGTrapStats: [
    // statsList: Explosion Zone - Duration - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level - Stat reduce
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [15, 17, 18, 19, 21, 23, 24, 26, 28, 30],
      [0, 5, 5, 5, 10, 20, 25, 35, 55, 85],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 150, 600, 1800, 3600, 4860, 6480, 8100, 12960, 19440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
      [10, 10.8, 11.66, 12.6, 13.6, 14.69, 15.87, 17.14, 18.51, 19.99],
    ],
    [
      [1, 1, 1, 1, 1, 1, 1, 5, 5, 5],
      [30, 33, 35, 38, 41, 45, 48, 52, 56, 60],
      [0, 5, 5, 10, 25, 40, 55, 70, 115, 170],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 300, 1200, 3600, 7200, 9720, 12960, 16200, 25920, 38880],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
      [20, 21.6, 23.33, 25.19, 27.21, 29.39, 31.74, 34.28, 37.02, 39.98],
    ],
    [
      [1, 1, 1, 1, 5, 5, 5, 5, 5, 5],
      [30, 34, 39, 44, 49, 56, 63, 71, 80, 90],
      [0, 5, 5, 20, 40, 60, 85, 105, 170, 255],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 450, 1800, 5400, 10800, 14580, 19440, 24300, 38880, 58320],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
      [20, 22.6, 25.53, 28.84, 32.59, 36.82, 41.59, 46.99, 53.09, 59.99],
    ],
    [
      [1, 1, 1, 5, 5, 5, 5, 9, 9, 9],
      [30, 35, 41, 48, 56, 65, 76, 89, 103, 120],
      [0, 5, 5, 25, 55, 85, 115, 140, 230, 345],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 600, 2400, 7200, 14400, 19440, 25920, 32400, 51840, 77760],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
      [20, 23.33, 27.21, 31.75, 37.03, 43.2, 50.39, 58.78, 68.57, 79.98],
    ],
    [
      [5, 5, 5, 5, 5, 9, 9, 9, 9, 9],
      [30, 36, 43, 52, 62, 74, 88, 105, 126, 150],
      [0, 5, 5, 5, 20, 105, 215, 285, 360, 430],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 800, 3200, 9600, 19200, 25920, 34560, 43200, 69120, 103680],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
      [20, 23.92, 28.6, 34.2, 40.89, 48.9, 58.48, 69.93, 83.62, 99.99],
    ],
  ],
  STDTrapStats: [
    // statsList: Explosion Zone - Duration - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level - Stat reduce
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [15, 17, 18, 19, 21, 23, 24, 26, 28, 30],
      [0, 5, 5, 5, 10, 20, 25, 35, 55, 85],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 150, 600, 1800, 3600, 4860, 6480, 8100, 12960, 19440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
      [10, 10.8, 11.66, 12.6, 13.6, 14.69, 15.87, 17.14, 18.51, 19.99],
    ],
    [
      [1, 1, 1, 1, 1, 1, 1, 5, 5, 5],
      [30, 33, 35, 38, 41, 45, 48, 52, 56, 60],
      [0, 5, 5, 10, 25, 40, 55, 70, 115, 170],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 300, 1200, 3600, 7200, 9720, 12960, 16200, 25920, 38880],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
      [20, 21.6, 23.33, 25.19, 27.21, 29.39, 31.74, 34.28, 37.02, 39.98],
    ],
    [
      [1, 1, 1, 1, 5, 5, 5, 5, 5, 5],
      [30, 34, 39, 44, 49, 56, 63, 71, 80, 90],
      [0, 5, 5, 20, 40, 60, 85, 105, 170, 255],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 450, 1800, 5400, 10800, 14580, 19440, 24300, 38880, 58320],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
      [20, 22.6, 25.53, 28.84, 32.59, 36.82, 41.59, 46.99, 53.09, 59.99],
    ],
    [
      [1, 1, 1, 5, 5, 5, 5, 9, 9, 9],
      [30, 35, 41, 48, 56, 65, 76, 89, 103, 120],
      [0, 5, 5, 25, 55, 85, 115, 140, 230, 345],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 600, 2400, 7200, 14400, 19440, 25920, 32400, 51840, 77760],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
      [20, 23.33, 27.21, 31.75, 37.03, 43.2, 50.39, 58.78, 68.57, 79.98],
    ],
    [
      [5, 5, 5, 5, 5, 9, 9, 9, 9, 9],
      [30, 36, 43, 52, 62, 74, 88, 105, 126, 150],
      [0, 5, 5, 5, 20, 105, 215, 285, 360, 430],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 800, 3200, 9600, 19200, 25920, 34560, 43200, 69120, 103680],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
      [20, 23.92, 28.6, 34.2, 40.89, 48.9, 58.48, 69.93, 83.62, 99.99],
    ],
  ],
  AGRTrapStats: [
    // statsList: Explosion Zone - Duration - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level - Stat reduce
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [15, 17, 18, 19, 21, 23, 24, 26, 28, 30],
      [0, 5, 5, 5, 10, 20, 25, 35, 55, 85],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 150, 600, 1800, 3600, 4860, 6480, 8100, 12960, 19440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
      [10, 10.8, 11.66, 12.6, 13.6, 14.69, 15.87, 17.14, 18.51, 19.99],
    ],
    [
      [1, 1, 1, 1, 1, 1, 1, 5, 5, 5],
      [30, 33, 35, 38, 41, 45, 48, 52, 56, 60],
      [0, 5, 5, 10, 25, 40, 55, 70, 115, 170],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 300, 1200, 3600, 7200, 9720, 12960, 16200, 25920, 38880],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
      [20, 21.6, 23.33, 25.19, 27.21, 29.39, 31.74, 34.28, 37.02, 39.98],
    ],
    [
      [1, 1, 1, 1, 5, 5, 5, 5, 5, 5],
      [30, 34, 39, 44, 49, 56, 63, 71, 80, 90],
      [0, 5, 5, 20, 40, 60, 85, 105, 170, 255],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 450, 1800, 5400, 10800, 14580, 19440, 24300, 38880, 58320],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
      [20, 22.6, 25.53, 28.84, 32.59, 36.82, 41.59, 46.99, 53.09, 59.99],
    ],
    [
      [1, 1, 1, 5, 5, 5, 5, 9, 9, 9],
      [30, 35, 41, 48, 56, 65, 76, 89, 103, 120],
      [0, 5, 5, 25, 55, 85, 115, 140, 230, 345],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 600, 2400, 7200, 14400, 19440, 25920, 32400, 51840, 77760],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
      [20, 23.33, 27.21, 31.75, 37.03, 43.2, 50.39, 58.78, 68.57, 79.98],
    ],
    [
      [5, 5, 5, 5, 5, 9, 9, 9, 9, 9],
      [30, 36, 43, 52, 62, 74, 88, 105, 126, 150],
      [0, 5, 5, 5, 20, 105, 215, 285, 360, 430],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 800, 3200, 9600, 19200, 25920, 34560, 43200, 69120, 103680],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5],
      [20, 23.92, 28.6, 34.2, 40.89, 48.9, 58.48, 69.93, 83.62, 99.99],
    ],
  ],

  HPCampStats: [
    // statsList: HP - Slot - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level - Stat increase
    [
      [2500, 2850, 3245, 3700, 4220, 4810, 5485, 6255, 7130, 8125],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 35, 90, 140, 172, 276, 480, 1244, 1932, 2208],
      [0, 0, 0, 0, 1, 2, 3, 9, 13, 15],
      [0, 3600, 9000, 14400, 21600, 34560, 60480, 155520, 241920, 276480],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    ],
    [
      [4000, 4560, 5195, 5925, 6755, 7700, 8775, 10005, 11410, 13005],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 70, 180, 285, 344, 552, 964, 2488, 3868, 4420],
      [0, 0, 0, 0, 2, 4, 7, 17, 27, 31],
      [0, 7200, 18000, 28800, 43200, 69120, 120960, 311040, 483840, 552960],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [5, 5.4, 5.83, 6.3, 6.8, 7.35, 7.93, 8.57, 9.25, 10],
    ],
    [
      [5500, 6270, 7145, 8145, 9285, 10585, 12070, 13760, 15685, 17885],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 105, 270, 430, 516, 828, 1448, 3732, 5804, 6632],
      [0, 0, 0, 0, 4, 6, 10, 26, 40, 46],
      [0, 10800, 27000, 43200, 64800, 103680, 181440, 466560, 725760, 829440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [5, 5.65, 6.38, 7.21, 8.15, 9.2, 10.4, 11.75, 13.27, 15],
    ],
    [
      [7000, 7980, 9095, 10370, 11820, 13475, 15360, 17515, 19965, 22760],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 140, 360, 575, 688, 1104, 1932, 4976, 7740, 8844],
      [0, 0, 0, 0, 5, 8, 13, 35, 54, 61],
      [0, 14400, 36000, 57600, 86400, 138240, 241920, 622080, 967680, 1105920],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [5, 5.83, 6.8, 7.94, 9.26, 10.8, 12.6, 14.69, 17.14, 20],
    ],
    [
      [8000, 9120, 10395, 11850, 13510, 15400, 17555, 20015, 22820, 26015],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 180, 450, 720, 864, 1380, 2416, 6220, 9676, 11056],
      [0, 0, 0, 0, 6, 10, 17, 43, 67, 77],
      [
        0, 18000, 45000, 72000, 108000, 172800, 302400, 777600, 1209600,
        1382400,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [5, 5.98, 7.15, 8.55, 10.22, 12.23, 14.62, 17.48, 20.9, 25],
    ],
  ],
  MGCampStats: [
    // statsList: HP - Slot - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level - Stat increase
    [
      [2500, 2850, 3245, 3700, 4220, 4810, 5485, 6255, 7130, 8125],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 35, 90, 140, 172, 276, 480, 1244, 1932, 2208],
      [0, 0, 0, 0, 1, 2, 3, 9, 13, 15],
      [0, 3600, 9000, 14400, 21600, 34560, 60480, 155520, 241920, 276480],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [15, 15, 15, 15, 15, 15, 15, 15, 15, 15],
    ],
    [
      [4000, 4560, 5195, 5925, 6755, 7700, 8775, 10005, 11410, 13005],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 70, 180, 285, 344, 552, 964, 2488, 3868, 4420],
      [0, 0, 0, 0, 2, 4, 7, 17, 27, 31],
      [0, 7200, 18000, 28800, 43200, 69120, 120960, 311040, 483840, 552960],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [15, 16.2, 17.5, 18.9, 20.41, 22.04, 23.8, 25.71, 27.76, 29.99],
    ],
    [
      [5500, 6270, 7145, 8145, 9285, 10585, 12070, 13760, 15685, 17885],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 105, 270, 430, 516, 828, 1448, 3732, 5804, 6632],
      [0, 0, 0, 0, 4, 6, 10, 26, 40, 46],
      [0, 10800, 27000, 43200, 64800, 103680, 181440, 466560, 725760, 829440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [15, 16.95, 19.15, 21.63, 24.44, 27.61, 31.2, 35.25, 39.82, 44.99],
    ],
    [
      [7000, 7980, 9095, 10370, 11820, 13475, 15360, 17515, 19965, 22760],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 140, 360, 575, 688, 1104, 1932, 4976, 7740, 8844],
      [0, 0, 0, 0, 5, 8, 13, 35, 54, 61],
      [0, 14400, 36000, 57600, 86400, 138240, 241920, 622080, 967680, 1105920],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [15, 17.5, 20.41, 23.81, 27.77, 32.4, 37.79, 44.08, 51.42, 59.99],
    ],
    [
      [8000, 9120, 10395, 11850, 13510, 15400, 17555, 20015, 22820, 26015],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 180, 450, 720, 864, 1380, 2416, 6220, 9676, 11056],
      [0, 0, 0, 0, 6, 10, 17, 43, 67, 77],
      [
        0, 18000, 45000, 72000, 108000, 172800, 302400, 777600, 1209600,
        1382400,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [15, 17.94, 21.45, 25.65, 30.67, 36.68, 43.86, 52.44, 62.71, 74.99],
    ],
  ],
  STDCampStats: [
    // statsList: HP - Slot - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level - Stat increase
    [
      [2500, 2850, 3245, 3700, 4220, 4810, 5485, 6255, 7130, 8125],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 35, 90, 140, 172, 276, 480, 1244, 1932, 2208],
      [0, 0, 0, 0, 1, 2, 3, 9, 13, 15],
      [0, 3600, 9000, 14400, 21600, 34560, 60480, 155520, 241920, 276480],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [20, 20, 20, 20, 20, 20, 20, 20, 20, 20],
    ],
    [
      [4000, 4560, 5195, 5925, 6755, 7700, 8775, 10005, 11410, 13005],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 70, 180, 285, 344, 552, 964, 2488, 3868, 4420],
      [0, 0, 0, 0, 2, 4, 7, 17, 27, 31],
      [0, 7200, 18000, 28800, 43200, 69120, 120960, 311040, 483840, 552960],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [20, 21.6, 23.33, 25.19, 27.21, 29.39, 31.74, 34.28, 37.02, 39.98],
    ],
    [
      [5500, 6270, 7145, 8145, 9285, 10585, 12070, 13760, 15685, 17885],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 105, 270, 430, 516, 828, 1448, 3732, 5804, 6632],
      [0, 0, 0, 0, 4, 6, 10, 26, 40, 46],
      [0, 10800, 27000, 43200, 64800, 103680, 181440, 466560, 725760, 829440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [20, 22.6, 25.53, 28.84, 32.59, 36.82, 41.59, 46.99, 53.09, 59.99],
    ],
    [
      [7000, 7980, 9095, 10370, 11820, 13475, 15360, 17515, 19965, 22760],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 140, 360, 575, 688, 1104, 1932, 4976, 7740, 8844],
      [0, 0, 0, 0, 5, 8, 13, 35, 54, 61],
      [0, 14400, 36000, 57600, 86400, 138240, 241920, 622080, 967680, 1105920],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [20, 23.33, 27.21, 31.75, 37.03, 43.2, 50.39, 58.78, 68.57, 79.98],
    ],
    [
      [8000, 9120, 10395, 11850, 13510, 15400, 17555, 20015, 22820, 26015],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 180, 450, 720, 864, 1380, 2416, 6220, 9676, 11056],
      [0, 0, 0, 0, 6, 10, 17, 43, 67, 77],
      [
        0, 18000, 45000, 72000, 108000, 172800, 302400, 777600, 1209600,
        1382400,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [20, 23.92, 28.6, 34.2, 40.89, 48.9, 58.48, 69.93, 83.62, 99.99],
    ],
  ],
  AGRCampStats: [
    // statsList: HP - Slot - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level - Stat increase
    [
      [2500, 2850, 3245, 3700, 4220, 4810, 5485, 6255, 7130, 8125],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 35, 90, 140, 172, 276, 480, 1244, 1932, 2208],
      [0, 0, 0, 0, 1, 2, 3, 9, 13, 15],
      [0, 3600, 9000, 14400, 21600, 34560, 60480, 155520, 241920, 276480],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [15, 15, 15, 15, 15, 15, 15, 15, 15, 15],
    ],
    [
      [4000, 4560, 5195, 5925, 6755, 7700, 8775, 10005, 11410, 13005],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 70, 180, 285, 344, 552, 964, 2488, 3868, 4420],
      [0, 0, 0, 0, 2, 4, 7, 17, 27, 31],
      [0, 7200, 18000, 28800, 43200, 69120, 120960, 311040, 483840, 552960],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [15, 16.2, 17.5, 18.9, 20.41, 22.04, 23.8, 25.71, 27.76, 29.99],
    ],
    [
      [5500, 6270, 7145, 8145, 9285, 10585, 12070, 13760, 15685, 17885],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 105, 270, 430, 516, 828, 1448, 3732, 5804, 6632],
      [0, 0, 0, 0, 4, 6, 10, 26, 40, 46],
      [0, 10800, 27000, 43200, 64800, 103680, 181440, 466560, 725760, 829440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [15, 16.95, 19.15, 21.63, 24.44, 27.61, 31.2, 35.25, 39.82, 44.99],
    ],
    [
      [7000, 7980, 9095, 10370, 11820, 13475, 15360, 17515, 19965, 22760],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 140, 360, 575, 688, 1104, 1932, 4976, 7740, 8844],
      [0, 0, 0, 0, 5, 8, 13, 35, 54, 61],
      [0, 14400, 36000, 57600, 86400, 138240, 241920, 622080, 967680, 1105920],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [15, 17.5, 20.41, 23.81, 27.77, 32.4, 37.79, 44.08, 51.42, 59.99],
    ],
    [
      [8000, 9120, 10395, 11850, 13510, 15400, 17555, 20015, 22820, 26015],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 180, 450, 720, 864, 1380, 2416, 6220, 9676, 11056],
      [0, 0, 0, 0, 6, 10, 17, 43, 67, 77],
      [
        0, 18000, 45000, 72000, 108000, 172800, 302400, 777600, 1209600,
        1382400,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
      [15, 17.94, 21.45, 25.65, 30.67, 36.68, 43.86, 52.44, 62.71, 74.99],
    ],
  ],
  FoodBarrackStats: [
    // statsList: HP - Trap rarities (RarityType[]) - Food cost (%) - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [2750, 3120, 3540, 4020, 4560, 5175, 5875, 6670, 7570, 8595],
      [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 25, 70, 115, 136, 276, 480, 1244, 1932, 2208],
      [0, 0, 0, 0, 1, 2, 3, 9, 13, 15],
      [0, 2880, 7200, 11520, 17280, 34560, 60480, 155520, 241920, 276480],
      [1, 1, 2, 2, 3, 4, 5, 6, 7, 8],
    ],
    [
      [3300, 3745, 4250, 4825, 5475, 6215, 7050, 8005, 9085, 10315],
      [
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
        [0, 1],
      ],
      [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
      [0, 55, 140, 230, 276, 552, 964, 2488, 3868, 4420],
      [0, 0, 0, 0, 2, 4, 7, 17, 27, 31],
      [0, 5760, 14400, 23040, 34560, 69120, 120960, 311040, 483840, 552960],
      [1, 1, 2, 2, 3, 4, 5, 6, 7, 8],
    ],
    [
      [3850, 4365, 4955, 5625, 6385, 7250, 8230, 9340, 10600, 12030],
      [
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
        [0, 1, 2],
      ],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      [0, 85, 215, 345, 412, 828, 1448, 3732, 5804, 6632],
      [0, 0, 0, 0, 3, 6, 10, 26, 40, 46],
      [0, 8640, 21600, 34560, 51840, 103680, 181440, 466560, 725760, 829440],
      [1, 1, 2, 2, 3, 4, 5, 6, 7, 8],
    ],
    [
      [4400, 4990, 5665, 6430, 7300, 8285, 9405, 10675, 12115, 13750],
      [
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
      ],
      [2.5, 5, 7.5, 10, 12.5, 15, 17.5, 20, 22.5, 25],
      [0, 115, 285, 460, 552, 1104, 1932, 4976, 7740, 8844],
      [0, 0, 0, 0, 4, 8, 13, 35, 54, 61],
      [0, 11520, 28800, 46080, 69120, 138240, 241920, 622080, 967680, 1105920],
      [1, 1, 2, 2, 3, 4, 5, 6, 7, 8],
    ],
    [
      [4950, 5615, 6375, 7235, 8210, 9320, 10580, 12010, 13630, 15470],
      [
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4],
      ],
      [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
      [0, 140, 360, 575, 688, 1380, 2416, 6220, 9676, 11056],
      [0, 0, 0, 0, 5, 10, 17, 43, 67, 77],
      [0, 14400, 36000, 57600, 86400, 172800, 302400, 777600, 1209600, 1382400],
      [1, 1, 2, 2, 3, 4, 5, 6, 7, 8],
    ],
  ],
  RNGTowerStats: [
    // statsList: HP - Bonus RNG - Slot - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [6300, 7180, 8185, 9330, 10640, 12130, 13825, 15760, 17970, 20485],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 25, 70, 115, 136, 248, 432, 1116, 1740, 1988],
      [0, 0, 0, 0, 1, 2, 3, 8, 12, 14],
      [0, 2880, 7200, 11520, 17280, 31104, 54432, 139968, 217728, 248832],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [7550, 8605, 9810, 11185, 12750, 14535, 16570, 18890, 21535, 24550],
      [1, 1, 1, 1, 2, 2, 2, 2, 2, 3],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 55, 140, 230, 276, 496, 868, 2236, 3480, 3980],
      [0, 0, 0, 0, 2, 3, 6, 16, 24, 28],
      [0, 5760, 14400, 23040, 34560, 62208, 108864, 279936, 435456, 497664],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [8820, 10050, 11460, 13065, 14895, 16980, 19355, 22070, 25155, 28680],
      [1, 1, 1, 2, 2, 2, 3, 3, 4, 5],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 85, 215, 345, 412, 744, 1304, 3356, 5224, 5968],
      [0, 0, 0, 0, 3, 5, 9, 23, 36, 41],
      [0, 8640, 21600, 34560, 51840, 93312, 163296, 419904, 653184, 746496],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [10080, 11490, 13095, 14930, 17020, 19405, 22125, 25220, 28750, 32775],
      [1, 1, 2, 2, 2, 3, 4, 5, 6, 7],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 115, 285, 460, 552, 992, 1740, 4476, 6964, 7960],
      [0, 0, 0, 0, 4, 7, 12, 31, 48, 55],
      [0, 11520, 28800, 46080, 69120, 124416, 217728, 559872, 870912, 995328],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [11340, 12925, 14735, 16800, 19150, 21830, 24890, 28375, 32345, 36875],
      [1, 1, 2, 2, 3, 4, 5, 6, 8, 10],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 140, 360, 575, 688, 1244, 2176, 5596, 8708, 9952],
      [0, 0, 0, 0, 5, 9, 15, 39, 60, 69],
      [0, 14400, 36000, 57600, 86400, 155520, 272160, 699840, 1088640, 1244160],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
  ],
  CDTowerStats: [
    // statsList: HP - Reduce CD - Min reduce to - Slot - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [6300, 7180, 8185, 9330, 10640, 12130, 13825, 15760, 17970, 20485],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 25, 70, 115, 136, 248, 432, 1116, 1740, 1988],
      [0, 0, 0, 0, 1, 2, 3, 8, 12, 14],
      [0, 2880, 7200, 11520, 17280, 31104, 54432, 139968, 217728, 248832],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [7550, 8605, 9810, 11185, 12750, 14535, 16570, 18890, 21535, 24550],
      [1, 1, 1, 1, 1, 1, 2, 2, 2, 2],
      [3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 55, 140, 230, 276, 496, 868, 2236, 3480, 3980],
      [0, 0, 0, 0, 2, 3, 6, 16, 24, 28],
      [0, 5760, 14400, 23040, 34560, 62208, 108864, 279936, 435456, 497664],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [8820, 10050, 11460, 13065, 14895, 16980, 19355, 22070, 25155, 28680],
      [1, 1, 1, 1, 2, 2, 2, 2, 3, 3],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 85, 215, 345, 412, 744, 1304, 3356, 5224, 5968],
      [0, 0, 0, 0, 3, 5, 9, 23, 36, 41],
      [0, 8640, 21600, 34560, 51840, 93312, 163296, 419904, 653184, 746496],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [10080, 11490, 13095, 14930, 17020, 19405, 22125, 25220, 28750, 32775],
      [1, 1, 1, 2, 2, 2, 3, 3, 3, 4],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 115, 285, 460, 552, 992, 1740, 4476, 6964, 7960],
      [0, 0, 0, 0, 4, 7, 12, 31, 48, 55],
      [0, 11520, 28800, 46080, 69120, 124416, 217728, 559872, 870912, 995328],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [11340, 12925, 14735, 16800, 19150, 21830, 24890, 28375, 32345, 36875],
      [1, 1, 1, 2, 2, 2, 3, 3, 4, 5],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 140, 360, 575, 688, 1244, 2176, 5596, 8708, 9952],
      [0, 0, 0, 0, 5, 9, 15, 39, 60, 69],
      [0, 14400, 36000, 57600, 86400, 155520, 272160, 699840, 1088640, 1244160],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
  ],
  RNGBombardStats: [
    // statsList: HP - Bonus RNG - Slot - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [7200, 8170, 9275, 10525, 11945, 13560, 15390, 17470, 19825, 22505],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 25, 70, 115, 136, 248, 432, 1116, 1740, 1988],
      [0, 0, 0, 0, 1, 2, 3, 8, 12, 14],
      [0, 2880, 7200, 11520, 17280, 31104, 54432, 139968, 217728, 248832],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [8640, 9805, 11130, 12630, 14335, 16270, 18470, 20960, 23790, 27005],
      [1, 1, 1, 1, 2, 2, 2, 2, 2, 3],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 55, 140, 230, 276, 496, 868, 2236, 3480, 3980],
      [0, 0, 0, 0, 2, 3, 6, 16, 24, 28],
      [0, 5760, 14400, 23040, 34560, 62208, 108864, 279936, 435456, 497664],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [10080, 11440, 12985, 14735, 16725, 18985, 21545, 24455, 27760, 31505],
      [1, 1, 1, 2, 2, 2, 3, 3, 4, 5],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 85, 215, 345, 412, 744, 1304, 3356, 5224, 5968],
      [0, 0, 0, 0, 3, 5, 9, 23, 36, 41],
      [0, 8640, 21600, 34560, 51840, 93312, 163296, 419904, 653184, 746496],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [11520, 13075, 14840, 16840, 19115, 21695, 24625, 27950, 31725, 36005],
      [1, 1, 2, 2, 2, 3, 4, 5, 6, 7],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 115, 285, 460, 552, 992, 1740, 4476, 6964, 7960],
      [0, 0, 0, 0, 4, 7, 12, 31, 48, 55],
      [0, 11520, 28800, 46080, 69120, 124416, 217728, 559872, 870912, 995328],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [12960, 14705, 16695, 18945, 21505, 24410, 27705, 31445, 35690, 40510],
      [1, 1, 2, 2, 3, 4, 5, 6, 8, 10],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 140, 360, 575, 688, 1244, 2176, 5596, 8708, 9952],
      [0, 0, 0, 0, 5, 9, 15, 39, 60, 69],
      [0, 14400, 36000, 57600, 86400, 155520, 272160, 699840, 1088640, 1244160],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
  ],
  ENGBombardStats: [
    // statsList: HP - Bonus Eng - Slot - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [7200, 8170, 9275, 10525, 11945, 13560, 15390, 17470, 19825, 22505],
      [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 25, 70, 115, 136, 248, 432, 1116, 1740, 1988],
      [0, 0, 0, 0, 1, 2, 3, 8, 12, 14],
      [0, 2880, 7200, 11520, 17280, 31104, 54432, 139968, 217728, 248832],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [8640, 9805, 11130, 12630, 14335, 16270, 18470, 20960, 23790, 27005],
      [10, 10.8, 11.66, 12.6, 13.6, 14.69, 15.87, 17.14, 18.51, 19.99],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 55, 140, 230, 276, 496, 868, 2236, 3480, 3980],
      [0, 0, 0, 0, 2, 3, 6, 16, 24, 28],
      [0, 5760, 14400, 23040, 34560, 62208, 108864, 279936, 435456, 497664],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [10080, 11440, 12985, 14735, 16725, 18985, 21545, 24455, 27760, 31505],
      [10, 11.3, 12.76, 14.42, 16.29, 18.41, 20.8, 23.5, 26.55, 29.99],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 85, 215, 345, 412, 744, 1304, 3356, 5224, 5968],
      [0, 0, 0, 0, 3, 5, 9, 23, 36, 41],
      [0, 8640, 21600, 34560, 51840, 93312, 163296, 419904, 653184, 746496],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [11520, 13075, 14840, 16840, 19115, 21695, 24625, 27950, 31725, 36005],
      [10, 11.67, 13.61, 15.87, 18.52, 21.6, 25.19, 29.39, 34.28, 39.99],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 115, 285, 460, 552, 992, 1740, 4476, 6964, 7960],
      [0, 0, 0, 0, 4, 7, 12, 31, 48, 55],
      [0, 11520, 28800, 46080, 69120, 124416, 217728, 559872, 870912, 995328],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [12960, 14705, 16695, 18945, 21505, 24410, 27705, 31445, 35690, 40510],
      [10, 11.96, 14.3, 17.1, 20.45, 24.45, 29.24, 34.96, 41.81, 50],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 140, 360, 575, 688, 1244, 2176, 5596, 8708, 9952],
      [0, 0, 0, 0, 5, 9, 15, 39, 60, 69],
      [0, 14400, 36000, 57600, 86400, 155520, 272160, 699840, 1088640, 1244160],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
  ],

  // [Rarity [statsList [level]]]
  // storage plot stats
  StorageStats: [
    // statsList: HP - Capacity - Upgrade cost MAG - Upgrade cost MSTR - Upgrade time - Landcore level
    [
      [
        4800, 5200, 5650, 6100, 6650, 7200, 7800, 8450, 9200, 10000, 10850,
        11750, 12750, 13850, 15000,
      ],
      [
        880, 1350, 2150, 3400, 5400, 8400, 13250, 21000, 32500, 52000, 81000,
        127500, 200000, 315000, 500000,
      ],
      [0, 5, 10, 15, 35, 50, 70, 90, 115, 170, 230, 1035, 2070, 3110, 4145],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [
        0, 300, 1200, 1800, 3600, 5400, 7200, 9000, 11520, 17280, 23040, 103680,
        207360, 311040, 414720,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        5400, 5850, 6350, 6850, 7450, 8100, 8800, 9550, 10350, 11250, 12200,
        13200, 14350, 15550, 16900,
      ],
      [
        1780, 2750, 4350, 6900, 10800, 17000, 26850, 42000, 66500, 105000,
        164000, 258000, 405000, 637000, 1000000,
      ],
      [0, 5, 20, 35, 70, 105, 140, 180, 230, 345, 460, 2070, 4145, 6220, 8290],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [
        0, 600, 2400, 3600, 7200, 10800, 14400, 18000, 23040, 34560, 46080,
        207360, 414720, 622080, 829440,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        6000, 6500, 7050, 7650, 8300, 9000, 9750, 10600, 11500, 12500, 13550,
        14700, 15950, 17300, 18800,
      ],
      [
        3500, 5600, 8800, 14000, 21750, 34000, 54000, 85000, 133000, 209000,
        328500, 516500, 812000, 1276000, 2000000,
      ],
      [
        0, 5, 35, 50, 105, 160, 215, 270, 345, 515, 690, 3110, 6220, 9330,
        12440,
      ],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [
        0, 900, 3600, 5400, 10800, 16200, 21600, 27000, 34560, 51840, 69120,
        311040, 622080, 933120, 1244160,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        6600, 7150, 7750, 8400, 9100, 9900, 10750, 11650, 12650, 13750, 14900,
        16150, 17550, 19050, 20650,
      ],
      [
        5250, 8250, 12950, 20350, 32000, 50250, 79000, 124500, 196000, 308000,
        483800, 760000, 1196000, 1879500, 3000000,
      ],
      [
        0, 10, 45, 70, 140, 215, 285, 360, 460, 690, 920, 4145, 8290, 12440,
        16585,
      ],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [
        0, 1200, 4800, 7200, 14400, 21600, 28800, 36000, 46080, 69120, 92160,
        414720, 829440, 1244160, 1658880,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        7200, 7800, 8450, 9150, 9950, 10800, 11700, 12700, 13800, 15000, 16250,
        17650, 19150, 20750, 22550,
      ],
      [
        7250, 11350, 17900, 28000, 44250, 70000, 109400, 172000, 270000, 425000,
        668000, 1050000, 1650000, 2550000, 4000000,
      ],
      [
        0, 15, 60, 90, 180, 270, 360, 450, 575, 860, 1150, 5180, 10365, 15550,
        20735,
      ],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [
        0, 1500, 6000, 9000, 18000, 27000, 36000, 45000, 57600, 86400, 115200,
        518400, 1036800, 1555200, 2073600,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
  ],
  MongenName:
    // [level[race[rarity]]]
    // race: beast - tectos - mythic - celest - chaos
    // rarity: common - uncommon - rare - epic - legendary
    [
      [
        ["Cactus Cone", "Tulip Eggo", "Banana Rose", "Cotton Twinke", "-"],
        ["Steam Engine", "Scarecrow Gear", "Strap Clock", "Bulb Metal", "-"],
        [
          "Vegetable Caterpillar",
          "Pterois Jelly",
          "Lighting Seadog",
          "Water Succulent",
          "-",
        ],
        [
          "Lazy Feather",
          "White Elemental",
          "Fluppy Light",
          "Mystical Flame",
          "-",
        ],
        ["Bat Golem", "Yeti Golem", "King Crab", "Evil Candy", "-"],
      ],
      [
        [
          "Dragon Poly",
          "Melon Mutation",
          "Broccoli Builder",
          "Pineapple Spirits",
          "-",
        ],
        [
          "Plumber Roller",
          "Candy Bolt",
          "Electric Belt",
          "Chemical Pirate",
          "-",
        ],
        [
          "Hydra Aquatic",
          "Flying Emerald",
          "Darkness Horseshoe",
          "Bubble Hermit",
          "-",
        ],
        ["Pinky Cotton", "Star Flame", "Frozen Crystal", "Golden Flame", "-"],
        ["Stone Golem", "Gaunt Bio", "Ravenger Guard", "Darkness Crystal", "-"],
      ],
      [
        ["Dried Pumproom", "-", "-", "-", "-"],
        ["Assassin Worker", "-", "-", "-", "-"],
        ["Deepsea Aquatic", "-", "-", "-", "-"],
        ["Flower Leaf", "-", "-", "-", "-"],
        ["Red Bat", "-", "-", "-", "-"],
      ],
      [
        ["-", "-", "-", "-", "-"],
        ["-", "-", "-", "-", "-"],
        ["-", "-", "-", "-", "-"],
        ["-", "-", "-", "-", "-"],
        ["-", "-", "-", "-", "-"],
      ],
    ],

  // Plot size
  //[type [rarity]]
  // LandCore, Pasture, Breeding, Hatching, Production, Storage, Camp, Tower, Bombard, Trap, Barracks
  PlotsSize: [
    [4, 4, 4, 4, 4],
    [1, 2, 4, 4, 4],
    [4, 4, 4, 4, 4],
    [4, 4, 4, 4, 4],
    [1, 2, 4, 4, 4],
    [4, 4, 4, 4, 4],
    [4, 4, 4, 4, 4],
    [4, 4, 4, 4, 4],
    [4, 4, 4, 4, 4],
    [1, 1, 1, 1, 1],
    [1, 2, 4, 9, 9],
  ],
  //Hatching Fee MAG/MSTR
  HatchingFee: [
    [270, 600, 1200, 2340, 4410],
    [1, 1, 2, 2, 3],
  ],
  HatchingTime: [8100, 32400, 64800, 129600, 259200],

  MaxBreedingCount: 8,
  RegenSkillCoolDown: 2,
  RegenSkillCastTime: 1,
  // [births [rariry]]
  MongenBreedingExpReward: [
    [36, 145, 306, 624, 1355],
    [57, 242, 493, 1009, 2212],
    [93, 393, 795, 1624, 0],
    [156, 635, 1279, 0, 0],
    [249, 1023, 0, 0, 0],
    [401, 0, 0, 0, 0],
  ],
  // [level [rariry]]
  MongenEvolveUpExpReward: [
    [0, 0, 0, 0, 0],
    [60, 75, 90, 105, 135],
    [160, 200, 240, 280, 360],
    [800, 1000, 1200, 1400, 1800],
    [1600, 2000, 2400, 2800, 3600],
  ],
  // [plot_type [rariry [level]]]
  // plot_type: landcore - pasture - breeding - hatching - production - storage - camp - tower - bombard - trap - barrack
  PlotUpgradeExpReward: [
    [
      [0, 18, 34, 48, 103, 409, 759, 1744, 4779, 7984, 0, 0, 0, 0, 0],
      [0, 29, 135, 223, 1012, 2164, 2771, 5911, 9879, 12613, 0, 0, 0, 0, 0],
      [0, 48, 223, 349, 1578, 3377, 4316, 9202, 15388, 19645, 0, 0, 0, 0, 0],
      [0, 67, 300, 476, 2164, 4629, 5911, 12613, 21087, 26907, 0, 0, 0, 0, 0],
      [0, 77, 388, 602, 2771, 5911, 7547, 16094, 26907, 34348, 0, 0, 0, 0, 0],
    ],
    [
      [0, 10, 13, 34, 77, 110, 690, 1367, 2517, 4779, 0, 0, 0, 0, 0],
      [0, 29, 77, 135, 223, 1012, 1871, 5265, 8536, 9879, 0, 0, 0, 0, 0],
      [0, 48, 125, 223, 349, 1578, 2917, 8203, 13299, 15388, 0, 0, 0, 0, 0],
      [0, 67, 184, 300, 476, 2164, 4003, 11241, 18223, 21087, 0, 0, 0, 0, 0],
      [0, 77, 232, 388, 602, 2771, 5109, 14339, 23256, 26907, 0, 0, 0, 0, 0],
    ],
    [
      [0, 54, 142, 427, 900, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 223, 739, 2771, 5911, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 349, 1159, 4316, 9202, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 476, 1578, 5911, 12613, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 602, 2018, 7547, 16094, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    ],
    [
      [0, 54, 142, 427, 900, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 223, 739, 2771, 5911, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 349, 1159, 4316, 9202, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 476, 1578, 5911, 12613, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 602, 2018, 7547, 16094, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    ],
    [
      [0, 6, 6, 6, 6, 19, 48, 67, 77, 96, 135, 184, 388, 827, 1578],
      [0, 6, 6, 6, 9, 48, 96, 135, 184, 223, 300, 388, 827, 1774, 3377],
      [0, 6, 6, 6, 9, 67, 155, 223, 281, 349, 476, 602, 1295, 2771, 5265],
      [0, 9, 9, 9, 19, 96, 223, 300, 388, 476, 651, 827, 1774, 3798, 7214],
      [0, 9, 9, 9, 19, 125, 281, 388, 495, 602, 827, 1061, 2272, 4844, 9202],
    ],
    [
      [0, 6, 6, 6, 9, 19, 29, 38, 67, 96, 135, 405, 608, 1095, 2190],
      [0, 6, 6, 9, 29, 48, 67, 77, 135, 223, 300, 900, 1350, 2430, 4860],
      [0, 6, 6, 19, 48, 67, 96, 125, 223, 349, 476, 1428, 2142, 3856, 7712],
      [0, 9, 9, 29, 67, 96, 135, 184, 300, 476, 651, 1953, 2930, 5274, 10548],
      [0, 9, 9, 38, 77, 125, 184, 232, 388, 602, 827, 2481, 3722, 6700, 13400],
    ],
    [
      [0, 10, 21, 37, 55, 273, 503, 1417, 2301, 2661, 0, 0, 0, 0, 0],
      [0, 29, 38, 67, 111, 506, 935, 2632, 4268, 4939, 0, 0, 0, 0, 0],
      [0, 48, 62, 111, 174, 789, 1458, 4101, 6649, 7694, 0, 0, 0, 0, 0],
      [0, 67, 92, 150, 238, 1082, 2001, 5620, 9111, 10543, 0, 0, 0, 0, 0],
      [0, 77, 116, 194, 301, 1385, 2554, 7169, 11628, 13453, 0, 0, 0, 0, 0],
    ],
    [
      [0, 10, 21, 37, 55, 273, 503, 1417, 2301, 2661, 0, 0, 0, 0, 0],
      [0, 29, 38, 67, 111, 506, 935, 2632, 4268, 4939, 0, 0, 0, 0, 0],
      [0, 48, 62, 111, 174, 789, 1458, 4101, 6649, 7694, 0, 0, 0, 0, 0],
      [0, 67, 92, 150, 238, 1082, 2001, 5620, 9111, 10543, 0, 0, 0, 0, 0],
      [0, 77, 116, 194, 301, 1385, 2554, 7169, 11628, 13453, 0, 0, 0, 0, 0],
    ],
    [
      [0, 10, 21, 37, 55, 273, 503, 1417, 2301, 2661, 0, 0, 0, 0, 0],
      [0, 29, 38, 67, 111, 506, 935, 2632, 4268, 4939, 0, 0, 0, 0, 0],
      [0, 48, 62, 111, 174, 789, 1458, 4101, 6649, 7694, 0, 0, 0, 0, 0],
      [0, 67, 92, 150, 238, 1082, 2001, 5620, 9111, 10543, 0, 0, 0, 0, 0],
      [0, 77, 116, 194, 301, 1385, 2554, 7169, 11628, 13453, 0, 0, 0, 0, 0],
    ],
    [
      [0, 0, 0, 0, 9, 19, 29, 38, 67, 96, 0, 0, 0, 0, 0],
      [0, 0, 0, 9, 29, 48, 67, 77, 135, 223, 0, 0, 0, 0, 0],
      [0, 0, 0, 19, 48, 67, 96, 125, 223, 349, 0, 0, 0, 0, 0],
      [0, 0, 9, 29, 67, 96, 135, 184, 300, 476, 0, 0, 0, 0, 0],
      [0, 0, 9, 38, 77, 125, 184, 232, 388, 602, 0, 0, 0, 0, 0],
    ],
    [
      [0, 10, 21, 37, 55, 273, 503, 1417, 2301, 2661, 0, 0, 0, 0, 0],
      [0, 29, 38, 67, 111, 506, 935, 2632, 4268, 4939, 0, 0, 0, 0, 0],
      [0, 48, 62, 111, 174, 789, 1458, 4101, 6649, 7694, 0, 0, 0, 0, 0],
      [0, 67, 92, 150, 238, 1082, 2001, 5620, 9111, 10543, 0, 0, 0, 0, 0],
      [0, 77, 116, 194, 301, 1385, 2554, 7169, 11628, 13453, 0, 0, 0, 0, 0],
    ],
  ],
  // max exp of level
  // [level]
  TotalExpPerLevel: [
    0, 20, 108, 194, 334, 618, 1218, 2218, 3718, 5678, 8478, 12678, 20508,
    36018, 59858, 95508, 132158, 170598, 213398, 267708,
  ],
  // [rarity [?]] : slot - CO_max - UC_max - RA_max - EP_max - LE_max
  RaritySkillPool: [
    [3, 3, 0, 0, 0, 0],
    [4, 1, 3, 0, 0, 0],
    [5, 1, 2, 4, 0, 0],
    [5, 1, 1, 2, 5, 0],
    [5, 1, 1, 2, 3, 5],
  ],
  // [level]
  LevelReward: [
    [],
    [{ reward_type: 4, reward_quantity: 1, race: -1, rarity: 0 }],
    [
      { reward_type: 2, reward_quantity: 50 },
      { reward_type: 6, reward_quantity: 1, race: -1, plot_type: 1, rarity: 0 },
    ],
    [
      { reward_type: 0, reward_quantity: 500 },
      { reward_type: 6, reward_quantity: 1, race: 5, plot_type: 10, rarity: 0 },
      { reward_type: 17, reward_quantity: 10 },
    ],
    [
      { reward_type: 1, reward_quantity: 2 },
      { reward_type: 17, reward_quantity: 10 },
      { reward_type: 6, reward_quantity: 1, race: 5, plot_type: 4, rarity: 0 },
    ],
    [
      { reward_type: 0, reward_quantity: 1500 },
      { reward_type: 17, reward_quantity: 10 },
      { reward_type: 6, reward_quantity: 2, race: 5, plot_type: 7, rarity: 0 },
    ],
    [
      { reward_type: 1, reward_quantity: 2 },
      { reward_type: 9, reward_quantity: 1 },
      { reward_type: 17, reward_quantity: 10 },
      { reward_type: 6, reward_quantity: 1, race: 5, plot_type: 5, rarity: 0 },
    ],
    [
      { reward_type: 0, reward_quantity: 3000 },
      { reward_type: 12, reward_quantity: 1 },
      { reward_type: 17, reward_quantity: 10 },
      { reward_type: 6, reward_quantity: 1, race: -1, plot_type: 1, rarity: 0 },
    ],
    [
      { reward_type: 1, reward_quantity: 3 },
      { reward_type: 10, reward_quantity: 1 },
      { reward_type: 17, reward_quantity: 10 },
      { reward_type: 6, reward_quantity: 1, race: 5, plot_type: 8, rarity: 0 },
    ],
    [
      { reward_type: 2, reward_quantity: 100 },
      { reward_type: 17, reward_quantity: 10 },
      { reward_type: 6, reward_quantity: 1, race: 5, plot_type: 9, rarity: 0 },
    ],
    [
      { reward_type: 0, reward_quantity: 10000 },
      { reward_type: 9, reward_quantity: 1 },
      { reward_type: 17, reward_quantity: 10 },
      { reward_type: 6, reward_quantity: 1, race: 5, plot_type: 7, rarity: 0 },
    ],
    [
      { reward_type: 1, reward_quantity: 3 },
      { reward_type: 13, reward_quantity: 1 },
      { reward_type: 17, reward_quantity: 20 },
      { reward_type: 6, reward_quantity: 1, race: 5, plot_type: 6, rarity: 0 },
    ],
    [
      { reward_type: 0, reward_quantity: 15000 },
      { reward_type: 12, reward_quantity: 1 },
      { reward_type: 6, reward_quantity: 1, race: 5, plot_type: 9, rarity: 0 },
    ],
    [
      { reward_type: 1, reward_quantity: 5 },
      { reward_type: 6, reward_quantity: 1, race: 5, plot_type: 9, rarity: 0 },
    ],
    [{ reward_type: 0, reward_quantity: 20000 }],
    [
      { reward_type: 1, reward_quantity: 5 },
      { reward_type: 10, reward_quantity: 1 },
    ],
    [
      { reward_type: 0, reward_quantity: 30000 },
      { reward_type: 9, reward_quantity: 1 },
    ],
    [{ reward_type: 1, reward_quantity: 5 }],
    [{ reward_type: 0, reward_quantity: 50000 }],
    [{ reward_type: 2, reward_quantity: 200 }],
  ],

  // [rarity [leadership - DecreaseDMG(%) - maxUnit]]
  TroopCapacity: [
    [3, 0, 15],
    [4, 0, 20],
    [4, 0, 25],
    [5, 0, 30],
    [5, 0, 40],
  ],
  MAGExchangeRate: 10,
  // [rarity [training duration - training cost]]
  troopTrainingConfig: [
    [90, 50],
    [90, 100],
    [90, 235],
    [90, 420],
    [90, 800],
  ],
  // questType[level]
  DailyQuestsTrigger: [
    [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    [
      24, 24, 24, 24, 100, 100, 100, 250, 250, 250, 1500, 1500, 2000, 2000,
      2500, 2500, 3000, 3000, 3000, 3000,
    ],
    [2, 2, 2, 2, 4, 4, 4, 8, 8, 8, 12, 12, 15, 15, 17, 17, 19, 19, 21, 21],
    [
      850, 850, 1150, 1150, 1500, 1500, 1900, 1900, 2350, 2350, 3400, 3400,
      3400, 3400, 3400, 3400, 3400, 3400, 3400, 3400,
    ],
    [1, 1, 1, 2, 2, 2, 3, 3, 3, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [
      500, 500, 500, 500, 500, 500, 500, 650, 650, 650, 650, 650, 650, 650, 800,
      800, 800, 800, 800, 800,
    ],
    [
      -1, -1, -1, 750, 750, 750, 750, 750, 2375, 2750, 2750, 3125, 3125, 3500,
      3500, 3875, 3875, 4250, 4250, 4250,
    ],
    [-1, -1, -1, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 10],
    [
      -1, -1, -1, 10, 10, 10, 10, 10, 20, 20, 20, 20, 20, 20, 30, 30, 30, 30,
      30, 30,
    ],
    [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
    [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 9, 9, 9, 9, 9, 9, 9, 24, 24, 24],
    [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 6, 6, 6, 6, 6, 6, 6, 8, 8, 8],
    [
      -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 6, 8, 8, 8, 10, 10, 10, 12, 12,
      12,
    ],
    [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 6, 6, 6, 6, 8, 8, 8, 10, 10, 10],
    [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 8],
    [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 8, 8, 8, 8, 8, 8, 8, 8, 8, 24],
    [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 8, 8, 8, 8, 8, 8, 8, 8, 8, 24],
    [
      -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 60, 80, 100, 120, 140,
      160, 180,
    ],
    [
      150, 250, 400, 750, 1000, 1000, 1000, 1500, 1500, 1500, 6000, 9000, 12000,
      24000, 36000, 48000, 56000, 72000, 90000, 108000,
    ],
    [
      10, 10, 15, 15, 15, 20, 20, 20, 30, 30, 10, 10, 10, 15, 15, 20, 20, 25,
      25, 30,
    ],
    [
      -1, -1, -1, -1, -1, -1, 3, 3, 4, 4, 6800, 10200, 13600, 20400, 27200,
      34000, 51000, 68000, 102000, 136000,
    ],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
    [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 300000, 300000, 300000, 400000, 400000,
      500000, 500000, 600000, 600000, 700000,
    ],
  ],
  // [day]
  FaucetReward: [
    [
      { reward_type: 5, reward_quantity: 30 },
      { reward_type: 2, reward_quantity: 3000 },
      { reward_type: 4, rarity: 1, race: -1, reward_quantity: 1 },
      { reward_type: 6, rarity: 1, race: 5, plot_type: 10, reward_quantity: 1 },
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 1 },
    ],
    [
      { reward_type: 9, reward_quantity: 20 },
      { reward_type: 12, reward_quantity: 20 },
      { reward_type: 6, rarity: 1, race: 5, plot_type: 4, reward_quantity: 1 },
      { reward_type: 6, rarity: 1, race: 5, plot_type: 5, reward_quantity: 1 },
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 1 },
    ],
    [
      { reward_type: 10, reward_quantity: 20 },
      { reward_type: 13, reward_quantity: 20 },
      { reward_type: 6, rarity: 2, race: 5, plot_type: 0, reward_quantity: 1 },
      { reward_type: 6, rarity: 1, race: 5, plot_type: 5, reward_quantity: 1 },
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 2 },
    ],
    [
      { reward_type: 5, reward_quantity: 20 },
      { reward_type: 2, reward_quantity: 2000 },
      { reward_type: 6, rarity: 1, race: 5, plot_type: 4, reward_quantity: 1 },
      { reward_type: 6, rarity: 1, race: 5, plot_type: 5, reward_quantity: 1 },
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 2 },
    ],
    [
      { reward_type: 9, reward_quantity: 20 },
      { reward_type: 12, reward_quantity: 20 },
      { reward_type: 6, rarity: 1, race: -1, plot_type: 1, reward_quantity: 1 },
      { reward_type: 6, rarity: 1, race: 5, plot_type: 5, reward_quantity: 1 },
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 3 },
    ],
    [
      { reward_type: 10, reward_quantity: 20 },
      { reward_type: 13, reward_quantity: 20 },
      { reward_type: 7, reward_pool_id: 3, reward_quantity: 1 },
    ],
    [
      { reward_type: 5, reward_quantity: 20 },
      { reward_type: 2, reward_quantity: 2000 },
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 4 },
    ],
    [
      { reward_type: 5, reward_quantity: 3 },
      { reward_type: 2, reward_quantity: 350 },
      { reward_type: 4, rarity: 2, race: -1, reward_quantity: 1 },
      { reward_type: 6, rarity: 2, race: -1, plot_type: 1, reward_quantity: 1 },
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 4 },
    ],
    [
      { reward_type: 5, reward_quantity: 2 },
      { reward_type: 2, reward_quantity: 250 },
      { reward_type: 6, rarity: 2, race: 5, plot_type: 4, reward_quantity: 1 },
      { reward_type: 6, rarity: 2, race: 5, plot_type: 5, reward_quantity: 1 },
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 5 },
    ],
    [
      { reward_type: 5, reward_quantity: 2 },
      { reward_type: 2, reward_quantity: 250 },
      { reward_type: 6, rarity: 2, race: 5, plot_type: 0, reward_quantity: 1 },
      { reward_type: 6, rarity: 2, race: 5, plot_type: 5, reward_quantity: 1 },
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 5 },
    ],
    [
      { reward_type: 5, reward_quantity: 2 },
      { reward_type: 2, reward_quantity: 250 },
      { reward_type: 6, rarity: 2, race: 5, plot_type: 4, reward_quantity: 1 },
      { reward_type: 6, rarity: 2, race: 5, plot_type: 5, reward_quantity: 1 },
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 6 },
    ],
    [
      { reward_type: 5, reward_quantity: 1 },
      { reward_type: 2, reward_quantity: 200 },
      { reward_type: 6, rarity: 3, race: -1, plot_type: 1, reward_quantity: 1 },
      { reward_type: 6, rarity: 3, race: 5, plot_type: 4, reward_quantity: 1 },
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 6 },
    ],
    [
      { reward_type: 5, reward_quantity: 1 },
      { reward_type: 2, reward_quantity: 150 },
      { reward_type: 7, reward_pool_id: 7, reward_quantity: 1 },
    ],
    [
      { reward_type: 5, reward_quantity: 1 },
      { reward_type: 2, reward_quantity: 100 },
      { reward_type: 7, reward_pool_id: 7, reward_quantity: 1 },
    ],
  ],

  // [type[Rarity[value]]]
  // type: Soulcore - Mongen - Landcore - Pasture - Breeding - Hatching - Production - Storage - Camp type - Tower type - Bombard type - Trap type - Barracks -
  ConversionFee: [
    [1050, 1360, 2100, 4810, 7350],
    [1050, 1700, 1950, 4130, 6300],
    [2625, 3400, 3750, 5000, 6830],
    [4200, 6120, 6000, 6250, 8400],
    [525, 1700, 1950, 4380, 5250],
    [525, 1700, 1950, 4380, 5250],
    [1260, 2040, 4500, 6250, 6300],
    [2100, 4080, 4500, 5000, 6300],
    [4200, 5440, 5850, 6560, 10240],
    [3500, 4420, 5550, 6440, 10130],
    [3850, 4760, 5700, 6500, 10190],
    [3150, 4080, 4500, 4810, 6830],
    [6300, 6800, 7500, 8500, 11810],
    [10],
    [40],
    [200],
    [80],
    [450],
    [525],
    [1300],
    [2400],
    [3000],
    [6250],
    [1000],
    [250],
    [525],
    [525],
    [80],
    [450],
    [100],
    [560],
    [115],
    [630],
    [125],
    [700],
  ],
  // expectMAGRateDaily
  // [day]
  ExpectMAGRateDaily: [
    16, 16, 16, 16, 16, 27, 27, 18, 18, 18, 15, 15, 15, 15, 10, 10, 10, 5, 5, 5,
    2, 2, 2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
  ],
  // [statsList_rarity [hidden_trait_rarity]]
  BreedingSpecialCase: [
    // statsList: Form - Head - Eyes - Horns - Tail - Back
    [
      [90, 10, 0, 0, 0],
      [0, 90, 10, 0, 0],
      [0, 0, 95, 5, 0],
      [0, 0, 0, 95, 5],
      [0, 0, 0, 0, 100],
    ],
    [
      [95, 5, 0, 0, 0],
      [5, 90, 5, 0, 0],
      [0, 2, 95, 3, 0],
      [0, 0, 2, 95, 3],
      [0, 0, 0, 5, 95],
    ],
    [
      [95, 5, 0, 0, 0],
      [5, 90, 5, 0, 0],
      [0, 2, 95, 3, 0],
      [0, 0, 2, 95, 3],
      [0, 0, 0, 5, 95],
    ],
    [
      [95, 5, 0, 0, 0],
      [5, 90, 5, 0, 0],
      [0, 2, 95, 3, 0],
      [0, 0, 2, 95, 3],
      [0, 0, 0, 5, 95],
    ],
    [
      [95, 5, 0, 0, 0],
      [5, 90, 5, 0, 0],
      [0, 2, 95, 3, 0],
      [0, 0, 2, 95, 3],
      [0, 0, 0, 5, 95],
    ],
    [
      [95, 5, 0, 0, 0],
      [5, 90, 5, 0, 0],
      [0, 2, 95, 3, 0],
      [0, 0, 2, 95, 3],
      [0, 0, 0, 5, 95],
    ],
  ],
  // fame rule
  // fame point - fame_bonus_rate
  FameConfig: [
    { famePoint: 0, fameBonusRate: 0.4 },
    { famePoint: 600000, fameBonusRate: 1 },
    { famePoint: 2000000, fameBonusRate: 1.2 },
  ],
  // [level]
  FaucetInviteReward: [
    [{ reward_type: 0, reward_quantity: 500 }],
    [
      { reward_type: 2, reward_quantity: 50 },
      { reward_type: 8, rarity: 0, reward_quantity: 1 },
    ],
    [
      { reward_type: 1, reward_quantity: 3 },
      { reward_type: 8, rarity: 0, reward_quantity: 1 },
    ],
    [
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 1 },
      { reward_type: 8, rarity: 0, reward_quantity: 1 },
    ],
    [
      { reward_type: 0, reward_quantity: 750 },
      { reward_type: 8, rarity: 0, reward_quantity: 1 },
    ],
    [
      { reward_type: 2, reward_quantity: 75 },
      { reward_type: 8, rarity: 0, reward_quantity: 1 },
    ],
    [
      { reward_type: 1, reward_quantity: 3 },
      { reward_type: 8, rarity: 0, reward_quantity: 1 },
    ],
    [
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 1 },
      { reward_type: 8, rarity: 0, reward_quantity: 2 },
    ],
    [
      { reward_type: 0, reward_quantity: 750 },
      { reward_type: 8, rarity: 1, reward_quantity: 1 },
    ],
    [
      { reward_type: 2, reward_quantity: 75 },
      { reward_type: 8, rarity: 1, reward_quantity: 1 },
    ],
    [
      { reward_type: 1, reward_quantity: 5 },
      { reward_type: 8, rarity: 1, reward_quantity: 1 },
    ],
    [
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 2 },
      { reward_type: 8, rarity: 1, reward_quantity: 1 },
    ],
    [
      { reward_type: 0, reward_quantity: 1000 },
      { reward_type: 8, rarity: 1, reward_quantity: 1 },
    ],
    [
      { reward_type: 2, reward_quantity: 100 },
      { reward_type: 8, rarity: 1, reward_quantity: 1 },
    ],
    [
      { reward_type: 1, reward_quantity: 5 },
      { reward_type: 8, rarity: 2, reward_quantity: 1 },
    ],
    [
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 2 },
      { reward_type: 8, rarity: 2, reward_quantity: 1 },
    ],
    [
      { reward_type: 0, reward_quantity: 1000 },
      { reward_type: 8, rarity: 2, reward_quantity: 1 },
    ],
    [
      { reward_type: 2, reward_quantity: 100 },
      { reward_type: 8, rarity: 2, reward_quantity: 1 },
    ],
    [
      { reward_type: 1, reward_quantity: 8 },
      { reward_type: 8, rarity: 3, reward_quantity: 1 },
    ],
    [
      { reward_type: 7, reward_quantity: 1, reward_pool_id: 3 },
      { reward_type: 8, rarity: 3, reward_quantity: 1 },
    ],
  ],
  // [rarity]
  TicketPool: [
    [
      {
        reward_type: 4,
        reward_quantity: 1,
        plot_type: null,
        rarity: 0,
        race: -1,
        rate: 30,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 1,
        rarity: 0,
        race: -1,
        rate: 25,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 2,
        rarity: 0,
        race: 5,
        rate: 20,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 3,
        rarity: 0,
        race: 5,
        rate: 20,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 4,
        rarity: 0,
        race: 5,
        rate: 20,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 5,
        rarity: 0,
        race: 5,
        rate: 20,
      },
    ],
    [
      {
        reward_type: 4,
        reward_quantity: 1,
        plot_type: null,
        rarity: 1,
        race: -1,
        rate: 20,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 0,
        rarity: 1,
        race: 5,
        rate: 20,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 1,
        rarity: 1,
        race: -1,
        rate: 25,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 2,
        rarity: 1,
        race: 5,
        rate: 10,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 3,
        rarity: 1,
        race: 5,
        rate: 10,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 4,
        rarity: 1,
        race: 5,
        rate: 10,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 5,
        rarity: 1,
        race: 5,
        rate: 10,
      },
    ],
    [
      {
        reward_type: 4,
        reward_quantity: 1,
        plot_type: null,
        rarity: 2,
        race: -1,
        rate: 15,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 0,
        rarity: 2,
        race: 5,
        rate: 5,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 1,
        rarity: 2,
        race: -1,
        rate: 5,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 2,
        rarity: 2,
        race: 5,
        rate: 5,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 3,
        rarity: 2,
        race: 5,
        rate: 5,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 4,
        rarity: 2,
        race: 5,
        rate: 5,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 5,
        rarity: 2,
        race: 5,
        rate: 5,
      },
    ],
    [
      {
        reward_type: 4,
        reward_quantity: 1,
        plot_type: null,
        rarity: 3,
        race: -1,
        rate: 5,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 0,
        rarity: 3,
        race: 5,
        rate: 2,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 1,
        rarity: 3,
        race: -1,
        rate: 3,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 2,
        rarity: 3,
        race: 5,
        rate: 3,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 3,
        rarity: 3,
        race: 5,
        rate: 3,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 4,
        rarity: 3,
        race: 5,
        rate: 3,
      },
      {
        reward_type: 6,
        reward_quantity: 1,
        plot_type: 5,
        rarity: 3,
        race: 5,
        rate: 3,
      },
    ],
  ],
  GAME_EVENT_INTERVAL: 5,
  NFTBoxesPool: [
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 0,
      rarity: 1,
      race: 5,
      standard_rate: 771,
      intermediate_rate: 402,
      special_rate: 199,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 0,
      rarity: 2,
      race: 5,
      standard_rate: 289,
      intermediate_rate: 603,
      special_rate: 336,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 0,
      rarity: 3,
      race: 5,
      standard_rate: 97,
      intermediate_rate: 151,
      special_rate: 622,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 1,
      rarity: 1,
      race: 0,
      standard_rate: 771,
      intermediate_rate: 402,
      special_rate: 199,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 1,
      rarity: 1,
      race: 1,
      standard_rate: 771,
      intermediate_rate: 402,
      special_rate: 199,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 1,
      rarity: 1,
      race: 2,
      standard_rate: 771,
      intermediate_rate: 402,
      special_rate: 199,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 1,
      rarity: 2,
      race: 0,
      standard_rate: 289,
      intermediate_rate: 603,
      special_rate: 336,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 1,
      rarity: 2,
      race: 1,
      standard_rate: 289,
      intermediate_rate: 603,
      special_rate: 336,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 1,
      rarity: 2,
      race: 2,
      standard_rate: 289,
      intermediate_rate: 603,
      special_rate: 336,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 1,
      rarity: 3,
      race: 0,
      standard_rate: 97,
      intermediate_rate: 151,
      special_rate: 622,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 1,
      rarity: 3,
      race: 1,
      standard_rate: 97,
      intermediate_rate: 151,
      special_rate: 622,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 1,
      rarity: 3,
      race: 2,
      standard_rate: 97,
      intermediate_rate: 151,
      special_rate: 622,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 2,
      rarity: 1,
      race: 5,
      standard_rate: 771,
      intermediate_rate: 402,
      special_rate: 199,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 2,
      rarity: 2,
      race: 5,
      standard_rate: 289,
      intermediate_rate: 603,
      special_rate: 336,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 2,
      rarity: 3,
      race: 5,
      standard_rate: 97,
      intermediate_rate: 151,
      special_rate: 622,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 3,
      rarity: 1,
      race: 5,
      standard_rate: 771,
      intermediate_rate: 402,
      special_rate: 199,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 3,
      rarity: 2,
      race: 5,
      standard_rate: 289,
      intermediate_rate: 603,
      special_rate: 336,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 3,
      rarity: 3,
      race: 5,
      standard_rate: 97,
      intermediate_rate: 151,
      special_rate: 622,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 4,
      rarity: 1,
      race: 5,
      standard_rate: 771,
      intermediate_rate: 402,
      special_rate: 199,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 4,
      rarity: 2,
      race: 5,
      standard_rate: 289,
      intermediate_rate: 603,
      special_rate: 336,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 4,
      rarity: 3,
      race: 5,
      standard_rate: 97,
      intermediate_rate: 151,
      special_rate: 622,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 5,
      rarity: 1,
      race: 5,
      standard_rate: 771,
      intermediate_rate: 402,
      special_rate: 199,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 5,
      rarity: 2,
      race: 5,
      standard_rate: 289,
      intermediate_rate: 603,
      special_rate: 336,
    },
    {
      reward_type: 6,
      reward_quantity: 1,
      plot_type: 5,
      rarity: 3,
      race: 5,
      standard_rate: 97,
      intermediate_rate: 151,
      special_rate: 622,
    },
    {
      reward_type: 4,
      reward_quantity: 1,
      plot_type: -1,
      rarity: 1,
      race: 0,
      standard_rate: 386,
      intermediate_rate: 201,
      special_rate: 100,
    },
    {
      reward_type: 4,
      reward_quantity: 1,
      plot_type: -1,
      rarity: 1,
      race: 1,
      standard_rate: 386,
      intermediate_rate: 201,
      special_rate: 100,
    },
    {
      reward_type: 4,
      reward_quantity: 1,
      plot_type: -1,
      rarity: 1,
      race: 2,
      standard_rate: 386,
      intermediate_rate: 201,
      special_rate: 100,
    },
    {
      reward_type: 4,
      reward_quantity: 1,
      plot_type: -1,
      rarity: 2,
      race: 0,
      standard_rate: 145,
      intermediate_rate: 302,
      special_rate: 168,
    },
    {
      reward_type: 4,
      reward_quantity: 1,
      plot_type: -1,
      rarity: 2,
      race: 1,
      standard_rate: 145,
      intermediate_rate: 302,
      special_rate: 168,
    },
    {
      reward_type: 4,
      reward_quantity: 1,
      plot_type: -1,
      rarity: 2,
      race: 2,
      standard_rate: 145,
      intermediate_rate: 302,
      special_rate: 168,
    },
    {
      reward_type: 4,
      reward_quantity: 1,
      plot_type: -1,
      rarity: 3,
      race: 0,
      standard_rate: 48,
      intermediate_rate: 76,
      special_rate: 311,
    },
    {
      reward_type: 4,
      reward_quantity: 1,
      plot_type: -1,
      rarity: 3,
      race: 1,
      standard_rate: 48,
      intermediate_rate: 76,
      special_rate: 311,
    },
    {
      reward_type: 4,
      reward_quantity: 1,
      plot_type: -1,
      rarity: 3,
      race: 2,
      standard_rate: 48,
      intermediate_rate: 76,
      special_rate: 311,
    },
  ],
  DailyQuestMAGDefault: 500,
  // GameDifficultEnum: Easy,Moderate,Hard,SuperHard,
  // battle_level start from 1
  // [battle_level - 1][GameDifficultEnum - energy - evoPoint]
  BattleEnergyPerLevel: [
    [0, 1, 10],
    [0, 1, 10],
    [0, 1, 10],
    [0, 1, 10],
    [0, 1, 10],
    [1, 1, 10],
    [1, 1, 10],
    [1, 1, 10],
    [1, 1, 10],
    [1, 1, 10],
    [1, 2, 10],
    [1, 2, 10],
    [1, 2, 10],
    [1, 2, 10],
    [1, 2, 10],
    [1, 2, 10],
    [1, 2, 10],
    [1, 2, 10],
    [1, 2, 10],
    [1, 2, 10],
    [2, 2, 10],
    [2, 2, 10],
    [2, 2, 10],
    [2, 2, 10],
    [2, 2, 10],
    [2, 3, 20],
    [2, 3, 20],
    [2, 3, 20],
    [2, 3, 20],
    [2, 3, 20],
    [2, 3, 20],
    [2, 3, 20],
    [2, 3, 20],
    [2, 3, 20],
    [2, 3, 20],
    [2, 4, 30],
    [2, 4, 30],
    [2, 4, 30],
    [2, 4, 30],
    [2, 4, 30],
    [3, 4, 30],
    [3, 4, 30],
    [3, 4, 30],
    [3, 4, 30],
    [3, 4, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
    [3, 5, 30],
  ],

  // [milestones - 1][totalStarRequired - poolId]
  MileStonesData: [
    [3, 8],
    [8, 8],
    [13, 8],
    [18, 8],
    [25, 8],
    [32, 8],
    [39, 8],
    [46, 9],
    [57, 9],
    [68, 9],
    [79, 9],
    [90, 9],
    [101, 9],
    [117, 9],
    [133, 9],
    [149, 10],
    [165, 10],
    [181, 10],
    [197, 10],
    [217, 10],
    [237, 10],
    [257, 10],
    [277, 10],
    [297, 10],
    [317, 11],
    [337, 11],
    [362, 11],
    [387, 11],
    [412, 11],
    [437, 11],
    [462, 11],
    [487, 11],
    [512, 11],
    [537, 11],
    [566, 12],
    [595, 12],
    [624, 12],
    [653, 12],
    [682, 12],
    [711, 12],
    [740, 12],
    [769, 12],
    [798, 12],
  ],
  UnlockReward: [
    {
      unlock_game_mode: [0, 6, 7, 8],
      limit: {
        mongen_evo_level: 1,
        max_energy: 0,
        max_sMAG: 100000,
        plot: {
          "0": { level: 1 },
          "6": { quantity: 0 },
          "7": { quantity: 0 },
          "8": { quantity: 0 },
          "9": { quantity: 0 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5],
    },
    {
      unlock_game_mode: [0, 6, 7, 8],
      limit: {
        mongen_evo_level: 1,
        max_energy: 0,
        max_sMAG: 100000,
        plot: {
          "0": { level: 1 },
          "6": { quantity: 0 },
          "7": { quantity: 0 },
          "8": { quantity: 0 },
          "9": { quantity: 0 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5],
    },
    {
      unlock_game_mode: [0, 6, 7, 8],
      limit: {
        mongen_evo_level: 1,
        max_energy: 0,
        max_sMAG: 100000,
        plot: {
          "0": { level: 2 },
          "6": { quantity: 0 },
          "7": { quantity: 0 },
          "8": { quantity: 0 },
          "9": { quantity: 0 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1],
      limit: {
        mongen_evo_level: 5,
        max_energy: 10,
        max_sMAG: 100000,
        plot: {
          "0": { level: 2 },
          "6": { quantity: 0 },
          "7": { quantity: 0 },
          "8": { quantity: 0 },
          "9": { quantity: 0 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1],
      limit: {
        mongen_evo_level: 5,
        max_energy: 20,
        max_sMAG: 100000,
        plot: {
          "0": { level: 3 },
          "6": { quantity: 0 },
          "7": { quantity: 0 },
          "8": { quantity: 0 },
          "9": { quantity: 0 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5, 6, 11, 16],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1, 2],
      limit: {
        mongen_evo_level: 5,
        max_energy: 30,
        max_sMAG: 100000,
        plot: {
          "0": { level: 3 },
          "6": { quantity: 0 },
          "7": { quantity: 2 },
          "8": { quantity: 0 },
          "9": { quantity: 0 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5, 6, 11, 16],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1, 2],
      limit: {
        mongen_evo_level: 5,
        max_energy: 40,
        max_sMAG: 100000,
        plot: {
          "0": { level: 4 },
          "6": { quantity: 0 },
          "7": { quantity: 2 },
          "8": { quantity: 1 },
          "9": { quantity: 0 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5, 6, 11, 16],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1, 2],
      limit: {
        mongen_evo_level: 5,
        max_energy: 50,
        max_sMAG: 100000,
        plot: {
          "0": { level: 4 },
          "6": { quantity: 1 },
          "7": { quantity: 2 },
          "8": { quantity: 1 },
          "9": { quantity: 0 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5, 6, 11, 16],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1, 2],
      limit: {
        mongen_evo_level: 5,
        max_energy: 60,
        max_sMAG: 100000,
        plot: {
          "0": { level: 5 },
          "6": { quantity: 1 },
          "7": { quantity: 3 },
          "8": { quantity: 2 },
          "9": { quantity: 0 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5, 6, 11, 16],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1, 2, 3],
      limit: {
        mongen_evo_level: 5,
        max_energy: 70,
        max_sMAG: 100000,
        plot: {
          "0": { level: 5 },
          "6": { quantity: 1 },
          "7": { quantity: 4 },
          "8": { quantity: 2 },
          "9": { quantity: 1 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5, 6, 11, 16, 7, 12, 17],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1, 2, 3, 5],
      limit: {
        mongen_evo_level: 5,
        max_energy: 80,
        max_sMAG: 100000,
        plot: {
          "0": { level: 6 },
          "6": { quantity: 1 },
          "7": { quantity: 5 },
          "8": { quantity: 2 },
          "9": { quantity: 2 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5, 6, 11, 16, 7, 12, 17],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1, 2, 3, 5],
      limit: {
        mongen_evo_level: 5,
        max_energy: 100,
        max_sMAG: 100000,
        plot: {
          "0": { level: 6 },
          "6": { quantity: 1 },
          "7": { quantity: 5 },
          "8": { quantity: 3 },
          "9": { quantity: 3 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5, 6, 11, 16, 7, 12, 17],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1, 2, 3, 5],
      limit: {
        mongen_evo_level: 5,
        max_energy: 100,
        max_sMAG: 100000,
        plot: {
          "0": { level: 7 },
          "6": { quantity: 2 },
          "7": { quantity: 5 },
          "8": { quantity: 3 },
          "9": { quantity: 4 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5, 6, 11, 16, 7, 12, 17],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1, 2, 3, 5],
      limit: {
        mongen_evo_level: 5,
        max_energy: 100,
        max_sMAG: 100000,
        plot: {
          "0": { level: 7 },
          "6": { quantity: 2 },
          "7": { quantity: 6 },
          "8": { quantity: 3 },
          "9": { quantity: 5 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5, 6, 11, 16, 7, 12, 17],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1, 2, 3, 5],
      limit: {
        mongen_evo_level: 5,
        max_energy: 100,
        max_sMAG: 100000,
        plot: {
          "0": { level: 8 },
          "6": { quantity: 2 },
          "7": { quantity: 6 },
          "8": { quantity: 4 },
          "9": { quantity: 6 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5, 6, 11, 16, 7, 12, 17, 8, 13, 18],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1, 2, 3, 5],
      limit: {
        mongen_evo_level: 5,
        max_energy: 100,
        max_sMAG: 100000,
        plot: {
          "0": { level: 8 },
          "6": { quantity: 3 },
          "7": { quantity: 6 },
          "8": { quantity: 4 },
          "9": { quantity: 7 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5, 6, 11, 16, 7, 12, 17, 8, 13, 18],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1, 2, 3, 5],
      limit: {
        mongen_evo_level: 5,
        max_energy: 100,
        max_sMAG: 100000,
        plot: {
          "0": { level: 9 },
          "6": { quantity: 3 },
          "7": { quantity: 7 },
          "8": { quantity: 5 },
          "9": { quantity: 7 },
        },
      },
      unlock_avatar: [1, 2, 3, 4, 5, 6, 11, 16, 7, 12, 17, 8, 13, 18],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1, 2, 3, 5],
      limit: {
        mongen_evo_level: 5,
        max_energy: 100,
        max_sMAG: 100000,
        plot: {
          "0": { level: 9 },
          "6": { quantity: 3 },
          "7": { quantity: 7 },
          "8": { quantity: 6 },
          "9": { quantity: 8 },
        },
      },
      unlock_avatar: [
        1, 2, 3, 4, 5, 6, 11, 16, 7, 12, 17, 8, 13, 18, 9, 14, 19,
      ],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1, 2, 3, 5],
      limit: {
        mongen_evo_level: 5,
        max_energy: 100,
        max_sMAG: 100000,
        plot: {
          "0": { level: 10 },
          "6": { quantity: 3 },
          "7": { quantity: 8 },
          "8": { quantity: 6 },
          "9": { quantity: 9 },
        },
      },
      unlock_avatar: [
        1, 2, 3, 4, 5, 6, 11, 16, 7, 12, 17, 8, 13, 18, 9, 14, 19,
      ],
    },
    {
      unlock_game_mode: [0, 6, 7, 8, 1, 2, 3, 5],
      limit: {
        mongen_evo_level: 5,
        max_energy: 100,
        max_sMAG: 100000,
        plot: {
          "0": { level: 10 },
          "6": { quantity: 3 },
          "7": { quantity: 9 },
          "8": { quantity: 6 },
          "9": { quantity: 10 },
        },
      },
      unlock_avatar: [
        1, 2, 3, 4, 5, 6, 11, 16, 7, 12, 17, 8, 13, 18, 9, 14, 19, 10, 15, 20,
      ],
    },
  ],
  LandSizeBattle: [
    {
      type: 0,
      race: 5,
      rarity: 0,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 0,
      endy: 3,
    },
    {
      type: 0,
      race: 5,
      rarity: 1,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 0,
      endy: 4,
    },
    {
      type: 0,
      race: 5,
      rarity: 2,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 0,
      endy: 3,
    },
    {
      type: 0,
      race: 5,
      rarity: 3,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 0,
      endy: 4,
    },
    {
      type: 0,
      race: 5,
      rarity: 4,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 5,
    },
    {
      type: 2,
      race: 5,
      rarity: 0,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 1,
      endy: 4,
    },
    {
      type: 2,
      race: 5,
      rarity: 1,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 2,
      endy: 5,
    },
    {
      type: 2,
      race: 5,
      rarity: 2,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 0,
      endy: 4,
    },
    {
      type: 2,
      race: 5,
      rarity: 3,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 1,
      endy: 5,
    },
    {
      type: 2,
      race: 5,
      rarity: 4,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 5,
    },
    {
      type: 3,
      race: 5,
      rarity: 0,
      size: [2, 2],
      startx: 1,
      endx: 5,
      starty: 0,
      endy: 4,
    },
    {
      type: 3,
      race: 5,
      rarity: 1,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 5,
    },
    {
      type: 3,
      race: 5,
      rarity: 2,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 5,
    },
    {
      type: 3,
      race: 5,
      rarity: 3,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 5,
    },
    {
      type: 3,
      race: 5,
      rarity: 4,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 5,
    },
    {
      type: 5,
      race: 5,
      rarity: 0,
      size: [2, 2],
      startx: 1,
      endx: 3,
      starty: 0,
      endy: 3,
    },
    {
      type: 5,
      race: 5,
      rarity: 1,
      size: [2, 2],
      startx: 0,
      endx: 4,
      starty: 0,
      endy: 4,
    },
    {
      type: 5,
      race: 5,
      rarity: 2,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 0,
      endy: 3,
    },
    {
      type: 5,
      race: 5,
      rarity: 3,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 3,
    },
    {
      type: 5,
      race: 5,
      rarity: 4,
      size: [2, 2],
      startx: 0,
      endx: 4,
      starty: 0,
      endy: 4,
    },
    {
      type: 4,
      race: 5,
      rarity: 0,
      size: [1, 1],
      startx: 0,
      endx: 2,
      starty: 0,
      endy: 1,
    },
    {
      type: 4,
      race: 5,
      rarity: 1,
      size: [2, 1],
      startx: 2,
      endx: 5,
      starty: 0,
      endy: 2,
    },
    {
      type: 4,
      race: 5,
      rarity: 2,
      size: [2, 2],
      startx: 2,
      endx: 5,
      starty: 0,
      endy: 3,
    },
    {
      type: 4,
      race: 5,
      rarity: 3,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 1,
      endy: 4,
    },
    {
      type: 4,
      race: 5,
      rarity: 4,
      size: [2, 2],
      startx: 0,
      endx: 4,
      starty: 0,
      endy: 3,
    },
    {
      type: 1,
      race: 0,
      rarity: 0,
      size: [1, 1],
      startx: 0,
      endx: 2,
      starty: 0,
      endy: 1,
    },
    {
      type: 1,
      race: 0,
      rarity: 1,
      size: [2, 1],
      startx: 1,
      endx: 5,
      starty: 0,
      endy: 1,
    },
    {
      type: 1,
      race: 0,
      rarity: 2,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 3,
    },
    {
      type: 1,
      race: 0,
      rarity: 3,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 3,
    },
    {
      type: 1,
      race: 0,
      rarity: 4,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 3,
    },
    {
      type: 1,
      race: 1,
      rarity: 0,
      size: [1, 1],
      startx: 0,
      endx: 2,
      starty: 0,
      endy: 2,
    },
    {
      type: 1,
      race: 1,
      rarity: 1,
      size: [2, 1],
      startx: 0,
      endx: 4,
      starty: 0,
      endy: 2,
    },
    {
      type: 1,
      race: 1,
      rarity: 2,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 4,
    },
    {
      type: 1,
      race: 1,
      rarity: 3,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 3,
    },
    {
      type: 1,
      race: 1,
      rarity: 4,
      size: [2, 2],
      startx: 2,
      endx: 5,
      starty: 0,
      endy: 3,
    },
    {
      type: 1,
      race: 2,
      rarity: 0,
      size: [1, 1],
      startx: 0,
      endx: 2,
      starty: 1,
      endy: 2,
    },
    {
      type: 1,
      race: 2,
      rarity: 1,
      size: [2, 1],
      startx: 2,
      endx: 5,
      starty: 0,
      endy: 2,
    },
    {
      type: 1,
      race: 2,
      rarity: 2,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 3,
    },
    {
      type: 1,
      race: 2,
      rarity: 3,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 3,
    },
    {
      type: 1,
      race: 2,
      rarity: 4,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 4,
    },
    {
      type: 6,
      race: 5,
      rarity: 0,
      size: [2, 2],
      startx: 1,
      endx: 3,
      starty: 0,
      endy: 1,
    },
    {
      type: 6,
      race: 5,
      rarity: 1,
      size: [2, 2],
      startx: 2,
      endx: 4,
      starty: 0,
      endy: 2,
    },
    {
      type: 6,
      race: 5,
      rarity: 2,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 0,
      endy: 3,
    },
    {
      type: 6,
      race: 5,
      rarity: 3,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 3,
    },
    {
      type: 6,
      race: 5,
      rarity: 4,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 3,
    },
    {
      type: 7,
      race: 5,
      rarity: 0,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 1,
      endy: 4,
    },
    {
      type: 7,
      race: 5,
      rarity: 1,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 1,
      endy: 4,
    },
    {
      type: 7,
      race: 5,
      rarity: 2,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 1,
      endy: 4,
    },
    {
      type: 7,
      race: 5,
      rarity: 3,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 1,
      endy: 4,
    },
    {
      type: 7,
      race: 5,
      rarity: 4,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 0,
      endy: 4,
    },
    {
      type: 8,
      race: 5,
      rarity: 0,
      size: [2, 2],
      startx: 1,
      endx: 3,
      starty: 1,
      endy: 3,
    },
    {
      type: 8,
      race: 5,
      rarity: 1,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 1,
      endy: 4,
    },
    {
      type: 8,
      race: 5,
      rarity: 2,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 1,
      endy: 4,
    },
    {
      type: 8,
      race: 5,
      rarity: 3,
      size: [2, 2],
      startx: 1,
      endx: 4,
      starty: 0,
      endy: 3,
    },
    {
      type: 8,
      race: 5,
      rarity: 4,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 4,
    },
    {
      type: 10,
      race: 5,
      rarity: 0,
      size: [1, 1],
      startx: 1,
      endx: 2,
      starty: 0,
      endy: 1,
    },
    {
      type: 10,
      race: 5,
      rarity: 1,
      size: [2, 1],
      startx: 2,
      endx: 5,
      starty: 0,
      endy: 1,
    },
    {
      type: 10,
      race: 5,
      rarity: 2,
      size: [2, 2],
      startx: 1,
      endx: 5,
      starty: 0,
      endy: 3,
    },
    {
      type: 10,
      race: 5,
      rarity: 3,
      size: [3, 3],
      startx: 0,
      endx: 8,
      starty: 0,
      endy: 6,
    },
    {
      type: 10,
      race: 5,
      rarity: 4,
      size: [3, 3],
      startx: 0,
      endx: 8,
      starty: 0,
      endy: 5,
    },
    {
      type: 9,
      race: 5,
      rarity: 0,
      size: [1, 1],
      startx: 0,
      endx: 2,
      starty: 0,
      endy: 2,
    },
    {
      type: 9,
      race: 5,
      rarity: 1,
      size: [1, 1],
      startx: 0,
      endx: 2,
      starty: 0,
      endy: 2,
    },
    {
      type: 9,
      race: 5,
      rarity: 2,
      size: [1, 1],
      startx: 0,
      endx: 2,
      starty: 0,
      endy: 2,
    },
    {
      type: 9,
      race: 5,
      rarity: 3,
      size: [1, 1],
      startx: 0,
      endx: 2,
      starty: 0,
      endy: 2,
    },
    {
      type: 9,
      race: 5,
      rarity: 4,
      size: [1, 1],
      startx: 0,
      endx: 2,
      starty: 0,
      endy: 2,
    },
    {
      type: 11,
      race: 5,
      rarity: 0,
      size: [1, 1],
      startx: 0,
      endx: 2,
      starty: 0,
      endy: 2,
    },
    {
      type: 11,
      race: 5,
      rarity: 2,
      size: [2, 1],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 2,
    },
    {
      type: 11,
      race: 5,
      rarity: 3,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 5,
    },
    {
      type: 11,
      race: 5,
      rarity: 4,
      size: [3, 3],
      startx: 0,
      endx: 8,
      starty: 0,
      endy: 8,
    },
    {
      type: 1,
      race: 3,
      rarity: 0,
      size: [1, 1],
      startx: 0,
      endx: 2,
      starty: 0,
      endy: 1,
    },
    {
      type: 1,
      race: 3,
      rarity: 1,
      size: [2, 1],
      startx: 0,
      endx: 3,
      starty: 0,
      endy: 1,
    },
    {
      type: 1,
      race: 3,
      rarity: 2,
      size: [2, 2],
      startx: 2,
      endx: 5,
      starty: 0,
      endy: 3,
    },
    {
      type: 1,
      race: 3,
      rarity: 3,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 3,
    },
    {
      type: 1,
      race: 3,
      rarity: 4,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 4,
    },
    {
      type: 1,
      race: 4,
      rarity: 0,
      size: [1, 1],
      startx: 0,
      endx: 2,
      starty: 0,
      endy: 1,
    },
    {
      type: 1,
      race: 4,
      rarity: 1,
      size: [2, 1],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 1,
    },
    {
      type: 1,
      race: 4,
      rarity: 2,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 2,
    },
    {
      type: 1,
      race: 4,
      rarity: 3,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 3,
    },
    {
      type: 1,
      race: 4,
      rarity: 4,
      size: [2, 2],
      startx: 0,
      endx: 5,
      starty: 0,
      endy: 4,
    },
  ],

  // -LandCore, -Pasture, -Breeding, -Hatching, -Production, -Storage, -Camp, -Tower, -Bombard, -Trap, -Barracks, -Decor,
  FoodDestructureLand: [
    [7200, 10800, 14400, 18000, 21600],
    [3200, 4800, 6400, 8000, 9600],
    [1500, 2400, 3000, 3800, 4500],
    [1500, 2400, 3000, 3800, 4500],
    [5600, 8400, 11200, 14000, 16800],
    [10000, 15000, 20000, 25000, 30000],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
  ],
  InitialTrophyScore: 1200,
  NumberEnemiesBattleFront: 4,
  NumberEnemiesBattlePool: 20,
  // score, win, draw, lose, robbable food, lose elo, win streak, robbable sMAG
  ELOTrophyScore: [
    [0, 25, -1, -7, 0.8, 10, 7, 10],
    [1200, 25, -1, -7, 0.8, 12, 5, 10],
    [1600, 24, -1, -8, 0.8, 14, 5, 10],
    [2000, 23, -1, -9, 0.8, 16, 5, 10],
    [2500, 21, -1, -10, 0.85, 18, 4, 12.5],
    [3000, 20, -1, -11, 0.85, 19, 4, 12.5],
    [3500, 19, -1, -12, 0.85, 21, 4, 12.5],
    [4200, 17, -1, -14, 0.9, 35, 3, 15],
    [4900, 16, -1, -16, 0.9, 40, 3, 15],
    [5600, 15, -1, -19, 0.9, 50, 3, 15],
    [6400, 13, -1, -21, 0.9500000000000001, 55, 3, 15],
    [7200, 12, -1, -23, 0.9500000000000001, 60, 3, 15],
    [8000, 11, -1, -25, 0.9500000000000001, 65, 3, 15],
    [8200, 10, -1, -30, 1, 80, 3, 20],
  ],
  MaxBossConditionType: 20,

  // so lan toi da refresh enemies list (dung tra ve cho fe hien thi)
  MaxBattleFrontRefresh: 3,

  // energy spend when refresh enemies list
  EnergyRefreshBattlefront: 1,

  // energy spend when play battlefront
  EnergyBattlefront: 5,

  // event adventure energy token (KhanhND)
  MaxEnergyEvent: 3,

  // mag price when buy energy
  PriceEnergyEvent: 100,

  // so lan max buy energy
  MaxEnergyEventBuy: 10,

  // Destruction rate star - rank - rate - shield - Destruction rate shield
  BattleFrontDestructionData: [
    [60, 1, 0.1, 900, 60],
    [80, 2, 0.15, 1800, 80],
    [100, 3, 0.2, 2700, 100],
  ],

  // customer referral point level data
  CustomerReferralLevelPointData: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ],

  // customer referral point spend mstr data: amount spend - point
  CustomerReferralMSTRPointData: [1, 1],

  // referral event milestone: point - percent_cashback
  ReferralEventMilestoneData: [
    [0, 10],
    [20000, 11],
    [60000, 12],
    [180000, 15],
    [500000, 20],
  ],

  // time by seconds
  // duration - price - cooldown time
  ShieldPurchaseData: [
    [7200, 250, 82800],
    [86400, 2500, 432000],
    [172800, 3750, 864000],
  ],
  // [statsList [race [rarity]]]
  // race: beast - tectos - mystic - celest - chaos
  // rarity: uncommon - common - rare - epic
  // [statsList [race [rarity]]]
  // race: beast - tectos - mystic - celest - chaos
  // rarity: uncommon - common - rare - epic
  MaxBodyPart: [
    // statsList: Form - Head - Eyes - Horns - Tails - Back - Frontleg - Backleg - Mouth
    [
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
    ],
    [
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
    ],
    [
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
    ],
    [
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
    ],
    [
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
    ],
    [
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
    ],
    [
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
    ],
    [
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
      [4, 4, 3, 2, 4],
    ],
    [
      [200, 200, 200, 200, 200],
      [200, 200, 200, 200, 200],
      [200, 200, 200, 200, 200],
      [200, 200, 200, 200, 200],
      [200, 200, 200, 200, 200],
    ],
  ],
  // EditLand,CreateBluePrint,ConvertNft,ConvertItems,Hatch,Breed,Feed,Evolve,
  // ExtractEvo,LearnSkill,ChangeSkill,UpgradePlot,TrainTroops
  ScholarPermissions: {
    [0]: true,
    [1]: true,
    [2]: false,
    [3]: false,
    [4]: false,
    [5]: false,
    [6]: true,
    [7]: true,
    [8]: true,
    [9]: true,
    [10]: true,
    [11]: true,
  },
  MarketTicketReward: [
    { min: 100, max: 299, type: 11, amount: 1 },
    { min: 300, max: 499, type: 12, amount: 1 },
    { min: 500, max: 999, type: 13, amount: 1 },
    { min: 1000, max: null, type: 14, amount: 1 },
  ],
  BattlefrontRank: [
    "No League",
    "Bronze III",
    "Bronze II",
    "Bronze I",
    "Sliver III",
    "Sliver II",
    "Sliver I",
    "Gold III",
    "Gold II",
    "Gold I",
    "Platinum III",
    "Platinum II",
    "Platinum I",
    "Diamond",
  ],
  INIT_MONGEN_BATTLE: 1,
  INIT_ACC_BATTLE: 800,

  // interface: MongenRale
  MORALE_RANGE: [65, 120, 300, 150, 75],

  // interface: MongenRale - [min-max]
  RANDOM_MORALE_RANGE: [[130, 220], [110, 160], [100], [75, 95], [40, 75]],
  MIN_LOYALTY: -30,
  MAX_LOYALTY: 70,
  MAX_BATTLE_COUNT: 160,
  STEP_BATTLE_COUNT: 10,
  STEP_MORALE_EFFECT: 150,

  WIN_COUNT_CAP: 10,
  LOSE_COUNT_CAP: 3,
  FED_FOOD_CAP: 2000000,
  LEVELUP_CAP: 5,
  SKILL_CONSUME_CAP: 5,
  FED_EVOLVE_CAP: 300,
  FED_STONES_CAP: 6,
  MSTR_POINT: 100000,
  MAG_POINT: 100,

  BACKGROUND_STATS: [
    {
      code: "Background_size_1",
      description: "Background_size_1_desc",
      rarity: 0,
      craft_cost: 100,
      convert_fee: 0.3,
    },
    {
      code: "Background_size_2",
      description: "Background_size_2_desc",
      rarity: 2,
      craft_cost: 250,
      convert_fee: 0.75,
    },
    {
      code: "Background_size_4",
      description: "Background_size_4_desc",
      rarity: 3,
      craft_cost: 750,
      convert_fee: 2.25,
    },
    {
      code: "Background_size_9",
      description: "Background_size_9_desc",
      rarity: 4,
      craft_cost: 1500,
      convert_fee: 4.5,
    },
  ],
  WithdrawMintMAG: [
    10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
    9500, 9000, 8500, 8000, 7500, 7000, 6500, 6000, 5000,
  ],
  sMAGCapacity: [
    1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 6000, 7000, 8000,
    9000, 10000, 11000, 12000, 13000, 14000, 15000, 16000,
  ],
  ConvertFeeRate: 0.3,
  HighConvertFeeRate: 3,

  // farm common 1 - farm common 2 - farm common 3 - battle
  ActionPoint: [15, 30, 45, 10],

  BattleFrontQuestRate: [
    [1500, 501, 0.8],
    [500, 201, 0.7],
    [200, 1, 0.6],
  ],

  BattlepassPrice: 300,

  BattlepassLevelPriceConfig: [
    [1, 20],
    [10, 180],
  ],

  BattlepassLevelExp: 1000,

  UnlockBattlePassLevel: 6,

  // Battle front reward pool for top
  BattlefrontRewardPool: [
    92198, 62411, 45390, 29168, 26990, 25010, 23211, 21575, 20087, 18735, 17506,
    16388, 15372, 14449, 13609, 12846, 12152, 11521, 10948, 10426, 7176, 6967,
    6766, 6573, 6387, 6208, 6037, 5871, 5713, 5560, 5413, 5272, 5136, 5006,
    4880, 4759, 4643, 4532, 4424, 4321, 4222, 4127, 4035, 3947, 3862, 3780,
    3702, 3627, 3554, 3484, 3339, 3309, 3279, 3249, 3219, 3190, 3161, 3132,
    3104, 3075, 3048, 3020, 2993, 2965, 2939, 2912, 2886, 2860, 2834, 2809,
    2783, 2758, 2734, 2709, 2685, 2661, 2637, 2613, 2590, 2567, 2544, 2522,
    2499, 2477, 2455, 2433, 2412, 2390, 2369, 2348, 2328, 2307, 2287, 2267,
    2247, 2227, 2208, 2188, 2169, 2150, 2132, 2113, 2095, 2077, 2059, 2041,
    2023, 2006, 1988, 1971, 1954, 1937, 1921, 1904, 1888, 1872, 1856, 1840,
    1824, 1809, 1794, 1778, 1763, 1748, 1734, 1719, 1705, 1690, 1676, 1662,
    1648, 1634, 1621, 1607, 1594, 1581, 1568, 1555, 1542, 1529, 1516, 1504,
    1492, 1479, 1467, 1455, 1444, 1432, 1420, 1409, 1397, 1386, 1375, 1364,
    1353, 1342, 1331, 1321, 1310, 1300, 1289, 1279, 1269, 1259, 1249, 1239,
    1230, 1220, 1211, 1201, 1192, 1182, 1173, 1164, 1155, 1146, 1138, 1129,
    1120, 1112, 1103, 1095, 1087, 1078, 1070, 1062, 1054, 1046, 1039, 1031,
    1023, 1016, 1008, 1001, 993, 986, 979, 972, 965, 958, 951, 944, 937, 930,
    924, 917, 911, 904, 898, 891, 885, 879, 873, 867, 861, 855, 849, 843, 837,
    831, 826, 820, 815, 809, 804, 798, 793, 788, 782, 777, 772, 767, 762, 757,
    752, 747, 742, 737, 733, 728, 723, 719, 714, 710, 705, 701, 696, 692, 688,
    684, 679, 675, 671, 667, 663, 659, 655, 651, 647, 643, 639, 636, 632, 628,
    625, 621, 617, 614, 610, 607, 603, 600, 597, 593, 590, 587, 583, 580, 577,
    574, 571, 568, 564, 561, 558, 555, 553, 550, 547, 544, 541, 538, 535, 533,
    530, 527, 525, 522, 519, 517,
  ],

  TutCompleteExpReward: 68,
};
export default GameConstant;
