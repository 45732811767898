import { RewardEntity } from "interfaces";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Button, Card, Input } from "semantic-ui-react";
import Enum from "./enum";
import RewardEditor from "./reward-editor";
import dataService from "../services/data";

export default function TopBattlefrontListRewardEditor({
  value,
  onChange,
  viewOnly,
}: {
  viewOnly?: boolean;
  value: {
    rewards: RewardEntity[][];
    topX: number;
    rank: number;
  }[];
  onChange: Function;
}) {
  const { t } = useTranslation();
  function handleChange(index: number, val: any, key: string, ind?: number) {
    let tmp: {
      rewards: RewardEntity[][];
      topX: number;
      rank: number;
    }[] = [...value];

    if (key !== "rewards") {
      //@ts-ignore
      tmp[index][key] = val;
      if (tmp[index].rewards.length > tmp[index].topX) {
        tmp[index].rewards = tmp[index].rewards.slice(0, tmp[index].topX);
      } else {
        console.log(tmp[index].topX - tmp[index].rewards.length);
        for (let i = tmp[index].topX - tmp[index].rewards.length; i > 0; i--) {
          tmp[index].rewards.push([]);
        }
      }
    } else {
      //@ts-ignore
      tmp[index][key][ind] = val;
    }
    onChange(tmp);
  }
  const addTop = () => {
    value.push({
      rewards: [],
      topX: 0,
      rank: 0,
    });
    onChange(value);
  };
  return (
    <div>
      {value.map((valTop: any, index) => {
        return (
          <div key={index} className="mt-5">
            <Card fluid>
              <Card.Content>
                <Card.Header>
                  <div className="flex items-center">
                    <div className="mr-5 text-lg">{`Top ${
                      valTop.topX
                    } Customer in ${
                      dataService
                        .getEnum("battlefront-rank-top-reward")
                        ?.items?.filter((x) => x.value === valTop.rank)[0]
                        ?.label ?? "Rank"
                    }`}</div>
                    <Button
                      icon="trash"
                      color="red"
                      onClick={() => {
                        let tmp = _.cloneDeep(value);
                        tmp.splice(index, 1);
                        onChange(tmp);
                      }}
                    />
                  </div>
                </Card.Header>
              </Card.Content>
              <Card.Content>
                <div>
                  <div className="font-bold">Number Customer in Top</div>
                  <Input
                    className="w-20"
                    value={valTop.topX}
                    placeholder="Number"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(index, Number(evt.target.value), "topX");
                    }}
                  />
                </div>
                <div className="mt-5">
                  <div className="font-bold">Customer Rank</div>
                  <Enum
                    placeholder="Customer rank"
                    enumName="battlefront-rank-top-reward"
                    value={valTop.rank}
                    onChange={(val) => {
                      handleChange(index, Number(val), "rank");
                    }}
                  />
                </div>
                <div className="mt-5">
                  <div className="font-bold">Rewards</div>
                  {valTop.rewards.map((reward: any, ind: number) => (
                    <div className="mt-5 border-t pt-3">
                      <div className="text-base">reward top {ind + 1}</div>
                      <RewardEditor
                        viewOnly={viewOnly}
                        value={reward || []}
                        onChange={(val: any) => {
                          handleChange(index, val, "rewards", ind);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </Card.Content>
            </Card>
          </div>
        );
      })}
      <div className="mt-5 text-right">
        <Button
          icon="plus"
          content="Add"
          labelPosition="left"
          onClick={addTop}
        />
      </div>
    </div>
  );
}
