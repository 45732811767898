import { RewardEntity } from "interfaces";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Button, Card, Input } from "semantic-ui-react";
import Enum from "./enum";
import RewardEditor from "./reward-editor";
import dataService from "../services/data";

export default function TopArenaListRewardEditor({
  value,
  onChange,
  viewOnly,
}: {
  viewOnly?: boolean;
  value: {
    rewards: RewardEntity[][];
    topX: number;
  };
  onChange: Function;
}) {
  const { t } = useTranslation();
  function handleChange(val: any, key: string, ind?: number) {
    let tmp: {
      rewards: RewardEntity[][];
      topX: number;
    } = { ...value };

    if (key !== "rewards") {
      //@ts-ignore
      tmp[key] = val;
      if(!tmp.rewards) {
        tmp.rewards = []
      }
      if (tmp.rewards.length > tmp.topX) {
        tmp.rewards = tmp.rewards.slice(0, tmp.topX);
      } else {
        console.log(tmp.topX - tmp.rewards.length);
        for (let i = tmp.topX - tmp.rewards.length; i > 0; i--) {
          tmp.rewards.push([]);
        }
      }
    } else {
      //@ts-ignore
      tmp[key][ind] = val;
    }
    onChange(tmp);
  }
  return (
    <div>
      <div className="mt-5">
        <Card fluid>
          <Card.Content>
            <div>
              <div className="font-bold">Number Customer in Top</div>
              <Input
                className="w-20"
                value={value.topX}
                placeholder="Number"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(Number(evt.target.value), "topX");
                }}
              />
            </div>
            <div className="mt-5">
              <div className="font-bold">Rewards</div>
              {value.rewards?.map((reward: any, ind: number) => (
                <div className="mt-5 border-t pt-3">
                  <div className="text-base">reward top {ind + 1}</div>
                  <RewardEditor
                    viewOnly={viewOnly}
                    value={reward || []}
                    onChange={(val: any) => {
                      handleChange(val, "rewards", ind);
                    }}
                  />
                </div>
              ))}
            </div>
          </Card.Content>
        </Card>
      </div>
    </div>
  );
}
