import Config from "config";
import { DailyQuestRewardItem, MongenRace, PlotType, RewardEntity, RewardType } from "interfaces";
import { Button, Checkbox, Input } from "semantic-ui-react";
import { utils } from "services";
import Entity from "./entity";
import Enum from "./enum";

export default function RewardEditor({
  value,
  onChange,
  viewOnly,
}: {
  viewOnly?: boolean;
  value: DailyQuestRewardItem[];
  onChange: Function;
}) {
  function addReward() {
    let tmp: DailyQuestRewardItem[] = [...value];
    tmp.push({
      reward: {
        reward_type: null,
        reward_quantity: null,
        plot_type: null,
        race: null,
        rarity: null,
        is_high_fee: false,
      },
      limit_per_user: 0,
      limit_pool_min: 0,
      limit_pool_max: 0,
      is_pool_infinity: false,
      is_user_infinity: false,
      cost: 0,
      multiplier: 1,
      min_level: 1
    });
    onChange(tmp);
  }
  function removeReward(index: number) {
    let tmp: DailyQuestRewardItem[] = [...value];
    tmp.splice(index, 1);
    onChange(tmp);
  }
  function handleChange(index: number, name1: string, name2: string, val: any) {
    let tmp: DailyQuestRewardItem[] = [...value];
    //@ts-ignore
    tmp[index][name1][name2] = val;
    onChange(tmp);
  }
  function handleChange2(index: number, name: string, val: any) {
    let tmp: DailyQuestRewardItem[] = [...value];
    //@ts-ignore
    tmp[index][name] = val;
    onChange(tmp);
  }
  return (
    <div>
      {value.map((i: DailyQuestRewardItem, index: number) => (
        <div
          key={index}
          className="gap-2 justify-between border hover:border-gray-200 border-white p-1"
        >
          <div className="w-full flex gap-2 items-center">
            <span className="font-semibold mr-2 w-6">{index + 1}.</span>
            <div className="w-44">
              <Enum
                placeholder="Reward"
                enumName="reward-type"
                value={i.reward.reward_type}
                onChange={(val) => {
                  handleChange(index, "reward", "reward_type", Number(val));
                }}
              />
            </div>
            <div className="w-20">
              <Input
                className="w-20"
                value={i.reward.reward_quantity || ""}
                placeholder="Quantity"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(index, "reward", "reward_quantity", Number(evt.target.value));
                }}
              />
            </div>
            {i.reward.reward_type === RewardType.PLOT && (
              <div className="w-44">
                <Enum
                  placeholder="Plot type"
                  enumName="plot-type"
                  value={i.reward.plot_type}
                  onChange={(val) => {
                    handleChange(index, "reward", "plot_type", Number(val));
                    if (Number(val) !== PlotType.Pasture && Number(val) !== -1 && Number(val) !== -1) {
                      handleChange(index, "reward", "race", MongenRace.All);
                    } else if ((Number(val) === PlotType.Pasture && i.reward.race === MongenRace.All) || Number(val) === -1) {
                      handleChange(index, "reward", "race", -1);
                    }
                    if ([...Config.SubablePlotType, -1].includes(Number(val))) {
                      handleChange(index, "reward", "sub_plot_type", 0)
                    }
                  }}
                />
              </div>
            )}
            {(i.reward.reward_type === RewardType.PLOT ||
              i.reward.reward_type === RewardType.SOULCORE) && (
                <>
                  <div className="w-44">
                    <Enum
                      placeholder="Race"
                      enumName="mongen-race"
                      value={i.reward.race}
                      onChange={(val) => {
                        handleChange(index, "reward", "race", Number(val));
                      }}
                      // @ts-ignore
                      disabledValue={(i.reward.reward_type === RewardType.SOULCORE || (i.reward.reward_type === RewardType.PLOT && (i.reward.plot_type === PlotType.Pasture || i.reward.plot_type === null || i.reward.plot_type === -1))) ? [MongenRace.All] : [MongenRace.Beast, MongenRace.Tectos, MongenRace.Mystic, MongenRace.Celest, MongenRace.Chaos, -1]}
                    />
                  </div>
                </>
              )}
            {(i.reward.reward_type === RewardType.PLOT && [...Config.SubablePlotType, -1].includes(i.reward.plot_type)) && (
              <>
                <div className="w-44">
                  <Enum
                    placeholder="Subtype"
                    enumName={utils.getEnumSubType(i.reward.plot_type)}
                    value={i.reward.sub_plot_type}
                    onChange={(val) => {
                      handleChange(index, "reward", "sub_plot_type", Number(val));
                    }}
                  />
                </div>
              </>
            )}
            {(i.reward.reward_type === RewardType.PLOT ||
              i.reward.reward_type === RewardType.SOULCORE) && (
                <div className="w-44">
                  <Enum
                    placeholder="Rarity"
                    enumName="rarity"
                    value={i.reward.rarity}
                    onChange={(val) => {
                      handleChange(index, "reward", "rarity", Number(val));
                    }}
                  />
                </div>
              )}
            {i.reward.reward_type === RewardType.TICKET &&
              (
                <div className="w-44">
                  <Enum
                    placeholder="Rarity"
                    enumName="ticketrarity"
                    value={i.reward.rarity}
                    onChange={(val) => {
                      handleChange(index, "reward", "rarity", Number(val));
                    }}
                  />
                </div>
              )}
            {i.reward.reward_type === RewardType.BOX && (
              <div className="w-80">
                <Entity
                  displayField={"name"}
                  value={i.reward.reward_pool_id}
                  gridName={"reward-pool"}
                  multiple={false}
                  onChange={(val: any, raw: any) => {
                    handleChange(index, "reward", "reward_pool_id", val);
                    handleChange(index, "reward", "box_name", raw.name);
                    handleChange(index, "reward", "box_type", raw.name);
                  }}
                />
              </div>
            )}
            {i.reward.reward_type === RewardType.Avatar && (
              <div className="w-44">
                <Input
                  className="w-32"
                  value={i.reward.avatar_id || ""}
                  placeholder="Avatar id"
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(index, "reward", "avatar_id", Number(evt.target.value));
                  }}
                />
              </div>
            )}
            {i.reward.reward_type === RewardType.DecorationList && (
              <div className="w-44">
                <Enum
                  enumName="decoration-list"
                  value={i.reward.decoration_code}
                  onChange={(val, raw) => {
                    console.log({ val, raw });
                    handleChange(index, "reward", "decoration_code", val);
                    handleChange(index, "reward", "rarity", raw.rarity);
                  }}
                />
                {/* <Entity
                  displayField={"code"}
                  value={i.reward.decoration_code}
                  gridName={"decoration-list"}
                  multiple={false}
                  onChange={(val: any, entity: any) => {
                    handleChange(index, "decoration_code", entity.code);
                    handleChange(index, "rarity", entity.rarity);
                  }}
                  value_field={"code"}
                /> */}
              </div>
            )}
            {([RewardType.PLOT, RewardType.SOULCORE, RewardType.BOX]).includes(i.reward.reward_type) && (
              <div className="w-44">
                <Checkbox
                  className="translate-y-2"
                  label="High fee clean"
                  checked={i.reward.is_high_fee}
                  toggle
                  onChange={(evt: any, { checked }) => {
                    handleChange(index, "reward", "is_high_fee", checked);
                  }}
                />
              </div>
            )}
          </div>
          <div className="w-full flex gap-2 items-center my-4 pl-10">
            <Input
              className="w-10"
              value={i.cost || ""}
              placeholder="Cost"
              label="Cost"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                handleChange2(index, "cost", Number(evt.target.value));
              }}
            />
            <Input
              className="w-max-10"
              value={i.multiplier || ""}
              placeholder="Multiplier"
              label="Multiplier"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                handleChange2(index, "multiplier", Number(evt.target.value));
              }}
            />
            <Input
              className="w-max-10"
              value={i.min_level || ""}
              placeholder="Min level"
              label="Min level"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                handleChange2(index, "min_level", Number(evt.target.value));
              }}
            />
          </div>

          <div className="w-full flex inline-block gap-2 my-4 pl-10">
            <Checkbox
              className="translate-y-2 w-[300px] "
              label="Is infinity User limit"
              checked={i.is_user_infinity}
              toggle
              onChange={(evt: any, { checked }) => {
                handleChange2(index, "is_user_infinity", checked);
              }}
            />
            {!i.is_user_infinity && <Input
              className="w-10"
              value={i.limit_per_user || ""}
              placeholder="Limit items per user"
              label="Limit items per user"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                handleChange2(index, "limit_per_user", Number(evt.target.value));
              }}
            />}
          </div>
          <div className="w-full flex inline-block gap-2 my-4 pl-10">
            <Checkbox
              className="translate-y-2 w-[600px]"
              label="Is inifinity pool limit"
              checked={i.is_pool_infinity}
              toggle
              onChange={(evt: any, { checked }) => {
                handleChange2(index, "is_pool_infinity", checked);
              }}
            />
            {!i.is_pool_infinity && <><Input
              className="w-max-10"
              value={i.limit_pool_min || ""}
              placeholder="Min claim limit"
              label="Min claim limit"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                handleChange2(index, "limit_pool_min", Number(evt.target.value));
              }}
            />
              <Input
                className="w-max-10"
                value={i.limit_pool_max || ""}
                placeholder="Max claim limit"
                label="Max claim limit"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange2(index, "limit_pool_max", Number(evt.target.value));
                }}
              /></>}
          </div>
          {
            !viewOnly && (
              <Button
                icon="times"
                color="red"
                onClick={() => {
                  removeReward(index);
                }}
              />
            )
          }
        </div >
      ))
      }
      <div className="mt-2">
        {!viewOnly && (
          <Button
            icon="plus"
            content="Add"
            labelPosition="left"
            onClick={addReward}
          />
        )}
      </div>
    </div >
  );
}
