import { Rarity, RewardEntity, RewardType } from "interfaces";
import { Button, Card, Input } from "semantic-ui-react";
import Entity from "./entity";
import Enum from "./enum";
import RewardEditor from "./reward-editor";

export default function RewardRarityEditor({
  value,
  onChange,
  viewOnly,
}: {
  viewOnly?: boolean;
  value: RewardEntity[][];
  onChange: Function;
}) {
  function handleChange(rarity: number, val: any) {
    let tmp: RewardEntity[][] = [...value];
    //@ts-ignore
    tmp[rarity] = val;
    onChange(tmp);
  }
  return (
    <>
      {[
        Rarity.Common,
        Rarity.Uncommon,
        Rarity.Rare,
        Rarity.Epic,
        Rarity.Legendary,
      ].map((r: Rarity, rarity: number) => {
        return (
          <Card fluid>
            <Card.Content>
              <p className="font-semibold">{Rarity[r]}</p>
              <RewardEditor
                viewOnly={viewOnly}
                value={value[r] || []}
                onChange={(val: any) => {
                  handleChange(rarity, val);
                }}
              />
            </Card.Content>
          </Card>
        );
      })}
    </>
  );
}
