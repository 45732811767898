import { useParams } from "react-router-dom";
import EventReportTable from "./event-report";
import MagMstrReportTable from "./mag-mstr-report";
import UserAdventureStageTable from "./user-adventure-stage";
import UserCountryReportTable from "./user-country-report";
import UserSignupCountryReport from "./user-signup-country-report";
import WorldcupMatchPredictTable from "./worldcup-match-predict";
export default function Table() {
  const { type } = useParams();
  switch (type) {
    case "user-adventure-stage":
      return <UserAdventureStageTable />;
    case "user-country-report":
      return <UserCountryReportTable />;
    case "user-signup-country-report":
      return <UserSignupCountryReport />;
    case "mag-mstr-report":
      return <MagMstrReportTable />;
    case "event-report":
      return <EventReportTable />;
    case "match-predict-report":
      return <WorldcupMatchPredictTable />;
    default:
      return <p>Chart {type}</p>;
  }
}
