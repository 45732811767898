import { RewardEntity } from "interfaces";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Button, Card, Checkbox, Input } from "semantic-ui-react";
import Enum from "./enum";
import RewardEditor from "./reward-editor";
import dataService from "../services/data";

export default function BattlepassReward({
  value,
  onChange,
  viewOnly,
}: {
  viewOnly?: boolean;
  value: {
    rewards: RewardEntity[];
    is_premium: boolean;
  }[];
  onChange: Function;
}) {
  const { t } = useTranslation();
  function handleChange(index: number, val: any, key: string) {
    let tmp: {
      rewards: RewardEntity[];
      is_premium: boolean;
    }[] = [...value];
    //@ts-ignore
    tmp[index][key] = val;
    onChange(tmp);
    console.log(tmp)
  }
  const addLevel = () => {
    value.push({
      rewards: [],
      is_premium: false,
    });
    onChange(value);
  };
  return (
    <div>
      {value.map((valLevel: any, index) => {
        return (
          <div key={index} className="mt-5">
            <Card fluid>
              <Card.Content>
                <Card.Header>
                  <div className="flex items-center">
                    <div className="mr-5 text-lg">{`Level ${index
                      }`}</div>
                    <Button
                      icon="trash"
                      color="red"
                      onClick={() => {
                        let tmp = _.cloneDeep(value);
                        tmp.splice(index, 1);
                        onChange(tmp);
                      }}
                    />
                  </div>
                </Card.Header>
              </Card.Content>
              <Card.Content>
                <div className="mt-5">
                  <div className="font-bold">Is Premium</div>
                  <Checkbox
                    checked={valLevel.is_premium}
                    toggle
                    onChange={(evt: any, { checked }) => {
                      console.log({ checked })
                      handleChange(index, checked, "is_premium");
                    }}
                  />
                </div>
                <div className="mt-5">
                  <div className="font-bold">Rewards</div>
                  <div className="mt-5 border-t pt-3">
                    <RewardEditor
                      viewOnly={viewOnly}
                      value={valLevel.rewards || []}
                      onChange={(val: any) => {
                        handleChange(index, val, "rewards");
                      }}
                    />
                  </div>
                </div>
              </Card.Content>
            </Card>
          </div>
        );
      })}
      <div className="mt-5 text-right">
        <Button
          icon="plus"
          content="Add"
          labelPosition="left"
          onClick={addLevel}
        />
      </div>
    </div>
  );
}
