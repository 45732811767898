/** @format */

import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
//@ts-ignore
import { api } from "services";
import dayjs from "dayjs";
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Statistic,
} from "semantic-ui-react";
import { DatePicker, Enum } from "components";
import _ from "lodash";
const RESOURCES = ["MAG", "MSTR", "FOOD"];
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);
export default function MinerChart() {
  const [loading, setLoading] = useState<boolean>(true);
  const [earn, setEarn] = useState<{ options: any; data: any }>(null);
  const [dateFrom, setDateFrom] = useState(
    dayjs().add(-1, "week").startOf("day").toDate()
  );
  const [dateTo, setDateTo] = useState(dayjs().add(1, "day").toDate());
  const [versions, setVersions] = useState<string>("");
  const [resource, setResource] = useState<string>("MSTR");
  const [group, setGroup] = useState<string>("date");
  const [type, setType] = useState<string>("mstr");
  async function loadData() {
    setLoading(true);
    try {
      let rs = await api.post("/operation/get-miner-report", {
        dateFrom: dateFrom,
        dateTo: dateTo,
        versions,
        type,
        groupBy: group,
      });
      setEarn(rs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div>
      <Card fluid>
        {earn !== null && (
          <Card.Content>
            <Line options={earn.options} data={earn.data} />
          </Card.Content>
        )}
        <Card.Content>
          <Form className="flex gap-2 w-full">
            <Form.Field className="w-full">
              <label>View by</label>
              <Select
                placeholder={"Select view time"}
                fluid
                value={group}
                options={["date", "week", "month"].map((i) => {
                  return { text: i, value: i };
                })}
                onChange={(evt: any, val: any) => {
                  setGroup(val.value);
                }}
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>From</label>
              <DatePicker
                todayButton="Today"
                selected={dateFrom}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateFrom(null);
                  } else {
                    setDateFrom(dayjs(val).startOf("day").toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>To</label>
              <DatePicker
                todayButton="Today"
                selected={dateTo}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateTo(null);
                  } else {
                    setDateTo(dayjs(val).endOf("day").toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>Type</label>
              <Select
                placeholder={"Select type"}
                fluid
                value={type}
                options={[
                  { label: "S-MAG", value: "mag" },
                  { label: "MSTR", value: "mstr" },
                  { label: "Food", value: "food" },
                  { label: "Pigment", value: "pigment" },
                  { label: "Stake MAG", value: "stake_mag" },
                  { label: "Dailyquest Points", value: "dailyquest_points" },
                ].map((i) => {
                  return { text: i.label, value: i.value };
                })}
                onChange={(evt: any, val: any) => {
                  setType(val.value);
                }}
              />
            </Form.Field>
            <div>
              <Form.Field>
                <label>Action</label>
                <Button
                  loading={loading}
                  color="blue"
                  icon="search"
                  content="Search"
                  labelPosition="left"
                  onClick={loadData}
                />
              </Form.Field>{" "}
              <Form.Field>
                <Button
                  loading={loading}
                  color="teal"
                  icon="download"
                  content="Download CSV"
                  labelPosition="left"
                  onClick={() => {
                    api.downloadReport(earn.data);
                  }}
                />
              </Form.Field>
            </div>
          </Form>
        </Card.Content>
      </Card>
    </div>
  );
}
