import { RewardEntity } from "interfaces";
import { useTranslation } from "react-i18next";
import RewardEditor from "./reward-editor";

export default function ListRewardEditor({
  value,
  onChange,
  viewOnly,
  subLabels
}: {
  viewOnly?: boolean;
  value: RewardEntity[][];
  onChange: Function;
  subLabels: string[];
}) {
  const { t } = useTranslation();
  function handleChange(index: number, val: any) {
    let tmp: RewardEntity[][] = [...value];

    //@ts-ignore
    tmp[index] = val;
    onChange(tmp);
  }
  return (
    <div>
    {
      subLabels.map((lb, index) => {
        return (
          <div key={index}>
            <label>{t(lb)}</label>
            <RewardEditor
              viewOnly={viewOnly}
              value={value[index] || []}
              onChange={(val: any) => {
                handleChange(index, val);
              }}
            />
          </div>
        )
      })
    }
    </div>
  );
}
