import { BodyPart, PlotType } from "interfaces";
const breeding = require("./abi/breeding_id_part.json");

let uid = 0;
function matchText(search: string, text: string) {
  if (!(search && text)) return false;
  return text.toLowerCase().indexOf(search.toLowerCase()) >= 0;
}
function getUid() {
  return uid++;
}
function intToString(val: number) {
  if (val < 10000) {
    return val;
  }
  let value = Math.round(val);
  var suffixes = ["", "k", "m", "b", "t"];
  var suffixNum = Math.floor(("" + value).length / 3);
  var shortValue = parseFloat(
    (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
  );
  if (shortValue % 1 != 0) {
    // @ts-ignore
    shortValue = shortValue.toFixed(1).toString();
  }
  return shortValue + suffixes[suffixNum];
}
function convertValue(val: any, data: any) {
  if (!val) return val;
  if (typeof val === "number") {
    return val;
  }
  if (val[0] === "$") {
    const key: string = val.substr(1, val.length - 1);
    //@ts-ignore
    return data[key];
  }
  return val;
}
function checkExpression(express: any, data: any) {
  for (var i in express) {
    let e = express[i];
    switch (String(i)) {
      case "$eq":
        if (convertValue(e[0], data) !== convertValue(e[1], data)) {
          return false;
        }
        break;
      case "$in":
        if (
          !(
            convertValue(e[0], data) in
            e[1].map((i: any) => convertValue(i, data))
          )
        ) {
          return false;
        }
        break;
      case "$nin":
        if (
          convertValue(e[0], data) in
          e[1].map((i: any) => convertValue(i, data))
        ) {
          return false;
        }
        break;
      case "$neq":
        if (convertValue(e[0], data) === convertValue(e[1], data)) {
          return false;
        }
        break;

      default:
        return false;
    }
  }
  return true;
}
const getStringifyValue = (value: any) => {
  try {
    let data = JSON.parse(value);
    return value;
  } catch (err) {
    return JSON.stringify(value);
  }
};
const getEnumSubType = (plot_type: PlotType | -1) => {
  switch (plot_type) {
    case PlotType.Barracks:
      return "barrack-type";
    case PlotType.Camp:
      return "camp-type";
    case PlotType.Tower:
      return "tower-type";
    case PlotType.Bombard:
      return "bombard-type";
    case PlotType.Trap:
      return "trap-type";
    case -1:
      return "sub-type";
  }
};
export function canBeAncester(
  part: BodyPart,
  rarity: number,
  id: number
): boolean {
  if (part === BodyPart.Mouth || part === BodyPart.Aura) {
    return true;
  }
  return breeding[part][rarity][id].ancestor;
}
export function canInherit(part: number, rarity: number, id: number): boolean {
  if (part === BodyPart.Mouth || part === BodyPart.Aura) {
    return true;
  }
  return breeding[part][rarity][id].inheritable;
}
const utils = {
  getEnumSubType,
  matchText,
  getUid,
  checkExpression,
  intToString,
  getStringifyValue,
};
export default utils;
