import Config from "config";
import { MongenRace, PlotType, RewardEntity, RewardType } from "interfaces";
import { useEffect } from "react";
import { Button, Checkbox, Input, Popup } from "semantic-ui-react";
import { utils } from "services";
import Entity from "./entity";
import Enum from "./enum";
import _ from "lodash";

export default function RewardEditor({
  value,
  onChange,
  viewOnly,
  length,
  analytics,
}: {
  viewOnly?: boolean;
  value: RewardEntity[];
  onChange: Function;
  length?: number;
  analytics?: RewardEntity[];
}) {
  useEffect(() => {
    if (length) {
      let tmp = Array.from({ length }).map((i, idx) => {
        if (!value[idx] || value[idx]?.reward_type === null)
          return {
            reward_type: null,
            reward_quantity: null,
            plot_type: null,
            race: null,
            rarity: null,
            is_high_fee: false,
          }
        else return value[idx]
      })
      onChange(tmp)
    }
  }, [length])

  function addReward() {
    let tmp: RewardEntity[] = [...value];
    tmp.push({
      reward_type: null,
      reward_quantity: null,
      plot_type: null,
      race: null,
      rarity: null,
      is_high_fee: false,
    });
    onChange(tmp);
  }
  function removeReward(index: number) {
    let tmp: RewardEntity[] = [...value];
    tmp.splice(index, 1);
    onChange(tmp);
  }
  function handleChange(index: number, name: string, val: any) {
    let tmp: RewardEntity[] = [...value];
    //@ts-ignore
    tmp[index][name] = val;
    onChange(tmp);
  }
  function renderToolTipAnalytics(reward: RewardEntity) {
    if(!analytics) {
      return;
    }
    let isRender = false;
    let quant = 0;
    analytics.map((analytic) => {
      const ana = _.cloneDeep(analytic);
      delete ana.reward_quantity;
      const tmp = _.cloneDeep(reward);
      delete tmp.reward_quantity;
      if (_.isEqual(tmp, ana)) {
        quant = analytic.reward_quantity;
        isRender = true;
      }
    });
    if(isRender) {
      return (
        <Popup
          content={`${quant} opened`}
          on="click"
          pinned
          trigger={
            <Button
              icon={"question"}
              className="!rounded-full !p-[1px] !text-xs w-[15px] h-[15px] "
            />
          }
        />
      );
    }
  }
  return (
    <div>
      {value.map((i: RewardEntity, index: number) => (
        <div
          key={index}
          className="flex gap-2 justify-between border hover:border-gray-200 border-white p-1"
        >
          <div className="w-full flex gap-2 items-center">
            <div className="flex gap-1">
              <span className="font-semibold">{index + 1}.</span>
              {renderToolTipAnalytics(i)}
            </div>
            <div className="w-44">
              <Enum
                placeholder="Reward"
                enumName="reward-type"
                value={i.reward_type}
                onChange={(val) => {
                  handleChange(index, "reward_type", Number(val));
                }}
              />
            </div>
            <div className="w-20">
              <Input
                className="w-20"
                value={i.reward_quantity || ""}
                placeholder="Quantity"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(
                    index,
                    "reward_quantity",
                    Number(evt.target.value)
                  );
                }}
              />
            </div>
            <div className="w-20">
              <Input
                className="w-20"
                value={i.rate || ""}
                placeholder="Open rate"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(index, "rate", Number(evt.target.value));
                }}
              />
            </div>
            <div className="w-20">
              <Input
                className="w-20"
                value={i.fake_rate || ""}
                placeholder="Fake open rate"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(index, "fake_rate", Number(evt.target.value));
                }}
              />
            </div>
            <div className="w-40">
              <Input
                className="w-40"
                value={i.total_rewards || ""}
                placeholder="Total Reward"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(
                    index,
                    "total_rewards",
                    Number(evt.target.value)
                  );
                }}
              />
            </div>

            {i.reward_type === RewardType.PLOT && (
              <div className="w-44">
                <Enum
                  placeholder="Plot type"
                  enumName="plot-type"
                  value={i.plot_type}
                  onChange={(val) => {
                    handleChange(index, "plot_type", Number(val));
                    if (
                      Number(val) !== PlotType.Pasture &&
                      Number(val) !== -1 &&
                      Number(val) !== -1
                    ) {
                      handleChange(index, "race", MongenRace.All);
                    } else if (
                      (Number(val) === PlotType.Pasture &&
                        i.race === MongenRace.All) ||
                      Number(val) === -1
                    ) {
                      handleChange(index, "race", -1);
                    }
                    handleChange(index, "sub_plot_type", 0)
                  }}
                />
              </div>
            )}
            {(i.reward_type === RewardType.PLOT ||
              i.reward_type === RewardType.SOULCORE) && (
                <>
                  <div className="w-44">
                    <Enum
                      placeholder="Race"
                      enumName="mongen-race"
                      value={i.race}
                      onChange={(val) => {
                        handleChange(index, "race", Number(val));
                      }}
                      disabledValue={
                        i.reward_type === RewardType.SOULCORE ||
                          (i.reward_type === RewardType.PLOT &&
                            (i.plot_type === PlotType.Pasture ||
                              i.plot_type === null ||
                              // @ts-ignore
                              i.plot_type === -1))
                          ? [MongenRace.All]
                          : [
                            MongenRace.Beast,
                            MongenRace.Tectos,
                            MongenRace.Mystic,
                            MongenRace.Celest,
                            MongenRace.Chaos,
                            -1,
                          ]
                      }
                    />
                  </div>
                </>
              )}
            {i.reward_type === RewardType.PLOT &&
              [...Config.SubablePlotType, -1].includes(i.plot_type) && (
                <>
                  <div className="w-44">
                    <Enum
                      placeholder="Subtype"
                      enumName={utils.getEnumSubType(i.plot_type)}
                      value={i.sub_plot_type}
                      onChange={(val) => {
                        handleChange(index, "sub_plot_type", Number(val));
                      }}
                    />
                  </div>
                </>
              )}
            {[RewardType.PLOT, RewardType.SOULCORE, RewardType.Rune].includes(
              i.reward_type
            ) && (
                <div className="w-44">
                  <Enum
                    placeholder="Rarity"
                    enumName="rarity"
                    value={i.rarity}
                    onChange={(val) => {
                      handleChange(index, "rarity", Number(val));
                    }}
                  />
                </div>
              )}
            {i.reward_type === RewardType.TICKET && (
              <div className="w-44">
                <Enum
                  placeholder="Rarity"
                  enumName="ticketrarity"
                  value={i.rarity}
                  onChange={(val) => {
                    handleChange(index, "rarity", Number(val));
                  }}
                />
              </div>
            )}
            {i.reward_type === RewardType.BOX && (
              <div className="w-80">
                <Entity
                  displayField={"name"}
                  value={i.reward_pool_id}
                  gridName={"reward-pool"}
                  multiple={false}
                  onChange={(val: any, raw: any) => {
                    handleChange(index, "reward_pool_id", val);
                    handleChange(index, "box_name", raw.name);
                    handleChange(index, "box_type", raw.name);
                    handleChange(index, "icon", raw.icon);
                  }}
                />
              </div>
            )}
            {i.reward_type === RewardType.Avatar && (
              <div className="w-44">
                <Input
                  className="w-32"
                  value={i.avatar_id || ""}
                  placeholder="Avatar id"
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(index, "avatar_id", Number(evt.target.value));
                  }}
                />
              </div>
            )}
            {i.reward_type === RewardType.ArenaMap && (
              <div className="w-44">
                <Input
                  className="w-32"
                  value={i.arena_map || ""}
                  placeholder="Arena map"
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(index, "arena_map", Number(evt.target.value));
                  }}
                />
              </div>
            )}
            {i.reward_type === RewardType.AWish && (
              <div className="w-44">
                <Input
                  className="w-32"
                  value={i.wish || ""}
                  placeholder="Type your wish"
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(index, "wish", evt.target.value);
                  }}
                />
              </div>
            )}
            {i.reward_type === RewardType.DecorationList && (
              <div className="w-120">
                <Enum
                  enumName="decoration-list"
                  value={i.decoration_code}
                  onChange={(val, raw) => {
                    console.log({ val, raw });
                    handleChange(index, "decoration_code", val);
                    handleChange(index, "rarity", raw.rarity);
                  }}
                />
              </div>
            )}
            {i.reward_type === RewardType.Rune && (
              <div className="w-44">
                <Enum
                  enumName="rune-list"
                  value={i.rune_code}
                  onChange={(val) => {
                    handleChange(index, "rune_code", val);
                  }}
                />
              </div>
            )}
            {i.reward_type === RewardType.PlotSkin && (
              <div className="w-80">
                <Enum
                  enumName="plot-skin-list"
                  value={i.skin_code}
                  onChange={(val) => {
                    handleChange(index, "skin_code", val);
                  }}
                />
              </div>
            )}
            {i.reward_type === RewardType.Mongen && (
              <div className="w-80">
                <Input
                  className="w-80"
                  value={i.dna || ""}
                  placeholder="DNA"
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(index, "dna", evt.target.value);
                  }}
                />
              </div>
            )}            
            {i.reward_type === RewardType.Mongen && (
              <div className="w-80">
                <Input
                  className="w-80"
                  value={i.troop_list || ""}
                  placeholder="Troop List"
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(index, "troop_list", evt.target.value);
                  }}
                />
              </div>
            )}
            {i.reward_type === RewardType.Mongen && (
              <div className="w-40">
                <div className="font-bold">Remove Breeding</div>
                <Checkbox
                  className="translate-y-2"
                  checked={i.remove_breeding}
                  toggle
                  onChange={(evt: any, { checked }) => {
                    handleChange(index, "remove_breeding", checked);
                  }}
                />
              </div>
            )}
            {[
              RewardType.PLOT,
              RewardType.SOULCORE,
              RewardType.BOX,
              RewardType.Rune,
              RewardType.Mongen,
            ].includes(i.reward_type) && (
                <div className="w-44">
                  <Checkbox
                    className="translate-y-2"
                    label="High fee clean"
                    checked={i.is_high_fee}
                    toggle
                    onChange={(evt: any, { checked }) => {
                      handleChange(index, "is_high_fee", checked);
                    }}
                  />
                </div>
              )}
            {i.reward_type === RewardType.EMOJI && (
              <div className="w-44">
                <Enum
                  placeholder="Emoji"
                  enumName="emoji-pool"
                  value={i.emoji_id}
                  onChange={(val) => {
                    handleChange(index, "emoji_id", val);
                  }}
                />
              </div>
            )}
            {i.reward_type === RewardType.BLESSING && (
              <div className="w-44">
                <Checkbox
                  className="translate-y-2"
                  label="Is premium"
                  checked={i.is_premium}
                  toggle
                  onChange={(evt: any, { checked }) => {
                    handleChange(index, "is_premium", checked);
                  }}
                />
              </div>
            )}
          </div>
          {!viewOnly && !length && (
            <Button
              icon="times"
              color="red"
              onClick={() => {
                removeReward(index);
              }}
            />
          )}
        </div>
      ))}
      <div className="mt-2">
        {!viewOnly && !length && (
          <Button
            icon="plus"
            content="Add"
            labelPosition="left"
            onClick={addReward}
          />
        )}
      </div>
    </div>
  );
}
