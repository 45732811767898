import _ from "lodash";
import { useState } from "react";
import { Button, Input, Table } from "semantic-ui-react";
const RACES = ["Beast", "Tectos", "Mythic", "Celest", "Chaos"];
const TIERS = ["D", "C", "B", "A", "S"];
const BodyPart = [
    "Form",
    "Head",
    "Eyes",
    "Horns",
    "Tail",
    "Back",
    "FrontLeg",
    "BackLeg",
    "Mouth",
    "Aura",
];
function Part({ onChange, value }: any) {
    return (
        <div style={{ display: "flex" }}>
            <div style={{ marginRight: 10 }}>
                <p style={{ margin: 0 }}>Race</p>
                <select
                    style={{ width: 100 }}
                    onChange={(evt) => {
                        let tmp = [...value];
                        tmp[0] = Number(evt.target.value);
                        onChange(tmp);
                    }}
                >
                    {RACES.map((name, index) => (
                        <option value={index} selected={value[0] === Number(index)}>
                            {name}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{ marginRight: 10 }}>
                <p style={{ margin: 0 }}>Tier</p>
                <select
                    style={{ width: 100 }}
                    onChange={(evt) => {
                        let tmp = [...value];
                        tmp[1] = Number(evt.target.value);
                        onChange(tmp);
                    }}
                >
                    {TIERS.map((name, index) => (
                        <option value={index} selected={value[1] === Number(index)}>
                            {name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <p style={{ margin: 0 }}>ID</p>
                <select
                    style={{ width: 100 }}
                    onChange={(evt) => {
                        let tmp = [...value];
                        tmp[2] = Number(evt.target.value);
                        onChange(tmp);
                    }}
                >
                    {[1, 2, 3, 4, 5].map((name) => (
                        <option value={name} selected={value[2] === Number(name)}>
                            {name}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export default function DnaSample() {
    const [quantity, setQuantity] = useState(100);
    const [dna, setDna] = useState([
        [
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0],
        ],
        [
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0],
        ],
        [
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0],
        ],
        [
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0],
        ],
        [
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0],
        ],
        [
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0, 0, 1],
            [0],
        ],
    ]);
    const hirarchy = [
        "Mom Mongen",
        "Dad Mongen",
        "Mom Grand Mom",
        "Mom Grand Dad",
        "Dad Grand Mom",
        "Dad Grand Dad",
    ];
    function del(hirarchyIndex: any) {
        let rs = _.cloneDeep(dna);
        rs[hirarchyIndex] = null;
        setDna(rs);
    }
    function handleChange(hirarchyIndex: any, bodyIndex: any, dnaIndex: any, value: any) {
        let rs = _.cloneDeep(dna);
        rs[hirarchyIndex][bodyIndex][dnaIndex] = value;
        setDna(rs);
    }
    function encode(dna: any) {
        if (!dna) return "";
        return encodeURIComponent(JSON.stringify(dna));
    }
    function download() {
        let url = `${process.env.REACT_APP_HOST
            }/cheat/generate-dna-sample?quantity=${quantity}&mom_dna=${encode(
                dna[0]
            )}&dad_dna=${encode(dna[1])}&mom_grand_mom=${encode(
                dna[2]
            )}&mom_grand_dad=${encode(dna[3])}&dad_grand_mom=${encode(
                dna[4]
            )}&dad_grand_dad=${encode(dna[5])}&access=bearer%20monsterravjppro`;
        window.open(url, "_blank");
    }
    return (
        <div className="bootstrap-iso">
            <div className="text-3xl my-1 mb-4">Generate dna sample</div>
            <Input
                label="Quantity"
                type="text"
                className="me-2"
                defaultValue={quantity}
                onChange={(evt: any) => {
                    let v = Number(evt.target.value);
                    setQuantity(v);
                }}
            />
            <Button color="green" className="" onClick={download}>
                Download
            </Button>
            <Table className="table table-bordered mt-2" ui celled table >
                <Table.Header className="text-center">
                    <Table.Row>
                        <Table.HeaderCell>Hierarchy</Table.HeaderCell>
                        {hirarchy.map((i, hIndex) => (
                            <Table.HeaderCell >
                                <div className="grid grid-cols-3">
                                    <div className="col-span-2 mt-2">
                                        {i}
                                    </div>
                                    <Button
                                        className="col-span-1"
                                        color="red"
                                        onClick={() => {
                                            del(hIndex);
                                        }}
                                    >
                                        X
                                    </Button>
                                </div>

                            </Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {BodyPart.map((part, bodyIndex) => {
                        return (
                            <tr>
                                <td>{part}</td>
                                {hirarchy.map((i, index) => (
                                    <td>
                                        <div className="d-flex justify-center">
                                            <Input
                                                onChange={(evt: any) => {
                                                    let v = Number(evt.target.value);
                                                    handleChange(index, bodyIndex, 0, v);
                                                }}
                                                type="text"
                                                style={{ width: 50, marginRight: 5 }}
                                                value={
                                                    dna[index] && dna[index][bodyIndex]
                                                        ? dna[index][bodyIndex][0]
                                                        : ""
                                                }
                                            />
                                            <Input
                                                type="text"
                                                style={{ width: 50, marginRight: 5 }}
                                                value={
                                                    dna[index] && dna[index][bodyIndex]
                                                        ? dna[index][bodyIndex][1]
                                                        : ""
                                                }
                                                onChange={(evt: any) => {
                                                    let v = Number(evt.target.value);
                                                    handleChange(index, bodyIndex, 1, v);
                                                }}
                                            />
                                            <Input
                                                type="text"
                                                style={{ width: 50 }}
                                                value={
                                                    dna[index] && dna[index][bodyIndex]
                                                        ? dna[index][bodyIndex][2]
                                                        : ""
                                                }
                                                onChange={(evt: any) => {
                                                    let v = Number(evt.target.value);
                                                    handleChange(index, bodyIndex, 2, v);
                                                }}
                                            />
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </Table.Body>
            </Table>
            <div>
                <p>
                    <b>Body part: </b> [Race - Rarity - Id]
                </p>
                <p>
                    <b>Aura: </b> [Mongen Rarity]
                </p>
                <p>
                    <b>Dna: </b>
                    {RACES.map((i, index) => (
                        <span className="mr-4 inline-block">
                            [{index}-{i}]
                        </span>
                    ))}
                </p>
                <p>
                    <b>Tier: </b>
                    {TIERS.map((i, index) => (
                        <span className="mr-4 inline-block">
                            [{index}-{i}]
                        </span>
                    ))}
                </p>
            </div>
        </div>
    );
}
