/** @format */

import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
//@ts-ignore
import { api } from "services";
import dayjs from "dayjs";
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Statistic,
  Table,
} from "semantic-ui-react";
import { DatePicker, Enum } from "components";
import _ from "lodash";
import saveAs from "file-saver";
const RESOURCES = ["MAG", "MSTR", "FOOD"];
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);
export default function UserAdventureStageTable() {
  const [loading, setLoading] = useState<boolean>(false);
  const [earn, setEarn] = useState<{ options: any; data: any }>(null);
  const [dateTo, setDateTo] = useState(dayjs().add(1, "day").toDate());
  const [versions, setVersions] = useState<string>("");
  const [resource, setResource] = useState<string>("MSTR");
  async function loadData() {
    setLoading(true);
    try {
      let rs = await api.post("/operation/get-user-adventure-stage", {
        dateTo: dateTo,
        versions,
      });
      setEarn(rs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  return (
    <div>
      <Card fluid>
        {earn !== null && (
          <Card.Content>
            <Table>
              <Table.Header>
                {earn?.data?.labels.map((label: string) => (
                  <Table.HeaderCell>{label}</Table.HeaderCell>
                ))}
              </Table.Header>
              <Table.Body>
                {earn?.data?.datasets.map((dataset: any) => {
                  return (
                    <Table.Row>
                      {dataset.map((data: any) => (
                        <Table.Cell>{data}</Table.Cell>
                      ))}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Card.Content>
        )}
        <Card.Content>
          <Form className="flex gap-2 w-full">
            <Form.Field className="w-full">
              <label>To</label>
              <DatePicker
                todayButton="Today"
                selected={dateTo}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateTo(null);
                  } else {
                    setDateTo(dayjs(val).endOf("day").toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </Form.Field>
            <div>
              <Form.Field>
                <label>Action</label>
                <Button
                  loading={loading}
                  color="blue"
                  icon="search"
                  content="Search"
                  labelPosition="left"
                  onClick={loadData}
                />
              </Form.Field>
            </div>
            <div>
              <Form.Field>
                <Button
                  style={{ marginTop: "15px" }}
                  loading={loading}
                  color="teal"
                  icon="download"
                  content="Download CSV"
                  labelPosition="left"
                  onClick={() => {
                    const labels = earn?.data?.labels
                      ? [earn?.data?.labels]
                      : [[]];
                    const data = earn?.data?.datasets ?? [];
                    console.log(labels.concat(data));
                    let text = labels
                      .concat(data)
                      .map((s: string[]) => s.join(","))
                      .join("\n");
                    var blob = new Blob([text], {
                      type: "text/plain;charset=utf-8;",
                    });
                    saveAs(blob, "report.csv");
                  }}
                />
              </Form.Field>
            </div>
          </Form>
        </Card.Content>
      </Card>
    </div>
  );
}
