import { Rarity, RewardEntity, RewardType, TicketRarity } from "interfaces";
import { Button, Card, Input } from "semantic-ui-react";
import Entity from "./entity";
import Enum from "./enum";
import RewardEditor from "./reward-editor";

export default function TicketRewardRarityEditor({
  value,
  onChange,
  viewOnly,
}: {
  viewOnly?: boolean;
  value: RewardEntity[][];
  onChange: Function;
}) {
  function handleChange(rarity: number, val: any) {
    let tmp: RewardEntity[][] = [...value];
    //@ts-ignore
    tmp[rarity] = val;
    onChange(tmp);
  }
  return (
    <>
      {[
        TicketRarity.Common,
        TicketRarity.Uncommon,
        TicketRarity.Rare,
        TicketRarity.Epic,
        TicketRarity.General,
        TicketRarity.Global,
      ].map((r: TicketRarity, rarity: number) => {
        return (
          <Card fluid>
            <Card.Content>
              <p className="font-semibold">{TicketRarity[r]}</p>
              <RewardEditor
                viewOnly={viewOnly}
                value={value[r] || []}
                onChange={(val: any) => {
                  handleChange(r, val);
                }}
              />
            </Card.Content>
          </Card>
        );
      })}
    </>
  );
}
