import { DatePicker, Entity } from "components";
import dayjs from "dayjs";
import { AnalyticsEventSrc, AnalyticsEventSrcEarnTokenOff, AnalyticsEventSrcSpendTokenOff, AnalyticsTable, LoginType, QueryMode, TokenType } from "interfaces";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { Button, Checkbox, Input, Pagination, Select, Table } from "semantic-ui-react";
import { api } from "services";

export default function QueryTool() {
  const [wallet, setWallet] = useState("");
  const [loginType, setLoginType] = useState(LoginType.BSC);
  const [userId, setUserId] = useState("");
  const [mongenId, setMongenId] = useState("");
  const [plotId, setPlotId] = useState("");
  const [daoTransactionId, setDaoTransactionId] = useState("");
  const [eventLimit, setEventLimit] = useState(10);
  const [queryMode, setQueryMode] = useState(QueryMode.UserInfo);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [page, setPage] = useState(1);
  const [from_address, setFromAddress] = useState("");
  const [table, setTable] = useState(AnalyticsTable[0]);
  const [tokenType, setTokenType] = useState(null);
  const [source, setSource] = useState(null);
  const [dateFrom, setDateFrom] = useState(dayjs().add(-1, "week").toDate());
  const [dateTo, setDateTo] = useState(dayjs().add(1, "day").toDate());
  const [eventProperties, setEventProperties] = useState(null);

  const resetData = () => {
    setWallet("");
    setLoginType(LoginType.BSC);
    setUserId("");
    setMongenId("");
    setPlotId("");
    setDaoTransactionId("");
    setEventLimit(10);
    setData([]);
    setPage(1);
    setFromAddress("");
    setTable(AnalyticsTable[0]);
    setTokenType(null);
    setSource(null);
    setDateFrom(dayjs().add(-1, "week").toDate());
    setDateTo(dayjs().add(1, "day").toDate());
    setIsReset(!isReset)
    setEventProperties(null)
  }

  const resetAnalytics = () => {
    setWallet("");
    setLoginType(LoginType.BSC);
    setUserId("");
    setEventLimit(10);
    setData([]);
    setPage(1);
    setFromAddress("");
    setTokenType(null);
    setSource(null);
    setDateFrom(dayjs().add(-1, "week").toDate());
    setDateTo(dayjs().add(1, "day").toDate());
    setEventProperties(null)
    setIsReset(!isReset)
  }

  useEffect(() => {
    resetAnalytics();
  }, [table])

  const loadData = async () => {
    try {
      setLoading(true);
      let data = await api.loadData({
        identity: wallet !== "" ? wallet : null,
        loginType: loginType,
        userId: userId !== "" ? userId : null,
        mongenId: mongenId !== "" ? mongenId : null,
        plotId: plotId !== "" ? plotId : null,
        transactionId: daoTransactionId !== "" ? daoTransactionId : null,
        queryType: queryMode,
        limit: eventLimit,
        offset: (page - 1) * eventLimit,
        from_address,
        table,
        filters: {
          user_id: userId ? userId : null,
          customer_id: userId ? userId : null,
          type: tokenType,
          source,
          event_id: eventProperties
        },
        dateFrom,
        dateTo,
      })
      console.log(data);
      setData(data);
    } catch (err: any) {
      console.log({ err })
      alert(err?.messagge?.toString() ?? err)
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <div className="text-2xl mb-4 text-center">Query tool</div>
      <div className="mt-4 flex">
        <div className='justify-center self-center border-2 py-2 px-2 bg-[#E8E8E8]'>Query mode</div>
        <Select
          className="form-control mr-3"
          onChange={(evt: any, data: any) => {
            setQueryMode(Number(data.value));
            resetData();
          }}
          options={Object.keys(QueryMode)
            .filter((key, i) => i >= Object.keys(QueryMode).length / 2)
            // @ts-ignore
            .map((key) => ({ value: QueryMode[key], text: key }))
          }
          defaultValue={QueryMode.UserInfo}
        ></Select>
        <Button color="green" onClick={async () => await loadData()} loading={loading}>
          Load data
        </Button>
      </div>
      <div className="mt-10"></div>
      {queryMode === QueryMode.ChainEventInfo &&
        <div className="flex gap-2 mt-4 mb-4">
          <Input
            label="From wallet address"
            className="form-control"
            type="text"
            value={from_address}
            onChange={(evt: any) => {
              console.log(evt.target.value);
              setFromAddress(evt.target.value);
            }}
          />
        </div>
      }
      {queryMode === QueryMode.Analytics &&
        <>
          <div className="flex gap-2 mt-4 mb-4">
            <div className='justify-center self-center border-2 py-2 px-2 bg-[#E8E8E8]'>Analytics table</div>
            <Select
              className="form-control"
              onChange={(evt: any, data: any) => {
                setTable(data.value);
              }}
              options={AnalyticsTable.map((key) => ({ value: key, text: key }))}
              defaultValue={AnalyticsTable[0]}
            ></Select>
          </div>
          <div>Filters: </div>
          {<div className="mb-2 flex gap-2">
            <Input
              label="User ID"
              className="form-control"
              value={userId}
              type="text"
              onChange={(evt: any) => {
                setUserId(evt.target.value);
              }}
            />
            {[AnalyticsTable[0], AnalyticsTable[1]].includes(table) &&  <div className="flex">
              <div className='justify-center self-center border-2 py-2 px-2 bg-[#E8E8E8]'>Token Type</div>
              <Select
                className="form-control"
                onChange={(evt: any, data: any) => {
                  setTokenType(data.value);
                }}
                options={Object.keys(TokenType)
                  // .filter((key, i) => i >= Object.keys(TokenType).length / 2)
                  // @ts-ignore
                  .map((key) => ({ value: TokenType[key], text: key }))
                }
              ></Select>
            </div>}
            {[AnalyticsTable[0], AnalyticsTable[1], AnalyticsTable[5]].includes(table) &&  <div className="flex">
              <div className='justify-center self-center border-2 py-2 px-2 bg-[#E8E8E8]'>Source</div>
              {([AnalyticsTable[0], AnalyticsTable[5]].includes(table)) && <Select
                className="form-control"
                onChange={(evt: any, data: any) => {
                  setSource(data.value);
                }}
                options={Object.keys(AnalyticsEventSrcEarnTokenOff)
                  // .filter((key, i) => i >= Object.keys(AnalyticsEventSrc).length / 2)
                  // @ts-ignore
                  .map((key) => ({ value: AnalyticsEventSrcEarnTokenOff[key], text: key }))
                }
              ></Select>}{
                table === AnalyticsTable[1] && <Select
                  className="form-control"
                  onChange={(evt: any, data: any) => {
                    setSource(data.value);
                  }}
                  options={Object.keys(AnalyticsEventSrcSpendTokenOff)
                    // .filter((key, i) => i >= Object.keys(AnalyticsEventSrc).length / 2)
                    // @ts-ignore
                    .map((key) => ({ value: AnalyticsEventSrcSpendTokenOff[key], text: key }))
                  }
                ></Select>
              }
            </div>}
            {[AnalyticsTable[2]].includes(table) && <div className="w-[200px]">
              <Entity
                  displayField={"id"}
                  value={eventProperties}
                  gridName={"event"}
                  multiple={false}
                  onChange={(val: any) => {
                    setEventProperties(val);
                  }}
                />
            </div>}
          </div>}
          <div className="flex mb-4">
            <div className="flex mr-4">
              <div className='justify-center self-center border-2 py-2 px-2 bg-[#E8E8E8]'>From</div>
              <DatePicker
                className="self-center justify-center py-2 border-2"
                todayButton="Today"
                selected={dateFrom}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateFrom(null);
                  } else {
                    setDateFrom(dayjs(val).toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd hh:mm"
              />
            </div>
            <div className="flex">
              <div className='justify-center self-center border-2 py-2 px-2 bg-[#E8E8E8]'>To</div>
              <DatePicker
                className="self-center justify-center py-2 border-2"
                todayButton="Today"
                selected={dateTo}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateTo(null);
                  } else {
                    setDateTo(dayjs(val).toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd hh:mm"
              />
            </div>
          </div>
        </>
      }
      {([QueryMode.UserInfo, QueryMode.BoxInfo, QueryMode.MailInfo, QueryMode.MintEventInfo, QueryMode.AuthInfo, QueryMode.StakingData, QueryMode.CustomerQuestInfo].includes(queryMode)) && <div className="flex gap-2 mt-4 mb-4">
        <Input
          label="Auth identity"
          className="form-control"
          type="text"
          value={wallet}
          onChange={(evt: any) => {
            setWallet(evt.target.value);
          }}
        />
        <div className="flex">
          <div className='justify-center self-center border-2 py-2 px-2 bg-[#E8E8E8]'>Login type</div>
          <Select
            className="form-control"
            onChange={(evt: any, data: any) => {
              setLoginType(Number(data.value));
            }}
            options={Object.keys(LoginType)
              .filter((key, i) => i >= Object.keys(LoginType).length / 2)
              // @ts-ignore
              .map((key) => ({ value: LoginType[key], text: key }))
            }
            defaultValue={LoginType.BSC}
          ></Select>
        </div>
        <div className='justify-center self-center py-2 px-2'>OR</div>
        <Input
          label="User ID"
          className="form-control"
          value={userId}
          type="text"
          onChange={(evt: any) => {
            setUserId(evt.target.value);
          }}
        />
      </div>
      }
      {
        queryMode === QueryMode.MongenInfo && <div>
          <Input
            label="Mongen ID"
            className="form-control mr-2"
            type="text"
            value={mongenId}
            onChange={(evt: any) => {
              setMongenId(evt.target.value);
            }}
          />
        </div>
      }
      {
        queryMode === QueryMode.PlotInfo && <div>
          <Input
            label="Plot ID"
            className="form-control mr-2"
            type="text"
            value={plotId}
            onChange={(evt: any) => {
              setPlotId(evt.target.value);
            }}
          />
        </div>
      }
      {
        queryMode === QueryMode.DAOTransactionInfo && <div>
          <Input
            label="Transaction ID"
            className="form-control mr-2"
            type="text"
            value={daoTransactionId}
            onChange={(evt: any) => {
              setDaoTransactionId(evt.target.value);
            }}
          />
        </div>
      }
      {
        [QueryMode.MintEventInfo, QueryMode.ChainEventInfo, QueryMode.BoxInfo, QueryMode.MailInfo, QueryMode.CustomerQuestInfo, QueryMode.Analytics].includes(queryMode) && <div>
          <Input
            label="Record limit"
            className="form-control mr-2"
            type="text"
            onChange={(evt: any) => {
              setEventLimit(evt.target.value);
            }}
            value={eventLimit}
          />
          <Input
            label="Page"
            className="form-control mr-2"
            type="text"
            onChange={(evt: any) => {
              setPage(evt.target.value);
            }}
            value={page}
          />
        </div>
      }

      <div className="w-full border-2 h-min-[200px] mt-4 overflow-scroll">
        {data ? isArray(data) ? <CustomTable data={data} isReset={isReset} /> : <CustomTable data={[data]} isReset={isReset} /> : "Null data"}
      </div>
    </div >
  )
}

const CustomTable = ({ data, isReset }: { data: any[], isReset: boolean }) => {
  const [hideColumn, setHideColumn] = useState([]);

  useEffect(() => {
    setHideColumn([])
  }, [isReset])

  if (data.length <= 0) {
    return <div>No data</div>
  }
  return (
    <>
      <div className="flex gap-2 mb-4">
        <div className='justify-center self-center border-2 py-2 px-2'>Show column</div>
        <Button color="yellow" size="small" onClick={() => {
          setHideColumn([])
        }}>Clear filter</Button>
      </div>

      <div className="grid grid-cols-6 gap-2">
        {Object.keys(data[0]).map((key) => {
          return (
            <Checkbox
              key={key}
              label={key}
              onChange={(evt: any, { checked }) => {
                let idx = hideColumn.indexOf(key);
                console.log({ idx, checked })
                if (idx <= -1 && checked) {
                  setHideColumn([...hideColumn, key]);
                }
                if (!checked && idx >= 0) {
                  let newData = hideColumn.filter((column) => column !== key);
                  setHideColumn(newData);
                }
              }}
              checked={hideColumn.includes(key)}
            />
          )
        })}
      </div>
      <Table celled sortable className="max-w-[200px] overflow-scroll">
        <Table.Header>
          <Table.Row>
            {hideColumn.map((key) => {
              return (
                <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
              )
            })}
          </Table.Row>
        </Table.Header >
        <Table.Body>
          {data.map((dataValue) => {
            return (
              <Table.Row>
                {hideColumn.map((key) => {
                  return (
                    <Table.Cell>
                      {JSON.stringify(dataValue[key])}
                    </Table.Cell>
                  )
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )
}