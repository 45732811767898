import { useState } from "react";
import { Button, Card, Form, Input } from "semantic-ui-react";
import { api, ui } from "services";
import ReactJson from "react-json-view";

export default function TournamentSimulator() {
  const [numberCustomer, setNumberCustomer] = useState(0);
  const [responseSimulator, setResponseSimulator] = useState<any>({});

  async function update() {
    let rs = await api.post("/cheat/cheat-tournament-simulator", {
      number_customer: numberCustomer,
    });
    setResponseSimulator(rs);
    ui.alert("Success");
  }
  return (
    <div>
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <div className="flex justify-between">
              <div>
                <Button
                  icon="save"
                  content={"Start"}
                  primary
                  onClick={() => {
                    update();
                  }}
                  labelPosition="left"
                />
              </div>
            </div>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Form className="mt-4">
            <Form.Field>
              <label>Value</label>
              <div>
                <Input
                  type="text"
                  value={numberCustomer}
                  onChange={(evt: any) => {
                    setNumberCustomer(evt.target.value);
                  }}
                />
              </div>
            </Form.Field>
          </Form>
          <div className="w-full flex gap-2">
            <div className="w-1/2">
              <ReactJson
                src={responseSimulator?.res}
                displayDataTypes={false}
                displayObjectSize={false}
                enableClipboard={false}
                name="members"
              />
            </div>
            <div className="w-1/2">
              <ReactJson
                src={responseSimulator?.matchs}
                displayDataTypes={false}
                displayObjectSize={false}
                enableClipboard={false}
                name="matchs"
              />
            </div>
          </div>
        </Card.Content>
      </Card>
    </div>
  );
}
