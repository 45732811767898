/** @format */

import axios from "axios";
import Config from "config";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { Rarity } from "interfaces";
var saveData = (function () {
  var a: any = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  return function (data: Blob, fileName: any) {
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();
async function downloadReport(data: any) {
  // let data = {
  //   labels: [
  //     "2022-07-26",
  //     "2022-07-27",
  //     "2022-07-28",
  //     "2022-07-29",
  //     "2022-07-30",
  //     "2022-07-31",
  //     "2022-08-01",
  //   ],
  //   datasets: [
  //     {
  //       label: "total-spend",
  //       data: [115.5, 99, 119, 102, 67.077, 102, 112],
  //       borderColor: "#f3a935",
  //       backgroundColor: "#f3a935",
  //     },
  //     {
  //       label: "total-earn",
  //       data: [
  //         2905.041425562854, 101901.57737209015, 1372.6302349966827,
  //         1265.7621200265087, 1325.4579311459129, 1158.589779916097,
  //         898.9731311401043,
  //       ],
  //       borderColor: "#c73558",
  //       backgroundColor: "#c73558",
  //     },
  //   ],
  // };
  let csv = [["Date"]];
  data.datasets.forEach((s: any) => {
    csv[0].push(s.label);
  });
  for (var i = 0; i < data.labels.length; i++) {
    csv[i + 1] = [data.labels[i]];
    data.datasets.forEach((s: any) => {
      csv[i + 1].push(s.data[i]);
    });
  }
  let text = csv.map((s: string[]) => s.join(",")).join("\n");
  var blob = new Blob([text], {
    type: "text/plain;charset=utf-8;",
  });
  saveAs(blob, "report.csv");
}
async function uploadGameBuild(signedUrl: string, data: any): Promise<any> {
  // let formData = new FormData();
  // formData.append("file ", data);
  let rs = await fetch(signedUrl, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/octet-stream",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: data,
  });
  return rs;
}
async function post(url: string, data: any): Promise<any> {
  let rs = await fetch(`${Config.ApiHost}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });

  switch (rs.status) {
    case 200:
      if (data.export || url.includes("operation/export-winner")) {
        console.log({ url });
        let data = await rs.blob();
        let now = dayjs().format("DD_MM_YYYY");
        saveData(data, `${now}.xlsx`);
        return rs;
      }
      let tmp = await rs.json();
      return tmp;
    default:
      let err = await rs.json();
      throw err;
  }
}
async function auctionPost(url: string, data: any): Promise<any> {
  let rs = await fetch(`${Config.AuctionHost}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });
  switch (rs.status) {
    case 201:
      let tmp = rs;
      return tmp;
    case 403:
      window.location.href = "#/login";
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}
async function postFormData(
  url: string,
  data: any,
  cb?: Function
): Promise<any> {
  var formdata = new FormData();
  for (var i in data) {
    formdata.append(i, data[i]);
  }
  let tmp: any = await axios.request({
    method: "post",
    url: `${Config.ApiHost}${url}`,
    data: formdata,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "multipart/form-data",
    },
    onUploadProgress: (p) => {
      if (cb) cb(p);
      //this.setState({
      //fileprogress: p.loaded / p.total
      //})
    },
  });
  console.log("handle fe finish");
  if (tmp.code === "forbidden") {
    window.location.href = "/auth";
    throw new Error("forbidden");
  }
  return tmp.data;
}
async function postFormDataRaw(
  url: string,
  formdata: any,
  cb?: Function
): Promise<any> {
  console.log("handle");
  let tmp: any = await axios.request({
    method: "post",
    url: `${Config.ApiHost}${url}`,
    data: formdata,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "multipart/form-data",
    },
    timeout: 300 * 1000,
    timeoutErrorMessage: "Timeout on FrontEnd",
    maxContentLength: 10 * 1024 * 1024,
    onUploadProgress: (p) => {
      if (cb) cb(p);
      //this.setState({
      //fileprogress: p.loaded / p.total
      //})
    },
  });
  if (tmp.code === "forbidden") {
    window.location.href = "/auth";
    throw new Error("forbidden");
  }
  return tmp.data;
}
async function uploadImage(image: any): Promise<any> {
  return postFormData(`${Config.ApiHost}/file/upload-image`, {
    image,
  });
}
async function battlePost(url: any, data?: {}) {
  if (!data) {
    data = {};
  }
  let response = await fetch(`${Config.ApiHost}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });
  let rs = await response.json();
  if (response.status !== 200) {
    throw rs;
  }
  return rs;
}

async function bridgeGet(url: string, data: any): Promise<any> {
  let rs = await fetch(
    `${Config.BridgeHost}${url}?limit=${data.limit}&offset=${data.offset}&isApproved=${data.isApproved}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    }
  );

  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp.data;
    default:
      let err = await rs.json();
      throw err;
  }
}

async function contractBridgeGet(): Promise<any> {
  let rs = await fetch(`${Config.BridgeHost}/api/contract-address`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
  });

  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp.data;
    default:
      let err = await rs.json();
      throw err;
  }
}

async function tokenBridgeGet(): Promise<any> {
  let rs = await fetch(`${Config.BridgeHost}/api/currency`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
  });

  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp.data;
    default:
      let err = await rs.json();
      throw err;
  }
}

async function approveBridgePut(data: {}) {
  if (!data) {
    data = {};
  }
  let response = await fetch(`${Config.BridgeHost}/api/transaction/approve`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  let rs = await response.json();
  if (response.status !== 200) {
    throw rs;
  }
  return rs;
}

async function auctionGet(url: string, queryOption?: any): Promise<any> {
  let newUrl = queryOption
    ? `${url}?limit=${queryOption?.limit}&offset=${queryOption?.offset}`
    : url;
  newUrl = queryOption?.id ? `${url}?id=${queryOption?.id}` : newUrl;
  newUrl = queryOption?.token
    ? `${newUrl}&token=${queryOption?.token}`
    : newUrl;
  let rs = await fetch(`${Config.AuctionHost}${newUrl}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (queryOption?.token) {
    let data = await rs.blob();
    let now = dayjs().format("DD_MM_YYYY");
    saveData(data, `${now}.csv`);
    return rs;
  }

  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp;
    default:
      let err = await rs.json();
      throw err;
  }
}

async function hackCreatePlot(
  wallet_address: any,
  plot_race: any,
  plot_rarity: any,
  plot_type: any,
  amount: any,
  sub_plot_type: any,
  customer_id: any
) {
  return post("/cheat/create-plot", {
    wallet_address,
    plot_race,
    plot_rarity,
    plot_type,
    amount,
    sub_plot_type,
    customer_id: Number(customer_id),
  });
}
async function hackCreateEgg(
  wallet_address: any,
  mongen_race: any,
  mongen_rarity: any,
  amount: any,
  mark: any,
  customer_id: any
) {
  return post("/cheat/create-mongen", {
    wallet_address,
    mongen_race,
    mongen_rarity,
    amount,
    mongen_mark: mark,
    customer_id: Number(customer_id),
  });
}
async function hackSkiptime(
  address: any,
  plot_id: any,
  time: any,
  customer_id: any
) {
  return post("/cheat/skip-time", {
    address,
    plot_id,
    time,
    customer_id: Number(customer_id),
  });
}
async function hackDeleteCustomer(wallet_address: any, customer_id: any) {
  return post("/cheat/delete-customer", {
    address: wallet_address,
    customer_id: Number(customer_id),
  });
}
async function hackCustomerTransaction(
  wallet_address: any,
  amount: any,
  type: any,
  customer_id: any
) {
  return post("/cheat/customer-transaction", {
    address: wallet_address,
    amount,
    type,
    customer_id: Number(customer_id),
  });
}
async function hackCreateTroops(troop_id: any, mongen_id: any, quantity: any) {
  return post("/cheat/create-troops", { troop_id, mongen_id, quantity });
}
async function hackTutorialProcess(
  wallet_address: any,
  process: any,
  customer_id: any
) {
  return post("/cheat/change-tutorial-process", {
    wallet_address,
    process,
    customer_id: Number(customer_id),
  });
}
async function deleteName(wallet_address: any, customer_id: any) {
  return post("/cheat/delete-customer-name", {
    wallet_address,
    customer_id: Number(customer_id),
  });
}
async function outBattle(data: any) {
  return post("/cheat/out-battle", data);
}
async function recalculateStake() {
  return post("/cheat/recalculate-stake", {});
}
async function convertMongenToEgg(mongen_id: any) {
  return post("/cheat/convert-mongen-to-egg", { mongen_id });
}
async function cheatClaimLevelReward(
  wallet_address: any,
  level_reward: any,
  customer_id: any
) {
  return post("/cheat/claim-level-reward", {
    wallet_address,
    level_reward,
    customer_id: Number(customer_id),
  });
}
async function cheatClaimBattlefrontSeasonReward() {
  return post("/cheat/cheat-claim-battlefront-season", {});
}
async function cheatClaimArenaSeasonReward() {
  return post("/cheat/cheat-end-arena-season", {});
}
async function cheatSetQuestTime(
  wallet_address: any,
  time: any,
  customer_id: any
) {
  return post("/cheat/set-quest-time", {
    wallet_address,
    time,
    customer_id: Number(customer_id),
  });
}
async function cheatResetSharing(wallet_address: any, customer_id: any) {
  return post("/cheat/reset-sharing", {
    wallet_address,
    customer_id: Number(customer_id),
  });
}
async function cheatSetFaucetTime(time: any) {
  return post("/cheat/set-faucet-time", { time });
}
async function addWhitelist(type: any, identity: any) {
  return post("/cheat/add-whitelist", { type, identity });
}
async function getPool() {
  return post("/cheat/get-box-pool", {});
}
async function cheatCreateBox(
  wallet_address: any,
  pool_id: any,
  customer_id: any
) {
  return post("/cheat/create-box", {
    wallet_address,
    pool_id,
    customer_id: Number(customer_id),
  });
}
async function cheatAdventureStar(
  wallet_address: any,
  value: any,
  customer_id: any
) {
  return post("/cheat/cheat-adventure_star", {
    wallet_address,
    value,
    customer_id: Number(customer_id),
  });
}
async function cheatAdventureStarReward(
  wallet_address: any,
  value: any,
  customer_id: any
) {
  return post("/cheat/cheat-adventure_star_reward", {
    wallet_address,
    value,
    customer_id: Number(customer_id),
  });
}
async function getMongenInfo(mongen_id: any) {
  return post("/cheat/get-mongen-info", { mongen_id });
}
async function updateTroopMongen(mongen_id: any, troops: any) {
  return post("/cheat/update-troop-mongen", { mongen_id, troops });
}
async function updateSkillMongen(mongen_id: any, skill_list: any, type: any) {
  return post("/cheat/update-skill-mongens", { mongen_id, skill_list, type });
}
async function updateMoraleValue(mongen_id: any, type: any, amount: any) {
  return post("/cheat/cheat-mongen-morale", { mongen_id, amount, type });
}
async function hackgenerateInvest() {
  return post("/cheat/generate-invest", {});
}
async function hackClaimScholarReward() {
  return post("/cheat/handle-claim-scholar-reward", {});
}

async function randomMorale(mongen_id: any) {
  return post("/cheat/random-mongen-morale", { mongen_id });
}

async function hackUpdateCurse({ curseCode, curseLevel, wallet_address }: any) {
  return post("/cheat/cheat-curse-data", {
    curse_code: curseCode,
    curse_level: curseLevel,
    wallet_address,
  });
}
async function hackOpenPlusQuest(wallet_address: string, customer_id: any) {
  return post("/cheat/cheat-open-plus-quest", {
    wallet_address,
    customer_id: Number(customer_id),
  });
}

async function loadData({
  identity,
  loginType,
  userId,
  mongenId,
  plotId,
  transactionId,
  queryType,
  limit,
  offset,
  table,
  dateFrom,
  dateTo,
  filters,
  from_address,
}: any) {
  return post("/operation/query-data", {
    identity,
    loginType,
    userId,
    mongenId,
    plotId,
    transactionId,
    queryType,
    limit,
    offset,
    table,
    dateFrom,
    dateTo,
    filters,
    from_address,
  });
}

async function hackDateBattlefront({ customer_id, date }: any) {
  return battlePost("/cheat/cheat-day-battlefront", { customer_id, date });
}

async function hackPlusPoint(
  wallet_address: string,
  amount: any,
  customer_id: any
) {
  return battlePost("/cheat/cheat-dailyquest-plus-point", {
    wallet_address,
    amount,
    customer_id: Number(customer_id),
  });
}
async function hackAdventureStage(
  wallet_address: string,
  stage_id: any,
  customer_id: any
) {
  return battlePost("/cheat/cheat-stage-adventure", {
    wallet_address,
    stage_id,
    customer_id: Number(customer_id),
  });
}
async function skipFusionWait(wallet_address: string, customer_id: any) {
  return post("/cheat/skip-fusion-waittime", {
    wallet_address,
    customer_id: Number(customer_id),
  });
}
async function resetFusionLimit() {
  return post("/cheat/reset-current-fusion-limit", {});
}

async function getConfigAuction() {
  return auctionGet("/p2p-auction/get-config");
}

async function hackDaoRevenue(data: any) {
  return post("/cheat/update-dao-revenue", data);
}

async function hackGetDaoRevenue() {
  return post("/cheat/cheat-get-dao-revenue", {});
}

async function hackCalculateStakeDao() {
  return post("/cheat/cheat-calculate-stake", {});
}

async function hackRune(
  wallet_address: string,
  rune_code: string,
  rarity: Rarity,
  amount: number,
  customer_id: any
) {
  return post("/cheat/cheat-rune", {
    wallet_address,
    rune_code,
    rarity,
    amount,
    customer_id: Number(customer_id),
  });
}

async function hackCreateMongenByDna(
  customer_id: string,
  dna: string,
  troop_list: string,
  amount: number
) {
  return post("/cheat/create-mongen-by-dna", {
    customer_id,
    troop_list,
    dna,
    amount,
  });
}

async function hackMatchBattlefront(
  customer_id1: string,
  customer_id2: string
) {
  return post("/cheat/cheat-match-battlefront", {
    customer_id1,
    customer_id2,
  });
}

const api = {
  cheatClaimArenaSeasonReward,
  hackMatchBattlefront,
  hackCreateMongenByDna,
  hackRune,
  hackGetDaoRevenue,
  hackCalculateStakeDao,
  hackDaoRevenue,
  skipFusionWait,
  resetFusionLimit,
  cheatResetSharing,
  hackOpenPlusQuest,
  hackAdventureStage,
  hackPlusPoint,
  loadData,
  hackUpdateCurse,
  randomMorale,
  updateMoraleValue,
  postFormDataRaw,
  downloadReport,
  post,
  postFormData,
  uploadImage,
  cheatAdventureStarReward,
  cheatAdventureStar,
  cheatCreateBox,
  addWhitelist,
  getPool,
  cheatSetFaucetTime,
  cheatSetQuestTime,
  cheatClaimLevelReward,
  convertMongenToEgg,
  battlePost,
  recalculateStake,
  hackCreatePlot,
  hackCreateEgg,
  hackSkiptime,
  hackDeleteCustomer,
  hackCustomerTransaction,
  hackCreateTroops,
  hackTutorialProcess,
  deleteName,
  getMongenInfo,
  updateTroopMongen,
  updateSkillMongen,
  hackgenerateInvest,
  outBattle,
  auctionPost,
  hackClaimScholarReward,
  hackDateBattlefront,
  cheatClaimBattlefrontSeasonReward,
  bridgeGet,
  contractBridgeGet,
  tokenBridgeGet,
  approveBridgePut,
  getConfigAuction,
  auctionGet,
  uploadGameBuild,
};
export default api;
