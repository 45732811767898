import { SupportTicketComment } from "interfaces";
import { useEffect, useRef, useState } from "react";
import Lightbox from "react-image-lightbox";
import { api, ui } from "services";
import Avatar from "./avatar";
export default function Comment({
  id,
  comments,
  setData,
}: {
  id: number,
  comments: SupportTicketComment[];
  setData: any;
}) {
  const ref = useRef();
  const [viewImage, setViewImage] = useState<string>("");
  async function removeMessage(idx: number) {
    let rs = await api.post("/support-ticket/admin-remove-message", {
      id,
      idx
    });
    setData(rs);
  }

  useEffect(() => {
    if (!ref) return;
    // @ts-ignore
    ref.current.scrollTo(0, 99999999);
    // @ts-ignore
    // ref.scrollToBottom();
  }, [comments, ref]);
  return (
    <div
      style={{ maxHeight: "calc(100vh - 200px)" }}
      className="overflow-auto border border-gray-200 rounded-md p-4"
      ref={ref}
    >
      {viewImage !== "" && (
        <Lightbox
          mainSrc={viewImage}
          onCloseRequest={() => {
            setViewImage("");
          }}
        />
      )}
      {comments.map((cmt: SupportTicketComment, index: number) => {
        if (cmt.is_admin) {
          return (
            <div className="flex gap-2 items-end mt-2 justify-start">
              {/* <Avatar name={cmt.name} isAdmin={false} /> */}
              <img src="img/admin.webp" className="w-10 h-10 object-cover" alt="admin" />
              <div className="bg-[#F4F6F8] rounded-md overflow-hidden px-4 py-2 max-w-[80%]">
                <pre>{cmt.content}</pre>
                {cmt.attachs?.length > 0 && (
                  <div className="flex gap-2 mt-2">
                    {cmt.attachs.map((url: string, index: number) => {
                      return (
                        <div
                          onClick={() => {
                            setViewImage(url);
                          }}
                          className="w-36 h-36 rounded-md border border-gray-300 p-2 flex items-center justify-center cursor-pointer"
                        >
                          <img
                            src={url}
                            className="h-full w-full rounded-md object-cover"
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {<div className="flex self-center">
                <img src="/assets/images/delete.png" alt="delete" className="w-6 h-6 cursor-pointer"
                  onClick={async () => {
                    await ui.confirm("Are you sure want to delete this message ?");
                    await removeMessage(index);
                  }}
                />
              </div>}
            </div>
          );
        } else {
          return (
            <div className="flex gap-2 items-end mt-2  justify-end">
              <div className="bg-[#E7E8FF] rounded-md overflow-hidden px-4 py-2 max-w-[80%]">
                <pre>{cmt.content}</pre>
                {cmt.attachs?.length > 0 && (
                  <div className="flex gap-2 mt-2">
                    {cmt.attachs.map((url: string, index: number) => {
                      return (
                        <div
                          onClick={() => {
                            setViewImage(url);
                          }}
                          className="w-36 h-36 rounded-md border border-gray-300 p-2 flex items-center justify-center cursor-pointer"
                        >
                          <img
                            src={url}
                            className="h-full w-full rounded-md object-cover"
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <Avatar name={cmt.name} isAdmin={false} />
            </div>
          );
        }
      })}
    </div>
  );
}
