import { Select } from "semantic-ui-react";
interface EnumProps {
    value: string | number | string[] | number[];
    enumName: string;
    multiple?: boolean;
    dataType?: "string" | "number" | "boolean" | "array" | "object";
    onChange?: (val: string | number | string[] | number[], raw?: any) => void;
    placeholder?: string;
    disabledValue?: any[];
}
function Enum({
    value,
    enumName,
    multiple,
    onChange,
    placeholder,
    disabledValue = [],
}: EnumProps): React.ReactElement {

    let iconList = Array.from({ length: 72 }, (_, i) => 0 + i * 1); // 0 - 71

    return (
        <div>
            <Select
                placeholder={placeholder}
                fluid
                multiple={multiple}
                value={value === undefined ? (multiple ? [] : null) : value}
                options={iconList
                    .filter((i) => !disabledValue.includes(i))
                    .map((i) => {
                        return {
                            text: (<div><span>Icon {i} </span><img src={`/assets/pool_icon/${i}.png`} alt="pool" className="w-10 object-contain" /></div>), value: i
                        };
                    })}
                onChange={(evt: any, val: any) => {
                    let raw = iconList.find((e: number) => e == val.value);
                    onChange(val.value, raw);
                }}
            />
        </div>
    );
}
export default Enum;
