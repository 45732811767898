import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
import { api } from "services";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Pagination,
  Table,
} from "semantic-ui-react";
import saveAs from "file-saver";
import { Entity } from "components";
import _ from "lodash";
import ReactJson from "react-json-view";
const pageSize = 10;
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);
export default function WorldcupMatchPredictTable() {
  const [loading, setLoading] = useState<boolean>(false);
  const [eventId, setEventId] = useState<number>(null);
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<any>(null);
  const [detail, setDetail] = useState(null);
  async function loadData(page: number) {
    setLoading(true);
    try {
      let rs = await api.post("/operation/get-match-predict-worldcup-report", {
        event_id: eventId,
        page: page ?? 1,
      });
      setData(rs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const download = async () => {
    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
    setLoading(true);
    let result: any = [[]];
    let i = 1;
    while (true) {
     let rs = await api.post("/operation/get-match-predict-worldcup-report", {
      event_id: eventId,
      page: i,
      limit: 300
    });
     result = result.concat(rs?.table?.data ?? []);
     if(i == 1) {
      result = result.concat(rs?.table?.table ?? []);
     }
     i++;
     if (i > Math.ceil(rs?.total / 300)) break;
     await sleep(300);
    }
    let text = result.map((s: string[]) => s.join(",")).join("\n");
    var blob = new Blob([text], {
     type: "text/plain;charset=utf-8;",
    });
    saveAs(blob, "report.csv");
    setLoading(false);
   };

  return (
    <div>
      <Card fluid>
        <Card.Content>
          <Form>
            <div className="flex w-full gap-2">
              <Form.Field className="w-full">
                <label>Event ID</label>
                <Entity
                  displayField={"name"}
                  value={eventId}
                  gridName={"match-predict-event"}
                  multiple={false}
                  onChange={(val: any) => {
                    setEventId(val);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Action</label>
                <Button
                  color="blue"
                  icon="search"
                  loading={loading}
                  content="Search"
                  labelPosition="left"
                  onClick={() => loadData(1)}
                />
              </Form.Field>
              <Form.Field>
                <label>Download</label>
                <Button
                  color="blue"
                  loading={loading}
                  icon="search"
                  content="Download"
                  labelPosition="left"
                  onClick={download}
                />
              </Form.Field>
            </div>
          </Form>
        </Card.Content>
      </Card>
      {data !== null && (
        <Card fluid>
          <Card.Content>
            <Table>
              <Table.Header>
                {data.table.labels.map((labelTable: any) => {
                  return <Table.HeaderCell>{labelTable}</Table.HeaderCell>;
                })}
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {data.table.data.map((dataset: any) => {
                  return (
                    <Table.Row>
                      {dataset.map((data: any) => (
                        <Table.Cell>{data}</Table.Cell>
                      ))}
                      <Table.Cell>
                        <Button
                          icon="eye"
                          color="blue"
                          onClick={() => {
                            setDetail(data.dataDetails[dataset[0]]);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            <Pagination
              activePage={page}
              onPageChange={(page: any, rs: any) => {
                console.log({ page });
                setPage(rs.activePage);
                loadData(rs.activePage);
              }}
              size="mini"
              totalPages={data?.total ? Math.ceil(data?.total / pageSize) : 0}
            />
          </Card.Content>
        </Card>
      )}
      <Modal
        open={detail !== null}
        closeIcon
        onClose={() => {
          setDetail(null);
        }}
      >
        <Modal.Content>
          <div className="w-full flex gap-2">
            <ReactJson
              src={detail}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              name="input"
            />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
}
