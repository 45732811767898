import BasicChart from "./basic-chart";

export default function ArpuChart(props: any) {
  return (
    <BasicChart
      {...props}
      url="/operation/get-arpu-report"
      chartType="line"
      source="analytic"
    />
  );
}
