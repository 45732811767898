import { DatePicker } from "components";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Select,
  Table,
} from "semantic-ui-react";
import { api } from "services";

export default function ReportMongenWheelTransaction() {
  const [dateFrom, setDateFrom] = useState(
    dayjs("02/02/2022").startOf("day").toDate()
  );
  const [dateTo, setDateTo] = useState(dayjs().add(1, "day").toDate());
  const [data, setData] = useState<any>([])
  const [header, setHeaders] = useState<any>([])
  const [type, setType] = useState('time')

  async function loadData() {
    let rs = await api.post("/operation/get-wheel-transaction-analytics", {
      dateFrom: dateFrom,
      dateTo: dateTo,
      type,
    });
    if (!rs?.length) return;
    let first = rs[0];
    let headers = Object.keys(first).map((i) => i);
    setHeaders(headers)
    setData(rs)
  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <div>
      <Card fluid>
        <Card.Content>
          <Form className="flex gap-2 w-full">
            <Form.Field className="w-full">
              <label>From</label>
              <DatePicker
                todayButton="Today"
                selected={dateFrom}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateFrom(null);
                  } else {
                    setDateFrom(dayjs(val).startOf("day").toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>To</label>
              <DatePicker
                todayButton="Today"
                selected={dateTo}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateTo(null);
                  } else {
                    setDateTo(dayjs(val).endOf("day").toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>Type</label>
              <Select
                placeholder={"Select type"}
                fluid
                value={type}
                options={[
                  { label: "Top 100 play", value: "time" },
                  { label: "Top 100 Win", value: "earn" },
                  { label: "Top 100 Spent", value: "spent" },
                  { label: "Top 100 Profit", value: "profit" },
                ].map((i) => {
                  return { text: i.label, value: i.value };
                })}
                onChange={(evt: any, val: any) => {
                  setType(val.value);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Action</label>
              <Button
                color="blue"
                icon="search"
                content="Search"
                labelPosition="left"
                onClick={() => loadData()}
              />
            </Form.Field>
          </Form>
        </Card.Content>

        <Card.Content>
          <Table celled sortable>
            <Table.Header>
              {header.map((labelTable: any) => {
                return <Table.HeaderCell>{labelTable}</Table.HeaderCell>;
              })}
            </Table.Header>
            <Table.Body>
              {data.map((i: any) => {
                return (
                  <Table.Row>
                    {header.map((key: string) => {
                      return (
                        <Table.Cell>{i[key]}</Table.Cell>
                      )
                    })}
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    </div>
  );
}
