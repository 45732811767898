import { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { api } from "services";

export default function Staking() {
  const [data, setData] = useState(null);
  const arr = Array.from(Array(10).keys());
  useEffect(() => {
    async function load() {
      let rs = await api.post("/operation/get-staking-report", {});
      setData(rs);
    }
    load();
  }, []);
  return (
    <div>
      <p className="text-3xl font-semibold">Staking Report</p>
      {data === null ? (
        <p>Loading...</p>
      ) : (
        <div>
          <Table celled structured>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell colSpan="4">6 months</Table.HeaderCell>
                <Table.HeaderCell colSpan="4">9 months</Table.HeaderCell>
                <Table.HeaderCell colSpan="4">12 months</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Total</Table.Cell>
                <Table.Cell colSpan="4">
                  {Math.round(data.totalStaking[0]["sum(amount)"])}
                </Table.Cell>
                <Table.Cell colSpan="4">
                  {Math.round(data.totalStaking[1]["sum(amount)"])}
                </Table.Cell>
                <Table.Cell colSpan="4">
                  {Math.round(data.totalStaking[2]["sum(amount)"])}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>TOP USERS</Table.Cell>
                <Table.Cell>ID</Table.Cell>
                <Table.Cell>NAME</Table.Cell>
                <Table.Cell>AMOUNT</Table.Cell>
                <Table.Cell>DATE</Table.Cell>
                <Table.Cell>ID</Table.Cell>
                <Table.Cell>NAME</Table.Cell>
                <Table.Cell>AMOUNT</Table.Cell>
                <Table.Cell>DATE</Table.Cell>
                <Table.Cell>ID</Table.Cell>
                <Table.Cell>NAME</Table.Cell>
                <Table.Cell>AMOUNT</Table.Cell>
                <Table.Cell>DATE</Table.Cell>
              </Table.Row>
              {Array.from(Array(50).keys()).map((i: number) => (
                <Table.Row>
                  <Table.Cell>TOP {i + 1}</Table.Cell>
                  <Table.Cell>{data.staking0[i]?.customer_id}</Table.Cell>
                  <Table.Cell>{data.staking0[i]?.name}</Table.Cell>
                  <Table.Cell>{data.staking0[i]?.amount}</Table.Cell>
                  <Table.Cell>{data.staking0[i]?.created_at}</Table.Cell>

                  <Table.Cell>{data.staking1[i]?.customer_id}</Table.Cell>
                  <Table.Cell>{data.staking1[i]?.name}</Table.Cell>
                  <Table.Cell>{data.staking1[i]?.amount}</Table.Cell>
                  <Table.Cell>{data.staking1[i]?.created_at}</Table.Cell>

                  <Table.Cell>{data.staking2[i]?.customer_id}</Table.Cell>
                  <Table.Cell>{data.staking2[i]?.name}</Table.Cell>
                  <Table.Cell>{data.staking2[i]?.amount}</Table.Cell>
                  <Table.Cell>{data.staking2[i]?.created_at}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  );
}
