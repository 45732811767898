import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
//@ts-ignore
import { api } from "services";
import { Button, Card, Form } from "semantic-ui-react";
import { Entity } from "components";
import _ from "lodash";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);
export default function ReportChampionWorldcupChart() {
  const [loading, setLoading] = useState<boolean>(false);
  const [earn, setEarn] = useState<{ options: any; data: any }>(null);
  const [eventId, setEventId] = useState<number>(null);
  async function loadData() {
    console.log({ eventId });
    setLoading(true);
    try {
      let rs = await api.post("/operation/get-champion-worldcup-report", {
        event_id: Number(eventId),
      });
      setEarn(rs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  return (
    <div>
      <Card fluid>
        {earn !== null && (
          <Card.Content>
            <Bar options={earn.options} data={earn.data} />
          </Card.Content>
        )}
        <Card.Content>
          <Form className="flex gap-2 w-full">
            <Form.Field className="w-60">
              <label>Event</label>
              <Entity
                displayField={"name"}
                value={eventId}
                gridName={"world-cup-event"}
                multiple={false}
                onChange={(val: any) => {
                  setEventId(val);
                }}
              />
            </Form.Field>
            <div>
              <Form.Field>
                <label>Action</label>
                <Button
                  loading={loading}
                  color="blue"
                  icon="search"
                  content="Search"
                  labelPosition="left"
                  onClick={loadData}
                />
              </Form.Field>
            </div>
          </Form>
        </Card.Content>
      </Card>
    </div>
  );
}
