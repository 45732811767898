import _ from "lodash";
import { useState } from "react";
import ReactJson from "react-json-view";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Pagination,
  Table,
} from "semantic-ui-react";
import { api, ui } from "services";

export default function IAPTransaction() {
  const pageSize = 10;
  const defaultPage = 1;
  const [transactions, setTransactions] = useState(null);
  const [id, setId] = useState("");
  const [detail, setDetail] = useState(null);
  const [page, setPage] = useState<any>(defaultPage);
  async function loadIapTransaction(page: any) {
    let rs = await api.post("/operation/get-iap-transaction-analytics", {
      id,
      page,
    });
    if (!rs) return;
    setTransactions(rs);
  }

  return (
    <div>
      <Card fluid>
        <Card.Content>
          <div className="flex justify-between">
            <p>
              IAP Transaction <b>#{id}</b>
            </p>
          </div>
        </Card.Content>
        <Card.Content>
          <Form>
            <div className="flex w-full gap-2">
              <Form.Field className="w-full">
                <label>Customer ID</label>
                <Input
                  value={id}
                  type="text"
                  fluid
                  onChange={(evt: any) => {
                    setId(evt.target.value);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Action</label>
                <Button
                  color="blue"
                  icon="search"
                  content="Search"
                  labelPosition="left"
                  onClick={() => loadIapTransaction(defaultPage)}
                />
              </Form.Field>
            </div>
          </Form>
        </Card.Content>
      </Card>

      <Card fluid>
        <Card.Content>Customer IAP Transaction</Card.Content>
        <Card.Content>
          <Table>
            <Table.Header>
              {transactions?.table?.labels.map((labelTable: any) => {
                return <Table.HeaderCell>{labelTable}</Table.HeaderCell>;
              })}
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {transactions?.table?.data.map((dataset: any) => {
                return (
                  <Table.Row>
                    {dataset.map((data: any) => (
                      <Table.Cell>{data}</Table.Cell>
                    ))}
                    <Table.Cell>
                      <Button
                        icon="eye"
                        color="blue"
                        onClick={() => {
                          setDetail(
                            transactions?.iapTransactionDetailData[dataset[0]]
                          );
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <Pagination
            activePage={page}
            onPageChange={(page: any, rs: any) => {
              setPage(rs.activePage);
              loadIapTransaction(rs.activePage);
            }}
            size="mini"
            totalPages={
              transactions?.total
                ? Math.ceil(transactions?.total / pageSize)
                : 0
            }
          />
        </Card.Content>
      </Card>
      <Modal
        open={detail !== null}
        closeIcon
        onClose={() => {
          setDetail(null);
        }}
      >
        <Modal.Content>
          <div className="w-full flex gap-2">
            <ReactJson
              src={detail}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              name="input"
            />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
}
