import {
  SupportTicketStatus,
  SupportTicketSubject,
  YesNoEnum,
  AllColors,
} from "interfaces";
import UploadImage from "./upload-image";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Icon,
  Label,
  Modal,
  Select,
  TextArea,
} from "semantic-ui-react";
import { api } from "services";
import Comment from "./comment";
import { GridView } from "components";
export default function TicketDetail({ id }: { id: number }) {
  const StatusColors = ["yellow", "green", "red"];
  const { search } = useLocation();
  // const query = new URLSearchParams(search);
  // const id = Number(query.get("id"));
  const [showAssign, setShowAssign] = useState<boolean>(false);
  const nav = useNavigate();
  useEffect(() => {
    async function loadTicket() {
      let rs = await api.post("/operation/api/get-support-ticket", {
        where: { id },
      });
      setData(rs?.data[0]);
    }
    loadTicket();
  }, [id]);
  const [data, setData] = useState<any>({ id });
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<any>({});
  let subjectItems = useMemo(() => {
    let rs: any = [];
    for (var i in SupportTicketSubject) {
      if (!isNaN(Number(i))) {
        rs.push({ key: i, value: Number(i), text: SupportTicketSubject[i] });
      }
    }
    return rs;
  }, []);
  function handleChange(name: string, value: any) {
    let tmp = _.cloneDeep(comment);
    // @ts-ignore
    tmp[name] = value;
    setComment(tmp);
  }
  function onRemoveImage(index: number) {
    let tmp = _.cloneDeep(comment?.attachs || []);
    tmp.splice(index, 1);
    handleChange("attachs", tmp);
  }
  async function submitTicket() {
    try {
      setLoading(true);
      if (!comment.content) {
        setError("Please enter ticket content");
        return;
      }
      setError("");
      comment.id = id;
      let rs = await api.post("/support-ticket/admin-comment-ticket", comment);
      setComment({});
      setData(rs);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }
  async function resolveTicket(status: SupportTicketStatus) {
    let rs = await api.post("/support-ticket/admin-resolve-ticket", {
      id,
    });
    setData(rs);
  }
  async function unresolveTicket() {
    let rs = await api.post("/support-ticket/admin-unresolve-ticket", {
      id,
    });
    setData(rs);
  }
  async function assign(user_id: number) {
    let rs = await api.post("/support-ticket/admin-assign", { id, user_id });
    setData(rs);
  }
  return (
    <div>
      <Modal
        closeIcon={true}
        open={showAssign}
        header={"Assign"}
        onClose={() => {
          setShowAssign(false);
        }}
        size="small"
      >
        <GridView
          gridName={"user"}
          canSelect
          disableButton
          selectedItems={[]}
          onItemSelected={(item: any) => {
            assign(item.id);
            setShowAssign(false);
          }}
          onChangeSelectedItems={(val: any[]) => {
            console.log(val);
          }}
        />
      </Modal>
      <div className="rounded-md overflow-hidden mt-4">
        {/* <p
          className="text-xl font-semibold cursor-pointer"
          onClick={() => {
            nav(-1);
          }}
        >
          <Icon name="arrow left" />
          Back
        </p> */}
        <div className="py-3 px-6 flex gap-2 w-full">
          <div className="w-full">
            <div className="flex w-full py-4">
              <p className=" w-1/4">
                Ticket No: <b>{id}</b>
              </p>
              <p className="  w-1/4">
                User ID: <b>{data?.user_id}</b>
              </p>
              <p className=" w-1/4">
                Subject: <b>{SupportTicketSubject[data?.subject]}</b>
              </p>
              <p className=" w-1/4">
                Assignee: <b>{data?.assign_name}</b>
              </p>
            </div>
            <div className="h-[1px] bg-[#eeeeee] w-full"></div>
            <div className="flex w-full py-4">
              <p className=" w-1/4">
                Priority: {/* @ts-ignore */}
                <Label color={AllColors[data.priority]}>{data?.priority}</Label>
              </p>
              <p className="  w-1/4">
                Status: {/* @ts-ignore */}
                <Label color={AllColors[data.status]}>
                  {SupportTicketStatus[data?.status]}
                </Label>
              </p>
              <p className=" gap-2 w-1/4 flex">
                Responded: {/* @ts-ignore */}
                <Label color={AllColors[data.is_response ? 4 : 1]}>
                  {YesNoEnum[data.is_response ? 1 : 0]}
                </Label>
              </p>
            </div>
          </div>
          <div className="w-56">
            <Button
              onClick={() => {
                setShowAssign(true);
              }}
              className="secondary-gradient py-2 w-48 rounded-md text-white mx-auto block mt-4"
              color="yellow"
            >
              Assign
            </Button>
            {data?.status !== SupportTicketStatus.Resolved && (
              <div className="mt-4">
                <Button
                  onClick={() => {
                    resolveTicket(SupportTicketStatus.Resolved);
                  }}
                  color="green"
                  className="main-gradient py-2 w-48 rounded-md text-white mx-auto block mt-4"
                >
                  Mark as Resolved
                </Button>
              </div>
            )}
            {data?.status !== SupportTicketStatus.Unresolved && (
              <div className="mt-4">
                <Button
                  onClick={() => {
                    unresolveTicket();
                  }}
                  color="red"
                  className="main-gradient py-2 w-48 rounded-md text-white mx-auto block mt-4"
                >
                  Mark as Unresolved
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="p-3 bg-white min-h-[450px]">
          <Comment id={id} comments={data?.comments || []} setData={setData} />
          <div className="h-[1px] bg-[#DFE0E6] w-full mt-4"></div>
          <div>
            {comment?.attachs?.length > 0 && (
              <div className="flex flex-wrap gap-2 justify-end mt-4">
                {comment?.attachs.map((url: string, index: number) => (
                  <div
                    key={index}
                    className="w-56 h-36 rounded-md border border-gray-200 p-2 relative"
                  >
                    <div
                      className="p-2 bg-[red] rounded-md text-white w-10 h-10 text-center cursor-pointer absolute top-2 left-2"
                      onClick={() => {
                        onRemoveImage(index);
                      }}
                    >
                      <Icon className="trash" />
                    </div>
                    <img src={url} className="w-full h-full object-contain" />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex gap-2 justify-between mt-4">
            <TextArea
              value={comment.content || ""}
              className="w-full border-[#DFE0E6] border rounded-md p-4"
              placeholder="Type here..."
              rows={2}
              onChange={(evt: any) => {
                handleChange("content", evt.target.value);
              }}
            />
            <div className="flex justify-end">
              <UploadImage
                setUploading={setUploading}
                onChange={(url: any) => {
                  let tmp = _.cloneDeep(comment.attachs || []);
                  tmp.push(url);
                  handleChange("attachs", tmp);
                }}
              >
                <button
                  disabled={uploading}
                  className="bg-[#44240E] rounded-md py-2 px-4 text-white h-28"
                >
                  {uploading ? "Uploading..." : "Attach file"}
                </button>
              </UploadImage>
            </div>
          </div>
          {error !== "" && (
            <p className="text-red-600 font-semibold text-center mt-4">
              {error}
            </p>
          )}
          <div className="flex justify-center mt-4">
            <Button
              onClick={() => {
                submitTicket();
              }}
              content={loading ? "Submiting..." : "Submit"}
              icon="send"
              primary
            />
          </div>
        </div>
      </div>
    </div>
  );
}
