/** @format */

import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
//@ts-ignore
import { api } from "services";
import dayjs from "dayjs";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Select,
  Statistic,
  Table,
} from "semantic-ui-react";
import { DatePicker, Enum } from "components";
import _ from "lodash";
import Config from "cheat/Config";
import ReactJson from "react-json-view";
const RESOURCES = ["MAG", "MSTR", "FOOD"];
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);
export default function ItemChart({ source }: { source: String }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [earn, setEarn] = useState<{ options: any; data: any }>(null);
  const [dateFrom, setDateFrom] = useState(
    dayjs().add(-1, "week").startOf("day").toDate()
  );
  const [dateTo, setDateTo] = useState(dayjs().add(1, "day").toDate());
  const [group, setGroup] = useState<string>("date");
  const [type, setType] = useState("");
  const [id, setId] = useState("");
  const [detail, setDetail] = useState(null);
  const [inventory, setInventory] = useState(null);
  const [itemType, setItemType] = useState("");
  const [itemTypeDetail, setItemTypeDetail] = useState("");
  async function loadItemInfo() {
    let rs = await api.post("/operation/get-item-detail", {
      id,
      type: itemTypeDetail,
    });
    if (!rs) return;
    setInventory(rs);
  }
  async function loadData() {
    setLoading(true);
    try {
      let rs = await api.post("/operation/get-new-item-report", {
        dateFrom: dateFrom,
        dateTo: dateTo,
        itemType,
        groupBy: group,
        source,
        type,
      });
      setEarn(rs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    setEarn(null);
  }, [itemType]);
  return (
    <div>
      <Card fluid>
        {earn !== null && (
          <Card.Content>
            {itemType !== "land" ? (
              <Bar options={earn.options} data={earn.data} />
            ) : (
              <Line options={earn.options} data={earn.data} />
            )}
          </Card.Content>
        )}
        <Card.Content>
          <Form className="flex gap-2 w-full">
            <Form.Field className="w-full">
              <label>View by</label>
              <Select
                placeholder={"Select view time"}
                fluid
                value={group}
                options={["date", "week", "month"].map((i) => {
                  return { text: i, value: i };
                })}
                onChange={(evt: any, val: any) => {
                  setGroup(val.value);
                }}
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>From</label>
              <DatePicker
                todayButton="Today"
                selected={dateFrom}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateFrom(null);
                  } else {
                    setDateFrom(dayjs(val).startOf("day").toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>To</label>
              <DatePicker
                todayButton="Today"
                selected={dateTo}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateTo(null);
                  } else {
                    setDateTo(dayjs(val).endOf("day").toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </Form.Field>
            {itemType === "plot" && (
              <Form.Field className="w-full">
                <label>Plot type</label>
                <Form.Select
                  placeholder={"Select plot type"}
                  fluid
                  value={type}
                  onChange={(evt: any, val: any) => {
                    setType(val.value);
                  }}
                  options={[
                    "All",
                    "Landplot_LandCore",
                    "Landplot_Storage",
                    "Landplot_Production",
                    "Landplot_Breeding",
                    "Landplot_Hatching",
                    "Landplot_Pasture_B",
                    "Landplot_Pasture_T",
                    "Landplot_Pasture_M",
                    "Landplot_Camp",
                    "Landplot_Tower",
                    "Landplot_Bombard",
                    "Landplot_Trap",
                    "Landplot_Barracks",
                    "Landplot_Decor",
                  ].map((i) => {
                    return { text: i, value: i };
                  })}
                ></Form.Select>
              </Form.Field>
            )}
            {itemType === "soulcore" && (
              <Form.Field className="w-full">
                <label>Soulcore type</label>
                <Form.Select
                  placeholder={"Select plot type"}
                  fluid
                  value={type}
                  onChange={(evt: any, val: any) => {
                    setType(val.value);
                  }}
                  options={[
                    "All",
                    "Soulcore_B",
                    "Soulcore_T",
                    "Soulcore_M",
                  ].map((i) => {
                    return { text: i, value: i };
                  })}
                ></Form.Select>
              </Form.Field>
            )}
            {itemType === "mongen" && (
              <Form.Field className="w-full">
                <label>Mongen type</label>
                <Form.Select
                  placeholder={"Select plot type"}
                  fluid
                  value={type}
                  onChange={(evt: any, val: any) => {
                    setType(val.value);
                  }}
                  options={["All", "Mongen_B", "Mongen_T", "Mongen_M"].map(
                    (i) => {
                      return { text: i, value: i };
                    }
                  )}
                ></Form.Select>
              </Form.Field>
            )}
            <Form.Field className="w-full">
              <label>Type</label>
              <Select
                placeholder={"Select type"}
                fluid
                value={itemType}
                options={["soulcore", "mongen", "plot", "land"].map((i) => {
                  return { text: i, value: i };
                })}
                onChange={(evt: any, val: any) => {
                  setItemType(val.value);
                }}
              />
            </Form.Field>
            <div>
              <Form.Field>
                <label>Action</label>
                <Button
                  loading={loading}
                  color="blue"
                  icon="search"
                  content="Search"
                  labelPosition="left"
                  onClick={loadData}
                />
              </Form.Field>
              <Form.Field>
                <Button
                  loading={loading}
                  color="teal"
                  icon="download"
                  content="Download CSV"
                  labelPosition="left"
                  onClick={() => {
                    api.downloadReport(earn.data);
                  }}
                />
              </Form.Field>
            </div>
          </Form>
        </Card.Content>
      </Card>

      <Card fluid>
        <Card.Content>
          <div className="flex justify-between">
            <p>
              Item Info <b>#{id}</b>
            </p>
          </div>
        </Card.Content>
        <Card.Content>
          <Form>
            <div className="flex w-full gap-2">
              <Form.Field className="w-full">
                <label>Item ID</label>
                <Input
                  value={id}
                  type="text"
                  fluid
                  onChange={(evt: any) => {
                    setId(evt.target.value);
                  }}
                />
              </Form.Field>
              <Form.Field className="w-full">
                <label>Type</label>
                <Select
                  placeholder={"Select type"}
                  fluid
                  value={itemTypeDetail}
                  options={["mongen", "plot", "land"].map((i) => {
                    return { text: i, value: i };
                  })}
                  onChange={(evt: any, val: any) => {
                    setItemTypeDetail(val.value);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Action</label>
                <Button
                  color="blue"
                  icon="search"
                  content="Search"
                  labelPosition="left"
                  onClick={() => loadItemInfo()}
                />
              </Form.Field>
            </div>
          </Form>
        </Card.Content>
      </Card>

      {inventory &&
        inventory.labelTable.map((label: any, index: any) => {
          return (
            <Card key={`table_${index}`} fluid>
              <Card.Content>{label}</Card.Content>
              <Card.Content>
                <Table>
                  <Table.Header>
                    {inventory.tables[index].labels.map((labelTable: any) => {
                      return <Table.HeaderCell>{labelTable}</Table.HeaderCell>;
                    })}
                    <Table.HeaderCell>Action</Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    {inventory.tables[index].data.map((dataset: any) => {
                      return (
                        <Table.Row>
                          {dataset.map((data: any) => (
                            <Table.Cell>{data}</Table.Cell>
                          ))}
                          <Table.Cell>
                            <Button
                              icon="eye"
                              color="blue"
                              onClick={() => {
                                setDetail(
                                  inventory.dataDetails[index][dataset[0]]
                                );
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Card.Content>
            </Card>
          );
        })}
      <Modal
        open={detail !== null}
        closeIcon
        onClose={() => {
          setDetail(null);
        }}
      >
        <Modal.Content>
          <div className="w-full flex gap-2">
            <ReactJson
              src={detail}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              name="input"
            />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
}
