import dayjs from "dayjs";
import BasicChart from "./basic-chart";

export default function UserProfileChart() {
  return (
    <BasicChart
      url="/operation/get-user-profile-report"
      chartType="line"
      source="backend"
      showTimeSelect={true}
      value={{
        group: "date",
        dateFrom: dayjs().add(-1, "week").toDate(),
        dateTo: dayjs().add(1, "day").toDate(),
      }}
    />
  );
}
