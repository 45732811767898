import Config from "config";
import React, { useEffect } from "react";
import { MongenRace, PlotType, BidEntity, RewardType } from "interfaces";
import { Button, Checkbox, Input } from "semantic-ui-react";
import { utils } from "services";
import Entity from "./entity";
import Enum from "./enum";

export default function BidEditor({
  value,
  onChange,
  viewOnly,
}: {
  viewOnly?: boolean;
  value: BidEntity[];
  onChange: Function;
}) {
  useEffect(() => {    
    let tmp: BidEntity[] = [...value];
    tmp.push({
      type: null,
      quantity: null,
      plot_type: null,
      race: null,
      rarity: null,
    });
    onChange(tmp);
  }, []) 
  function removeReward(index: number) {
    let tmp: BidEntity[] = [...value];
    tmp.splice(index, 1);
    onChange(tmp);
  }
  function handleChange(index: number, name: string, val: any) {
    let tmp: BidEntity[] = [...value];
    //@ts-ignore
    tmp[index][name] = val;
    onChange(tmp);
  }
  return (
    <div>
      {value.map((i: BidEntity, index: number) => (
        <div
          key={index}
          className="flex gap-2 justify-between border hover:border-gray-200 border-white p-1"
        >
          <div className="w-full flex gap-2 items-center">
            <div className="w-44">
              <Enum
                placeholder="Reward"
                enumName="reward-type"
                value={i.type}
                onChange={(val) => {
                  handleChange(index, "type", Number(val));
                }}
              />
            </div>
            <div className="w-20">
              <Input
                className="w-20"
                value={i.quantity || ""}
                placeholder="Quantity"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(index, "quantity", Number(evt.target.value));
                }}
              />
            </div>


            {i.type === RewardType.PLOT && (
              <div className="w-44">
                <Enum
                  placeholder="Plot type"
                  enumName="plot-type"
                  value={i.plot_type}
                  onChange={(val) => {
                    handleChange(index, "plot_type", Number(val));
                    if (Number(val) !== PlotType.Pasture && Number(val) !== -1 && Number(val) !== -1) {
                      handleChange(index, "race", MongenRace.All);
                    } else if ((Number(val) === PlotType.Pasture && i.race === MongenRace.All) || Number(val) === -1) {
                      handleChange(index, "race", -1);
                    }
                    if ([...Config.SubablePlotType, -1].includes(Number(val))) {
                      handleChange(index, "sub_plot_type", 0)
                    }
                  }}
                />
              </div>
            )}
            {(i.type === RewardType.PLOT ||
              i.type === RewardType.SOULCORE) && (
                <>
                  <div className="w-44">
                    <Enum
                      placeholder="Race"
                      enumName="mongen-race"
                      value={i.race}
                      onChange={(val) => {
                        handleChange(index, "race", Number(val));
                      }}
                      // @ts-ignore
                      disabledValue={(i.type === RewardType.SOULCORE || (i.type === RewardType.PLOT && (i.plot_type === PlotType.Pasture || i.plot_type === null || i.plot_type === -1))) ? [MongenRace.All] : [MongenRace.Beast, MongenRace.Tectos, MongenRace.Mystic, MongenRace.Celest, MongenRace.Chaos, -1]}
                    />
                  </div>
                </>
              )}
            {(i.type === RewardType.PLOT && [...Config.SubablePlotType, -1].includes(i.plot_type)) && (
              <>
                <div className="w-44">
                  <Enum
                    placeholder="Subtype"
                    enumName={utils.getEnumSubType(i.plot_type)}
                    value={i.sub_plot_type}
                    onChange={(val) => {
                      handleChange(index, "sub_plot_type", Number(val));

                    }}
                  />
                </div>
              </>
            )}
            {(
              [RewardType.PLOT, RewardType.SOULCORE, RewardType.Rune].includes(i.type)
            ) && (
                <div className="w-44">
                  <Enum
                    placeholder="Rarity"
                    enumName="rarity"
                    value={i.rarity}
                    onChange={(val) => {
                      handleChange(index, "rarity", Number(val));
                    }}
                  />
                </div>
              )}
            {i.type === RewardType.TICKET &&
              (
                <div className="w-44">
                  <Enum
                    placeholder="Rarity"
                    enumName="ticketrarity"
                    value={i.rarity}
                    onChange={(val) => {
                      handleChange(index, "rarity", Number(val));
                    }}
                  />
                </div>
              )}
            {i.type === RewardType.BOX && (
              <div className="w-80">
                <Entity
                  displayField={"name"}
                  value={i.reward_pool_id}
                  gridName={"reward-pool"}
                  multiple={false}
                  onChange={(val: any, raw: any) => {
                    handleChange(index, "reward_pool_id", val);
                    handleChange(index, "box_name", raw.name);
                    handleChange(index, "box_type", raw.name);
                  }}
                />
              </div>
            )}
            {i.type === RewardType.Avatar && (
              <div className="w-44">
                <Input
                  className="w-32"
                  value={i.avatar_id || ""}
                  placeholder="Avatar id"
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(index, "avatar_id", Number(evt.target.value));
                  }}
                />
              </div>
            )}
            {i.type === RewardType.DecorationList && (
              <div className="w-44">
                <Enum
                  enumName="decoration-list"
                  value={i.decoration_code}
                  onChange={(val, raw) => {
                    console.log({ val, raw });
                    handleChange(index, "decoration_code", val);
                    handleChange(index, "rarity", raw.rarity);
                  }}
                />
              </div>
            )}
            {i.type === RewardType.Rune && (
              <div className="w-44">
                <Enum
                  enumName="rune-list"
                  value={i.rune_code}
                  onChange={(val) => {
                    handleChange(index, "rune_code", val);
                  }}
                />
              </div>
            )}
            {i.type === RewardType.Mongen && (
              <>
                <div className="w-80">
                  <Input
                    className="w-80"
                    value={i.dna || ""}
                    placeholder="DNA"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(index, "dna", evt.target.value);
                    }}
                  />
                </div>
                <div className="w-80">
                  <Input
                    className="w-80"
                    value={i.troop_list || ""}
                    placeholder="Troop List"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(index, "troop_list", evt.target.value);
                    }}
                  />
                </div>
                <div className="w-80">
                  <div className="font-bold">Remove Breeding</div>
                  <Checkbox
                    className="translate-y-2"
                    checked={i.remove_breeding}
                    toggle
                    onChange={(evt: any, { checked }) => {
                      handleChange(index, "remove_breeding", checked);
                    }}
                  />
                </div>
                <Button
                  icon="edit"
                  color="blue"
                  onClick={() => {
                    window.open('/#/dna-editor', '_blank')
                  }}
                />
              </>
              
            )}
            
            {i.type === RewardType.EMOJI &&
              (
                <div className="w-44">
                  <Enum
                    placeholder="Emoji"
                    enumName="emoji-pool"
                    value={i.emoji_id}
                    onChange={(val) => {
                      handleChange(index, "emoji_id", val);
                    }}
                  />
                </div>
              )}
            {i.type === RewardType.BLESSING &&
              (
                <div className="w-44">
                  <Checkbox
                    className="translate-y-2"
                    label="Is premium"
                    checked={i.is_premium}
                    toggle
                    onChange={(evt: any, { checked }) => {
                      handleChange(index, "is_premium", checked);
                    }}
                  />
                </div>
              )}
          </div>
          {!viewOnly && (
            <Button
              icon="times"
              color="red"
              onClick={() => {
                removeReward(index);
              }}
            />
          )}
        </div>
      ))}
      {/* <div className="mt-2">
        {!viewOnly && (
          <Button
            icon="plus"
            content="Add"
            labelPosition="left"
            onClick={addReward}
          />
        )}
      </div> */}
    </div>
  );
}
