import { EnumEntity, FormEntity, GridEntity, MenuEntity, NewbieQuest, PlotSkin, DecorationPart } from "../interfaces";
let grids: { [key: string]: GridEntity } = {};
let forms: { [key: string]: FormEntity } = {};
let enums: { [key: string]: EnumEntity } = {};
let menus: MenuEntity[] = [];
let troops: any[];
let skills: any[];
let runes: any[];
let newbieQuests: any[];
let plotSkin: any[];
let decorationLists: any[];
let isSetMeta: boolean = false;

function setMeta(
  _grids: { [key: string]: GridEntity },
  _forms: { [key: string]: FormEntity },
  _enums: { [key: string]: EnumEntity },
  _menus: MenuEntity[],
  _troops: any[],
  _skills: any[],
  _runes: any[],
  _newbieQuests: NewbieQuest[],
  _plotSkin: PlotSkin[],
  _decorationLists: DecorationPart[],
): void {
  isSetMeta = true;
  grids = _grids;
  forms = _forms;
  enums = _enums;
  menus = _menus;
  troops = _troops ?? [];
  skills = _skills ?? [];
  runes = _runes ?? []
  newbieQuests = _newbieQuests ?? []
  plotSkin = _plotSkin ?? []
  decorationLists = _decorationLists ?? []
}
function getMenus() {
  return menus;
}
function getAllEnums(): { [key: string]: EnumEntity } {
  return enums;
}
function getEnum(name: string): EnumEntity {
  return enums[name];
}

function getGrid(name: string): GridEntity {
  return grids[name];
}

function getFormByName(name: string): FormEntity {
  return forms[name];
}
function checkIsSetMeta(): boolean {
  return isSetMeta;
}
function getAllTroop(): any[] {
  return troops;
}
function getAllSkill(): any[] {
  return skills;
}
function getAllRune(): any[] {
  return runes;
}
function getAllNewbieQuests(): NewbieQuest[] {
  return newbieQuests;
}
function getAllPlotSkin(): PlotSkin[] {
  return plotSkin;
}
function getAllDecor(): DecorationPart[] {
  return decorationLists;
}
const dataServices = {
  getMenus,
  getGrid,
  getFormByName,
  getEnum,
  checkIsSetMeta,
  setMeta,
  getAllEnums,
  getAllTroop,
  getAllSkill,
  getAllRune,
  getAllNewbieQuests,
  getAllPlotSkin,
  getAllDecor
};
export default dataServices;
