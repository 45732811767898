import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
import { api } from "services";
import dayjs from "dayjs";
import { Button, Card, Form, Select, Table } from "semantic-ui-react";
import { DatePicker } from "components";
import _ from "lodash";
import saveAs from "file-saver";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);
export default function UserSignupCountryChart() {
  const [loading, setLoading] = useState<boolean>(false);
  const [earn, setEarn] = useState<{ options: any; data: any }>(null);
  const [dateFrom, setDateFrom] = useState(
    dayjs().add(-1, "week").startOf("day").toDate()
  );
  const [dateTo, setDateTo] = useState(dayjs().add(1, "day").toDate());
  const [type, setType] = useState("");
  async function loadData() {
    setLoading(true);
    try {
      let rs = await api.post("/operation/get-signup-country-report-chart", {
        dateFrom: dateFrom,
        dateTo: dateTo,
        geocountry: "",
        type,
      });
      setEarn(rs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  return (
    <div>
      <Card.Content>
        <Form className="flex gap-2 w-full">
          <Form.Field className="w-full">
            <label>Type</label>
            <Select
              placeholder={"Select Type"}
              fluid
              value={type}
              search
              options={[
                "TotalSpentMAG",
                "TotalSpentMSTR",
                "TotalRegister",
                "TotalUserSpentMag",
                "TotalUserSpentMstr",
                "TotalEarnMAG",
                "TotalEarnMSTR",
                "IAP",
                "DepositMSTR",
                "DepositStakeMAG",
              ].map((i) => {
                return { text: i, value: i };
              })}
              onChange={(evt: any, val: any) => {
                setType(val.value);
              }}
            />
          </Form.Field>
          <Form.Field className="w-full">
            <label>From</label>
            <DatePicker
              todayButton="Today"
              selected={dateFrom}
              onChange={(val: Date) => {
                if (!val) {
                  setDateFrom(null);
                } else {
                  setDateFrom(dayjs(val).startOf("day").toDate());
                }
              }}
              dateFormat="yyyy/MM/dd HH:mm"
            />
          </Form.Field>
          <Form.Field className="w-full">
            <label>To</label>
            <DatePicker
              todayButton="Today"
              selected={dateTo}
              onChange={(val: Date) => {
                if (!val) {
                  setDateTo(null);
                } else {
                  setDateTo(dayjs(val).endOf("day").toDate());
                }
              }}
              dateFormat="yyyy/MM/dd HH:mm"
            />
          </Form.Field>
          <div>
            <Form.Field>
              <label>Action</label>
              <Button
                loading={loading}
                color="blue"
                icon="search"
                content="Search"
                labelPosition="left"
                onClick={loadData}
              />
            </Form.Field>
          </div>
          <div>
            <Form.Field>
              <Button
                style={{ marginTop: "15px" }}
                loading={loading}
                color="teal"
                icon="download"
                content="Download CSV"
                labelPosition="left"
                onClick={() => {
                  const labels = earn?.data?.labels
                    ? [earn?.data?.labels]
                    : [[]];
                  const data = earn?.data?.datasets ?? [];
                  console.log(labels.concat(data));
                  let text = labels
                    .concat(data)
                    .map((s: string[]) => s.join(","))
                    .join("\n");
                  var blob = new Blob([text], {
                    type: "text/plain;charset=utf-8;",
                  });
                  saveAs(blob, "report.csv");
                }}
              />
            </Form.Field>
          </div>
        </Form>
      </Card.Content>
      <Card fluid>
        {earn !== null && (
          <Card.Content>
            <Line options={earn.options} data={earn.data} />
          </Card.Content>
        )}
      </Card>
    </div>
  );
}
