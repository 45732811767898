import BridgeABI from "./abi/bridge.json";
import Web3 from "web3";
import api from "./api";
import { ui } from "services";

export const BSCMainnetChainId = "0x38";
export const BSCMainnetChainIdDec = 56;
export const BSCTestnetChainId = "0x61";
export const BSCTestnetChainIdDec = 97;

export const EtherMainnetChainIdDec = 1;
export const EtherTestnetChainId = "0x4";
export const EtherTestnetChainIdDec = 4;

export const AvalancheMainnetIdDec = 43114;
export const AvalancheTestnetIdDec = 43113;

export const OKXMainnetChainIdDec = 66;
export const OKXTestnetChainIdDec = 65;

export const CHAIN_ID = {
  //EVM
  BSC: BSCMainnetChainIdDec.toString(),
  BSC_TEST: BSCTestnetChainIdDec.toString(),
  ETH: EtherMainnetChainIdDec.toString(),
  ETH_TEST: EtherTestnetChainIdDec.toString(),
  AVAX: AvalancheMainnetIdDec.toString(),
  AVAX_TEST: AvalancheTestnetIdDec.toString(),
  OKX: OKXMainnetChainIdDec.toString(),
  OKX_TEST: OKXTestnetChainIdDec.toString(),
};

export const LIST_NETWORK_RPC_MAINNET: any = {
  [CHAIN_ID.BSC]: process.env.REACT_APP_BRIDGE_RPC_BSC,
  [CHAIN_ID.AVAX]: process.env.REACT_APP_BRIDGE_RPC_AVAX,
  [CHAIN_ID.OKX]: process.env.REACT_APP_BRIDGE_RPC_OKC,
};

export const LIST_NETWORK_RPC_TESTNET: any = {
  [CHAIN_ID.BSC_TEST]: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  [CHAIN_ID.AVAX_TEST]: "https://api.avax-test.network/ext/bc/C/rpc",
  [CHAIN_ID.OKX_TEST]: "https://exchaintestrpc.okex.org",
};

type CHAIN_INFO_TYPE = {
  name: string;
  valueString?: string;
  textWarning: string;
  url: string;
  suffixToken: string;
  explorerName: string;
  suffixKey: string;
  shortName: string;
  key: string;
  nativeToken: string;
};

export const CHAIN_INFO: Record<string | number, CHAIN_INFO_TYPE> = {
  //EVM
  [BSCMainnetChainIdDec]: {
    name: "Binance Smart Chain",
    valueString: BSCMainnetChainIdDec.toString(),
    textWarning: "Binance Smart Chain - Mainnet",
    url: "https://bscscan.com",
    suffixToken: "BEP-20",
    explorerName: "BSCscan",
    suffixKey: "",
    shortName: "BSC",
    key: "BSC",
    nativeToken: "BNB",
  },
  [BSCTestnetChainIdDec]: {
    name: "BSC Testnet",
    valueString: BSCTestnetChainIdDec.toString(),
    textWarning: "Binance Smart Chain - Testnet",
    url: "https://testnet.bscscan.com",
    suffixToken: "BEP-20",
    explorerName: "BSCscan",
    suffixKey: "",
    shortName: "BSC",
    key: "BSC",
    nativeToken: "BNB",
  },
  [EtherMainnetChainIdDec]: {
    name: "Ethereum",
    valueString: EtherMainnetChainIdDec.toString(),
    textWarning: "Ethereum - Mainnet",
    url: "https://etherscan.io",
    suffixToken: "ERC-20",
    explorerName: "Etherscan",
    suffixKey: "_ETH",
    shortName: "ETH",
    key: "ETH",
    nativeToken: "ETH",
  },
  [EtherTestnetChainIdDec]: {
    name: "Ethereum Testnet (Rinkeby)",
    valueString: EtherTestnetChainIdDec.toString(),
    textWarning: "Ethereum (Rinkeby) - Testnet",
    url: "https://rinkeby.etherscan.io",
    suffixToken: "ERC-20",
    explorerName: "Etherscan",
    suffixKey: "_ETH",
    shortName: "ETH",
    key: "ETH",
    nativeToken: "ETH",
  },
  [AvalancheMainnetIdDec]: {
    name: "Avalanche Mainnet C-Chain",
    valueString: AvalancheMainnetIdDec.toString(),
    textWarning: "Avalanche Mainnet",
    url: "https://snowtrace.io",
    suffixToken: "C-Chain",
    explorerName: "Snowtrace",
    suffixKey: "",
    shortName: "AVAX",
    key: "AVAX",
    nativeToken: "AVAX",
  },
  [AvalancheTestnetIdDec]: {
    name: "Avalanche Testnet C-Chain",
    valueString: AvalancheTestnetIdDec.toString(),
    textWarning: "Avalanche Testnet",
    url: "https://testnet.snowtrace.io",
    suffixToken: "C-Chain",
    explorerName: "Snowtrace",
    suffixKey: "",
    shortName: "AVAX",
    key: "AVAX",
    nativeToken: "AVAX",
  },
  [OKXMainnetChainIdDec]: {
    name: "OKXChain Mainnet",
    valueString: OKXMainnetChainIdDec.toString(),
    textWarning: "OKXChain Mainnet",
    url: "https://www.oklink.com/en/okc",
    suffixToken: "KIP-20",
    explorerName: "OKX",
    suffixKey: "",
    shortName: "OKX",
    key: "OKX",
    nativeToken: "OKX",
  },
  [OKXTestnetChainIdDec]: {
    name: "OKXChain Testnet",
    valueString: OKXTestnetChainIdDec.toString(),
    textWarning: "OKXChain Testnet",
    url: "https://www.oklink.com/en/okc-test",
    suffixToken: "KIP-20",
    explorerName: "OKX",
    suffixKey: "",
    shortName: "OKX",
    key: "OKX",
    nativeToken: "OKX",
  },
};

export const APP_NETWORKS_SUPPORT: Record<string | number, { details: any }> = {
  [EtherMainnetChainIdDec]: {
    details: {
      chainId: `0x${(+EtherMainnetChainIdDec).toString(16)}`,
      chainName: CHAIN_INFO[EtherMainnetChainIdDec].name,
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: [LIST_NETWORK_RPC_MAINNET[EtherMainnetChainIdDec]],
      blockExplorerUrls: [CHAIN_INFO[EtherMainnetChainIdDec].url],
    },
  },
  [BSCMainnetChainIdDec]: {
    details: {
      chainId: `0x${(+BSCMainnetChainIdDec).toString(16)}`,
      chainName: CHAIN_INFO[BSCMainnetChainIdDec].name,
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: [LIST_NETWORK_RPC_MAINNET[BSCMainnetChainIdDec]],
      blockExplorerUrls: [CHAIN_INFO[BSCMainnetChainIdDec].url],
    },
  },
  [EtherTestnetChainIdDec]: {
    details: {
      chainId: `0x${(+EtherTestnetChainIdDec).toString(16)}`,
      chainName: CHAIN_INFO[EtherTestnetChainIdDec].name,
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: [LIST_NETWORK_RPC_TESTNET[EtherTestnetChainIdDec]],
      blockExplorerUrls: [CHAIN_INFO[EtherTestnetChainIdDec].url],
    },
  },
  [BSCTestnetChainIdDec]: {
    details: {
      chainId: `0x${(+BSCTestnetChainIdDec).toString(16)}`,
      chainName: CHAIN_INFO[BSCTestnetChainIdDec].name,
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: [LIST_NETWORK_RPC_TESTNET[BSCTestnetChainIdDec]],
      blockExplorerUrls: [CHAIN_INFO[BSCTestnetChainIdDec].url],
    },
  },
  [AvalancheMainnetIdDec]: {
    details: {
      chainId: `0x${(+AvalancheMainnetIdDec).toString(16)}`,
      chainName: "Avalanche Mainnet C-Chain",
      nativeCurrency: {
        name: "Avalanche",
        symbol: "AVAX",
        decimals: 18,
      },
      rpcUrls: [LIST_NETWORK_RPC_MAINNET[AvalancheMainnetIdDec]],
      blockExplorerUrls: ["https://snowtrace.io/"],
    },
  },
  [AvalancheTestnetIdDec]: {
    details: {
      chainId: `0x${(+AvalancheTestnetIdDec).toString(16)}`,
      chainName: "Avalanche Testnet C-Chain",
      nativeCurrency: {
        name: "Avalanche",
        symbol: "AVAX",
        decimals: 18,
      },
      rpcUrls: [LIST_NETWORK_RPC_TESTNET[AvalancheTestnetIdDec]],
      blockExplorerUrls: ["https://testnet.snowtrace.io/"],
    },
  },

  [OKXMainnetChainIdDec]: {
    details: {
      chainId: `0x${(+OKXMainnetChainIdDec).toString(16)}`,
      chainName: "OKC Main",
      nativeCurrency: {
        name: "OKT",
        symbol: "OKT",
        decimals: 18,
      },
      rpcUrls: [LIST_NETWORK_RPC_TESTNET[OKXMainnetChainIdDec]],
      blockExplorerUrls: ["https://www.oklink.com/okc"],
    },
  },
  [OKXTestnetChainIdDec]: {
    details: {
      chainId: `0x${(+OKXTestnetChainIdDec).toString(16)}`,
      chainName: "OKC Testnet",
      nativeCurrency: {
        name: "OKT",
        symbol: "OKT",
        decimals: 18,
      },
      rpcUrls: [LIST_NETWORK_RPC_TESTNET[OKXTestnetChainIdDec]],
      blockExplorerUrls: ["https://www.oklink.com/okc-test"],
    },
  },
};
const web3 = new Web3(Web3.givenProvider);
const abi: any = BridgeABI;

export const setApproveTxByAmount = async (id: string, chain: any) => {
  const BridgeContract = new web3.eth.Contract(abi, chain.contractProxy);
  const approveData = BridgeContract.methods
    .setApprovedTxByAmount([id], true)
    .encodeABI();
  try {
    const account = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    await web3.eth
      .sendTransaction(
        {
          from: account[0],
          to: chain.contractProxy,
          data: approveData,
        },
        async (err: any, res: any) => {
          if (res) {
          }
          if (err) {
            throw err;
          }
        }
      )
      .on("receipt", async (rs: any) => {
        const { transactionHash } = rs;
        await api.approveBridgePut({
          transactionId: id,
          txHash: transactionHash,
        });
        return rs;
      });
  } catch (err) {
    throw err;
  }
};

export const networkValidation = async (requiredChainNetwork: any) => {
  const chainId = await web3.eth.net.getId();
  let chain = APP_NETWORKS_SUPPORT[Number(requiredChainNetwork.chainId)];
  let rs = +chainId == +requiredChainNetwork.chainId;
  if (!rs) {
    // ui.alert(`Please change network on Wallet to ${requiredChainNetwork?.name} to continue`, 5);
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chain.details?.chainId }],
      });
      return true;
    } catch (error: any) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                ...(chain.details || {}),
              },
            ],
          });
          return true;
        } catch (addError) {
          return false;
        }
      } else {
        return false;
      }
    }
  }
  return rs;
};

const contract = {
  setApproveTxByAmount,
  networkValidation,
};

export default contract;
