import { useTranslation } from "react-i18next";
import { Button, Card, Input } from "semantic-ui-react";
import Entity from "./entity";
import Enum from "./enum";
import ListRewardEditor from "./list-reward-editor";

export default function ExchangePointFormula({
  value,
  onChange,
  viewOnly,
}: {
  viewOnly?: boolean;
  value: any;
  onChange: Function;
}) {
  const { t } = useTranslation();
  function handleChange(index: number, field: string, val: any) {
    let tmp = Object.assign([], value);
    if (!tmp[index]) {
      tmp[index] = {};
    }
    //@ts-ignore
    tmp[index][field] = val;
    onChange(tmp);
  }
  function addFormula() {
    let tmp = Object.assign([], value);
    tmp.push({
      title: "",
      type: -1,
      rarity: -1,
      plot_type: -1,
      point: 0,
      amount: 0,
    });
    onChange(tmp);
  }
  return (
    <div>
      {value.map((formula: any, index: number) => {
        return (
          <div key={index} className="mt-5">
            <Card fluid className="mt-4" key={index}>
              <Card.Content className="w-full flex gap-2 items-center">
                <div className="mt-5 w-60">
                  <label className="font-bold">Title</label>
                  <Input
                    fluid
                    type="text"
                    value={formula.title}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(index, "title", evt.target.value);
                    }}
                  />
                </div>
                <div className="mt-5 w-40">
                  <label className="font-bold">Type</label>
                  <Enum
                    placeholder={"Type"}
                    value={formula.type}
                    enumName={"asset-type"}
                    onChange={(val) => {
                      handleChange(index, "type", val);
                    }}
                  />
                </div>
                <div className="mt-5 w-40">
                  <label className="font-bold">Rarity</label>
                  <Enum
                    placeholder={"Rarity"}
                    value={formula.rarity}
                    enumName={"rarity"}
                    onChange={(val) => {
                      handleChange(index, "rarity", val);
                    }}
                  />
                </div>
                {formula.type === 1 && (
                  <div className="mt-5 w-40">
                    <label className="font-bold">Plot Type</label>
                    <Enum
                      placeholder={"Plot Type"}
                      value={formula.plot_type}
                      enumName={"plot-type"}
                      onChange={(val) => {
                        handleChange(index, "plot_type", val);
                      }}
                    />
                  </div>
                )}
                <div className="mt-5 w-40">
                  <label className="font-bold">Point</label>
                  <Input
                    fluid
                    type="number"
                    value={formula.point}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(index, "point", evt.target.value);
                    }}
                  />
                </div>
                <div className="mt-5 w-40">
                  <label className="font-bold">Amount</label>
                  <Input
                    fluid
                    type="number"
                    value={formula.amount}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(index, "amount", evt.target.value);
                    }}
                  />
                </div>
              </Card.Content>
            </Card>
          </div>
        );
      })}
      <div className="mt-2">
        {!viewOnly && (
          <Button
            icon="plus"
            content="Add"
            labelPosition="left"
            onClick={addFormula}
          />
        )}
      </div>
    </div>
  );
}
