import { DatePicker } from "components";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Table,
} from "semantic-ui-react";
import { api } from "services";

export default function ReportScholarEarns() {
  const [scholar_id, setScholarId] = useState("");
  const [owner_id, setOwnerId] = useState("");
  const [data, setData] = useState<any>([])
  const [header, setHeaders] = useState<any>([])
  const [totalScholar, setTotalScholar] = useState<any>([])

  async function loadData() {
    let rs = await api.post("/operation/get-scholar-total-earn", {
      owner_id: owner_id ? Number(owner_id) : null,
      scholar_id: scholar_id ? Number(scholar_id) : null,
    });

    // let totalScholar = 0;
    // totalScholar = await api.post("/operation/get-all-scholar", {
    //   owner_id: owner_id ? Number(owner_id) : null,
    //   scholar_id: scholar_id ? Number(scholar_id) : null,
    //   limit: 0,
    //   offset: 0
    // });
    // setTotalScholar(totalScholar)
    let headers = ["owner_id", "scholar_id", "mag", "mstr", "elixir", "mutategen"]
    let data = rs.scholarRewardInfos[0];
    if (!data) {
      setHeaders(headers)
      setData([{
        owner_id,
        scholar_id,
        ...data
      }])
    } else {
      setHeaders(headers)
      setData([{
        owner_id,
        scholar_id,
        ...data
      }])
    }

  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <div>
      <Card fluid>
        <Card.Content>
          <Form className="flex gap-2 w-full">
            <Form.Field className="w-full">
              <label>Owner id</label>
              <Input value={owner_id} onChange={(e, data) => {
                setOwnerId(data.value)
              }} />
            </Form.Field>
            <Form.Field className="w-full">
              <label>Scholar id</label>
              <Input value={scholar_id} onChange={(e, data) => {
                setScholarId(data.value)
              }} />
            </Form.Field>
            <Form.Field>
              <label>Action</label>
              <Button
                color="blue"
                icon="search"
                content="Search"
                labelPosition="left"
                onClick={() => loadData()}
              />
            </Form.Field>
          </Form>
        </Card.Content>

        <Card.Content>
          <Table celled sortable>
            <Table.Header>
              {header.map((labelTable: any) => {
                return <Table.HeaderCell>{labelTable}</Table.HeaderCell>;
              })}
            </Table.Header>
            <Table.Body>
              {data.map((i: any) => {
                return (
                  <Table.Row>
                    {header.map((key: string) => {
                      return (
                        <Table.Cell>{i[key]}</Table.Cell>
                      )
                    })}
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    </div>
  );
}
