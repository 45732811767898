import BasicChart from "./basic-chart";

export default function AdaptationChart(props: any) {
  return (
    <BasicChart
      {...props}
      url="/operation/get-adaptation"
      chartType="line"
      source="backend"
    />
  );
}
