import { DatePicker } from "components";
import dayjs from "dayjs";
import { AlertType, IAPMethod, IAPProduct, IAPTranscationStatus } from "interfaces";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Pagination,
  Select,
  Table,
} from "semantic-ui-react";
import { api, ui } from "services";

export default function ReportIapTransaction() {
  const [customer_id, setCustomerId] = useState(null);
  const [receipt_id, setReceiptId] = useState(null);
  const [status, setStatus] = useState(-1);
  const [data, setData] = useState<any>([])
  const [header, setHeaders] = useState<any>([])
  const [page, setPage] = useState<any>(1);
  const [total, setTotal] = useState<any>(1);
  const pageSize = 50;

  async function loadData() {
    let rs = await api.post("/operation/get-iap-transactions", {
      offset: Math.max(0, (page - 1) * pageSize),
      limit: pageSize,
      status: status,
      customer_id: customer_id,
      method: -1,
      receipt_id: receipt_id
    });
    let headers = ["id", "customer_id", "method", "product", "receipt_id", "revoked_amount", "total_revoke_amount", "amount", "status", "is_processed", "created_at"];
    setHeaders(headers)
    let data = rs.rows
    setTotal(rs.count ?? 0)
    if (!data) {
      setData([])
    } else {
      setData(data)
    }
  }

  const retryIAP = async (receipt_id: string) => {
    try {
      let rs = await api.post("/operation/retry-iap-transaction", {
        receipt_id
      })
      ui.alert("Success", AlertType.Success)
      await loadData();
    } catch (err) {
      ui.alert("Failed", AlertType.Warning)
    }
  }

  const continueRevoke = async (id: number) => {
    try {
      let rs = await api.post("/operation/continue-revoke-transaction", {
        id
      })
      ui.alert("Success", AlertType.Success)
      await loadData();
    } catch (err) {
      ui.alert("Failed", AlertType.Warning)
    }
  }

  useEffect(() => {
    loadData();
  }, [customer_id, receipt_id, status, page])

  return (
    <div>
      <Card fluid>
        <Card.Content>
          <Form className="flex gap-2 w-full">
            <Form.Field className="w-full">
              <label>Customer id</label>
              <Input value={customer_id} onChange={(e, data) => {
                setCustomerId(Number(data.value))
              }} />
            </Form.Field>
            <Form.Field className="w-full">
              <label>Receipt Id</label>
              <Input value={receipt_id} onChange={(e, data) => {
                setReceiptId(data.value ? data.value : null)
              }} />
            </Form.Field>
            <Form.Field className="w-full">
              <label>Status</label>
              <Select
                placeholder={"Select status"}
                fluid
                value={status}
                options={[
                  { text: "SUCCESS", value: IAPTranscationStatus.SUCCESS },
                  { text: "REFUNDED", value: IAPTranscationStatus.REFUNDED },
                  { text: "PROCESSING", value: IAPTranscationStatus.PROCESSING },
                ].map((i) => {
                  return i;
                })}
                onChange={(evt: any, val: any) => {
                  setStatus(val.value);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Action</label>
              <Button
                color="blue"
                icon="search"
                content="Search"
                labelPosition="left"
                onClick={() => loadData()}
              />
            </Form.Field>
          </Form>
        </Card.Content>

        <Card.Content>
          <Table celled sortable size="small">
            <Table.Header>
              {header.map((labelTable: any) => {
                return <Table.HeaderCell>{labelTable}</Table.HeaderCell>;
              })}
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {data.map((i: any) => {
                return (
                  <Table.Row>
                    {header.map((key: string) => {
                      if (key === 'method') {
                        return (
                          <Table.Cell>{IAPMethod[i[key]]}</Table.Cell>
                        )
                      }
                      if (key === 'product') {
                        return (
                          <Table.Cell>{IAPProduct[i[key]]}</Table.Cell>
                        )
                      }
                      if (key === 'status') {
                        return (
                          <Table.Cell>{IAPTranscationStatus[i[key]]}</Table.Cell>
                        )
                      }
                      if (key === 'is_processed') {
                        return (
                          <Table.Cell>{[i.is_processed].toString()}</Table.Cell>
                        )
                      }
                      return (
                        <Table.Cell>{i[key]}</Table.Cell>
                      )
                    })}
                    <div className="flex gap-3">
                      {
                        i.status === IAPTranscationStatus.PROCESSING && <Table.Cell>
                          <Button onClick={async () => {
                            await retryIAP(i.receipt_id)
                          }}>Retry</Button>
                        </Table.Cell>
                      }
                      {
                        i.revoked_amount < i.total_revoke_amount && <Table.Cell>
                          <Button onClick={async () => {
                            await continueRevoke(i.id)
                          }}>Continue revoke</Button>
                        </Table.Cell>
                      }
                    </div>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
          <Pagination
            activePage={page}
            onPageChange={(page: any, rs: any) => {
              if (page < 1) {
                setPage(1)
              } else if (page > Math.ceil(total / pageSize)) {
                setPage(Math.ceil(total / pageSize))
              } else {
                setPage(rs.activePage)
              }
            }}
            size="mini"
            totalPages={Math.ceil(total / pageSize)}
          />
        </Card.Content>
      </Card>
    </div>
  );
}
