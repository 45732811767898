import { DatePicker } from "components";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Table,
} from "semantic-ui-react";
import { api } from "services";

export default function ReportMongenWheel() {
  const [dateFrom, setDateFrom] = useState(
    dayjs("02/02/2022").startOf("day").toDate()
  );
  const [dateTo, setDateTo] = useState(dayjs().add(1, "day").toDate());
  const [data, setData] = useState<any>([])
  const [type, setType] = useState<any>("all")
  const [customer_id, setCustomerId] = useState<any>(null)

  async function loadData() {
    let rs = await api.post("/operation/get-wheel-analytics", {
      dateFrom: dateFrom,
      dateTo: dateTo,
      view: type,
      customer_id
    });
    if (!rs) return;
    setData(rs)
  }

  async function exportData() {
    let rs = await api.post("/operation/get-wheel-analytics", {
      dateFrom: dateFrom,
      dateTo: dateTo,
      view: type,
      customer_id
    });
    if (!rs) return;
    setData(rs)

    let keys = Object.keys(rs[0]).map((key) => key);
    let csvContent = "data:text/csv;charset=utf-8," + keys.join(",") + "\n";
    rs.map((data: any) => {
      let t = keys.map((key) => data[key]).join(',') + '\n';
      csvContent += t;
    })

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "lucky_wheel_report.csv");
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "my_data.csv".
  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <div>
      <Card fluid>
        <Card.Content>
          <Form className="flex gap-2 w-full">
            <Form.Field className="w-full">
              <label>From</label>
              <DatePicker
                todayButton="Today"
                selected={dateFrom}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateFrom(null);
                  } else {
                    setDateFrom(dayjs(val).endOf("day").toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>To</label>
              <DatePicker
                todayButton="Today"
                selected={dateTo}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateTo(null);
                  } else {
                    setDateTo(dayjs(val).endOf("day").toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>View Mode</label>
              <Select
                placeholder={"Select type"}
                fluid
                value={type}
                options={[
                  { label: "Date", value: "day" },
                  { label: "Month", value: "month" },
                  { label: "All", value: "all" },
                ].map((i) => {
                  return { text: i.label, value: i.value };
                })}
                onChange={(evt: any, val: any) => {
                  setType(val.value);
                }}
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>CustomerID</label>
              <Input
                className="form-control"
                type="text"
                onChange={(evt: any) => {
                  setCustomerId(Number(evt.target.value))
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Action</label>
              <Button
                color="blue"
                icon="search"
                content="Search"
                labelPosition="left"
                onClick={() => loadData()}
              />
            </Form.Field>
            <Form.Field>
              <label>Export CSV</label>
              <Button
                color="green"
                icon="download"
                content="Export"
                labelPosition="left"
                onClick={() => exportData()}
              />
            </Form.Field>
          </Form>
        </Card.Content>

        <Card.Content>
          <div className="flex gap-2">
            {data?.length ? <Table>
              <Table.Header>
                {Object.keys(data[0]).map((key) => {
                  return (
                    <Table.HeaderCell>{key}</Table.HeaderCell>
                  )
                })}
              </Table.Header>
              <Table.Body>
                {data.map((rowData: any) => {
                  return (
                    <Table.Row>
                      {
                        Object.keys(data[0]).map((key) => {
                          return (
                            <Table.Cell>{rowData[key]}</Table.Cell>
                          )
                        })
                      }
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table> :
              <div>
                No data
              </div>
            }
          </div>
        </Card.Content>
      </Card>
    </div>
  );
}
