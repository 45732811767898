import { DatePicker } from "components";
import dayjs from "dayjs";
import { useState } from "react";
import { Button, Card, Form, Table } from "semantic-ui-react";
import { api } from "services";
import BasicChart from "./basic-chart";
import saveAs from "file-saver";

export default function AssetExchangeChart(props: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState(
    dayjs().add(-1, "week").startOf("day").toDate()
  );
  const [dateTo, setDateTo] = useState(dayjs().add(1, "day").toDate());
  const [data, setData] = useState(null);
  async function loadData() {
    setLoading(true);
    try {
      let rs = await api.post("/operation/get-user-asset-exchange-report", {
        dateFrom,
        dateTo,
      });
      setData(rs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  const download = async () => {
    setLoading(true);
    let result: any = [];
    let rs = await api.post("/operation/get-user-asset-exchange-report", {
      dateFrom,
      dateTo,
    });
    result = result.concat([rs?.labels]);
    result = result.concat(
      Object.keys(data?.val).map((key) => {
        return [key].concat(
          data?.columns?.map((k: any) => data?.val[key][k] ?? 0)
        );
      })
    );
    let text = result.map((s: string[]) => s.join(",")).join("\n");
    var blob = new Blob([text], {
      type: "text/plain;charset=utf-8;",
    });
    saveAs(blob, "report.csv");
    setLoading(false);
  };
  return (
    <div>
      <BasicChart
        {...props}
        url="/operation/get-asset-exchange-report"
        chartType="line"
        source="analytic"
      />

      <Card fluid>
        <Card.Content>
          <Form className="flex gap-2 w-full">
            <Form.Field className="w-full">
              <label>From</label>
              <DatePicker
                todayButton="Today"
                selected={dateFrom}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateFrom(null);
                  } else {
                    setDateFrom(dayjs(val).startOf("day").toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>To</label>
              <DatePicker
                todayButton="Today"
                selected={dateTo}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateTo(null);
                  } else {
                    setDateTo(dayjs(val).endOf("day").toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </Form.Field>
            <div>
              <Form.Field>
                <label>Action</label>
                <Button
                  loading={loading}
                  color="blue"
                  icon="search"
                  content="Search"
                  labelPosition="left"
                  onClick={() => loadData()}
                />
              </Form.Field>
              <Form.Field>
                <Button
                  loading={loading}
                  color="teal"
                  icon="download"
                  content="Download CSV"
                  labelPosition="left"
                  onClick={download}
                />
              </Form.Field>
            </div>
          </Form>

          <Table>
            <Table.Header>
              {data?.labels?.map((labelTable: any) => {
                return <Table.HeaderCell>{labelTable}</Table.HeaderCell>;
              })}
            </Table.Header>
            {data?.val &&
              Object.keys(data?.val).map((key) => {
                return (
                  <Table.Body>
                    <Table.Cell>{key}</Table.Cell>
                    {data?.columns?.map((k: any) => (
                      <Table.Cell>{data?.val[key][k] ?? 0}</Table.Cell>
                    ))}
                  </Table.Body>
                );
              })}
          </Table>
        </Card.Content>
      </Card>
    </div>
  );
}
