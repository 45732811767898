/** @format */

import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
//@ts-ignore
import { api } from "services";
import dayjs from "dayjs";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Select,
  Statistic,
} from "semantic-ui-react";
import { DatePicker, Enum } from "components";
import _ from "lodash";
const RESOURCES = ["MAG", "MSTR", "FOOD"];
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);
export default function LoginChart() {
  const [loading, setLoading] = useState<boolean>(true);
  const [earn, setEarn] = useState<{ options: any; data: any }>(null);
  const [dateFrom, setDateFrom] = useState(dayjs().add(-1, "week").toDate());
  const [dateTo, setDateTo] = useState(dayjs().add(1, "day").toDate());
  const [group, setGroup] = useState<string>("date");
  const [type, setType] = useState(-1);
  const [system, setSystem] = useState("-1");
  const [totalOnly, setTotalOnly] = useState<boolean>(false);
  async function loadData() {
    setLoading(true);
    try {
      let rs = await api.post("/operation/get-login-report", {
        dateFrom: dateFrom,
        dateTo: dateTo,
        groupBy: group,
        loginType: type,
        system,
        totalOnly,
      });
      setEarn(rs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div>
      <Card fluid>
        {earn !== null && (
          <Card.Content>
            <Line options={earn.options} data={earn.data} />
          </Card.Content>
        )}
        <Card.Content>
          <Form className="flex gap-2 w-full">
            <Form.Field className="w-full">
              <label>View by</label>
              <Select
                placeholder={"Select view time"}
                fluid
                value={group}
                options={["date", "week", "month"].map((i) => {
                  return { text: i, value: i };
                })}
                onChange={(evt: any, val: any) => {
                  setGroup(val.value);
                }}
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>From</label>
              <DatePicker
                todayButton="Today"
                selected={dateFrom}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateFrom(null);
                  } else {
                    setDateFrom(dayjs(val).startOf("day").toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>To</label>
              <DatePicker
                todayButton="Today"
                selected={dateTo}
                onChange={(val: Date) => {
                  if (!val) {
                    setDateTo(null);
                  } else {
                    setDateTo(dayjs(val).endOf("day").toDate());
                  }
                }}
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>Type</label>
              <Select
                placeholder={"Select type"}
                fluid
                value={type}
                options={[
                  { label: "BSC", value: 0 },
                  { label: "Terra Station", value: 1 },
                  { label: "User name", value: 2 },
                  { label: "Avalanche", value: 3 },
                  { label: "Facebook", value: 4 },
                  { label: "Google", value: 5 },
                  { label: "GoogleMobile", value: 6 },
                  { label: "SavedToken", value: 7 },
                  { label: "Okex", value: 8 },
                  { label: "All", value: -1 },
                ].map((i) => {
                  return { text: i.label, value: i.value };
                })}
                onChange={(evt: any, val: any) => {
                  setType(val.value);
                }}
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>System</label>
              <Select
                placeholder={"Select system"}
                fluid
                value={system}
                options={[
                  { label: "Game", value: "game" },
                  { label: "Support", value: "support" },
                  { label: "DAO", value: "dao" },
                  { label: "Scholar", value: "scholar" },
                  { label: "Admin", value: "admin" },
                  { label: "All", value: "-1" },
                ].map((i) => {
                  return { text: i.label, value: i.value };
                })}
                onChange={(evt: any, val: any) => {
                  setSystem(val.value);
                }}
              />
            </Form.Field>
            <Form.Field className="w-full">
              <label>Show Detail</label>
              <Checkbox
                checked={!totalOnly}
                toggle
                onChange={(evt: any, { checked }) => {
                  setTotalOnly(!checked);
                }}
              />
            </Form.Field>
            <div>
              <Form.Field>
                <label>Action</label>
                <Button
                  loading={loading}
                  color="blue"
                  icon="search"
                  content="Search"
                  labelPosition="left"
                  onClick={loadData}
                />
              </Form.Field>{" "}
              <Form.Field>
                <Button
                  loading={loading}
                  color="teal"
                  icon="download"
                  content="Download CSV"
                  labelPosition="left"
                  onClick={() => {
                    api.downloadReport(earn.data);
                  }}
                />
              </Form.Field>
            </div>
          </Form>
        </Card.Content>
      </Card>
    </div>
  );
}
