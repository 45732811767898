import { DatePicker, Loading } from "components";
import { AlertType, ConfigDataType } from "interfaces";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  TextArea,
} from "semantic-ui-react";
import { api, ui } from "services";

export default function SettingDaoConfig() {
  const { name } = useParams();
  const [config, setConfig] = useState<any>(null);
  const [isRedis, setIsRedis] = useState(false);
  const [stakeInfo, setStakeInfo] = useState<any>(null);
  const [daoStakeConfig, setDaoStakeConfig] = useState<any>({
    MstrSharedRevenueTimeRate: [0, 0, 0],
    MstrSharedRevenueInGameRate: 0,
    MagSharedRevenueInGameRate: 0,
    MagSharedRevenueMaxVaultRate: [0, 0, 0, 0, 0],
    MstrSharedRate: 0,
    MagSharedRate: 0,
    MagSharedRevenueMaxVaultRateTotal: 0,
  });
  useEffect(() => {
    async function loadConfig() {
      let host = isRedis ? '/operation/get-config-redis' : '/operation/api/get-config'
      let rs = await api.post(host, {
        where: { name: "DAO_STAKES_CONFIG" },
      });
      setConfig(rs.data[0]);
      if (rs.data[0].value === null) {
        ui.alert("This config is not setted in redis yet !", AlertType.Warning)
      }

      if (rs.data[0].value) {
        setDaoStakeConfig(JSON.parse(rs.data[0].value));
      }

      let rsStake = await api.post("/operation/get-dao-stake-pool", {});
      setStakeInfo(rsStake);
      console.log({ rsStake });
    }
    loadConfig();
  }, [name, isRedis]);
  async function update() {
    let configData = _.cloneDeep(daoStakeConfig);
    configData.MstrSharedRevenueTimeRate =
      configData.MstrSharedRevenueTimeRate.map((i: any) => Number(i));
    configData.MstrSharedRevenueInGameRate = Number(
      configData.MstrSharedRevenueInGameRate
    );
    configData.MagSharedRevenueInGameRate = Number(
      configData.MagSharedRevenueInGameRate
    );
    configData.MagSharedRevenueMaxVaultRate =
      configData.MagSharedRevenueMaxVaultRate.map((i: any) => Number(i));
    configData.MstrSharedRate = Number(configData.MstrSharedRate);
    configData.MagSharedRate = Number(configData.MagSharedRate);
    configData.MagSharedRevenueMaxVaultRateTotal = Number(
      configData.MagSharedRevenueMaxVaultRateTotal
    );

    let host = isRedis ? '/operation/update-config-redis' : '/operation/api/update-config'
    let rs = await api.post(host, {
      ...config,
      value: JSON.stringify(configData),
    });
    setConfig(rs);
    setDaoStakeConfig(JSON.parse(rs.value));
    ui.alert("Update success");
  }
  function handleChange(name: string, value: any) {
    let tmp = _.cloneDeep(daoStakeConfig);
    tmp[name] = value;
    setDaoStakeConfig(tmp);
  }
  let mstrSharedRevenueTimeData = ["6 Months", "9 Months", "12 Months"];
  let magSharedRevenueMaxVaultData = [
    "Common",
    "Uncommon",
    "Rare",
    "Epic",
    "Legend",
  ];
  return (
    <div>
      <div className="flex justify-between">
        <p className="text-3xl font-semibold">Edit DAO Stake Config {`${isRedis ? "(Redis)" : ""}`}</p>
        <div>
          <Button
            icon="exchange"
            content={isRedis ? "Convert to Database mode" : "Convert to Redis mode"}
            color={isRedis ? 'green' : 'yellow'}
            onClick={() => {
              setIsRedis(!isRedis)
            }}
            labelPosition="left"
          />
          <Button
            icon="save"
            content={isRedis ? "Save redis" : "Save"}
            primary
            onClick={() => {
              update();
            }}
            labelPosition="left"
          />
        </div>
      </div>
      <div className="flex mt-10">
        <div className="w-1/2">
          <Card fluid>
            <Card.Content>
              <Card.Header>MSTR </Card.Header>
            </Card.Content>
            <Card.Content>
              <div className="flex">
                <div className="w-1/3">
                  <p className="font-semibold mr-2">Pool Rate</p>
                  <Input
                    className="w-full"
                    value={daoStakeConfig?.MstrSharedRate}
                    placeholder="%"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange("MstrSharedRate", evt.target.value);
                    }}
                  />
                </div>
                <div className="w-1/3">
                  <p className="font-semibold mr-2">Revenue</p>
                  <Input
                    disabled
                    className="w-full"
                    value={stakeInfo?.mstrRevenue || 0}
                    placeholder="%"
                  />
                </div>
                <div className="w-1/3">
                  <p className="font-semibold mr-2">Total Pool</p>
                  <Input
                    className="w-full"
                    disabled
                    value={
                      ((stakeInfo?.mstrRevenue || 0) *
                        (daoStakeConfig?.MstrSharedRate || 0)) /
                      100
                    }
                    placeholder="%"
                  />
                </div>
              </div>
            </Card.Content>
          </Card>
        </div>
        <div className="w-1/2">
          <Card fluid>
            <Card.Content>
              <Card.Header>Unlock MAG </Card.Header>
            </Card.Content>
            <Card.Content>
              <div className="flex">
                <div className="w-1/3">
                  <p className="font-semibold mr-2">Pool Rate</p>
                  <Input
                    className="w-full"
                    value={daoStakeConfig?.MagSharedRate}
                    placeholder="%"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange("MagSharedRate", evt.target.value);
                    }}
                  />
                </div>
                <div className="w-1/3">
                  <p className="font-semibold mr-2">Revenue</p>
                  <Input
                    disabled
                    className="w-full"
                    value={stakeInfo?.unlockMagRevenue || 0}
                    placeholder="%"
                  />
                </div>
                <div className="w-1/3">
                  <p className="font-semibold mr-2">Total Pool</p>
                  <Input
                    className="w-full"
                    disabled
                    value={
                      ((stakeInfo?.unlockMagRevenue || 0) *
                        (daoStakeConfig?.MagSharedRate || 0)) /
                      100
                    }
                    placeholder="%"
                  />
                </div>
              </div>
            </Card.Content>
          </Card>
        </div>
      </div>
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <div className="flex justify-between">
              <p>MSTR in Game</p>
            </div>
          </Card.Header>
        </Card.Content>

        <Card.Content>
          <div className="flex">
            <div className="w-1/3">
              <p className="font-semibold mr-2">Rate</p>
              <Input
                className="w-full"
                value={daoStakeConfig?.MstrSharedRevenueInGameRate}
                placeholder="%"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange("MstrSharedRevenueInGameRate", evt.target.value);
                }}
              />
            </div>
            <div className="w-1/3">
              <p className="font-semibold mr-2">Pool</p>
              <Input
                className="w-full"
                value={
                  ((daoStakeConfig?.MstrSharedRevenueInGameRate || 0) *
                    (((stakeInfo?.mstrRevenue || 0) *
                      (daoStakeConfig?.MstrSharedRate || 0)) /
                      100)) /
                  100
                }
                placeholder="%"
                disabled
              />
            </div>
            <div className="w-1/3">
              <p className="font-semibold mr-2">Current Pool</p>
              <Input
                className="w-full"
                value={stakeInfo?.mstrInGamePool}
                disabled
                placeholder="%"
              />
            </div>
          </div>
        </Card.Content>
      </Card>

      <Card fluid>
        <Card.Content>
          <Card.Header>
            <div className="flex justify-between">
              <p>MSTR Share Revenue By Time</p>
            </div>
          </Card.Header>
        </Card.Content>

        <Card.Content>
          {mstrSharedRevenueTimeData.map((data, index) => {
            return (
              <div key={data} className="mt-8">
                <p className="font-semibold mr-2 text-xl">{data}</p>
                <div className="flex">
                  <div className="w-1/3">
                    <p className="font-semibold mr-2">Rate</p>
                    <Input
                      className="w-full"
                      value={
                        daoStakeConfig?.MstrSharedRevenueTimeRate?.[index]
                      }
                      placeholder="%"
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        const val = _.cloneDeep(
                          daoStakeConfig?.MstrSharedRevenueTimeRate
                        );
                        val[index] = evt.target.value;
                        handleChange("MstrSharedRevenueTimeRate", val);
                      }}
                    />
                  </div>
                  <div className="w-1/3">
                    <p className="font-semibold mr-2">Pool</p>
                    <Input
                      className="w-full"
                      value={
                        ((daoStakeConfig?.MstrSharedRevenueTimeRate?.[index] ||
                          0) *
                          (((stakeInfo?.mstrRevenue || 0) *
                            (daoStakeConfig?.MstrSharedRate || 0)) /
                            100)) /
                        100
                      }
                      placeholder="%"
                      disabled
                    />
                  </div>
                  <div className="w-1/3">
                    <p className="font-semibold mr-2">Current Pool</p>
                    <Input
                      className="w-full"
                      value={stakeInfo?.mstrTimePool?.[index]}
                      disabled
                      placeholder="%"
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <div className="mt-4"></div>
        </Card.Content>
      </Card>

      <Card fluid>
        <Card.Content>
          <Card.Header>
            <div className="flex justify-between">
              <p>MAG in Game</p>
            </div>
          </Card.Header>
        </Card.Content>

        <Card.Content>
          <div className="flex">
            <div className="w-1/3">
              <p className="font-semibold mr-2">Rate</p>
              <Input
                className="w-full"
                value={daoStakeConfig?.MagSharedRevenueInGameRate}
                placeholder="%"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange("MagSharedRevenueInGameRate", evt.target.value);
                }}
              />
            </div>
            <div className="w-1/3">
              <p className="font-semibold mr-2">Pool</p>
              <Input
                className="w-full"
                value={
                  ((daoStakeConfig?.MagSharedRevenueInGameRate || 0) *
                    (((stakeInfo?.unlockMagRevenue || 0) *
                      (daoStakeConfig?.MagSharedRate || 0)) /
                      100)) /
                  100
                }
                placeholder="%"
                disabled
              />
            </div>
            <div className="w-1/3">
              <p className="font-semibold mr-2">Current Pool</p>
              <Input
                className="w-full"
                value={stakeInfo?.magInGamePool}
                disabled
                placeholder="%"
              />
            </div>
          </div>
        </Card.Content>
      </Card>

      <Card fluid>
        <Card.Content>
          <Card.Header>
            <div className="flex justify-between">
              <p>MAG Vault</p>
            </div>
          </Card.Header>
          <div className="flex">
            <div className="w-1/3">
              <p className="font-semibold mr-2">Rate</p>
              <Input
                className="w-full"
                value={daoStakeConfig?.MagSharedRevenueMaxVaultRateTotal}
                placeholder="%"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(
                    "MagSharedRevenueMaxVaultRateTotal",
                    evt.target.value
                  );
                }}
              />
            </div>
            <div className="w-1/3">
              <p className="font-semibold mr-2">Pool</p>
              <Input
                className="w-full"
                value={
                  ((daoStakeConfig?.MagSharedRevenueMaxVaultRateTotal || 0) *
                    (((stakeInfo?.unlockMagRevenue || 0) *
                      (daoStakeConfig?.MagSharedRate || 0)) /
                      100)) /
                  100
                }
                placeholder="%"
                disabled
              />
            </div>
          </div>
        </Card.Content>

        <Card.Content>
          {magSharedRevenueMaxVaultData.map((data, index) => {
            return (
              <div key={data} className="mt-8">
                <p className="font-semibold mr-2 text-xl">{data}</p>
                <div className="flex">
                  <div className="w-1/3">
                    <p className="font-semibold mr-2">Rate</p>
                    <Input
                      className="w-full"
                      value={
                        daoStakeConfig?.MagSharedRevenueMaxVaultRate?.[index]
                      }
                      placeholder="%"
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        const val = _.cloneDeep(
                          daoStakeConfig?.MagSharedRevenueMaxVaultRate
                        );
                        val[index] = evt.target.value;
                        handleChange("MagSharedRevenueMaxVaultRate", val);
                      }}
                    />
                  </div>
                  <div className="w-1/3">
                    <p className="font-semibold mr-2">Pool</p>
                    <Input
                      className="w-full"
                      value={
                        ((daoStakeConfig?.MagSharedRevenueMaxVaultRate?.[
                          index
                        ] || 0) *
                          ((daoStakeConfig?.MagSharedRevenueMaxVaultRateTotal ||
                            0) *
                            (((stakeInfo?.unlockMagRevenue || 0) *
                              (daoStakeConfig?.MagSharedRate || 0)) /
                              100))) /
                        100 /
                        100
                      }
                      placeholder="%"
                      disabled
                    />
                  </div>
                  <div className="w-1/3">
                    <p className="font-semibold mr-2">Current Pool</p>
                    <Input
                      className="w-full"
                      value={stakeInfo?.magMaxVaultPool?.[index]}
                      disabled
                      placeholder="%"
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <div className="mt-4"></div>
        </Card.Content>
      </Card>
    </div>
  );
}
