/** @format */

import React, { useState, forwardRef, useImperativeHandle } from "react";
import { FormControl, SchemaControl, SchemaDataType } from "../interfaces";
import { useTranslation } from "react-i18next";
import dataServices from "../services/data";
import {
  DailyQuestRewardEditor,
  DatePicker,
  Entity,
  Enum,
  Upload,
} from "components";
import dayjs from "dayjs";
import {
  Accordion,
  Card,
  Checkbox,
  Form,
  Input,
  TextArea,
} from "semantic-ui-react";
import UploadImage from "./upload-image";
import RewardEditor from "./reward-editor";
import MultiLanguage from "./multi-language";
import Mail from "./mail";
import Tree from "./tree";
import RewardRarityEditor from "./reward-rarity-editor";
import BidEditor from "./bid-editor";
import LockFeature from "./lock-feature";
import UploadIdentity from "./upload-identity";
import Milestone from "./milestone";
import ListRewardEditor from "./list-reward-editor";
import BossInfoSchedule from "./boss-info-schedule";
import BossConditionConfig from "./boss-condition-config";
import BossMilestonesConfig from "./boss-milestones-config";
import BossConditionSelect from "./boss-condition-select";
import DecorationPatterns from "./decoration-patterns";
import TicketRewardRarityEditor from "./ticket-reward-rarity-editor";
import TopBattlefrontListRewardEditor from "./top-battlefront-list-reward-editor";
import PoolIcon from "./poolicon";
import BattlepassReward from "./battlepass-reward";
import ReferralSeasonInfo from "./referral-season-info";
import BattlepassRewardTopPlayer from "./battlepass-reward-top-player";
import ExchangePointFormula from "./exchange-point-formula";
import BetQuestion from "./bet-question";
import TopArenaListRewardEditor from "./top-arena-list-reward-editor";
import ArenaRoomTypeEditor from "./arena-room-type";
import TournamentClanRewards from "./tournament-clan-rewards";

interface FormViewProps {
  controls: FormControl[];
  showError?: boolean;
  onChange?: Function;
  value?: any;
  configAuction?: any;
}
function Schema(
  { controls, showError, value, onChange, configAuction }: FormViewProps,
  ref: any
) {
  useImperativeHandle(ref, () => ({
    validate: validate,
  }));
  const { t } = useTranslation();
  const [errors, setErrors] = useState<any>({});
  let schemas: any = {};
  controls.forEach((c) => {
    if (c.control === SchemaControl.Schema) {
      schemas[c.field] = dataServices.getFormByName(c.schemaName);
      schemas[c.field].ref = React.createRef();
    }
  });
  function validate(input: any) {
    let pass = true;
    const errors: { [key: string]: string } = {};
    controls.forEach((ctrl) => {
      if (ctrl.control === SchemaControl.Schema) {
        if (
          !schemas[ctrl.field]?.ref.current.validate(input[ctrl.field] || {})
        ) {
          pass = false;
        }
      } else {
        //@ts-ignore
        if (ctrl.required) {
          if (ctrl.multiple) {
            if (!(input[ctrl.field] && input[ctrl.field].length)) {
              errors[ctrl.field] = t("Required");
              pass = false;
            }
          } else {
            if (input[ctrl.field] === null || input[ctrl.field] === undefined) {
              errors[ctrl.field] = t("Required");
              pass = false;
            }
          }
        }
      }
    });
    setErrors(errors);
    return pass;
  }

  function handleChange(field: string, val: any) {
    let tmp = Object.assign({}, value);
    tmp[field] = val;
    validate(tmp);
    onChange(tmp);
  }

  return (
    <div className="block w-full bg-white">
      {showError && Object.keys(errors).length > 0 && (
        <div className="w-full rounded-md bg-primary-900 text-white mt-2 p-2">
          <p className="text-xl font-semibold">
            {t("Missing required fields")}:{" "}
          </p>
          {Object.keys(errors).map((key, index) => {
            let ctrl = controls.find((i) => i.field === key);
            return (
              <p className="text-sm" key={index}>
                {t(ctrl?.label)}
              </p>
            );
          })}
        </div>
      )}
      <Form>
        {controls.map((ctrl, index) => {
          let isShow = true;
          let condition = ctrl.show;
          condition &&
            Object.keys(condition).map((key: any) => {
              console.log(key);
              if (value[key] !== condition[key]) {
                isShow = false;
              }
            });

          if (!isShow) {
            return <></>;
          }

          switch (ctrl.control) {
            case SchemaControl.Tree:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <Tree
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.Image:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <UploadImage
                    value={value[ctrl.field]}
                    onChange={(url: string) => {
                      handleChange(ctrl.field, url);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.Upload:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <Upload
                    value={value[ctrl.field]}
                    onChange={(url: string) => {
                      handleChange(ctrl.field, url);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.TextArea:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <TextArea
                    placeholder={ctrl.placeholder}
                    fluid
                    type="text"
                    defaultValue={value[ctrl.field]}
                    onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                      handleChange(ctrl.field, evt.target.value);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.Date:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <DatePicker
                    isClearable
                    showTimeSelect
                    selected={
                      typeof value[ctrl.field] === "string"
                        ? new Date(value[ctrl.field])
                        : value[ctrl.field]
                    }
                    onChange={(val: Date) => {
                      if (!val) {
                        handleChange(ctrl.field, null);
                      } else {
                        handleChange(ctrl.field, dayjs(val).toDate());
                      }
                    }}
                    dateFormat="yyyy/MM/dd HH:mm"
                  />
                </Form.Field>
              );
            case SchemaControl.DateTime:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <DatePicker
                    isClearable
                    showTimeSelect
                    selected={
                      typeof value[ctrl.field] === "string"
                        ? new Date(value[ctrl.field])
                        : value[ctrl.field]
                    }
                    onChange={(val: Date) => {
                      if (!val) {
                        handleChange(ctrl.field, null);
                      } else {
                        handleChange(
                          ctrl.field,
                          configAuction
                            ? dayjs(val).valueOf()
                            : dayjs(val).toDate()
                        );
                      }
                    }}
                    dateFormat="yyyy/MM/dd HH:mm"
                  />
                </Form.Field>
              );
            case SchemaControl.TournamentClanRewards:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <TournamentClanRewards
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.RewardEdit:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <RewardEditor
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                    analytics={value["analytics"]}
                    length={ctrl.length}
                  />
                </Form.Field>
              );
            case SchemaControl.DailyQuestRewardEdit:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <DailyQuestRewardEditor
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.ListRewardEdit:
              return (
                <Form.Field required={ctrl.required}>
                  <Accordion
                    exclusive={false}
                    fluid
                    panels={[
                      {
                        key: ctrl.field,
                        title: {
                          content: (
                            <label className="text-center font-bold !text-xl cursor-pointer">
                              {t(ctrl.label)}{" "}
                              {ctrl.required ? (
                                <span className={"text-[red]"}>*</span>
                              ) : (
                                ""
                              )}
                            </label>
                          ),
                          key: ctrl.field,
                        },
                        content: {
                          content: (
                            <div className={`content`}>
                              <Card fluid>
                                <Card.Content>
                                  <ListRewardEditor
                                    subLabels={ctrl.subLabels}
                                    value={value[ctrl.field] || []}
                                    onChange={(val: any) => {
                                      handleChange(ctrl.field, val);
                                    }}
                                  />
                                </Card.Content>
                              </Card>
                            </div>
                          ),
                          className: "des-ml-1",
                          key: ctrl.field,
                        },
                      },
                    ]}
                  ></Accordion>
                </Form.Field>
              );
            case SchemaControl.TopBattlefrontListRewardEdit:
              return (
                <Form.Field required={ctrl.required}>
                  <Accordion
                    exclusive={false}
                    fluid
                    panels={[
                      {
                        key: ctrl.field,
                        title: {
                          content: (
                            <label className="text-center font-bold !text-xl cursor-pointer">
                              {t(ctrl.label)}{" "}
                              {ctrl.required ? (
                                <span className={"text-[red]"}>*</span>
                              ) : (
                                ""
                              )}
                            </label>
                          ),
                          key: ctrl.field,
                        },
                        content: {
                          content: (
                            <div className={`content`}>
                              <TopBattlefrontListRewardEditor
                                value={value[ctrl.field] || []}
                                onChange={(val: any) => {
                                  handleChange(ctrl.field, val);
                                }}
                              />
                            </div>
                          ),
                          className: "des-ml-1",
                          key: ctrl.field,
                        },
                      },
                    ]}
                  ></Accordion>
                </Form.Field>
              );
            case SchemaControl.ArenaRoomTypes:
              return (
                <Form.Field required={ctrl.required}>
                  <Accordion
                    exclusive={false}
                    fluid
                    panels={[
                      {
                        key: ctrl.field,
                        title: {
                          content: (
                            <label className="text-center font-bold !text-xl cursor-pointer">
                              {t(ctrl.label)}{" "}
                              {ctrl.required ? (
                                <span className={"text-[red]"}>*</span>
                              ) : (
                                ""
                              )}
                            </label>
                          ),
                          key: ctrl.field,
                        },
                        content: {
                          content: (
                            <div className={`content`}>
                              <ArenaRoomTypeEditor
                                value={value[ctrl.field] || []}
                                onChange={(val: any) => {
                                  handleChange(ctrl.field, val);
                                }}
                              />
                            </div>
                          ),
                          className: "des-ml-1",
                          key: ctrl.field,
                        },
                      },
                    ]}
                  ></Accordion>
                </Form.Field>
              );
            case SchemaControl.TopArenaListRewardEdit:
              return (
                <Form.Field required={ctrl.required}>
                  <Accordion
                    exclusive={false}
                    fluid
                    panels={[
                      {
                        key: ctrl.field,
                        title: {
                          content: (
                            <label className="text-center font-bold !text-xl cursor-pointer">
                              {t(ctrl.label)}{" "}
                              {ctrl.required ? (
                                <span className={"text-[red]"}>*</span>
                              ) : (
                                ""
                              )}
                            </label>
                          ),
                          key: ctrl.field,
                        },
                        content: {
                          content: (
                            <div className={`content`}>
                              <TopArenaListRewardEditor
                                value={value?.[ctrl.field] || []}
                                onChange={(val: any) => {
                                  handleChange(ctrl.field, val);
                                }}
                              />
                            </div>
                          ),
                          className: "des-ml-1",
                          key: ctrl.field,
                        },
                      },
                    ]}
                  ></Accordion>
                </Form.Field>
              );
            case SchemaControl.Battlepassreward:
              return (
                <Form.Field required={ctrl.required}>
                  <Accordion
                    exclusive={false}
                    fluid
                    panels={[
                      {
                        key: ctrl.field,
                        title: {
                          content: (
                            <label className="text-center font-bold !text-xl cursor-pointer">
                              {t(ctrl.label)}{" "}
                              {ctrl.required ? (
                                <span className={"text-[red]"}>*</span>
                              ) : (
                                ""
                              )}
                            </label>
                          ),
                          key: ctrl.field,
                        },
                        content: {
                          content: (
                            <div className={`content`}>
                              <BattlepassReward
                                value={value[ctrl.field] || []}
                                onChange={(val: any) => {
                                  handleChange(ctrl.field, val);
                                }}
                              />
                            </div>
                          ),
                          className: "des-ml-1",
                          key: ctrl.field,
                        },
                      },
                    ]}
                  ></Accordion>
                </Form.Field>
              );
            case SchemaControl.BattlepassRewardTopPlayer:
              return (
                <Form.Field required={ctrl.required}>
                  <Accordion
                    exclusive={false}
                    fluid
                    panels={[
                      {
                        key: ctrl.field,
                        title: {
                          content: (
                            <label className="text-center font-bold !text-xl cursor-pointer">
                              {t(ctrl.label)}{" "}
                              {ctrl.required ? (
                                <span className={"text-[red]"}>*</span>
                              ) : (
                                ""
                              )}
                            </label>
                          ),
                          key: ctrl.field,
                        },
                        content: {
                          content: (
                            <div className={`content`}>
                              <BattlepassRewardTopPlayer
                                value={value[ctrl.field] || {}}
                                detail={value}
                                onChange={(val: any) => {
                                  handleChange(ctrl.field, val);
                                }}
                              />
                            </div>
                          ),
                          className: "des-ml-1",
                          key: ctrl.field,
                        },
                      },
                    ]}
                  ></Accordion>
                </Form.Field>
              );
            case SchemaControl.BossInfoSchedule:
              return (
                <Form.Field required={ctrl.required}>
                  <label className="text-center !text-xl">
                    {t(ctrl.label)}
                  </label>
                  <BossInfoSchedule
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.ExchangePointFormula:
              return (
                <Form.Field required={ctrl.required}>
                  <label className="text-center !text-xl">
                    {t(ctrl.label)}
                  </label>
                  <ExchangePointFormula
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      console.log({ val, a: ctrl.field });
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.BetQuestion:
              return (
                <Form.Field required={ctrl.required}>
                  <label className="text-center !text-xl">
                    {t(ctrl.label)}
                  </label>
                  <BetQuestion
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      console.log({ val, a: ctrl.field });
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.BossConditionConfig:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <BossConditionConfig
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.BossMilestonesConfig:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <BossMilestonesConfig
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.Decorationpatterns:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <DecorationPatterns
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.BossConditionSelect:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <BossConditionSelect
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.LockFeature:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <LockFeature
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.UploadIdentity:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <UploadIdentity
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.BidEdit:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <BidEditor
                    value={
                      value["item_data"]
                        ? [value["item_data"]]
                        : value[ctrl.field] || []
                    }
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.Mail:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <Mail
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.MultiLanguage:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <MultiLanguage
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.Milestone:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <Milestone
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.Schema:
              return (
                <div className="w-full float-left p-2 relative" key={index}>
                  <label>{t(ctrl.label)}</label>
                  <SchemaComponent
                    value={value[ctrl.field] || {}}
                    showError={showError}
                    controls={schemas[ctrl.field].controls}
                    ref={schemas[ctrl.field].ref}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </div>
              );
            case SchemaControl.RewardRarity:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <RewardRarityEditor
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.TicketRewardRarity:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <TicketRewardRarityEditor
                    value={value[ctrl.field] || []}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.PoolIcon:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <PoolIcon
                    placeholder={ctrl.placeholder}
                    value={value[ctrl.field]}
                    enumName={ctrl.enum}
                    multiple={ctrl.multiple}
                    onChange={(val) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.CheckList:
              return (
                <div className="w-full float-left p-2 relative" key={index}>
                  <label>{t(ctrl.label)}</label>
                  <p>Checklist</p>
                </div>
              );
            case SchemaControl.Checkbox:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <Checkbox
                    checked={value[ctrl.field]}
                    toggle
                    onChange={(evt: any, { checked }) => {
                      handleChange(ctrl.field, checked);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.Entity:
              return (
                <Form.Field>
                  <label>{t(ctrl.label)}</label>
                  <Entity
                    displayField={ctrl.displayField || "name"}
                    disabled={ctrl.disabled}
                    value={value[ctrl.field]}
                    values={value[ctrl.field]}
                    gridName={ctrl.gridName}
                    multiple={false}
                    onChange={(val: any) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.Enum:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <Enum
                    placeholder={ctrl.placeholder}
                    value={value[ctrl.field]}
                    enumName={ctrl.enum}
                    multiple={ctrl.multiple}
                    onChange={(val) => {
                      handleChange(ctrl.field, val);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.Text:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <p className="text-gray-400 text-sm m-0 font-light">
                    {t(ctrl.description)}
                  </p>
                  <Input
                    fluid
                    maxLength={ctrl.maxLength ? ctrl.maxLength : null}
                    placeholder={ctrl.placeholder}
                    type="text"
                    value={value[ctrl.field]}
                    defaultValue={value[ctrl.field]}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      if (ctrl.dataType === SchemaDataType.Number) {
                        handleChange(ctrl.field, Number(evt.target.value));
                      } else {
                        handleChange(ctrl.field, evt.target.value);
                      }
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.Number:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <p className="text-gray-400 text-sm m-0 font-light">
                    {t(ctrl.description)}
                  </p>
                  <Input
                    fluid
                    placeholder={ctrl.placeholder}
                    type="number"
                    min={value[ctrl.min]}
                    max={value[ctrl.max]}
                    value={value[ctrl.field]}
                    defaultValue={value[ctrl.field]}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      if (ctrl.dataType === SchemaDataType.Number) {
                        handleChange(ctrl.field, Number(evt.target.value));
                      } else {
                        handleChange(ctrl.field, evt.target.value);
                      }
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.Password:
              return (
                <Form.Field required={ctrl.required}>
                  <label>{t(ctrl.label)}</label>
                  <Input
                    placeholder={ctrl.placeholder}
                    fluid
                    type="password"
                    value={value[ctrl.field]}
                    defaultValue={value[ctrl.field]}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(ctrl.field, evt.target.value);
                    }}
                  />
                </Form.Field>
              );
            case SchemaControl.ReferralEventInfo:
              return (
                <ReferralSeasonInfo
                  value={value[ctrl.field] || []}
                  onChange={(val: any) => {
                    handleChange(ctrl.field, val);
                  }}
                />
              );
            default:
              return <p key={index}>Unknown</p>;
          }
        })}
      </Form>
    </div>
  );
}
const SchemaComponent = forwardRef(Schema);
export default SchemaComponent;
