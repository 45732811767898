/** @format */

import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Form, Input, Select, TextArea } from "semantic-ui-react";
import { api } from "services";
export default function UploadGameBuild() {
  const [status, setStatus] = useState<
    "pending" | "loading" | "success" | "fail"
  >("pending");
  const [version, setVersion] = useState<string>("");
  const [files, setFiles] = useState<string[]>([]);
  let [raw, setRaw] = useState<any[]>([]);
  const [type, setType] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const onDrop = (acceptedFiles: any[]) => {
    if (!acceptedFiles.length) return;
    console.log({ acceptedFiles });
    setFiles(acceptedFiles.map((i: any) => i.name));
    setRaw(acceptedFiles);
  };

  async function uploadFileBuild() {
    if (!(version && type && raw.length === 4 && description)) {
      return alert("Version and type not set");
    }
    try {
      setStatus("loading");
      var formdata = new FormData();
      raw.forEach((f: any) => {
        formdata.append("files", f);
      });
      console.log("raw", raw);
      let input = {
        version,
        type,
        description,
        files: raw.map((i: any) => i.name),
      };
      // console.log("input ", input);
      let response: any = await api.post(
        `/operation/sign-game-build-url`,
        input
      );
      let rs = await Promise.all(
        raw.map((f: any, index: number) =>
          api.uploadGameBuild(response.urls[index], f)
        )
      );
      console.log("url", response.urls);
      setStatus("success");
    } catch (error) {
      console.log({ error });
      setStatus("fail");
    }
  }
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div>
      <p className="text-2xl font-semibold">Upload game build</p>
      <Form>
        <p className=" mt-4">Version</p>
        <Input
          type="text"
          onChange={(evt: any) => {
            setVersion(evt.target.value);
          }}
        />
        <p className=" mt-4">Description</p>
        <Form.Field>
          <TextArea
            type="text"
            onChange={(evt: any) => {
              setDescription(evt.target.value);
            }}
          />
        </Form.Field>
        <p className=" mt-4">Type</p>
        <Select
          options={[
            { text: "Lite", value: "lite" },
            { text: "Full", value: "full" },
            { text: "MonsTV", value: "monstv" },
          ]}
          onChange={(evt: any, val: any) => {
            setType(val.value);
          }}
        />
      </Form>

      <p className=" mt-4">Game build files</p>
      <div className="relative w-44 cursor-pointer" {...getRootProps()}>
        <input {...getInputProps()} />
        <Button icon="file" content="Select file" />
      </div>
      <div className="text-2xl font-semibold mt-4">
        {status === "pending" && <p>Waiting for file</p>}
        {status === "loading" && <p>Uploading</p>}
        {status === "success" && <p>Success</p>}
        {status === "fail" && <p>Fail</p>}
      </div>
      <div>
        {files.map((f: string) => (
          <p>{f}</p>
        ))}
      </div>
      <Button
        loading={status === "loading"}
        icon="upload"
        content="Submit"
        primary
        onClick={() => {
          uploadFileBuild();
        }}
      />
    </div>
  );
}
